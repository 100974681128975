interface InputStructure {
  Name: string;
  Content: {
    Key: string;
    Value: string[];
  }[];
}

export const getColumns = (input: InputStructure) => {
  const columns = input.Content.map((contentItem) => ({
    Header: contentItem.Key,
    accessor: contentItem.Key,
  }));

  return [
    {
      Header: input.Name,
      columns,
    },
  ];
};
