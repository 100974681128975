import { Core } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';

export const createConduitTool = (instanceCore: typeof Core) => {
  const { Annotations, Tools, documentViewer, annotationManager } = instanceCore;

  const ConduitAnnotation = Annotations.CustomAnnotation.createFromClass(
    ANNOTATION_TYPES.CONDUIT,
    Annotations.PolylineAnnotation,
  );

  const ConduitCreateTool = new Tools.GenericAnnotationCreateTool(
    documentViewer,
    ConduitAnnotation,
  );

  Annotations.setCustomDrawHandler(
    ConduitAnnotation,
    function (ctx, pageMatrix, rotation, options) {
      // @ts-ignore
      options.annotation.elementType = ANNOTATION_TYPES.CONDUIT;
      options.originalDraw(ctx, pageMatrix, rotation);
    },
  );

  annotationManager.registerAnnotationType(
    ConduitAnnotation.prototype.elementName,
    ConduitAnnotation,
  );

  const defaults = {
    StrokeColor: new Annotations.Color(139, 0, 0, 1),
    FillColor: new Annotations.Color(0, 0, 0, 1),
    Opacity: 1,
    StrokeThickness: 2,
  };

  ConduitCreateTool.defaults = defaults;

  const originalMouseLeftDown = instanceCore.Tools.PolylineCreateTool.prototype.mouseLeftDown;
  const originalMouseLeftUp = instanceCore.Tools.PolylineCreateTool.prototype.mouseLeftUp;
  const originalMouseMove = instanceCore.Tools.PolylineCreateTool.prototype.mouseMove;
  const originalMouseDbClick = instanceCore.Tools.PolylineCreateTool.prototype.mouseDoubleClick;
  const originalMouseRightDown = instanceCore.Tools.PolylineCreateTool.prototype.mouseRightDown;

  // Temporary solution
  ConduitCreateTool.mouseLeftDown = function () {
    // @ts-ignore
    originalMouseLeftDown.apply(this, arguments);
  };

  ConduitCreateTool.mouseLeftUp = function () {
    // @ts-ignore
    originalMouseLeftUp.apply(this, arguments);
  };

  ConduitCreateTool.mouseMove = function () {
    // @ts-ignore
    originalMouseMove.apply(this, arguments);
  };

  ConduitCreateTool.mouseDoubleClick = function () {
    // @ts-ignore
    originalMouseDbClick.apply(this, arguments);
  };

  ConduitCreateTool.mouseRightDown = function () {
    // @ts-ignore
    originalMouseRightDown.apply(this, arguments);
  };

  return ConduitCreateTool;
};
