import { TableLineIcon } from 'assets/icons';

export const TABLE_DATA = {
  headers: [
    { title: '', icon: <TableLineIcon /> },
    { title: 'Circuit symbol' },
    { title: 'Conductors (1 PH. 2W) with ground' },
    { title: 'Conduit size' },
    { title: 'Conductors (1 OR, 3 PH, 3 WIRE) with ground' },
    { title: 'Conduit size' },
    { title: 'Conductors (3 PH, 4 WIRE) with ground' },
    { title: 'Conduit size' },
    { title: 'Overcurrent rating' },
  ],
  rows: [
    {
      cells: [
        { value: '' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
      ],
    },
    {
      cells: [
        { value: '' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
      ],
    },
    {
      cells: [
        { value: '' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
      ],
    },
    {
      cells: [
        { value: '' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
        { value: 'Text' },
      ],
    },
  ],
};
