import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EMPTY_DOC } from 'constants/apryse';
import { parseBoolean } from 'utils/parse-boolean';

import { Annotation, AnnotationsState } from '../../types/redux';

const INITIAL_STATE = {
  selectedAnnotations: {},
  isAnnotationsListOpen: parseBoolean(process.env.REACT_APP_BOTTOM_BAR_DEFAULT_OPEN) ?? true,
  isAnnotationDetailsOpen: parseBoolean(process.env.REACT_APP_SIDE_BAR_DEFAULT_OPEN) ?? true,
  annotations: EMPTY_DOC,
};

export const annotationSlice = createSlice({
  name: 'annotations',
  initialState: INITIAL_STATE,
  reducers: {
    selectAnnotation(
      state: AnnotationsState,
      action: PayloadAction<{ documentId: number; annotationIds: Annotation['id'][] }>,
    ) {
      const annotations = state.annotations[action.payload.documentId].filter((annotation) =>
        action.payload.annotationIds.includes(annotation.id),
      );

      if (state.selectedAnnotations[action.payload.documentId]) {
        state.selectedAnnotations[action.payload.documentId] = [
          ...state.selectedAnnotations[action.payload.documentId],
          ...annotations,
        ];
      } else {
        state.selectedAnnotations[action.payload.documentId] = annotations;
      }
    },
    deselectAnnotation(
      state: AnnotationsState,
      action: PayloadAction<{ documentId: number; annotationIds: Annotation['id'][] }>,
    ) {
      if (!action.payload.annotationIds) {
        state.selectedAnnotations[action.payload.documentId] = [];
      } else {
        const annotationsState = state.selectedAnnotations[action.payload.documentId].filter(
          (annotation) => !action.payload.annotationIds.includes(annotation.id),
        );

        state.selectedAnnotations[action.payload.documentId] = annotationsState;
      }
    },
    toggleAnnotationVisibility(
      state: AnnotationsState,
      action: PayloadAction<{
        documentId: number;
        annotationId: Annotation['id'];
        _isVisible?: Annotation['_isVisible'];
      }>,
    ) {
      const annotationToToggle = state.annotations[action.payload.documentId].find(
        (annotation) => annotation.id === action.payload.annotationId,
      );

      if (annotationToToggle) {
        if (typeof action.payload._isVisible === 'boolean')
          annotationToToggle._isVisible = action.payload._isVisible;
        else {
          annotationToToggle._isVisible = !annotationToToggle._isVisible;
        }
      }
    },
    setAnnotations(
      state: AnnotationsState,
      action: PayloadAction<{ documentId: number; annotations: Annotation[] }>,
    ) {
      state.annotations[action.payload.documentId] = action.payload.annotations;
    },
    addAnnotations(
      state: AnnotationsState,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<{ documentId: number; annotations: any[] }>,
    ) {
      action.payload.annotations.forEach((annotation) => {
        state.annotations[action.payload.documentId].push(annotation);
      });
    },
    deleteAnnotations(
      state: AnnotationsState,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<{ documentId: number; annotations: any[] }>,
    ) {
      const annotationsToDelete = action.payload.annotations.map((annotation) => {
        return annotation.Id || annotation.id;
      });
      const filterById = (annotation: Annotation) => {
        if (annotationsToDelete.includes(annotation.id)) {
          return false;
        }
        return true;
      };
      const annotationsState = state.annotations[action.payload.documentId].filter(filterById);
      state.annotations[action.payload.documentId] = annotationsState;
    },
    modifyAnnotations(
      state: AnnotationsState,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<{ documentId: number; annotations: any[] }>,
    ) {
      action.payload.annotations.forEach((annotation) => {
        const annotationToModify = state.annotations[action.payload.documentId].find(
          (oldAnnotation: Annotation) => [annotation.Id, annotation.id].includes(oldAnnotation.id),
        ) as Annotation;
        if (typeof annotation.isLocked === 'boolean')
          annotationToModify._isLocked = annotation.isLocked;
        if (typeof annotation._isVisible === 'boolean')
          annotationToModify._isVisible = annotation._isVisible;
        if (annotation.pathPoints) {
          annotationToModify.pathPoints = annotation.pathPoints;
        }
        annotationToModify.data = { ...annotationToModify.data, ...annotation.data };
      });
    },
    setAnnotationsListOpen(
      state: AnnotationsState,
      action: PayloadAction<Pick<AnnotationsState, 'isAnnotationsListOpen'>>,
    ) {
      state.isAnnotationsListOpen = action.payload.isAnnotationsListOpen;
    },
    setAnnotationDetailsOpen(
      state: AnnotationsState,
      action: PayloadAction<Pick<AnnotationsState, 'isAnnotationDetailsOpen'>>,
    ) {
      state.isAnnotationDetailsOpen = action.payload.isAnnotationDetailsOpen;
    },
  },
});

export const annotationActions = annotationSlice.actions;
