export const styles = {
  exportMenu: {
    height: '36px',
    borderWidth: '1px',
    minWidth: 'unset',
    padding: '0px 9px',
    marginLeft: '36px',
    '&[data-active]': {
      background: 'transparent',
    },
  },
};
