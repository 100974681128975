import { pxToRem } from 'utils';

export const styles = {
  inputGroup: {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    background: 'gray.100',
    width: '172px',
    borderRadius: pxToRem(6),
    cursor: 'pointer',
  },
  input: {
    border: 'none',
    paddingLeft: '34px',
    color: 'gray.500',
    fontWeight: 500,
    fontSize: '10.5px',
    h: '36px',
  },
};
