import { ChakraProps } from '@chakra-ui/react';
import type { Color } from 'components/color-picker';
import ColorPicker from 'components/color-picker';
import { useFormContext } from 'react-hook-form';

interface ColorPickerRHFProps {
  name: string;
  isAnnotations?: boolean;
  wrapperProps?: ChakraProps;
  disabled?: boolean;
  onBlur?: () => void;
}

const ColorPickerRHF = ({ name, isAnnotations, ...rest }: ColorPickerRHFProps) => {
  const { watch, setValue } = useFormContext();

  const value = watch(name);

  if (value.length === 0) {
    setValue(name, { color: '#FF0000', alpha: 1 });
  }

  const handleChange = (newValue: Color) => setValue(name, newValue);

  return (
    <ColorPicker
      value={value}
      onColorChange={handleChange}
      isAnnotations={isAnnotations}
      {...rest}
    />
  );
};

export default ColorPickerRHF;
