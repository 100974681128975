import { BORDER_DASH_STYLES, WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';
import { GroupBase, StylesConfig } from 'react-select';

/* ElementInfoBlock styles */
export const styles = {
  elementInfoWrapper: {
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: '18px',
    ...WEBKIT_SCROLLBAR_STYLES,
  },
  headerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  scrollWrapper: {
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '4px',
    },
  },
  innerScrollWrapper: {
    flexDirection: 'column',
  },
  elementInfoTitleText: {
    fontSize: '13.5px',
    lineHeight: '93%',
    fontWeight: 600,
    color: 'gray.400',
    whiteSpace: 'nowrap',
  },
  textFieldLabel: {
    fontSize: '10.5px',
    fontWeight: 400,
    lineHeight: '16.5px',
    color: 'gray.400',
    marginBottom: '3px',
    marginTop: '9px',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
  customColumnFieldLabel: {
    display: 'inline',
    fontSize: '10.5px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  annotationMessageWrapper: {
    h: 'calc(100% - 70.5px)',
    marginTop: '31.5px',
    ...BORDER_DASH_STYLES,
    borderRadius: '6px',
    overflow: 'hidden',
  },
  annotationMessageContainer: {
    flexDirection: 'column',
    h: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 33px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16.5px',
    color: 'gray.500',
    svg: {
      minW: '27.75px',
      minH: '27.75px',
      marginBottom: '15.75px',
    },
  },
  selectWrapper: {
    height: '23.25px',
  },
};

export const LAYER_SELECT_STYLES: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  control: (base) => ({
    ...base,
    height: '23.25px',
    borderColor: '#AEADBC',
    borderRadius: '6px',
  }),
  container: (base) => ({
    ...base,
    flexGrow: 1,
    maxWidth: '184.5px',
    width: '100%',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '7.5px',
    fontWeight: '400',
    marginTop: 2,
    color: '#B9B9C3',
  }),
};
