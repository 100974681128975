import { ANNOTATION_TOOLS_WITH_KEY_LISTENER } from 'constants/apryse';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext, useEffect } from 'react';

export const useFinishPolylineOnEnterListener = () => {
  const { instance } = useContext(WebviewerContext);

  const handler = useCallback(
    (e: KeyboardEvent) => {
      if (instance) {
        const { documentViewer } = instance.Core;

        if (e.code === 'Enter') {
          const activeTool = documentViewer.getToolMode();

          if (
            ANNOTATION_TOOLS_WITH_KEY_LISTENER.some((tool) =>
              (activeTool.name as string).includes(tool),
            )
          ) {
            (activeTool as any).finish?.();
          }
        }
      }
    },
    [instance],
  );

  useEffect(() => {
    if (instance) {
      instance.Core.documentViewer.addEventListener('keyDown', handler);
    }

    return () => {
      if (instance) {
        instance.Core.documentViewer.removeEventListener('keyDown', handler);
      }
    };
  }, [instance, handler]);
};
