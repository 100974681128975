import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';

export const styles = {
  scrollbar: WEBKIT_SCROLLBAR_STYLES,
  icon: {
    '&>span': {
      h: 'full',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
};
