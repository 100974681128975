import { Box, Grid, GridItem } from '@chakra-ui/react';
import { WEBKIT_SCROLLBAR_GRAY } from 'constants/styles';

import {
  ConduitFittingsTable,
  HomerunBoxScheduleTable,
  JHookHangersTable,
  TrapezeHangersTable,
} from './tables';

const BomBlock = () => {
  return (
    <Box
      p='3px'
      flexGrow={1}
      display='flex'
      flexDirection='column'
      overflow='auto'
      sx={WEBKIT_SCROLLBAR_GRAY}
    >
      <Grid gap='15px' flexGrow={1}>
        <GridItem>
          <ConduitFittingsTable />
        </GridItem>
        <GridItem>
          <TrapezeHangersTable />
        </GridItem>
        <GridItem>
          <JHookHangersTable />
        </GridItem>
        <GridItem>
          <HomerunBoxScheduleTable />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default BomBlock;
