import { Annotation } from 'types/redux';

import { fitScaleForPathPoints } from '../apryse-block/utils';

interface FilterNoFlyZonesArguments {
  annotationList: Annotation[];
  docDimensions: {
    width: number;
    height: number;
  };
}

export const filterNoFlyZones = ({ annotationList, docDimensions }: FilterNoFlyZonesArguments) => {
  const documentNoFlyZones = annotationList.filter(
    (annotation) =>
      annotation.elementType === 'NoFlyZone' || annotation.elementType === 'NO_FLY_ZONE',
  );
  const resultObject = {};

  documentNoFlyZones.forEach((zone, idx) => {
    const convertedPathPoints = fitScaleForPathPoints({
      docDimensions,
      pathPoints: zone.pathPoints as number[][],
    });

    Object.assign(resultObject, {
      [`zone:${idx + 1}`]: [
        {
          x0: convertedPathPoints[0][0],
          x1: convertedPathPoints[2][0],
          y0: convertedPathPoints[0][1],
          y1: convertedPathPoints[2][1],
        },
      ],
    });
  });
  return resultObject;
};
