import { APRYSE_DOC_DIMENSIONS } from '../constants';

interface FitScaleForDimensionsArguments {
  docDimensions: { width: number; height: number };
  dimensions: { width: number; height: number };
  direction?: 'backend' | 'apryse';
}

export const fitScaleForDimensions = ({
  docDimensions,
  dimensions,
  direction = 'backend',
}: FitScaleForDimensionsArguments) => {
  const transformCoef = {
    widthCoef: docDimensions.width / APRYSE_DOC_DIMENSIONS.WIDTH,
    heightCoef: docDimensions.height / APRYSE_DOC_DIMENSIONS.HEIGHT,
  };

  if (direction === 'backend')
    return {
      width: dimensions.width * transformCoef.widthCoef,
      height: dimensions.height * transformCoef.heightCoef,
    };

  return {
    width: dimensions.width / transformCoef.widthCoef,
    height: dimensions.height / transformCoef.heightCoef,
  };
};
