import { Box } from '@chakra-ui/react';
import Card from 'components/card';
import { ProjectSidebar } from 'pages/projects/components/sidebar';
import { ReactNode } from 'react';

import { styles } from './styles';

interface ProjectLayoutProps {
  header: ReactNode;
  sidebar?: ReactNode;
  children: ReactNode;
  sidebarProps?: { minimizedEnabled?: boolean };
  contentOverflowX?: string;
}

const ProjectLayout = ({
  header,
  children,
  sidebarProps,
  contentOverflowX,
  sidebar,
}: ProjectLayoutProps) => {
  return (
    <Box sx={styles.projectsWrapper}>
      {header}
      <Box sx={styles.pageContentWrapper}>
        <ProjectSidebar {...sidebarProps} />
        <Card
          wrapperProps={{
            width: 'calc(100% - 241.5px)',
            marginLeft: '9px',
            padding: '13.5px',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflowX: contentOverflowX,
          }}
        >
          {children}
        </Card>
        {sidebar}
      </Box>
    </Box>
  );
};

export default ProjectLayout;
