import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const createLayer = yup.object().shape({
  layerName: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  documentId: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});

export const editLayer = yup.object().shape({
  layerName: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});
