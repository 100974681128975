import { checkAnnotationTypeNaming } from 'utils';

import { ExtendedAnnotation } from './types';

export const getLayerAnnotationData = (annotations: ExtendedAnnotation[]) => {
  return annotations.map((annotation) => {
    return {
      id: annotation.Id,
      type: annotation.Subject,
      elementType: annotation.elementName,
      _isLocked: annotation.Locked,
      _isVisible: !annotation.Invisible,
      isReadOnly: false,
      pathPoints: annotation.pathPoints,
      data: {
        name: checkAnnotationTypeNaming({
          elementType: annotation.elementName,
          annotation,
        }),
      },
    };
  });
};
