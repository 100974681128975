const ChevronDownIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#6E6B7B'
        d='M10 12.5L6.464 8.964l1.18-1.178L10 10.143l2.357-2.357 1.179 1.178L10 12.5z'
      ></path>
    </svg>
  );
};

export default ChevronDownIcon;
