import TextField from 'components/text-field';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';

interface DateColumnFilterProps {
  column: {
    filterValue?: string;
    setFilter: Dispatch<SetStateAction<string | undefined>>;
  };
}

export default function DateColumnFilter({
  column: { filterValue, setFilter },
}: DateColumnFilterProps) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <TextField type='date' wrapperProps={{ h: 30 }} value={filterValue} onChange={handleChange} />
  );
}
