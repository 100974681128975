import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  TableOptions,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { getSelectedAnnotations } from 'redux/selectors';
import { Annotation, State } from 'types/redux';

import GlobalFilterSection from './global-filter';
import { styles } from './styles';
import TableRow from './table-row';

function Table<TData extends object>({
  columns,
  data,
  defaultColumn,
  hiddenColumns,
}: TableOptions<TData>) {
  const [advancedFilters, setAdvancedFilters] = useState(false);
  const listRef = useRef<any>(null);
  const defaultSize = 300;
  const tableReference = useRef<HTMLTableElement>(null);

  const _columns = useMemo(() => {
    if (!advancedFilters) {
      return columns.map((column) => ({ ...column, disableFilters: true }));
    }

    return columns;
  }, [columns, advancedFilters]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state,
    setGlobalFilter,
    setAllFilters,
    setColumnOrder,
    allColumns,
    totalColumnsWidth,
  } = useTable(
    {
      columns: _columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns,
      },
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useColumnOrder,
  );

  const handleToggleAdvancedFilters = () => {
    setAllFilters([]);
    setAdvancedFilters((prev) => !prev);
  };

  const selectedAnnotations = useSelector<State, Annotation[]>((state) =>
    getSelectedAnnotations(state, { documentId: 1 }),
  );

  useEffect(() => {
    if (selectedAnnotations?.length) {
      const lastSelection = selectedAnnotations[selectedAnnotations.length - 1];
      // @ts-ignore
      const selectedRow = rows.find((row) => row.original.id === lastSelection.id);
      if (selectedRow) {
        const timeout = setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollToItem(selectedRow.index, 'start');
            clearTimeout(timeout);
          }
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedAnnotations?.length) {
      const lastSelection = selectedAnnotations[selectedAnnotations.length - 1];
      // @ts-ignore
      const selectedRow = rows.find((row) => row.original.id === lastSelection.id);
      if (selectedRow && listRef.current) {
        listRef.current.scrollToItem(selectedRow.index, 'start');
      }
    }
  }, [selectedAnnotations, rows]);

  const RenderedRow = useCallback(
    ({ index, style }: { index: number; style?: React.CSSProperties }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableRow row={row as Row<TData>} selectedAnnotations={selectedAnnotations} style={style} />
      );
    },
    [prepareRow, rows, selectedAnnotations],
  );

  return (
    <Flex as='table' sx={styles.tableWrapper} {...getTableProps} ref={tableReference}>
      <Flex as='thead' direction='column'>
        <Box as='tr'>
          <Flex as='th' colSpan={visibleColumns.length} textAlign='left'>
            <GlobalFilterSection
              setGlobalFilter={setGlobalFilter}
              globalFilter={state.globalFilter}
              onFilterClick={handleToggleAdvancedFilters}
            />
          </Flex>
        </Box>
        {headerGroups.map((headerGroup) => (
          <Box as='tr' sx={styles.tableHeaderRow} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => (
              <Flex
                wrap='wrap'
                as='th'
                {...column.getHeaderProps(column.getSortByToggleProps())}
                sx={styles.headerCell}
              >
                {column.render('Header')}
              </Flex>
            ))}
          </Box>
        ))}
      </Flex>

      <Box as='tbody' {...getTableBodyProps()} sx={styles.tableStyle}>
        <AutoSizer>
          {({ height }: Size) => (
            <FixedSizeList
              height={height || defaultSize}
              itemCount={rows.length}
              itemSize={30}
              width={totalColumnsWidth}
              style={{ overflowX: 'clip' }}
              ref={listRef}
            >
              {RenderedRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Flex>
  );
}

export default Table;
