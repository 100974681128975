import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import SearchIcon from 'assets/icons/SearchIcon';
import { styles } from 'components/page-header/components/search-input/search-input.styles';
import { EUrlSearchParamKeys } from 'components/page-header/types';
import { useGetUrlSearchParams } from 'hooks';
import { useDebounce } from 'hooks/useDebounce';
import React, { useCallback, useState } from 'react';

interface ISearchInputProps {
  value?: string;
  onChange?: (newValue: string) => void;
}

export function SearchInput({ value, onChange }: ISearchInputProps): JSX.Element {
  const { urlSearchParamsObject, setSearchParam } = useGetUrlSearchParams();
  const { query } = urlSearchParamsObject;
  const [searchValue, setSearchValue] = useState(query);

  useDebounce({
    value: searchValue,
    delay: 500,
    onChange: (newValue: string) => setSearchParam(EUrlSearchParamKeys.QUERY, newValue),
  });
  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e?.target?.value;
      onChange?.(newValue);
      setSearchValue(newValue);
    },
    [setSearchValue, setSearchParam, onChange],
  );

  return (
    <InputGroup sx={styles.inputGroup}>
      <InputLeftElement pointerEvents='none' display='flex' alignItems='center' height='100%'>
        <SearchIcon
          fill='#625F6E'
          style={{
            width: '13px',
            height: '13px',
          }}
        />
      </InputLeftElement>
      <Input sx={styles.input} value={searchValue} onChange={onInputChange} placeholder='Search' />
    </InputGroup>
  );
}
