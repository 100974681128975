import { Button, Flex, Text } from '@chakra-ui/react';
import { AddIcon } from 'assets/icons';
import { useGetBreadcrumbs } from 'components/header-breadcrums/hooks/useGetBreadcrumbs';
import { PageHeader } from 'components/page-header';
import { ERoutes } from 'components/router/types';
import { styles } from 'pages/projects/styles';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProjectPageHeaderProps } from 'types';

export const ProjectPageHeader = ({ path }: IProjectPageHeaderProps): ReactElement => {
  const [searchValue, setSearchValue] = useState('');
  const itemsToSkipProjectPrefixWithId = 2;
  const breadcrumbs = useGetBreadcrumbs(path, itemsToSkipProjectPrefixWithId);
  const navigate = useNavigate();

  return (
    <PageHeader
      title='Projects'
      headerHeight='48px'
      actionButtons={
        <Button
          variant='primary'
          sx={styles.addProjectBtn}
          onClick={() => navigate(ERoutes.PROJECTS + ERoutes.ADD_PROJECT)}
        >
          <Flex alignItems='center'>
            <AddIcon size='12.75px' />
            <Text ml='5.625px'>Add File</Text>
          </Flex>
        </Button>
      }
      withSortingMenu={true}
      withSearch={true}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      breadcrumbs={breadcrumbs}
    />
  );
};
