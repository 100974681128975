import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MockedProjectsState } from 'types/redux';

const INITIAL_STATE = {};

export const mockedProjectsSlice = createSlice({
  name: 'mockedProjects',
  initialState: INITIAL_STATE,
  reducers: {
    createProject(state: MockedProjectsState, action: PayloadAction<{ id: string; name: string }>) {
      state[action.payload.id] = action.payload.name;
    },
  },
});

export const mockedProjectsActions = { ...mockedProjectsSlice.actions };
