import React from 'react';

export const AnnotationsSettingsIcon = ({ size = '30px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='annotation-settings-icon'
    >
      <rect opacity='0.1' width='40' height='40' rx='6' fill='#AEADBC' />
      <path
        d='M21.8386 11.2564C22.2902 10.0102 23.6665 9.36608 24.9127 9.81767L27.7269 10.8375C28.9731 11.2891 29.6173 12.6654 29.1657 13.9116L28.1459 16.7258C27.6943 17.972 26.318 18.6162 25.0718 18.1646L22.2575 17.1448C21.0113 16.6932 20.3672 15.3169 20.8188 14.0707L21.8386 11.2564Z'
        fill='#868592'
      />
      <path
        d='M16.4595 10.5672C16.1096 10.5431 15.6808 10.5431 15.1564 10.5431H15.1077C14.5833 10.5431 14.1546 10.5431 13.8046 10.5672C13.4431 10.5921 13.1145 10.6451 12.8009 10.7767C12.1031 11.0696 11.5432 11.6164 11.2412 12.3076C11.1049 12.6196 11.0503 12.9463 11.0247 13.3031C11 13.6476 11 14.0691 11 14.5821V14.6323C11 15.1453 11 15.5668 11.0247 15.9113C11.0503 16.2682 11.1049 16.5948 11.2412 16.9068C11.5432 17.598 12.1031 18.1448 12.8009 18.4377C13.1145 18.5693 13.4431 18.6223 13.8046 18.6472C14.1546 18.6713 14.5833 18.6713 15.1077 18.6713H15.1564C15.6808 18.6713 16.1096 18.6713 16.4595 18.6472C16.821 18.6223 17.1496 18.5693 17.4632 18.4377C18.161 18.1448 18.721 17.598 19.0229 16.9068C19.1592 16.5948 19.2138 16.2682 19.2394 15.9113C19.2641 15.5668 19.2641 15.1453 19.2641 14.6323V14.5821C19.2641 14.0691 19.2641 13.6476 19.2394 13.3031C19.2138 12.9463 19.1592 12.6196 19.0229 12.3076C18.721 11.6164 18.161 11.0696 17.4632 10.7767C17.1496 10.6451 16.821 10.5921 16.4595 10.5672Z'
        fill='#B9B9C3'
      />
      <path
        d='M26.1954 19.8958C25.8454 19.8717 25.4167 19.8717 24.8923 19.8717H24.8436C24.3192 19.8717 23.8904 19.8717 23.5405 19.8958C23.179 19.9207 22.8504 19.9737 22.5368 20.1054C21.839 20.3983 21.279 20.9451 20.9771 21.6363C20.8408 21.9483 20.7862 22.2749 20.7606 22.6318C20.7359 22.9762 20.7359 23.3978 20.7359 23.9107V23.961C20.7359 24.4739 20.7359 24.8955 20.7606 25.24C20.7862 25.5968 20.8408 25.9235 20.9771 26.2355C21.279 26.9267 21.839 27.4734 22.5368 27.7663C22.8504 27.898 23.179 27.951 23.5405 27.9759C23.8904 28 24.3192 28 24.8436 28H24.8923C25.4167 28 25.8455 28 26.1954 27.9759C26.5569 27.951 26.8855 27.898 27.1991 27.7663C27.8969 27.4734 28.4568 26.9267 28.7588 26.2355C28.8951 25.9235 28.9497 25.5968 28.9753 25.24C29 24.8955 29 24.4739 29 23.9609V23.9108C29 23.3978 29 22.9762 28.9753 22.6318C28.9497 22.2749 28.8951 21.9483 28.7588 21.6363C28.4568 20.9451 27.8969 20.3983 27.1991 20.1054C26.8855 19.9737 26.5569 19.9207 26.1954 19.8958Z'
        fill='#B9B9C3'
      />
      <path
        d='M16.4595 19.8958C16.1096 19.8717 15.6808 19.8717 15.1564 19.8717H15.1077C14.5833 19.8717 14.1546 19.8717 13.8046 19.8958C13.4431 19.9207 13.1145 19.9737 12.8009 20.1054C12.1031 20.3983 11.5432 20.945 11.2412 21.6363C11.1049 21.9483 11.0503 22.2749 11.0247 22.6318C11 22.9762 11 23.3978 11 23.9107V23.961C11 24.4739 11 24.8955 11.0247 25.24C11.0503 25.5968 11.1049 25.9235 11.2412 26.2355C11.5432 26.9267 12.1031 27.4734 12.8009 27.7663C13.1145 27.898 13.4431 27.951 13.8046 27.9759C14.1545 28 14.5833 28 15.1077 28H15.1564C15.6808 28 16.1096 28 16.4595 27.9759C16.821 27.951 17.1496 27.898 17.4632 27.7663C18.161 27.4734 18.721 26.9267 19.0229 26.2355C19.1592 25.9235 19.2138 25.5968 19.2394 25.24C19.2641 24.8955 19.2641 24.4739 19.2641 23.961V23.9107C19.2641 23.3978 19.2641 22.9762 19.2394 22.6318C19.2138 22.2749 19.1592 21.9483 19.0229 21.6363C18.721 20.9451 18.161 20.3983 17.4632 20.1054C17.1496 19.9737 16.821 19.9207 16.4595 19.8958Z'
        fill='#B9B9C3'
      />
    </svg>
  );
};
