import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApryseAnnotation } from 'types';

export const drawerApiSlice = createApi({
  reducerPath: 'drawerApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_PREFIX }),
  endpoints: (builder) => ({
    getAnnotations: builder.query<{ [id: number]: ApryseAnnotation[] }, { documentId: number }>({
      query: ({ documentId }) => '/annotations.json',
    }),
    updateAnnotationsServerData: builder.mutation({
      query: (requestBody) => ({
        url: `/playground/compute/`,
        method: 'POST',
        body: requestBody,
      }),
    }),
  }),
});

export const { useGetAnnotationsQuery, useUpdateAnnotationsServerDataMutation } = drawerApiSlice;
