import { BUTTON_ICONS, BUTTON_NAMES, TOOL_NAMES, TOOLTIPS } from 'constants/apryse';

import { POLYGON_TOOL_GROUP_BUTTON, TOOL_BUTTON, TOOLBAR_GROUP } from '../constants';
import { AddToolToPanelParams } from './types';

export const addHomerunBoxToolToPanel = ({ UI, tool }: AddToolToPanelParams) => {
  UI.registerTool({
    toolName: TOOL_NAMES.HOMERUN_BOX,
    toolObject: tool,
    tooltip: TOOLTIPS.HOMERUN_BOX,
    buttonName: BUTTON_NAMES.HOMERUN_BOX,
    buttonImage: BUTTON_ICONS.HOMERUN_BOX,
  });

  UI.setHeaderItems((header) => {
    header.getHeader(TOOLBAR_GROUP.SHAPES).get(POLYGON_TOOL_GROUP_BUTTON).insertBefore({
      type: TOOL_BUTTON,
      toolName: TOOL_NAMES.HOMERUN_BOX,
    });
  });
};
