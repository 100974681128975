/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebViewerInstance } from '@pdftron/webviewer';
import {
  APRYSE_POINT_IN_CENTIMETRES,
  APRYSE_POINT_IN_INCHES,
  APRYSE_POINT_IN_MILLIMETRES,
} from 'constants/measure-units';

interface calculatePolylineLengts {
  annotation: any;
  isReduxAnnotation?: boolean;
  viewerInstance?: WebViewerInstance;
  convertFrom: {
    value: number;
    units: string;
  };
  convertTo: {
    value: number;
    units: string;
  };
}

export const calculatePolylineLength = ({
  annotation,
  isReduxAnnotation,
  viewerInstance,
  convertFrom,
  convertTo,
}: calculatePolylineLengts) => {
  let polylineLength = 0;
  if (isReduxAnnotation && viewerInstance) {
    annotation.pathPoints?.forEach((pathPoint: number[], idx: number) => {
      if (annotation.pathPoints.length - 1 > idx) {
        const firstPathPoint = new viewerInstance.Core.Math.Point(pathPoint[0], pathPoint[1]);
        const secondPathPoint = new viewerInstance.Core.Math.Point(
          annotation.pathPoints[idx + 1][0],
          annotation.pathPoints[idx + 1][1],
        );
        polylineLength += firstPathPoint.distance(secondPathPoint);
      }
    });
  } else {
    annotation?.getPath()?.forEach((pathPoint: any, idx: number) => {
      if (annotation.getPath().length - 1 > idx) {
        polylineLength += annotation.getPathPoint(idx).distance(annotation.getPathPoint(idx + 1));
      }
    });
  }

  switch (convertFrom.units) {
    case 'pt':
      return `${((polylineLength / convertFrom.value) * convertTo.value).toFixed(3)} ${
        convertTo.units
      }`;

    case 'mm':
      return `${(
        ((polylineLength * APRYSE_POINT_IN_MILLIMETRES) / convertFrom.value) *
        convertTo.value
      ).toFixed(3)} ${convertTo.units}`;

    case 'cm':
      return `${(
        ((polylineLength * APRYSE_POINT_IN_CENTIMETRES) / convertFrom.value) *
        convertTo.value
      ).toFixed(3)} ${convertTo.units}`;

    case 'in':
      return `${(
        ((polylineLength * APRYSE_POINT_IN_INCHES) / convertFrom.value) *
        convertTo.value
      ).toFixed(3)} ${convertTo.units}`;

    default:
      break;
  }
};
