interface InputStructure {
  Name: string;
  Content: {
    Key: string;
    Value: string[];
  }[];
}

export const getData = (input: InputStructure) => {
  return input.Content.reduce<Record<string, string>[]>((acc, next) => {
    next.Value.forEach((value, index) => {
      acc[index] = {
        ...acc[index],
        [next.Key]: value,
      };
    });
    return acc;
  }, []);
};
