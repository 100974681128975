export const styles = {
  customSwitch: {
    background: '#EBEBEB',
    alignItems: 'center',
    borderRadius: '42px',
    width: 'max-content',
    height: 'max-content',
  },
  toggleItem: (isActive: boolean) => ({
    cursor: 'pointer',
    height: '30px',
    borderRadius: '42px',
    padding: '6px 24px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '9px',
    lineHeight: '0px',
    boxShadow: isActive ? '0px 6px 17px rgba(0, 0, 0, 0.08)' : 'unset',
    background: isActive ? 'white' : 'transparent',
  }),
  itemText: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    lineHeight: '13.5px',
    color: '#2C2C2E',
  },
};
