import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { annotationActions, layerActions } from 'redux/slices';
import { ApryseAnnotation } from 'types';
import { drawAnnotations } from 'utils';

import { checkUptatingStatus } from './check-updating-status';
import { getLayerAnnotationData } from './get-layer-annotations-data';
import { prepareAnnotation } from './prepare-annotation';
import { UpdateAnnotationProps } from './types';

export const useUpdateAnnotation = () => {
  const { instance } = useContext(WebviewerContext);
  const dispatch = useDispatch();

  const handler = ({ data, isSpecificAnnotationSelected, updateMap }: UpdateAnnotationProps) => {
    if (instance) {
      if (isSpecificAnnotationSelected) {
        const { annotationManager } = instance.Core;

        const selectedAnnotations = annotationManager.getSelectedAnnotations();

        selectedAnnotations.forEach((annotation) => {
          // It removes data from update object that don't need to modified
          checkUptatingStatus({ annotation, data, updateMap });
          // Modifies annotation before drawing it on Apryse
          prepareAnnotation({ annotation, data, instance });
        });

        drawAnnotations({
          viewerInstance: instance,
          annotationList: selectedAnnotations as unknown as ApryseAnnotation[],
          redraw: true,
        });

        dispatch(
          annotationActions.modifyAnnotations({ documentId: 1, annotations: selectedAnnotations }),
        );

        if (data.layer) {
          const annotationsForLayer = getLayerAnnotationData(selectedAnnotations);

          dispatch(
            layerActions.addAnnotationsToLayer({
              documentId: 1,
              layerId: Number.parseInt(data.layer, 10),
              annotations: annotationsForLayer,
            }),
          );
        }
      }
    }
  };

  return handler;
};
