import { EUrlSearchParamKeys } from 'components/page-header/types';

export const HEADER_SORT_VARIANTS = {
  'createdAt,desc': 'Date: New to Old',
  'createdAt,asc': 'Date: Old to New',
  'name,asc': 'Name: A to Z',
  'name,desc': 'Name Z to A',
};

export const HEADER_SORT_DEFAULT_VALUE = {
  key: EUrlSearchParamKeys.SORT,
  defaultValue: 'createdAt,asc',
  possibleValues: Object.keys(HEADER_SORT_VARIANTS),
};
