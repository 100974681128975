import { Flex, Progress, Text } from '@chakra-ui/react';
import { styles } from 'pages/projects/components/project-files/project-files.styles';
import React from 'react';
import { DrawerProject } from 'types';
import { formatBytes } from 'utils';

export function ProjectFiles({
  handleLinkClick,
  projectFilesData,
}:
  | any
  | {
      projectFilesData: DrawerProject;
      handleLinkClick: VoidFunction;
    }) {
  const filesCount = projectFilesData?.totalFilesCount || 0;

  return (
    <Flex sx={styles.filesInfoWrapper}>
      <Progress value={100} sx={styles.progress} />
      <Flex sx={styles.filesInfoTextWrapper}>
        <Text sx={styles.filesInfoText} onClick={handleLinkClick}>{`${filesCount} files`}</Text>
        <Text sx={styles.filesInfoText} onClick={handleLinkClick}>
          {formatBytes(projectFilesData?.totalFilesSize)}
        </Text>
      </Flex>
    </Flex>
  );
}
