import { useEffect, useState } from 'react';

export const useDebounce = ({ value = '', delay = 500, onChange }: any) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      onChange?.(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};
