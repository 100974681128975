import { State } from '../../types/redux';

export const getColumns = (state: State) => state.columns.allColumns;
export const getColumnsList = (state: State) => {
  const columnsOrder = state.columns.columnsOrder;
  const allColumns = state.columns.allColumns;
  const columnKeys = Object.keys(allColumns);

  return columnsOrder.map((orderedColumnId) => {
    const searchedItem = columnKeys.find((column) => column === orderedColumnId) as string;
    return allColumns[searchedItem];
  });
};

export const getNonExportableColumnsIds = (state: State) => state.columns.nonExportableColumnsIds;
