import { Box } from '@chakra-ui/react';
import { Cell } from 'react-table';
import { Annotation } from 'types/redux';

import { styles } from './styles';
import TableCell from './table-cell';

interface TableRowProps {
  row: any;
  selectedAnnotations: Annotation[];
  style?: React.CSSProperties;
}

const TableRow = ({ row, selectedAnnotations, style }: TableRowProps) => {
  const isSelected = selectedAnnotations?.some(
    (annotation) => annotation.id === (row.original as Annotation).id,
  );
  return (
    <Box sx={isSelected ? styles.selectedRow : undefined} as='tr' {...row.getRowProps({ style })}>
      {row.cells.map((cell: Cell, idx: number) => {
        return <TableCell cell={cell} key={idx} />;
      })}
    </Box>
  );
};

export default TableRow;
