import { Flex } from '@chakra-ui/react';
import { LayersModal } from 'pages/sheet/components/layers-block/layers-modal';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLayersInDocument, isTabModalOpen } from 'redux/selectors';
import { branchTabActions } from 'redux/slices';
import { Layer, State } from 'types/redux';

import { LayerItem } from './layer-item';
import { styles } from './styles';

const LayersInfoBlock = () => {
  /* TODO: Add functionality for getting current document id */
  // const documentEntities = useSelector(getDocuments);
  // const currentDocumentId = Number?.parseInt(Object?.keys(documentEntities)?.[0], 10);
  const currentDocumentId = 1;

  const layerEntities = useSelector<State, { [layerId: number]: Layer }>((state) =>
    getLayersInDocument(state, { documentId: currentDocumentId }),
  );
  const isModalOpen = useSelector(isTabModalOpen);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(branchTabActions.toggleTabModal({ isTabModalOpen: false }));
  };

  return (
    <>
      <Flex sx={styles.layersInfoWrapper}>
        <Flex sx={styles.scrollWrapper}>
          {layerEntities &&
            Object.values(layerEntities)?.map((layer: Layer, idx) => (
              <LayerItem key={idx} documentId={currentDocumentId} layer={layer} />
            ))}
        </Flex>
      </Flex>
      <LayersModal
        currentDocumentId={currentDocumentId}
        isModalOpen={isModalOpen}
        setCloseModal={handleCloseModal}
      />
    </>
  );
};

export default LayersInfoBlock;
