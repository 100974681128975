import { Box, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { WebViewerInstance } from '@pdftron/webviewer';
import { EUrlSearchParamKeys } from 'components/page-header/types';
import { FormInput } from 'components/react-hook-form/number-input';
import { DOCUMENT_ID } from 'constants/apryse';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useGetUrlSearchParams } from 'hooks';
import {
  SETTINGS_FORM_FIELDS,
  SETTINGS_FORM_VALIDATION,
} from 'pages/sheet/components/settings-block/constants';
import { EViewMods } from 'pages/sheet/types';
import { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnotations, getDetectionStatus, getFormValues } from 'redux/selectors';
import { useImportJsonMutation, useUpdateAnnotationsServerDataMutation } from 'redux/slices';
import { redlineFormActions } from 'redux/slices/redline-form.slice';
import { EFixedCachedKeys } from 'types';
import { Annotation } from 'types/redux';
import { drawAnnotations } from 'utils';

import { convertCoordsByElementType } from '../apryse-block/utils';
import { filterCircuits } from './filter-circuits';
import { filterNoFlyZones } from './filter-no-fly-zones';
import { filterPanels } from './filter-panels';
import { filterPreferredZones } from './filter-preferred-zones';
import { styles } from './styles';

export function SettingsForm(): JSX.Element {
  const formValues = useSelector(getFormValues);
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: formValues,
    mode: 'onBlur',
    resolver: yupResolver(SETTINGS_FORM_VALIDATION),
  });
  const watchFields = form.watch(['height', 'width']);

  useEffect(() => {
    dispatch(
      redlineFormActions.updateDocumentDimensions({
        height:
          typeof watchFields[0] === 'number' ? watchFields[0] : Number.parseInt(watchFields[0], 10),
        width:
          typeof watchFields[1] === 'number' ? watchFields[1] : Number.parseInt(watchFields[1], 10),
      }),
    );
  }, [dispatch, watchFields]);

  const [updateSettings] = useUpdateAnnotationsServerDataMutation({
    fixedCacheKey: EFixedCachedKeys.SETTINGS_FORM_MUTATION,
  });
  const { urlSearchParamsObject, setSearchParam } = useGetUrlSearchParams();

  const annotationEntities = useSelector(getAnnotations);
  const areAnnotationsDetected = useSelector(getDetectionStatus);
  const { instance } = useContext(WebviewerContext);
  const [importJson, { data, reset }] = useImportJsonMutation({
    fixedCacheKey: EFixedCachedKeys.IMPORT_JSON,
  });
  const isDisabledSubmit = Boolean(Object.keys(form.formState.errors).length);
  const handleResetImportMutationState = () => {
    if (data) {
      reset();
      if (urlSearchParamsObject[EUrlSearchParamKeys.VIEW_MODE] === EViewMods._3D) {
        setSearchParam(EUrlSearchParamKeys.VIEW_MODE, EViewMods._2D);
      }
    }
  };

  return (
    <Flex direction='column' sx={styles.scrollWrapper}>
      <FormProvider {...form}>
        <Flex
          justifyContent='space-between'
          direction='column'
          height='100%'
          as='form'
          onSubmit={form.handleSubmit((data) => {
            const docDimensions = {
              width: data.width,
              height: data.height,
            };
            handleResetImportMutationState();
            updateSettings({
              settings: data,
              circuits: filterCircuits({
                annotationList: annotationEntities[DOCUMENT_ID],
                docDimensions,
              }),
              panels: filterPanels({
                annotationList: annotationEntities[DOCUMENT_ID],
                docDimensions,
              }),
              preferred_zones: filterPreferredZones({
                annotationList: annotationEntities[DOCUMENT_ID],
                docDimensions,
              }),
              no_fly_zones: filterNoFlyZones({
                annotationList: annotationEntities[DOCUMENT_ID],
                docDimensions,
              }),
            })
              .then((response: any) => {
                const existingAnnotations: Annotation[] = [];
                const newAnnotations: Annotation[] = [];

                const formData = new FormData();
                formData.append('model-json-content', JSON.stringify(response?.data));
                importJson(formData);
                instance?.Core.documentViewer.refreshPage(1);

                response?.data[DOCUMENT_ID].forEach((annotation: Annotation) => {
                  const fittedAnnotation = convertCoordsByElementType({
                    annotation: annotation as any,
                    docDimensions,
                  });

                  if (
                    annotationEntities[DOCUMENT_ID].find(
                      (reduxAnnotation) => reduxAnnotation.id === fittedAnnotation.id,
                    )
                  ) {
                    existingAnnotations.push(fittedAnnotation);
                  } else {
                    newAnnotations.push(fittedAnnotation);
                  }
                });
                drawAnnotations({
                  viewerInstance: instance as WebViewerInstance,
                  // @ts-ignore
                  annotationList: newAnnotations,
                  redraw: false,
                });
                drawAnnotations({
                  viewerInstance: instance as WebViewerInstance,
                  // @ts-ignore
                  annotationList: existingAnnotations,
                  redraw: true,
                });
              })
              .then(() => {
                instance?.Core.documentViewer.disableViewportRenderMode();
              });
          })}
        >
          <Box>
            <FormInput
              {...SETTINGS_FORM_FIELDS[0]}
              key={SETTINGS_FORM_FIELDS[0].name}
              heightInput='30px'
              fontSizeInput='12px'
            />
            <SimpleGrid
              columns={2}
              spacingY='9px'
              spacingX='9px'
              sx={{
                mt: '9px',
              }}
            >
              {SETTINGS_FORM_FIELDS.map((field, idx) => {
                if (idx === 0) {
                  return null;
                }
                return (
                  <FormInput {...field} key={field.name} heightInput='30px' fontSizeInput='12px' />
                );
              })}
            </SimpleGrid>
          </Box>
          <Button
            width='100%'
            isDisabled={!areAnnotationsDetected || isDisabledSubmit}
            type='submit'
            background='purple.100'
            aria-label='Submit'
            sx={{ my: '7.5px', minH: '36px' }}
          >
            <Text color='white'>Submit</Text>
          </Button>
        </Flex>
      </FormProvider>
    </Flex>
  );
}
