import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';

export const styles = {
  settingsInfoWrapper: {
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...WEBKIT_SCROLLBAR_STYLES,
  },
  headerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '7.5px',
  },
  settingsTitleText: {
    fontSize: '13.5px',
    lineHeight: '93%',
    fontWeight: 600,
    color: 'gray.400',
    whiteSpace: 'nowrap',
  },
  scrollWrapper: {
    paddingLeft: '4.5px',
    paddingRight: '4.5px',
    height: 'calc(100% - 36px)',
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '4px',
    },
  },

  settingsFieldLabel: {
    fontSize: '10.5px',
    marginLeft: '4.5px',
    cursor: 'pointer',
  },
};
