const DotsIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#B9B9C3'
        d='M10 2.5c-.688 0-1.25.563-1.25 1.25C8.75 4.438 9.313 5 10 5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25zM10 15c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25zm0-6.25c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25z'
      ></path>
    </svg>
  );
};

export default DotsIcon;
