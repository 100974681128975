import React from 'react';

export const SourcesActiveIcon = ({ width = '18px', height = '22px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='sources-active-icon'
    >
      <path
        d='M14.4154 21.8337H3.58204C1.78695 21.8337 0.332031 20.3788 0.332031 18.5837V3.417C0.332031 1.62191 1.78695 0.166992 3.58204 0.166992H11.1654L17.6654 6.667V18.5837C17.6654 20.3788 16.2105 21.8337 14.4154 21.8337Z'
        fill='#7368F0'
      />
      <path
        d='M11.166 4.50033V0.166992L17.666 6.667H13.3327C12.1356 6.667 11.166 5.69742 11.166 4.50033Z'
        fill='#D5D5D5'
        fillOpacity='0.5'
      />
      <path
        d='M4.23884 16.448C4.01611 16.448 3.79243 16.3709 3.61253 16.2129C3.21656 15.8664 3.17658 15.2649 3.5221 14.8699L6.85356 11.0625C7.03631 10.8512 7.27808 10.7465 7.58172 10.7369C7.86061 10.7407 8.12332 10.8654 8.30227 11.0787L9.93563 13.0395L13.0139 9.19019C13.3442 8.7809 13.941 8.71237 14.3522 9.04171C14.7624 9.37104 14.8291 9.96976 14.5007 10.38L10.6933 15.1392C10.5144 15.362 10.245 15.4933 9.95943 15.4962C9.6615 15.539 9.40165 15.3724 9.2189 15.1545L7.55317 13.1546L4.95558 16.1225C4.76712 16.3386 4.50345 16.448 4.23884 16.448Z'
        fill='#E8E7EF'
      />
    </svg>
  );
};
