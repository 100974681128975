export const styles = {
  sortWrapper: {
    margin: '0 30px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    width: '135px',
  },
  sortByText: {
    color: 'gray.200',
    fontSize: '10.5px',
    marginRight: '6px',
    fontWeight: 500,
  },
  menu: {
    position: 'relative',
  },
  sortMenuBtn: {
    cursor: 'pointer',
  },
  sortMenuBtnContent: {
    alignItems: 'center',
  },
  sortMenuBtnText: {
    fontSize: '10.5px',
    color: 'gray.400',
    lineHeight: '18px',
    marginRight: '6px',
  },
  menuList: {
    width: '145px',
    boxShadow: '0px 4px 24px rgba(34, 41, 47, 0.09)',
    border: 'none',
  },
  menuListItem: (isSelected?: boolean) => {
    return {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '7.125px 15px',
      fontWeight: 500,
      fontSize: '10.5px',
      color: 'dark.100',
      background: isSelected ? 'gray.25' : undefined,
      marginRight: '6px',
    };
  },
};
