import { Text } from '@chakra-ui/react';
import { ERoutes } from 'components/router/types';
import ProjectLayout from 'layouts/project-layout';
import AddProject from 'pages/add-project';
import ProjectsPage from 'pages/projects';
import ProjectPage from 'pages/projects/project';
import { ProjectPageHeader } from 'pages/projects/project/headers';
import ProjectSheet from 'pages/sheet';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DrawingRoutes } from './drawing-routes';
import { ReportsRoutes } from './reports-routes';
import { SourcesRoutes } from './sources-routes';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to={ERoutes.PROJECTS} replace />} />
      <Route path={ERoutes.PROJECTS} element={<ProjectsPage />} />
      <Route path={ERoutes.PROJECTS + ERoutes.ADD_PROJECT} element={<AddProject />} />
      <Route path={ERoutes.PROJECTS + ERoutes.ID} element={<ProjectPage />} />
      <Route
        path={
          ERoutes.PROJECTS +
          ERoutes.ID +
          ERoutes.DRAWINGS +
          ERoutes.DRAWINGS_TYPE +
          ERoutes.SHEET_ID
        }
        element={<ProjectSheet />}
      />
      {DrawingRoutes}
      {ReportsRoutes}
      {SourcesRoutes}
      <Route
        path={ERoutes.PROJECTS + ERoutes.ID + ERoutes._3D_MODELS}
        element={
          <ProjectLayout header={<ProjectPageHeader />} sidebarProps={{ minimizedEnabled: true }}>
            <Text fontSize='12px'>3D Models</Text>
          </ProjectLayout>
        }
      />
    </Routes>
  );
};

export default Router;
