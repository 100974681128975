import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { mergeStyles, Props } from 'react-select';

import { STYLES } from './styles';
import { SelectOption } from './types';

interface ReactSelectRHFProps extends Props {
  name: string;
  disabled?: boolean;
}

const ReactSelectRHF = ({ name, styles, ...rest }: ReactSelectRHFProps) => {
  const { register, watch, setValue } = useFormContext();

  const selectReference = useRef<any>();

  const selectValue = watch(name);

  const _value = useMemo(() => {
    return rest.options?.find((option) => (option as SelectOption).value === selectValue);
  }, [rest.options, selectValue]);

  useEffect(() => {
    if (!_value && rest.options?.length) {
      selectReference.current?.clearValue?.();
    }
  }, [_value, rest.options?.length]);

  return (
    <Select
      styles={mergeStyles(STYLES, styles)}
      {...register(name)}
      ref={selectReference}
      value={_value}
      onChange={(data) => {
        setValue(name, (data as SelectOption)?.value || undefined);
      }}
      isClearable
      {...rest}
    />
  );
};

export default ReactSelectRHF;
