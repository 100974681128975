export const styles = {
  headerCard: {
    width: '100%',
    padding: '12px 15px',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  heading: {
    fontSize: '18px',
    color: 'gray.400',
    fontWeight: 500,
    marginRight: '25.5px',
  },
  headerRightSection: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
};
