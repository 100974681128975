import { useMemo } from 'react';

import conduitFittings from '../mock/conduit-fittings.json';
import { getColumns } from '../utils';
import { getData } from '../utils/get-data';
import Table from './table';

export const ConduitFittingsTable = () => {
  const data = useMemo(() => {
    return getData(conduitFittings);
  }, []);

  const columns = useMemo(() => {
    return getColumns(conduitFittings);
  }, []);

  return <Table data={data} columns={columns} />;
};
