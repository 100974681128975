import { WebViewerInstance } from '@pdftron/webviewer';
import { ANNOTATION_TYPES, DOCUMENT_ID } from 'constants/apryse';
import { CIRCUITS_PRESET, PANELS_PRESET } from 'constants/request-body';
import { ApryseAnnotation } from 'types';
import { Annotation } from 'types/redux';
import { drawAnnotations } from 'utils';

import { convertCoordsByElementType, ExtendedAnnotation } from './convertCoordsByElementType';

interface HandleDetectCircuitsProps {
  annotationEntities: any;
  instance: WebViewerInstance | null;
  documentDimensions: {
    height: number;
    width: number;
  };
}

export const detectCircuits = ({
  annotationEntities,
  instance,
  documentDimensions,
}: HandleDetectCircuitsProps) => {
  const annotationsToDetect: ExtendedAnnotation[] = [];
  Object.values(CIRCUITS_PRESET).forEach((circuit: any, idx: number) => {
    const annotationToConvert = {
      id: `Detect-${idx + 1}`,
      type: 'Rectangle',
      elementType: ANNOTATION_TYPES.CIRCUIT,
      _isLocked: false,
      _isVisible: true,
      isReadOnly: false,
      pageNumber: 1,
      x: circuit[0].x,
      y: circuit[0].y,
      width: 7,
      height: 7,
      data: {
        circuit: circuit[0].circuit,
        panelName: circuit[0].panel,
        type: circuit[0].type,
      },
    };

    if (
      !annotationEntities[DOCUMENT_ID].find(
        (circuitAnnotation: Annotation) => circuitAnnotation.id === annotationToConvert.id,
      )
    ) {
      annotationsToDetect.push(
        convertCoordsByElementType({
          annotation: annotationToConvert,
          docDimensions: documentDimensions,
        }),
      );
    }
  });

  PANELS_PRESET.forEach((panel) => {
    if (
      !annotationEntities[DOCUMENT_ID].find(
        (panelAnnotation: Annotation) => panelAnnotation.id === panel.id,
      )
    ) {
      annotationsToDetect.push(
        convertCoordsByElementType({
          annotation: panel,
          docDimensions: documentDimensions,
        }),
      );
    }
  });
  drawAnnotations({
    viewerInstance: instance as WebViewerInstance,
    annotationList: annotationsToDetect as unknown as ApryseAnnotation[],
    redraw: false,
  });
};
