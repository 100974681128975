import React from 'react';

interface IconProps {
  fill?: string;
  size?: string;
}

const TrophyIcon = ({ fill = '#6E6B7B', size = '16px' }: IconProps) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' fill='none' viewBox='0 0 16 17'>
      <path
        fill='#6E6B7B'
        d='M8.667 11.792v1.375H12V14.5H4v-1.333h3.334v-1.375A5.334 5.334 0 012.667 6.5v-4h10.667v4a5.334 5.334 0 01-4.667 5.292zM4 3.833V6.5a4 4 0 108 0V3.833H4zm-3.333 0H2V6.5H.667V3.833zm13.333 0h1.334V6.5H14V3.833z'
      ></path>
    </svg>
  );
};

export default TrophyIcon;
