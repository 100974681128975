import { Annotation } from 'types/redux';

import { fitScaleForCoords } from '../apryse-block/utils';

interface FilterCircuitsArguments {
  annotationList: Annotation[];
  docDimensions: {
    width: number;
    height: number;
  };
}

export const filterCircuits = ({ annotationList, docDimensions }: FilterCircuitsArguments) => {
  const documentCircuits = annotationList.filter(
    (annotation) =>
      annotation.elementType.includes('Circuit') || annotation.elementType.includes('CIRCUIT'),
  );
  const resultObject = {};

  documentCircuits.forEach((circuit: any, idx) => {
    const convertedCoords = fitScaleForCoords({
      docDimensions,
      coords: { x: circuit.pathPoints[0][0], y: circuit.pathPoints[0][1] },
      direction: 'backend',
    });

    Object.assign(resultObject, {
      [`circuit:${idx}`]: [
        {
          x: convertedCoords.x,
          y: convertedCoords.y,
          panel: circuit.data.panelName || '',
          circuit: circuit.data.circuit || '',
          type: circuit.data.type || 'None',
        },
      ],
    });
  });

  return resultObject;
};
