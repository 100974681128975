export const styles = {
  projectsWrapper: {
    padding: '13.5px',
    paddingTop: '9px',
    height: `calc(100vh - 45px)`,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  pageContentWrapper: {
    height: `80.9vh`,
    justifyContent: 'space-between',
    marginTop: '9px',
    width: '100%',
    marginBottom: '13.5px',
  },
  contentCard: {
    width: '100%',
    overflow: 'auto',
    padding: '45px',
    flexDirection: 'column',
    flexGrow: 1,
  },
  cancelBtn: {
    height: '36px',
    padding: '11.7px 30px',
    marginLeft: '30px',
    fontSize: '10.5px',
    fontWeight: 500,
    color: 'gray.500',
  },
};
