import { GroupBase, StylesConfig } from 'react-select';

export const STYLES: StylesConfig<any, boolean, GroupBase<unknown>> = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '22.5px',
    height: '27px',
    borderColor: '#625F6E',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '8.25px',
    fontWeight: '300',
    marginTop: 2,
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    fontSize: '8.25px',
    fontWeight: 300,
    marginTop: 2,
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    margin: 0,
  }),
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    width: '11.25px',
    marginRight: '3.75px',
    marginLeft: '3.75px',
  }),
};
