import React from 'react';

export const FolderIcon = ({ fill = 'white', size = '16px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='folder-icon'
    >
      <path
        d='M2.05759 6H13.9416C14.0338 5.99996 14.1251 6.01906 14.2095 6.05609C14.294 6.09312 14.3699 6.14728 14.4324 6.21514C14.4948 6.283 14.5425 6.36308 14.5725 6.45033C14.6024 6.53757 14.6139 6.63008 14.6063 6.722L14.0503 13.3887C14.0364 13.5553 13.9604 13.7106 13.8374 13.8238C13.7145 13.937 13.5534 13.9999 13.3863 14H2.61293C2.44576 13.9999 2.28472 13.937 2.16174 13.8238C2.03876 13.7106 1.9628 13.5553 1.94893 13.3887L1.39293 6.722C1.38527 6.63008 1.39677 6.53757 1.4267 6.45033C1.45663 6.36308 1.50434 6.283 1.56682 6.21514C1.62929 6.14728 1.70516 6.09312 1.78964 6.05609C1.87411 6.01906 1.96536 5.99996 2.05759 6ZM3.22626 12.6667H12.7729L13.2169 7.33333H2.78226L3.22626 12.6667ZM8.94226 3.33333H13.3329C13.5097 3.33333 13.6793 3.40357 13.8043 3.5286C13.9294 3.65362 13.9996 3.82319 13.9996 4V4.66667H1.99959V2.66667C1.99959 2.48986 2.06983 2.32029 2.19485 2.19526C2.31988 2.07024 2.48945 2 2.66626 2H7.60892L8.94226 3.33333Z'
        fill={fill}
      />
    </svg>
  );
};
