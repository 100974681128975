import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';

export const SourcesSidebarLoader = () => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
    }}
  >
    <Spinner />
  </Box>
);
