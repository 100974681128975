import React from 'react';

export const CropIcon = ({ size = '38px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.4173 29.7916H20.5423C14.7283 29.7916 11.8213 29.7916 10.0152 27.9855C8.20898 26.1793 8.20898 23.2723 8.20898 17.4583V3.58331'
        stroke='#6E6B7B'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M12.834 8.20831H17.459C23.273 8.20831 26.18 8.20831 27.9861 10.0145C29.7923 11.8207 29.7923 14.7277 29.7923 20.5416V25.1666M3.58398 8.20831H8.20898M29.7923 29.7916V34.4166'
        stroke='#6E6B7B'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M13.6035 18.2292C13.6035 16.0489 13.6035 14.9588 14.2808 14.2815C14.9581 13.6042 16.0483 13.6042 18.2285 13.6042H19.7702C21.9504 13.6042 23.0406 13.6042 23.7179 14.2815C24.3952 14.9588 24.3952 16.0489 24.3952 18.2292V19.7709C24.3952 21.9511 24.3952 23.0412 23.7179 23.7185C23.0406 24.3959 21.9504 24.3959 19.7702 24.3959H18.2285C16.0483 24.3959 14.9581 24.3959 14.2808 23.7185C13.6035 23.0412 13.6035 21.9511 13.6035 19.7709V18.2292Z'
        stroke='#6E6B7B'
        strokeWidth='1.5'
      />
    </svg>
  );
};
