import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAnnotations, getColumnsList } from 'redux/selectors';
import { Annotation } from 'types/redux';

import DefaultColumnFilter from './default-filter-column';
import { getAnnotationColumnsInReactTableFormat } from './parse-annotations';
import { getColumnEntitiesInReactTableFormat } from './parse-columns';
import { styles } from './styles';
import Table from './table';

const AnnotationsListTable = () => {
  const annotationEntitities = useSelector(getAnnotations);
  const columnEntities = useSelector(getColumnsList);

  const [columns, hiddenColumns] = useMemo(
    () => getColumnEntitiesInReactTableFormat(columnEntities),
    [columnEntities],
  );

  const defaultColumn = useMemo(
    () => ({
      minWidth: 116.25,
      maxWidth: 1000,
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const annotationData = (annotationEntitities?.[1] as Annotation[]) ?? [];

  return (
    <Flex sx={styles.wrapper}>
      <Flex sx={styles.scrollWrapper}>
        <Table
          data={getAnnotationColumnsInReactTableFormat({
            annotationEntities: annotationData,
            columns: columnEntities,
          })}
          columns={columns}
          hiddenColumns={hiddenColumns}
          defaultColumn={defaultColumn}
        />
      </Flex>
    </Flex>
  );
};

export default AnnotationsListTable;
