import { Flex } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';

interface SortableItemProps {
  id: string;
  children: ReactNode;
  onClick: () => void;
}

function SortableItem(props: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: '12px',
    color: 'gray.400',
    paddingBottom: '9px',
  };

  return (
    <Flex
      ref={setNodeRef}
      style={style}
      {...props}
      {...attributes}
      {...listeners}
      onClick={props.onClick}
    >
      {props.children}
    </Flex>
  );
}

export default SortableItem;
