import { Core } from '@pdftron/webviewer';
import { Color } from 'components/color-picker';
import ColorPickerRHF from 'components/react-hook-form/color-picker-rhf';
import ReactSelectRHF from 'components/react-hook-form/react-select-rhf';
import TextFieldRHF from 'components/react-hook-form/textfield-rhf';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { Annotation, ColumnData, ColumnsState } from 'types/redux';
import { convertRGBtoHex, HEXToRGB, isInHexFormat, isInRGBAFormat } from 'utils/rgb-hex';

import { INPUT_TYPES_OPTIONS } from './constants';

export const getAnnotationDetailsDefaultValues = ({
  columns,
}: {
  columns: ColumnsState['allColumns'];
}) => {
  const defaultValues = Object.values(columns);

  return defaultValues.reduce((acc, next) => {
    if (
      next.inputType === 'color' &&
      next.defaultValue !== undefined &&
      isInRGBAFormat(next.defaultValue)
    ) {
      acc[next.id] = convertRGBtoHex(next.defaultValue);
    } else {
      acc[next.id] = next.defaultValue || '';
    }

    return acc;
  }, {} as { [key: string]: string });
};

export const generateIdFromLabel = (data: ColumnData) => {
  return data.label.toLowerCase().replace(' ', '-');
};

export const getAnnotationValues = (selectedAnnotations: Annotation[]) => {
  // if user selects only one annotation => return all its data
  if (selectedAnnotations.length === 1) {
    return selectedAnnotations[0].data;
  }

  // if user selects two or more annotations => return only those data fields that match between those annotations
  const values: Record<string, string> = {};
  const [compareAnnotation, ...rest] = selectedAnnotations.map((annotation) => annotation.data);

  for (const key in compareAnnotation) {
    if (rest.every((annot) => annot[key] === compareAnnotation[key])) {
      values[key] = compareAnnotation[key];
    }
  }

  return values;
};

export const getAnnotationCurrentColor = (
  annotation: Core.Annotations.Annotation,
  colorMode: string,
) => {
  if (colorMode === 'fillColor') {
    return (annotation as Core.Annotations.RectangleAnnotation).FillColor.toHexString();
  }

  return annotation.Color.toHexString();
};

export const renderDefaultValueInput = ({
  inputType,
  selectOptions,
}: {
  inputType: string;
  selectOptions?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
}) => {
  if (inputType === INPUT_TYPES_OPTIONS.color.value) {
    return <ColorPickerRHF name='defaultValue' />;
  }

  if (inputType === INPUT_TYPES_OPTIONS.select.value) {
    return (
      <ReactSelectRHF
        styles={{ control: (base) => ({ ...base, height: '30px' }) }}
        name='defaultValue'
        options={selectOptions || []}
      ></ReactSelectRHF>
    );
  }

  return (
    <TextFieldRHF
      name='defaultValue'
      placeholder='Default Value'
      wrapperProps={{ height: '30px' }}
    />
  );
};

export const constructColorString = (colorInput: Color) => {
  return isInHexFormat(colorInput.color)
    ? HEXToRGB(colorInput.color, colorInput.alpha.toString())
    : colorInput.color;
};
