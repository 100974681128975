const invalidMessage = (value) => `Invalid ${value} format`;

export const EMAIL_MESSAGE = invalidMessage('email');
export const EMAIL_DETAIL_MESSAGE = 'Please enter an email in this format: name@example.com';
export const USERNAME_MESSAGE = invalidMessage('username');
export const PHONE_MESSAGE = invalidMessage('p');

export const REQUIRE_MESSAGE = 'This field is required';
export const INTEGER_MESSAGE = 'Should be integer';
export const INCORRECT_SYMBOL_MESSAGE = 'Использован запрещённый символ';
