import { Td, Tr } from '@chakra-ui/react';

import { styles } from './styles';
import { TABLE_DATA } from './table-data';

export const TableHeader = () => {
  return (
    <Tr>
      {TABLE_DATA.headers.map((header, idx) => {
        if (idx === 0) {
          return (
            <Td key={idx} sx={styles.tableCell}>
              {header.icon}
            </Td>
          );
        }
        return (
          <Td key={idx} sx={styles.tableCell}>
            {header.title}
          </Td>
        );
      })}
    </Tr>
  );
};
