import React from 'react';

export const AnnotationsIcon = ({ size = '15px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='10' fill='#B9B9C3' />
      <path d='M10 15V9' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
      <circle
        cx='1'
        cy='1'
        r='0.5'
        transform='matrix(1 0 0 -1 9 7)'
        fill='#B9B9C3'
        stroke='white'
      />
    </svg>
  );
};
