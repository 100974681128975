import { Box, Button } from '@chakra-ui/react';
import { PageHeader } from 'components/page-header';
import { useNavigate, useParams } from 'react-router-dom';

export const PageHeaderReportSheet = () => {
  const navigate = useNavigate();
  const params = useParams();

  const handleBackToReport = () => {
    navigate(`/projects/${params.id}/reports`);
  };
  return (
    <PageHeader
      title='Projects'
      headerHeight='48px'
      actionButtons={
        <Box>
          <Button
            variant='outline'
            onClick={handleBackToReport}
            height='36px'
            mr='9px'
            borderWidth='1px'
          >
            Back to reports
          </Button>
          <Button variant='primary' height='36px'>
            Download
          </Button>
        </Box>
      }
      withoutToggle
    />
  );
};
