import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';
import { pxToRem } from 'utils';

export const styles = {
  tabWrapper: {
    marginTop: '15px',
    marginLeft: '36px',
    overflowY: 'auto',
    maxHeight: pxToRem(168),
    ...WEBKIT_SCROLLBAR_STYLES,
  },
  tableWrapper: {
    borderRadius: '10px 10px 0px 0px',
    border: '1px solid #EFEEFF',
    maxWidth: pxToRem(1539),
    ...WEBKIT_SCROLLBAR_STYLES,
  },
  tableHeader: {
    background: '#EFEEFF',
    color: '#24202D',
    fontSize: '9px',
    fontFamily: 'Poppins',
    lineHeight: '118.5%',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  tableCell: {
    width: 'fit-content',
    padding: '10.5px',
  },
  tableRow: {
    height: '15px',
    _even: {
      background: '#F3F2FA',
    },
  },
};
