import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState, WORK_MODES } from 'types/redux';

export const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    dirty: false,
    selectedTool: '',
    areAnnotationsDetected: false,
    isDocumentLoaded: true,
    workMode: WORK_MODES.BRANCH,
    activeTabIndex: 1,
  },
  reducers: {
    setDirty(state: ApplicationState, action: PayloadAction<{ isDirty: boolean }>) {
      state.dirty = action.payload.isDirty;
    },
    setSelectedTool: (state: ApplicationState, action: PayloadAction<{ tool: string }>) => {
      state.selectedTool = action.payload.tool;
    },
    submitAnnotationsDetection(
      state: ApplicationState,
      action: PayloadAction<{
        areAnnotationsDetected: boolean;
      }>,
    ) {
      state.areAnnotationsDetected = action.payload.areAnnotationsDetected;
    },
    setDocumentLoaded(
      state: ApplicationState,
      action: PayloadAction<Pick<ApplicationState, 'isDocumentLoaded'>>,
    ) {
      state.isDocumentLoaded = action.payload.isDocumentLoaded;
    },
    setWorkMode(
      state: ApplicationState,
      action: PayloadAction<{
        workMode: WORK_MODES;
      }>,
    ) {
      state.workMode = action.payload.workMode;
    },
  },
});

export const applicationActions = applicationSlice.actions;
