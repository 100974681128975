import { APIDrawingCategories } from 'pages/projects/project/drawings/card-list/constants';

export const DRAWINGS_SIDEBAR_CATEGORIES = [
  { key: APIDrawingCategories.unknown, label: 'Unknown' },
  {
    key: APIDrawingCategories.general,
    label: 'General',
  },
  {
    key: APIDrawingCategories.electrical,
    label: 'Electrical',
  },
  {
    key: APIDrawingCategories.architectural,
    label: 'Architectural',
  },
];
