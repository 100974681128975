import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { annotationActions } from 'redux/slices';
import { ApryseAnnotation } from 'types';

export const useAnnotationSelectedListener = () => {
  const { instance } = useContext(WebviewerContext);
  const dispatch = useDispatch();

  const handler = useCallback(
    (annotations: ApryseAnnotation[], action: string) => {
      const annotationIds = annotations.map((annot) => annot.Id);
      if (action === 'selected') {
        dispatch(annotationActions.selectAnnotation({ documentId: 1, annotationIds }));
      } else if (action === 'deselected') {
        dispatch(annotationActions.deselectAnnotation({ documentId: 1, annotationIds }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (instance) {
      instance.Core.annotationManager.addEventListener('annotationSelected', handler);
    }

    return () => {
      if (instance) {
        instance.Core.annotationManager.removeEventListener('annotationSelected', handler);
      }
    };
  }, [handler, instance]);
};
