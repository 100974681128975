import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

import { redrawPathPoints } from './redraw-path-points';

interface DrawMcCableProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawMcCable = ({ viewerInstance, annotations, redraw }: DrawMcCableProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationStrokeColor = extractRGB(
        annotation?.data?.strokeColor || 'rgba(173, 216, 230, 1)',
      );
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.PolylineAnnotation;

      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }

      // @ts-ignore
      annotationToUpdate.data = {
        // @ts-ignore
        ...annotationToUpdate.data,
        ...annotation.data,
      };

      redrawPathPoints({ annotation, annotationToUpdate });
      return annotationToUpdate;
    });
    annotationManager.addAnnotations(annotationsToRedraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  } else {
    const annotationsToDraw = annotations.map((annotation) => {
      const annotationStrokeColor = extractRGB(
        annotation?.data?.strokeColor || 'rgba(173, 216, 230, 1)',
      );
      const MCCableClass =
        viewerInstance.Core.annotationManager.getRegisteredAnnotationTypes()[
          ANNOTATION_TYPES.MC_CABLE
        ][0];

      const polylineAnnotation = new MCCableClass({
        Id: annotation.id,
        PageNumber: annotation.pageNumber,
        //   StrokeThickness: 2,
        ...(annotationStrokeColor && {
          StrokeColor: new viewerInstance.Core.Annotations.Color(
            annotationStrokeColor[0] as number,
            annotationStrokeColor[1] as number,
            annotationStrokeColor[2] as number,
            annotationStrokeColor[3] as number,
          ),
        }),
        Style: annotation?.style,
        Dashes: annotation?.dashes,
        Hidden: !annotation?._isVisible,
        Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
      });

      annotation.pathPoints?.forEach((pathPoint: number[]) => {
        polylineAnnotation.addPathPoint(pathPoint[0], pathPoint[1]);
      });

      // @ts-ignore
      polylineAnnotation.data = annotation.data;
      return polylineAnnotation;
    });

    annotationManager.addAnnotations(annotationsToDraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
