import { Box } from '@chakra-ui/react';
import { Column, useTable } from 'react-table';

const Table = ({
  columns,
  data,
}: {
  columns: Column<object>[];
  data: Record<string, string>[];
}) => {
  const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps } = useTable({
    columns,
    data,
  });

  return (
    <Box
      as='table'
      {...getTableProps()}
      w='full'
      sx={{
        th: {
          fontWeight: 100,
          fontSize: '10.5px',
        },
        td: {
          fontSize: '9px',
        },
      }}
    >
      <Box as='thead'>
        {headerGroups.map((headerGroup) => (
          <Box
            as='tr'
            {...headerGroup.getHeaderGroupProps()}
            sx={{
              '&:first-of-type': {
                bg: 'purple.100',
                color: 'white',
              },
              bg: 'purple.75',
            }}
          >
            {headerGroup.headers.map((column) => (
              <Box as='th' {...column.getHeaderProps()}>
                {column.render('Header')}
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      <Box as='tbody' {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Box
              as='tr'
              {...row.getRowProps()}
              _odd={{
                bg: 'purple.25',
              }}
            >
              {row.cells.map((cell) => {
                return (
                  <Box as='td' {...cell.getCellProps()} textAlign='center'>
                    {cell.render('Cell')}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Table;
