import { DEFAULT_HEADER_HEIGHT } from 'constants/styles';
import { pxToRem } from 'utils';

export const styles = {
  headerWrapper: {
    justifyContent: 'space-between',
    paddingX: '24px',
    alignItems: 'center',
    height: pxToRem(DEFAULT_HEADER_HEIGHT),
    background: 'white',
    display: 'flex',
  },
  headerFunctionalWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  projectsBtn: {
    padding: '7.5px 12px',
    fontSize: '12px',
    marginLeft: '16px',
  },
  knowledgebaseBtn: {
    padding: '7.5px 12px',
    fontSize: '12px',
    marginLeft: '12px',
  },
  buttonText: {
    marginLeft: '4.5px',
  },
};
