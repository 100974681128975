import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

interface drawNoFlyZoneProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawNoFlyZone = ({
  viewerInstance,
  annotations,
  redraw = false,
}: drawNoFlyZoneProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(annotation.data.fillColor || 'rgba(146, 232, 232, 1)');
      const annotationStrokeColor = extractRGB(
        annotation.data.strokeColor || 'rgba(153, 153, 153, 1)',
      );
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.RectangleAnnotation;

      if (annotationFillColor) {
        annotationToUpdate.FillColor = new viewerInstance.Core.Annotations.Color(
          annotationFillColor[0] as number,
          annotationFillColor[1] as number,
          annotationFillColor[2] as number,
          annotationFillColor[3] as number,
        );
      }

      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }
      return annotationToUpdate;
    });
    annotationManager.addAnnotations(annotationsToRedraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  } else {
    const annotationsToDraw = annotations.map((annotation) => {
      const ZoneAnnotation = viewerInstance.Core.Annotations.CustomAnnotation.createFromClass(
        'NO_FLY_ZOME',
        viewerInstance.Core.Annotations.RectangleAnnotation,
      );

      viewerInstance.Core.Annotations.setCustomDrawHandler(
        ZoneAnnotation,
        function drawHandler(ctx, pageMatrix, rotation, options) {
          options.originalDraw(ctx, pageMatrix, rotation);

          let fontSize;

          // @ts-ignore
          if (this.Height > this.Width) {
            // @ts-ignore
            fontSize = this.Width * 0.1;
          } else {
            // @ts-ignore
            fontSize = this.Height * 0.1;
          }

          ctx.save();
          ctx.fillStyle = '#FFFFFF';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = `100 ${fontSize || 0}px sans-serif`;
          // @ts-ignore
          const X = this.X + this.Width / 2;
          // @ts-ignore
          const Y = this.Y + this.Height / 2;
          ctx.fillText('NO FLY ZONE', X, Y);
          ctx.restore();
        },
      );

      const zoneAnnotation = new ZoneAnnotation({
        Id: annotation.id || annotation.Id,
        PageNumber: annotation.pageNumber || annotation.PageNumber,
        StrokeColor: new viewerInstance.Core.Annotations.Color(255, 165, 0, 1),
        FillColor: new viewerInstance.Core.Annotations.Color(255, 165, 0, 0.7),
        Style: annotation?.style,
        Dashes: annotation?.dashes,
        Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
        X: annotation?.x || annotation.X,
        Y: annotation?.y || annotation.Y,
        Width: annotation?.width || annotation.Width,
        Height: annotation?.height || annotation.Height,
      });
      return zoneAnnotation;
    });
    annotationManager.addAnnotations(annotationsToDraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
