import { Box, Image, Text } from '@chakra-ui/react';
import FolderOutlineIcon from 'assets/icons/FolderOutlineIcon';
import { useCreateImageUrl } from 'hooks/useCreateImageUrl';
import { ProjectCardMenu } from 'pages/projects/components/menu/project-card-menu';
import { styles } from 'pages/projects/components/project-card/project-card.styles';
import { ProjectFiles } from 'pages/projects/components/project-files/project-files';
import { ProjectInfoSection } from 'pages/projects/components/project-info-section/project-info-section';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DrawerProject } from 'types';

interface IProjectCardProps {
  projectData: DrawerProject;
}

const ProjectCard = React.forwardRef(({ projectData }: IProjectCardProps, ref: any) => {
  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate(`/projects/${projectData?.id}/reports`);
  };
  const { imageUrl } = useCreateImageUrl(
    `${process.env.REACT_APP_PROJECTS_API}projects/${projectData.id}/preview`,
  );

  return (
    <Box ref={ref} sx={styles.projectCardWrapper}>
      <Box sx={styles.cardMenuWrapper}>
        <ProjectCardMenu addCover />
      </Box>
      <Box sx={styles.cardCoverWrapper}>
        {imageUrl?.length ? (
          <Image src={imageUrl} alt='cover' sx={styles.cardCoverImage} onClick={handleLinkClick} />
        ) : (
          <FolderOutlineIcon size='61.5px' />
        )}
      </Box>
      <Text sx={styles.projectNameText} onClick={handleLinkClick}>
        {projectData?.name}
      </Text>
      <ProjectInfoSection handleLinkClick={handleLinkClick} projectData={projectData} />
      <ProjectFiles handleLinkClick={handleLinkClick} projectFilesData={projectData.filesMeta} />
    </Box>
  );
});

export default ProjectCard;
