import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { capitalizeWord } from 'utils/capitalize';

const getPathParamEntityName = (type: string, id: string) => {
  // TODO: get name of entity by it`s type from redux store after connecting with BE
  return `${capitalizeWord(type)} ${id}`;
};

export const useGetBreadcrumbs = (path?: string, itemsToSkip = 1) => {
  const location = useLocation();
  const skippedItems = itemsToSkip;
  const template = path?.replace('/', '').split('/').slice(skippedItems);
  const locations = location.pathname.replace('/', '').split('/');
  const splittedLocations = locations.slice(skippedItems);

  const breadCrumbs = useMemo(() => {
    if (!path) {
      return [];
    }

    return template?.map((item, index) => {
      const label =
        item.includes(':') && splittedLocations[index - 1]
          ? getPathParamEntityName(splittedLocations[index - 1], splittedLocations[index])
          : capitalizeWord(splittedLocations[index]);
      const isDisabled = splittedLocations.length - 1 === index;
      const sliceEnd = index + skippedItems + 1;

      return {
        label,
        link: `/${locations.slice(0, sliceEnd).join('/')}`,
        disabled: isDisabled,
      };
    });
  }, [path, splittedLocations, template]);
  return breadCrumbs;
};
