import { pxToRem } from 'utils';

export const styles = {
  filesInfoWrapper: {
    marginTop: '21px',
    flexDirection: 'column',
  },
  filesInfoTextWrapper: {
    justifyContent: 'space-between',
    marginTop: '12px',
  },
  filesInfoText: {
    fontSize: '10.5px',
    color: 'gray.200',
    cursor: 'pointer',
  },
  progress: {
    borderRadius: pxToRem(100),
    width: '100%',
    height: pxToRem(13.875),
  },
};
