export const styles = {
  formFieldsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& input': {
      borderColor: 'purple.100',
    },
  },
  formSubmitButton: {
    height: '100%',
    padding: '10.5px 15px',
    width: '225px',
    marginTop: '26.25px',
    marginBottom: '3px',
    fontSize: '12px',
    color: 'white',
    fontWeight: 500,
  },
};
