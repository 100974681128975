import { Box, Flex, Spinner } from '@chakra-ui/react';
import { ProjectSidebar } from 'pages/projects/components/sidebar';
import DrawingsBlock from 'pages/projects/project/drawings/card-list';
import { useSelector } from 'react-redux';
import {
  getActiveTabIndex,
  getMutationRequestInfo,
  getWorkMode,
  isAnnotationListOpen,
  isTabOpen,
} from 'redux/selectors';
import { IProjectPageHeaderProps } from 'types';
import { State, WORK_MODES } from 'types/redux';

import SheetHeader from '../sheet-header';
import { styles } from '../styles';
import ApryseBlock from './apryse-block';
import ElementListBlock from './element-list-block';
import { TabList } from './sidebar/tab-list';
import { TabPanelContent } from './sidebar/tab-panel-content';

const SheetContent = ({ path }: IProjectPageHeaderProps) => {
  const workMode = useSelector(getWorkMode);
  const isOpen = useSelector(isTabOpen);
  const activeTab = useSelector(getActiveTabIndex);
  const isAnnotListOpen = useSelector(isAnnotationListOpen);
  const requestStatus = useSelector((state: State) =>
    getMutationRequestInfo(state, { requestEndpointName: 'updateAnnotationsServerData' }),
  );

  return (
    <Flex sx={styles.sheetPageWrapper}>
      <SheetHeader path={path} />
      <Flex sx={styles.mainBlockWrapper}>
        {requestStatus?.status === 'pending' ? (
          <Flex sx={styles.loaderWrapper}>
            <Spinner
              thickness='3px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Flex>
        ) : null}
        <Flex>
          {workMode === WORK_MODES.BRANCH ? (
            <TabList />
          ) : (
            <ProjectSidebar styleProps={{ marginRight: '9px' }} minimizedEnabled={true} />
          )}
        </Flex>
        <Flex flexDirection='column' w='100%'>
          <Flex h={isAnnotListOpen ? '65%' : '100%'}>
            {workMode === WORK_MODES.BRANCH ? <TabPanelContent /> : null}
            <Flex sx={styles.functionalBlockWrapper(isAnnotListOpen, isOpen)}>
              {activeTab === 1 && isOpen && workMode === WORK_MODES.BRANCH ? (
                <Box sx={styles.drawingsWrapper}>
                  <DrawingsBlock />
                </Box>
              ) : null}
              <ApryseBlock />
            </Flex>
          </Flex>
          <ElementListBlock />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SheetContent;
