import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext } from 'react';

import { HIDDEN_TOOLS, MARQUEE_ZOOM_TOOL_IMAGE, TOOLBAR_GROUP } from '../constants';

export const useRemoveUnusedElements = () => {
  const { instance } = useContext(WebviewerContext);

  return useCallback(() => {
    if (instance) {
      instance.UI.setHeaderItems((header) => {
        const items = header.getItems();

        header.update([
          {
            toolName: 'MarqueeZoomTool',
            type: 'toolButton',
            img: MARQUEE_ZOOM_TOOL_IMAGE,
          },
          ...items,
        ]);
      });

      instance.UI.setHeaderItems((header) => {
        HIDDEN_TOOLS.INSERT.forEach((toolDataName) => {
          header.getHeader(TOOLBAR_GROUP.INSERT).get(toolDataName).delete();
        });

        HIDDEN_TOOLS.ANNOTATE.forEach((toolDataName) => {
          header.getHeader(TOOLBAR_GROUP.ANNOTATE).get(toolDataName).delete();
        });

        HIDDEN_TOOLS.SHAPES.forEach((toolDataName) => {
          header.getHeader(TOOLBAR_GROUP.SHAPES).get(toolDataName).delete();
        });
      });

      instance.UI.disableElements([
        TOOLBAR_GROUP.EDIT,
        TOOLBAR_GROUP.FILL_AND_SIGN,
        TOOLBAR_GROUP.FORMS,
        TOOLBAR_GROUP.INSERT,
        TOOLBAR_GROUP.ANNOTATE,
        TOOLBAR_GROUP.VIEW,
        TOOLBAR_GROUP.SHAPES,
        TOOLBAR_GROUP.LEFT_PANEL,
        TOOLBAR_GROUP.LEFT_PANEL_BUTTON,
        TOOLBAR_GROUP.VIEW_CONTROLS,
        TOOLBAR_GROUP.OUTLINES_PANEL,
        TOOLBAR_GROUP.VIEW_CONTROLS_BUTTON,
        TOOLBAR_GROUP.MENU_BUTTON,
      ]);

      // @ts-ignore
      instance.UI.disableNotesPanel();
      instance.UI.disableFeatures(['Search']);
    }
  }, [instance]);
};
