import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext, useEffect } from 'react';

export const useDeselectAllAnnotations = () => {
  const { instance } = useContext(WebviewerContext);

  const handler = useCallback(
    (event: React.KeyboardEvent) => {
      if (instance) {
        const { annotationManager } = instance.Core;

        if (event.key === 'Escape') {
          annotationManager.deselectAllAnnotations();
        }
      }
    },
    [instance],
  );

  useEffect(() => {
    if (instance) {
      instance.Core.documentViewer.addEventListener('keyDown', handler);
    }

    return () => {
      if (instance) {
        instance.Core.documentViewer.removeEventListener('keyDown', handler);
      }
    };
  }, [instance, handler]);
};
