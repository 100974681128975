import TextField from 'components/text-field';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';

interface DefaultColumnFilterProps {
  column: {
    filterValue?: string;
    setFilter: Dispatch<SetStateAction<string | undefined>>;
  };
}

export default function DefaultColumnFilter({
  column: { filterValue, setFilter },
}: DefaultColumnFilterProps) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
  };

  return (
    <TextField
      value={filterValue || ''}
      onChange={handleChange}
      placeholder={`Search ...`}
      wrapperProps={{ h: '22.5px' }}
    />
  );
}
