import { BORDER_DASH_STYLES } from 'constants/styles';

export const styles = {
  fileWindowWrapper: {
    height: '157.5px',
    width: '100%',
    borderRadius: '9px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    ...BORDER_DASH_STYLES,
  },
  fileWindowTextWrapper: {
    marginLeft: '19.5px',
  },
  addFileTitle: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '69.75%',
    color: 'purple.100',
  },
  addFileSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '105%',
    color: 'gray.500',
  },
};
