import { Flex, Table, TableContainer, Tbody, Td, Thead, Tr } from '@chakra-ui/react';

import { styles } from './styles';
import { TABLE_DATA } from './table-data';
import { TableHeader } from './table-header';

const FeederSchedule = () => {
  return (
    <Flex sx={styles.tabWrapper}>
      <TableContainer sx={styles.tableWrapper} h='full'>
        <Table>
          <Thead sx={styles.tableHeader}>
            <TableHeader />
          </Thead>
          <Tbody>
            {TABLE_DATA.rows.map((row, idx) => (
              <Tr key={`row-${idx}`} sx={styles.tableRow}>
                {row.cells.map((cell, idx) => (
                  <Td key={`cell-${idx}`} sx={styles.tableCell}>
                    {cell.value}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default FeederSchedule;
