import { Button, Flex, Text } from '@chakra-ui/react';
import { AddIcon } from 'assets/icons';
import { PageHeader } from 'components/page-header';
import { ERoutes } from 'components/router/types';
import { styles } from 'pages/projects/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const PageHeaderReport = () => {
  const [searchValue, setSearchValue] = useState('');

  const navigate = useNavigate();

  return (
    <PageHeader
      title='Projects'
      headerHeight='48px'
      actionButtons={
        <Button
          variant='primary'
          sx={styles.addProjectBtn}
          onClick={() => navigate(ERoutes.PROJECTS + ERoutes.ADD_PROJECT)}
        >
          <Flex alignItems='center'>
            <AddIcon size='12.75px' />
            <Text ml='5.625px'>Add File</Text>
          </Flex>
        </Button>
      }
      withSortingMenu={true}
      withSearch={true}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
    />
  );
};
