import { Box, Button, Flex } from '@chakra-ui/react';
import Card from 'components/card';
import { PageHeader } from 'components/page-header';
import { EUrlSearchParamKeys } from 'components/page-header/types';
import { useGetUrlSearchParams } from 'hooks';
import { AddProjectForm } from 'pages/add-project/components/add-project-form/add-project-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { documentActions } from 'redux/slices';

import { styles } from './styles';

const AddProject = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCancelButtonClickHandler = () => {
    dispatch(documentActions.resetDocuments());
    navigate('/projects');
  };
  const { urlSearchParamsObject } = useGetUrlSearchParams();
  const projectName = urlSearchParamsObject[EUrlSearchParamKeys.PROJECT_NAME];

  return (
    <Box sx={styles.projectsWrapper}>
      <PageHeader
        title={projectName || 'Creating new project'}
        actionButtons={
          <Button variant='secondary' sx={styles.cancelBtn} onClick={onCancelButtonClickHandler}>
            Cancel
          </Button>
        }
      />
      <Flex sx={styles.pageContentWrapper}>
        <Card wrapperProps={styles.contentCard}>
          <AddProjectForm />
        </Card>
      </Flex>
    </Box>
  );
};

export default AddProject;
