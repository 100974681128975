import { Annotation } from 'types/redux';

import { fitScaleForPathPoints } from '../apryse-block/utils';

interface FilterPanelsArguments {
  annotationList: Annotation[];
  docDimensions: {
    width: number;
    height: number;
  };
}

export const filterPanels = ({ annotationList, docDimensions }: FilterPanelsArguments) => {
  const documentPanels = annotationList.filter(
    (annotation) =>
      annotation.elementType.includes('Panel') || annotation.elementType.includes('PANEL'),
  );
  const resultObject = {};

  documentPanels.forEach((panel, idx) => {
    const convertedPathPoints = fitScaleForPathPoints({
      docDimensions,
      pathPoints: panel.pathPoints as number[][],
    });

    Object.assign(resultObject, {
      [`panel:${idx}`]: [
        {
          x0: convertedPathPoints[0][0],
          x1: convertedPathPoints[2][0],
          y0: convertedPathPoints[0][1],
          y1: convertedPathPoints[2][1],
          name: panel.data.panelName || '',
        },
      ],
    });
  });

  return resultObject;
};
