import { Box, Flex, FormControl, FormErrorMessage, Input, Text } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { TrophyIcon } from 'assets/icons';
import React, { KeyboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import { styles } from './styles';

interface FormInputProps {
  label: string;
  name: string;
  step: number | string;
  heightInput?: string;
  fontSizeInput?: string;
}

export const FormInput = ({
  label,
  name,
  step,
  heightInput,
  fontSizeInput,
}: FormInputProps): JSX.Element => {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger,
  } = useFormContext();
  const error = errors[name];
  const onBlur = async () => {
    const value = getValues(name);
    if (/^[-0-9.,]+$/.exec(value)) {
      setValue(name, parseFloat(value.toString().replace(/,/g, '.')));
    }
    await trigger(name);
  };
  const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };
  const registeredInput = register(name, {
    onBlur,
  });

  return (
    <FormControl isInvalid={Boolean(error)}>
      <Box>
        <Flex alignItems='center' whiteSpace='nowrap'>
          <Text sx={styles.labelText}>{label}</Text>
          {label === 'Grouping' || label === 'Fly Zones' ? <TrophyIcon /> : null}
        </Flex>
        <Input
          {...registeredInput}
          type='number'
          min={0}
          isInvalid={Boolean(error)}
          step={step}
          onKeyDown={onKeyDown}
          h={heightInput}
          fontSize={fontSizeInput}
        />
        {Boolean(error) && (
          <ErrorMessage
            errors={errors}
            name={name as string}
            as={FormErrorMessage}
            fontSize='12px'
            top='calc(100% + 3.75px)'
            margin={0}
          />
        )}
      </Box>
    </FormControl>
  );
};
