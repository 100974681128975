import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { AddressIcon, FileListIcon, PaperSheetIcon } from 'assets/icons';
import { useCreateImageUrl } from 'hooks/useCreateImageUrl';
import {
  APIDrawingCategories,
  UNKNOWN_DATA_TEXT,
} from 'pages/projects/project/drawings/card-list/constants';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ProjectsApiUrl } from 'redux/slices';
import { TDrawing, TKeyOfDrawingCategories } from 'types/drawing';
import { cropText } from 'utils';
import { capitalizeWord } from 'utils/capitalize';

import { sheetCardWrapperActiveStyles, styles } from './styles';

interface ISheetCardProps {
  sheetData: TDrawing;
  isSelected: boolean;
}

export const SheetCard = ({ sheetData, isSelected }: ISheetCardProps) => {
  const { id, fileName, scaleImperial, revision, height, width, sheetNumber, category } = sheetData;

  const params = useParams();
  const projectId = params.id || '';
  const previewQueryUrl = ProjectsApiUrl.DRAWING_PREVIEW({ drawingId: id, projectId }).replace(
    '/',
    '',
  );
  const { imageUrl } = useCreateImageUrl(`${process.env.REACT_APP_PROJECTS_API}${previewQueryUrl}`);

  const drawingCategory = (Object.keys(APIDrawingCategories) as TKeyOfDrawingCategories[]).find(
    (key: TKeyOfDrawingCategories) => APIDrawingCategories[key] === category,
  ) as TKeyOfDrawingCategories;

  const castToUnknownText = (value?: string | null | number) => value || UNKNOWN_DATA_TEXT;
  const drawingSize = !width || !height ? UNKNOWN_DATA_TEXT : `${width} x ${height}`;
  const sheetCardWrapperStyles = {
    ...styles.sheetCardWrapper,
    ...(isSelected ? sheetCardWrapperActiveStyles : {}),
  };

  return (
    <Flex sx={sheetCardWrapperStyles}>
      <Flex sx={styles.sheetCardImageWrapper}>
        {imageUrl?.length ? (
          <Image src={imageUrl} alt='previewImage' sx={styles.sheetCardImage} />
        ) : (
          <FileListIcon fill='#7368F0' size='61.5px' />
        )}
      </Flex>
      <Text sx={styles.sheetCardNameText}>{cropText(fileName, 21)}</Text>
      <Flex justifyContent='space-between' w='100%'>
        <Flex alignItems='center'>
          <AddressIcon size='15px' />
          <Text sx={styles.sheetCardTypeText}>{capitalizeWord(drawingCategory)}</Text>
        </Flex>
        <Flex alignItems='center'>
          <PaperSheetIcon size='15px' />
          <Text sx={styles.sheetCardTypeText}>{castToUnknownText(sheetNumber)}</Text>
        </Flex>
      </Flex>
      <Flex sx={styles.expandableBlock}>
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Latest revision:</Text>
          <Text sx={styles.sheetAttributeValueText}>{castToUnknownText(revision)}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Page size:</Text>
          <Text sx={styles.sheetAttributeValueText}>{drawingSize}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Scale:</Text>
          <Text sx={styles.sheetAttributeValueText}>{castToUnknownText(scaleImperial)}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Upload date:</Text>
          <Text sx={styles.sheetAttributeValueText}>{UNKNOWN_DATA_TEXT}</Text>
        </Flex>
        <Box sx={styles.sheetCardDivider} />
        <Flex justifyContent='space-between'>
          <Text sx={styles.sheetAttributeNameText}>Uploaded by:</Text>
          <Text sx={styles.sheetAttributeValueText}>{UNKNOWN_DATA_TEXT}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
