import { Flex } from '@chakra-ui/react';
import ApryseBlock from 'pages/sheet/components/apryse-block';

const ProjectDrawings = () => {
  return (
    <Flex direction='column' flexGrow={1}>
      <ApryseBlock
        wrapperProps={{ p: 0, borderRadius: 0, h: 'unset', flexGrow: 5 }}
        blueprintStyles={{ borderRadius: 0 }}
        webviewerStyles={{ borderRadius: 0 }}
      />
    </Flex>
  );
};

export default ProjectDrawings;
