import React from 'react';

export const FileAddIcon = ({ width = '82px', height = '83px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 82 83'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M51.125 13.9168H17.375V68.5835H64.625V27.5835H51.125V13.9168ZM10.625 10.4728C10.625 8.6005 12.1336 7.0835 13.9966 7.0835H54.5L71.375 24.1668V71.9762C71.3781 72.4249 71.2939 72.8698 71.1272 73.2856C70.9604 73.7013 70.7144 74.0797 70.4032 74.3992C70.092 74.7187 69.7217 74.973 69.3134 75.1476C68.9051 75.3222 68.4668 75.4137 68.0236 75.4168H13.9764C13.09 75.4106 12.2417 75.0516 11.6146 74.4174C10.9875 73.7832 10.6321 72.9248 10.625 72.0275V10.4728ZM37.625 37.8335V27.5835H44.375V37.8335H54.5V44.6668H44.375V54.9168H37.625V44.6668H27.5V37.8335H37.625Z'
        fill='#7368F0'
      />
    </svg>
  );
};
