import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

interface drawRectangleProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawRectangle = ({ viewerInstance, annotations, redraw }: drawRectangleProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(annotation.data.fillColor || '');
      const annotationStrokeColor = extractRGB(annotation.data.strokeColor || '');
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.RectangleAnnotation;
      if (annotationFillColor) {
        annotationToUpdate.FillColor = new viewerInstance.Core.Annotations.Color(
          annotationFillColor[0] as number,
          annotationFillColor[1] as number,
          annotationFillColor[2] as number,
          annotationFillColor[3] as number,
        );
      }
      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }
      return annotationToUpdate;
    });
    annotationManager.addAnnotations(annotationsToRedraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  } else {
    const annotationsToDraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(annotation.data.fillColor || '');
      const annotationStrokeColor = extractRGB(annotation.data.strokeColor || '');
      const rectangleAnnotation = new viewerInstance.Core.Annotations.RectangleAnnotation({
        Id: annotation.id,
        PageNumber: annotation.pageNumber,
        ...(annotationFillColor && {
          FillColor: new viewerInstance.Core.Annotations.Color(
            annotationFillColor[0] as number,
            annotationFillColor[1] as number,
            annotationFillColor[2] as number,
            annotationFillColor[3] as number,
          ),
        }),
        ...(annotationStrokeColor && {
          StrokeColor: new viewerInstance.Core.Annotations.Color(
            annotationStrokeColor[0] as number,
            annotationStrokeColor[1] as number,
            annotationStrokeColor[2] as number,
            annotationStrokeColor[3] as number,
          ),
        }),
        Style: annotation?.style,
        Dashes: annotation?.dashes,
        Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
        X: annotation?.x,
        Y: annotation?.y,
        Width: annotation?.width,
        Height: annotation?.height,
        Hidden: !annotation?._isVisible,
      });
      // @ts-ignore
      rectangleAnnotation.data = annotation.data;
      return rectangleAnnotation;
    });
    annotationManager.addAnnotations(annotationsToDraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
