import WebviewerProvider from 'context/webviewer/webviewer-provider';
import ApryseBlock from 'pages/sheet/components/apryse-block';
import { useGetSource } from 'pages/sources-page/hooks/getSource';

export function SourcesPage(): JSX.Element {
  const { file } = useGetSource();

  return (
    <WebviewerProvider>
      <ApryseBlock wrapperProps={{ width: '100%', height: '100%' }} file={file} />
    </WebviewerProvider>
  );
}
