export const WEBKIT_SCROLLBAR_STYLES = {
  '&::-webkit-scrollbar': {
    width: '9px',
    borderRadius: '8px',
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `#cfccef`,
  },
};

export const WEBKIT_SCROLLBAR_GRAY = {
  scrollbarColor: '#AEADBC #F3F2FA',
  scrollbarWidth: 'thin',

  '&::-webkit-scrollbar': {
    width: '4.5px',
    height: '4.5px',
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px #F3F2FA',
    borderRadius: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#AEADBC',
    borderRadius: '4px',
  },
};

export const BORDER_DASH_STYLES = {
  backgroundImage:
    'repeating-linear-gradient(21deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px), repeating-linear-gradient(111deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px), repeating-linear-gradient(201deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px), repeating-linear-gradient(291deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px)',
  backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  backgroundRepeat: 'no-repeat',
};

export const DEFAULT_HEADER_HEIGHT = 45;
