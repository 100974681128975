import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext } from 'react';

import { DEFAULT_SCALE } from '../constants';

export const useSetupScale = () => {
  const { instance } = useContext(WebviewerContext);

  return useCallback(() => {
    if (instance) {
      const { documentViewer, Scale } = instance.Core;

      documentViewer.setToolMode(documentViewer.getTool('AnnotationCreateDistanceMeasurement'));
      documentViewer.setToolMode(documentViewer.getTool('PanTool'));

      /* Start of creating default scale preset */
      const scale = new Scale(DEFAULT_SCALE.SCALE_RATIO, DEFAULT_SCALE.PRECISION);

      const measurementManager = documentViewer.getMeasurementManager();
      measurementManager.createAndApplyScale({ scale, applyTo: [] });
      /* End of creating default scale preset */

      instance.UI.setToolbarGroup('toolbarGroup-View');
    }
  }, [instance]);
};
