// @ts-ignore
// eslint-disable-next-line import/extensions
import { AuthContext, IAuthContext } from 'auth-service/index.ts';
import { HEADER_SORT_VARIANTS } from 'components/page-header/constants';
import { useGetUrlSearchParams } from 'hooks';
import { useToastIfError } from 'pages/add-project/components/add-project-form/hooks/hooks';
import { FETCHED_PAGES_COUNT } from 'pages/projects/constants';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { projectsApiSlice, useGetProjectsQuery } from 'redux/slices';

type TSortKeys = keyof typeof HEADER_SORT_VARIANTS;

export const useGetProjects = (columnsCount: number) => {
  const [page, setPage] = useState(1);
  const { urlSearchParamsObject } = useGetUrlSearchParams();
  const { sort, query } = urlSearchParamsObject;
  const { token } = useContext<IAuthContext>(AuthContext);
  const projectsQuery = useGetProjectsQuery(
    {
      sort: sort as (typeof HEADER_SORT_VARIANTS)[TSortKeys],
      search: query ? `name:${query}` : undefined,
      page,
      size: columnsCount * FETCHED_PAGES_COUNT,
    },
    { skip: !columnsCount || !token },
  );
  const checkForErrors = useToastIfError();
  const dispatch = useDispatch();
  const [queryArgs, setQueryArgs] = useState({ sort, query });
  const invalidateQuery = useCallback(
    () => dispatch(projectsApiSlice.util.resetApiState()),
    [dispatch],
  );
  const { data } = projectsQuery;
  const projectsArray = data?.body;
  const paginationInfo = data?.meta;

  const fetchMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (projectsQuery.currentData && (queryArgs.sort !== sort || queryArgs.query !== query)) {
      setQueryArgs(() => ({
        sort,
        query,
      }));
      invalidateQuery();
    }
  }, [queryArgs, projectsQuery.currentData, invalidateQuery, sort, query]);

  useEffect(() => {
    checkForErrors(projectsQuery, 'query');
  }, [checkForErrors, projectsQuery]);

  return { projectsArray, paginationInfo, projectsQuery, fetchMore, invalidateQuery };
};
