import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';
import { pxToRem } from 'utils';

export const styles = {
  layersInfoWrapper: {
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...WEBKIT_SCROLLBAR_STYLES,
    padding: 0,
  },

  layerInfoWrapper: {
    alignItems: 'center',
    paddingLeft: '3px',
    width: '100%',
  },
  layerNameText: {
    width: '100%',
    display: 'flex',
    fontSize: '10.5px',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '7.5px',
  },
  layersTitleText: {
    fontSize: '13.5px',
    lineHeight: '93%',
    fontWeight: 600,
    color: 'gray.400',
    whiteSpace: 'nowrap',
  },
  scrollWrapper: {
    flexDirection: 'column',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
    '&::-webkit-scrollbar-track': {
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '4px',
    },
  },
  innerScrollWrapper: {
    flexDirection: 'column',
  },
  accordionButton: (activeLayer: boolean) => ({
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '24px',
    background: activeLayer ? 'purple.50' : 'unset',
    '&:hover': {
      background: 'purple.50',
      transition: '0.2s',
    },
  }),

  annotationInfoWrapper: {
    marginLeft: '6% !important',
    fontSize: '12px',
    color: 'gray.400',
  },
  textFieldLabel: {
    fontSize: '12px',
    lineHeight: '105%',
    fontWeight: 600,
    marginBottom: pxToRem(2),
    marginTop: '12px',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
  layerFormFieldWrapper: {
    flexDirection: 'column',
    width: '100%',
  },
  layerFieldLabel: {
    fontSize: '10.5px',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
};
