import {
  Icon,
  MenuItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import FormContent from 'pages/sheet/components/layers-block/form-content';
import React from 'react';
import { FaEdit } from 'react-icons/fa';
import { Layer } from 'types/redux';
import { funcWithPreventLogic } from 'utils/functWithPrevenLogicUtil';

interface ILayerModalProps {
  currentDocumentId: number;
  isEditMode?: boolean;
  layerToEdit?: Layer;
  isModalOpen?: boolean;
  setCloseModal?: VoidFunction;
}

export const LayersModal = ({
  isEditMode,
  currentDocumentId,
  layerToEdit,
  isModalOpen,
  setCloseModal,
}: ILayerModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onModalCloseHandler = () => {
    setCloseModal?.();
    onClose();
  };
  return (
    <>
      {isEditMode && (
        <MenuItem
          icon={<Icon as={FaEdit} fontSize='18px' />}
          onClick={funcWithPreventLogic(onOpen)}
        >
          Rename layer
        </MenuItem>
      )}
      <Modal isOpen={isOpen || !!isModalOpen} onClose={onModalCloseHandler} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditMode ? 'Edit' : 'Create'} layer</ModalHeader>
          <FormContent
            isEditMode={!!isEditMode}
            onClose={onModalCloseHandler}
            currentDocumentId={currentDocumentId}
            layerToEdit={layerToEdit}
          />
        </ModalContent>
      </Modal>
    </>
  );
};
