import { pxToRem } from 'utils';

export const styles = {
  projectCardWrapper: {
    width: '195px',
    border: '1px solid',
    borderColor: ' gray.75',
    borderRadius: pxToRem(6),
    padding: '12px 18px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    _hover: { border: '1px solid #7368F0' },
    height: 'max-content',
  },
  cardMenuWrapper: {
    position: 'absolute',
    right: '6px',
  },
  cardCoverWrapper: {
    width: '120px',
    height: '120px',
    borderRadius: pxToRem(6),
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  cardCoverImage: {
    objectFit: 'cover',
    width: 'inherit',
    height: 'inherit',
    cursor: 'pointer',
  },

  projectNameText: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'purple.100',
    marginTop: '12px',
    marginBottom: '15px',
    cursor: 'pointer',
    display: '-webkit-box',
    maxWidth: '150px',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
};
