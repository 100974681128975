import { Box } from '@chakra-ui/react';
import React from 'react';

import { ReportSheetTable } from './report-sheet-table';

export const ProjectReportSheet = () => {
  return (
    <Box m='-15px'>
      <ReportSheetTable />
    </Box>
  );
};
