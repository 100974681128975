import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';

export const styles = {
  sidebarWrapper: {
    marginLeft: '9px',
    padding: '13.5px',
    width: '203.25px',
    overflowY: 'auto',
    ...WEBKIT_SCROLLBAR_STYLES,
  },
  formDivider: {
    marginBottom: '9px',
    marginTop: '9px',
  },
  formSectionHeadingText: {
    color: 'black.50',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
  },
  selectCategorySection: {
    marginBottom: '25.5px',
  },
  selectCategoryItem: {
    padding: '7.5px 6px',
    fontSize: '10.5px',
    fontWeight: 400,
    color: 'black.50',
    justifyContent: 'space-between',
    borderRadius: '4.5px',
  },
  selected: {
    background: 'blue.10',
    alignItems: 'center',
  },

  formInput: {
    background: 'blue.10',
    border: '1px solid',
    borderColor: 'purple.100',
    borderRadius: '4.5px',
    padding: '7.5px 9px',
    _field: {
      fontSize: '10.5px',
    },
  },
  formDateText: {
    fontSize: '10.5px',
    fontWeight: 400,
    color: 'gray.200',
  },
  formSection: {
    marginBottom: '33px',
  },
  submitButton: {
    backgroundColor: 'purple.100',
    width: '100%',
    paddingTop: '9px',
    paddingBottom: '9px',
    color: 'white',
    marginTop: '33px',
  },
};
