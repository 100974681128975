import { ANNOTATION_TYPES, TOOL_NAMES } from 'constants/apryse';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useDispatchSelectAnnotation } from 'pages/sheet/components/apryse-block/hooks/useDispatchSelectAnnotation';
import { useCallback, useContext, useEffect } from 'react';
import { drawConduit } from 'utils/annotations/draw-conduit';

const countToJoin = 2;

export const useConnectPanelAndConduit = () => {
  const { instance } = useContext(WebviewerContext);
  const selectAnnotation = useDispatchSelectAnnotation();
  const cb = useCallback(() => {
    const selectedAnnotations = instance?.Core?.annotationManager?.getSelectedAnnotations();

    const isTypesToConnectExists = (selectedAnnotations: any[]) => {
      const template = {
        PANEL: false,
        [TOOL_NAMES.HOMERUN_BOX]: false,
      };

      selectedAnnotations.forEach(({ ToolName }) => {
        if (ToolName.includes('Panel')) {
          template.PANEL = true;
          return;
        }
        template[ToolName] = true;
      });

      return template[TOOL_NAMES.HOMERUN_BOX] && template.PANEL;
    };

    const canBeJoined =
      selectedAnnotations?.length === countToJoin && isTypesToConnectExists(selectedAnnotations);

    if (canBeJoined && instance) {
      const firstRectangle = selectedAnnotations[0];
      const secondRectangle = selectedAnnotations[1];

      const pathPoints = [
        [firstRectangle.X + firstRectangle.Width / 2, firstRectangle.Y + firstRectangle.Height / 2],
        [
          secondRectangle.X + secondRectangle.Width / 2,
          secondRectangle.Y + secondRectangle.Height / 2,
        ],
      ];
      const polylineId = Date.now().toString();
      const annotation: any = {
        id: polylineId,
        type: 'Polyline',
        elementType: ANNOTATION_TYPES.CONDUIT,
        pathPoints,
        pageNumber: 1,
        _isLocked: false,
        _isVisible: true,
        isReadOnly: false,
        data: {
          panels: `${firstRectangle.Id},${secondRectangle.Id}`,
        },
      };

      drawConduit({
        viewerInstance: instance,
        annotations: [annotation],
      });
      selectAnnotation(polylineId);
    }
  }, [instance, selectAnnotation]);

  useEffect(() => {
    if (instance) {
      instance.UI.hotkeys.on('shift+c', cb);
    }

    return () => {
      if (instance) {
        instance.UI.hotkeys.off('shift+c');
      }
    };
  }, [instance, cb]);
};
