import SelectField, { SelectFieldProps } from 'components/select';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectRHFProps extends SelectFieldProps {
  name: string;
  children: ReactNode;
}

const SelectRHF = ({ name, children, ...rest }: SelectRHFProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = Boolean(errors[name]);

  return (
    <SelectField hasError={hasError} {...rest} {...register(name)}>
      {children}
    </SelectField>
  );
};

export default SelectRHF;
