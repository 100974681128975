import React from 'react';

export const DrawingsIcon = ({ size = '16.5px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.3'
        d='M19.4253 12.2038C19.4253 14.1084 18.8605 15.9702 17.8024 17.5538C16.7443 19.1374 15.2403 20.3716 13.4807 21.1005C11.7212 21.8293 9.78496 22.02 7.917 21.6484C6.04903 21.2769 4.3332 20.3598 2.98647 19.013C1.63974 17.6663 0.722608 15.9505 0.351046 14.0825C-0.0205151 12.2145 0.170184 10.2783 0.899028 8.51875C1.62787 6.75916 2.86213 5.25522 4.44571 4.1971C6.02929 3.13899 7.89108 2.57422 9.79564 2.57422V12.2038H19.4253Z'
        fill='#AEADBC'
      />
      <path
        d='M12.2038 0.167011C13.4684 0.167011 14.7206 0.416088 15.8889 0.900023C17.0573 1.38396 18.1188 2.09327 19.013 2.98747C19.9072 3.88166 20.6165 4.94322 21.1005 6.11154C21.5844 7.27986 21.8335 8.53206 21.8335 9.79664L12.2038 9.79664L12.2038 0.167011Z'
        fill='#AEADBC'
      />
    </svg>
  );
};
