import { WebViewerInstance } from '@pdftron/webviewer';

interface showAnnotationProps {
  viewerInstance: WebViewerInstance;
  annotationId: string;
}

export const showAnnotation = ({ viewerInstance, annotationId }: showAnnotationProps) => {
  const annotationToShow = viewerInstance?.Core.annotationManager.getAnnotationById(annotationId);
  viewerInstance?.Core.annotationManager.showAnnotation(annotationToShow);
};
