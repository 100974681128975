import React from 'react';

export const ColumnListIcon = ({ width = '10.5px', height = '13.5px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect opacity='0.8' width='6' height='18' rx='1' fill='#B9B9C3' />
      <rect x='8' width='6' height='18' rx='1' fill='#AEADBC' />
    </svg>
  );
};
