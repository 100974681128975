import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';

import { styles } from './styles';

export const ApryseLoader = () => {
  return (
    <Box sx={styles.apryseLoader}>
      <Spinner thickness='3px' speed='0.65s' emptyColor='gray.200' color='purple.100' size='xl' />
    </Box>
  );
};
