import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Document, DocumentsState, State } from '../../types/redux';

const saveDocument = createAction<{ id: number }>('documents/saveDocument');

const saveAllDocuments = createAsyncThunk(
  'documents/saveAllDocuments',
  async (params, thunkAPI) => {
    const allDocumentsIds = Object.keys((thunkAPI.getState() as State).documents);

    allDocumentsIds.forEach((id) => {
      thunkAPI.dispatch(saveDocument({ id: Number(id) }));
    });
  },
);

const INITIAL_STATE = {
  openedDocuments: {},
  uploadDocuments: {},
};

export const documentSlice = createSlice({
  name: 'documents',
  initialState: INITIAL_STATE,
  reducers: {
    openDocument(state: DocumentsState, action: PayloadAction<Document>) {
      state.openedDocuments[action.payload.id] = action.payload;
    },
    uploadDocument(state: DocumentsState, action: PayloadAction<Document>) {
      state.uploadDocuments[action.payload.id] = action.payload;
    },
    closeDocument(state: DocumentsState, action: PayloadAction<Pick<Document, 'id'>>) {
      delete state.openedDocuments[action.payload.id];
    },
    deleteUploadedDocument(state: DocumentsState, action: PayloadAction<Pick<Document, 'id'>>) {
      console.log('action ==>', action);
      delete state.uploadDocuments[action.payload.id];
    },
    deleteUploadedDocuments(state: DocumentsState) {
      state.uploadDocuments = {};
    },
    closeDocuments(state: DocumentsState) {
      state.openedDocuments = {};
    },

    resetDocuments() {
      return INITIAL_STATE;
    },
    renameDocument(state: DocumentsState, action: PayloadAction<Pick<Document, 'id' | 'name'>>) {
      state.openedDocuments[action.payload.id].name = action.payload.name;
    },
    setDocumentAsReady(
      state: DocumentsState,
      action: PayloadAction<{ id: Document['id']; isReady: Document['isReady'] }>,
    ) {
      state.openedDocuments[action.payload.id].isReady = action.payload.isReady;
    },
    setDocumentAsProcessing(
      state: DocumentsState,
      action: PayloadAction<{ id: Document['id']; isProcessing: Document['isProcessing'] }>,
    ) {
      state.openedDocuments[action.payload.id].isProcessing = action.payload.isProcessing;
    },
    setDocumentScale(
      state: DocumentsState,
      action: PayloadAction<{ id: Document['id']; scale: Document['scale'] }>,
    ) {
      state.openedDocuments[action.payload.id].scale = action.payload.scale;
    },
    saveDocument(state: DocumentsState, action: PayloadAction<Pick<Document, 'id'>>) {
      state.openedDocuments[action.payload.id].isSaved = true;
    },
    setDocumentDirty(state: DocumentsState, action: PayloadAction<Pick<Document, 'id'>>) {
      state.openedDocuments[action.payload.id].isSaved = false;
    },
    setCurrentPage(
      state: DocumentsState,
      action: PayloadAction<Pick<Document, 'id' | 'currentPage'>>,
    ) {
      state.openedDocuments[action.payload.id].currentPage = action.payload.currentPage;
    },
    setPagesCount(
      state: DocumentsState,
      action: PayloadAction<Pick<Document, 'id' | 'pagesCount'>>,
    ) {
      state.openedDocuments[action.payload.id].pagesCount = action.payload.pagesCount;
    },
    setAnnotationsLoading(
      state: DocumentsState,
      action: PayloadAction<Pick<Document, 'id' | 'areAnnotationsLoaded'>>,
    ) {
      state.openedDocuments[action.payload.id].areAnnotationsLoaded =
        action.payload.areAnnotationsLoaded;
    },
  },
});

export const documentActions = { ...documentSlice.actions, saveAllDocuments };
