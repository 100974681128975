import { useEffect, useMemo, useState } from 'react';
import { Annotation, ColumnsState } from 'types/redux';
import { convertRGBtoHex, getAlphaValue, isColorLikeString, isInRGBAFormat } from 'utils/rgb-hex';

import { getAnnotationValues } from './utils';

interface UseGetAnnotationDetailsValuesArgs {
  selectedAnnotations: Annotation[];
  isSpecificAnnotationSelected: boolean;
  defaultValues: Record<string, string>;
}

export const useGetAnnotationDetailsValues = ({
  selectedAnnotations,
  isSpecificAnnotationSelected,
  defaultValues,
}: UseGetAnnotationDetailsValuesArgs) => {
  return useMemo(() => {
    let formData: Record<string, any>;

    if (Array.isArray(selectedAnnotations) && isSpecificAnnotationSelected) {
      const annotationValues = getAnnotationValues(selectedAnnotations);

      formData = {
        ...defaultValues,
        ...annotationValues,
        type: selectedAnnotations[0].type,
      };
    } else {
      formData = defaultValues;
    }

    for (const key in formData) {
      // detecting colors and convert than to hex format if necessary
      if (isColorLikeString(formData[key])) {
        formData[key] = {
          color: isInRGBAFormat(formData[key]) ? convertRGBtoHex(formData[key]) : formData[key],
          alpha: getAlphaValue(formData[key]),
        };
      }
    }

    return formData;
  }, [defaultValues, selectedAnnotations, isSpecificAnnotationSelected]);
};

const getUpdateMap = (columns: ColumnsState['allColumns']) => {
  const map: Record<string, boolean> = {};
  const keys = Object.keys(columns);

  keys.forEach((key) => {
    map[key] = true;
  });

  return map;
};

export const useGetUpdateMap = (columns: ColumnsState['allColumns']) => {
  const [updateMap, setUpdateMap] = useState(() => getUpdateMap(columns));

  useEffect(() => {
    if (columns) {
      setUpdateMap(getUpdateMap(columns));
    }
  }, [columns, setUpdateMap, getUpdateMap]);

  const toggleUpdateMapItem = (key: string) => {
    setUpdateMap((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  return { updateMap, toggleUpdateMapItem };
};
