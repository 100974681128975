import { Checkbox, Flex } from '@chakra-ui/react';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useDispatchSelectAnnotation } from 'pages/sheet/components/apryse-block/hooks/useDispatchSelectAnnotation';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { annotationActions } from 'redux/slices';

interface AnnotationSelectorProps {
  id: string;
}

const AnnotationSelector = ({ id }: AnnotationSelectorProps) => {
  const { instance } = useContext(WebviewerContext);
  const selectAnnotation = useDispatchSelectAnnotation();

  const annotation = instance?.Core.annotationManager.getAnnotationById(id);

  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      instance?.Core?.annotationManager
        ?.getSelectedAnnotations()
        .find((annotation) => annotation.Id === id)
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, instance?.Core?.annotationManager?.getSelectedAnnotations()]);

  const handleUpdateAnnotationSelection = (event: SyntheticEvent) => {
    setChecked((event.target as HTMLInputElement).checked);
    if ((event.target as HTMLInputElement).checked) {
      selectAnnotation(id);
    } else {
      dispatch(annotationActions.deselectAnnotation({ documentId: 1, annotationIds: [id] }));
      instance?.Core.annotationManager.deselectAnnotation(annotation as any);
    }
  };

  return (
    <Flex justifyContent='center' h='full' alignItems='center'>
      <Checkbox
        colorScheme='purple'
        isChecked={checked}
        isDisabled={annotation?.Hidden}
        onChange={handleUpdateAnnotationSelection}
      />
    </Flex>
  );
};

export default AnnotationSelector;
