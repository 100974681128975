import { WebViewerInstance } from '@pdftron/webviewer';

interface hideAnnotationProps {
  viewerInstance: WebViewerInstance;
  annotationId: string;
}

export const hideAnnotation = ({ viewerInstance, annotationId }: hideAnnotationProps) => {
  const annotationToHide = viewerInstance?.Core.annotationManager.getAnnotationById(annotationId);
  viewerInstance?.Core.annotationManager.hideAnnotation(annotationToHide);
};
