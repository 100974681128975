import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { KeyboardEvent, useCallback, useContext, useEffect } from 'react';

export const useDeleteSelectedAnnotations = () => {
  const { instance } = useContext(WebviewerContext);

  const handler = useCallback(
    (event: KeyboardEvent) => {
      if (instance) {
        const { annotationManager } = instance.Core;

        const selectedAnnotations = annotationManager.getSelectedAnnotations();

        if (event.key === 'Delete' && selectedAnnotations?.length) {
          annotationManager.deleteAnnotations(selectedAnnotations);
        }
      }
    },
    [instance],
  ) as unknown as EventListenerOrEventListenerObject;

  useEffect(() => {
    document.addEventListener('keydown', handler);

    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handler]);
};
