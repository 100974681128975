import { Core } from '@pdftron/webviewer';
import { ApryseAnnotation } from 'types';

interface RedrawPathPointProps {
  annotation: ApryseAnnotation;
  annotationToUpdate: Core.Annotations.PolylineAnnotation;
}

export const redrawPathPoints = ({ annotation, annotationToUpdate }: RedrawPathPointProps) => {
  annotation.pathPoints?.forEach((pathPoint: number[]) => {
    annotationToUpdate.popPath();
  });

  annotation.pathPoints?.forEach((pathPoint: number[], idx) => {
    annotationToUpdate.setPathPoint(idx, pathPoint[0], pathPoint[1]);
  });
};
