import { Box, Text } from '@chakra-ui/react';
import TextFieldRHF from 'components/react-hook-form/textfield-rhf';
import { styles } from 'pages/add-project/components/form-textfield/form-textfield.styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface IFormTextFieldProps {
  name: string;
  label: string;
  placeholder: string;
}

export function FormTextField({ name, label, placeholder }: IFormTextFieldProps): JSX.Element {
  const { register } = useFormContext();

  return (
    <Box sx={styles.fieldWrapper}>
      <Text sx={styles.label}>{label}</Text>
      <TextFieldRHF {...register(name)} sx={styles.textField} placeholder={placeholder} />
    </Box>
  );
}
