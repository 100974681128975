import {
  BUTTON_ICONS,
  BUTTON_NAMES,
  TOOL_NAMES,
  TOOLTIPS,
  UPPER_TOOLBAR_MESURE_INDEX,
} from 'constants/apryse';

import { POLYGON_TOOL_GROUP_BUTTON, TOOL_BUTTON, TOOLBAR_GROUP, ZONES } from '../constants';
import { AddZoneToolToPanelParams } from './types';

export const addZoneToolToPanel = ({ UI, tool, zoneType }: AddZoneToolToPanelParams) => {
  UI.registerTool({
    toolName: zoneType === ZONES.NO_FLY_ZONE ? TOOL_NAMES.NO_FLY_ZONE : TOOL_NAMES.PREFERRED_ZONE,
    toolObject: tool,
    tooltip: zoneType === ZONES.NO_FLY_ZONE ? TOOLTIPS.NO_FLY_ZONE : TOOLTIPS.PREFERRED_ZONE,
    buttonName:
      zoneType === ZONES.NO_FLY_ZONE ? BUTTON_NAMES.NO_FLY_ZONE : BUTTON_NAMES.PREFERRED_ZONE,
    buttonImage:
      zoneType === ZONES.NO_FLY_ZONE ? BUTTON_ICONS.NO_FLY_ZONE : BUTTON_ICONS.PREFERRED_ZONE,
  });

  UI.setHeaderItems((header) => {
    header
      .getHeader(TOOLBAR_GROUP.SHAPES)
      .get(POLYGON_TOOL_GROUP_BUTTON)
      .insertBefore({
        type: TOOL_BUTTON,
        toolName:
          zoneType === ZONES.NO_FLY_ZONE ? TOOL_NAMES.NO_FLY_ZONE : TOOL_NAMES.PREFERRED_ZONE,
      });
  });

  UI.setHeaderItems((header) => {
    const customZoneButton = {
      type: TOOL_BUTTON,
      toolName: zoneType === ZONES.NO_FLY_ZONE ? TOOL_NAMES.NO_FLY_ZONE : TOOL_NAMES.PREFERRED_ZONE,
    };
    const items = header.getItems();
    items.splice(UPPER_TOOLBAR_MESURE_INDEX, 0, customZoneButton);
  });
};
