import React from 'react';

export const AddCircleLineIcon = ({ size = '20px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.16602 9.16663V5.83329H10.8327V9.16663H14.166V10.8333H10.8327V14.1666H9.16602V10.8333H5.83268V9.16663H9.16602ZM9.99935 18.3333C5.39685 18.3333 1.66602 14.6025 1.66602 9.99996C1.66602 5.39746 5.39685 1.66663 9.99935 1.66663C14.6018 1.66663 18.3327 5.39746 18.3327 9.99996C18.3327 14.6025 14.6018 18.3333 9.99935 18.3333ZM9.99935 16.6666C11.7675 16.6666 13.4632 15.9642 14.7134 14.714C15.9636 13.4638 16.666 11.7681 16.666 9.99996C16.666 8.23185 15.9636 6.53616 14.7134 5.28591C13.4632 4.03567 11.7675 3.33329 9.99935 3.33329C8.23124 3.33329 6.53555 4.03567 5.2853 5.28591C4.03506 6.53616 3.33268 8.23185 3.33268 9.99996C3.33268 11.7681 4.03506 13.4638 5.2853 14.714C6.53555 15.9642 8.23124 16.6666 9.99935 16.6666Z'
        fill='#7368F0'
      />
    </svg>
  );
};
