import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalState } from 'types/redux';

export const modalSlice = createSlice({
  name: 'modals',
  initialState: {},
  reducers: {
    setModalData(state: ModalState, action: PayloadAction<ModalState['data']>) {
      state.data = action.payload;
    },
    clearModalData(state: ModalState) {
      delete state.data;
    },
  },
});

export const modalActions = modalSlice.actions;
