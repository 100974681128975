/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebViewerInstance } from '@pdftron/webviewer';

import { calculatePolylineLength } from './calculate-polyline-length';

interface CalculateMeasurmentsProps {
  viewerInstance?: WebViewerInstance;
  annotation: any;
  scale: string;
  isReduxAnnotation?: boolean;
}

export const calculateMeasurments = ({
  viewerInstance,
  annotation,
  scale,
  isReduxAnnotation,
}: CalculateMeasurmentsProps) => {
  // eslint-disable-next-line no-useless-return
  if (annotation.elementName === 'Homerun Box') return;

  const splitedScale = scale.split(' = ');
  const convertFrom = {
    value: Number.parseFloat(splitedScale[0].split(' ')[0]),
    units: splitedScale[0].split(' ')[1],
  };
  const convertTo = {
    value: Number.parseFloat(splitedScale[1].split(' ')[0]),
    units: splitedScale[1].split(' ')[1],
  };

  switch (annotation.Subject || annotation.type) {
    case 'Polyline':
      return calculatePolylineLength({
        annotation,
        isReduxAnnotation,
        viewerInstance,
        convertFrom,
        convertTo,
      });
    default:
      return '';
  }
};
