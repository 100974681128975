import { Box, Card, Divider, Flex, Spinner, Text } from '@chakra-ui/react';
import { SOURCE_STATISTICS_CATEGORIES } from 'pages/sources-page/components/sidebar/constants';
import { useGetSourceStatistics } from 'pages/sources-page/components/sidebar/hooks/useGetSourceStatistics';
import { styles } from 'pages/sources-page/components/sidebar/styles';
import React from 'react';

export const SourcesSidebar = () => {
  const { isLoading, sourceData } = useGetSourceStatistics();
  return (
    <Card sx={styles.sidebarWrapper}>
      {isLoading ? (
        <Box sx={styles.loaderStyles}>
          <Spinner thickness='3px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      ) : (
        <>
          <Box sx={styles.formSection}>
            <Text sx={styles.formSectionHeadingText}>Categories</Text>
            <Divider sx={styles.formDivider} />
            <Box sx={styles.formSection}>
              {SOURCE_STATISTICS_CATEGORIES.map(({ key, label }) => (
                <Flex key={key} sx={styles.selectCategoryItem}>
                  <Text>{label}</Text>
                  <Text sx={styles.drawingsCount}>{sourceData?.[key]}</Text>
                </Flex>
              ))}
            </Box>
          </Box>
        </>
      )}
    </Card>
  );
};
