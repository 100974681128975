import { Box, Flex, Text } from '@chakra-ui/react';
import { CropIcon } from 'assets/icons';

import { styles } from './styles';

const AnnotationMessage = ({ message }: { message: string }) => {
  return (
    <Box sx={styles.annotationMessageWrapper}>
      <Flex sx={styles.annotationMessageContainer}>
        <CropIcon size='18px' />
        <Text textAlign='center'>{message}</Text>
      </Flex>
    </Box>
  );
};

export default AnnotationMessage;
