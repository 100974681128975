import { pxToRem } from '../../utils';

export const styles = {
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  userNameText: {
    color: 'gray.400',
    fontSize: '10.5px',
  },
  userRoleText: {
    color: 'gray.400',
    fontSize: '8.25px',
  },
  avatar: {
    height: '30px',
    width: '30px',
    marginLeft: '12px',
  },
  badge: {
    height: '12.75px',
    width: '12.75px',
    background: 'green.100',
    border: `${pxToRem(3)} solid white`,
    position: 'absolute',
    top: '16.125px',
  },
};
