import React, { SyntheticEvent } from 'react';

interface IconProps {
  fill?: string;
  size?: string;
  onClick?: (event: SyntheticEvent | never) => void;
}

export const EyeCloseIcon = ({ fill, size, onClick }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 20 21'
      fill={fill}
      onClick={onClick}
      cursor='pointer'
    >
      <path
        d='M7.78547 16.4865L6.1763 16.0548L6.83214 13.6056C5.85044 13.2436 4.93807 12.7159 4.13464 12.0456L2.34047 13.8406L1.1613 12.6615L2.9563 10.8673C1.94303 9.65377 1.26221 8.19794 0.980469 6.64231L2.62047 6.34314C3.25297 9.84481 6.3163 12.5015 10.0005 12.5015C13.6838 12.5015 16.748 9.84481 17.3805 6.34314L19.0205 6.64147C18.7391 8.19732 18.0586 9.65345 17.0455 10.8673L18.8396 12.6615L17.6605 13.8406L15.8663 12.0456C15.0629 12.7159 14.1505 13.2436 13.1688 13.6056L13.8246 16.0556L12.2155 16.4865L11.5588 14.0365C10.5274 14.2132 9.47351 14.2132 8.44214 14.0365L7.78547 16.4865Z'
        fill='#6E6B7B'
      />
    </svg>
  );
};
