import {
  Box,
  Button,
  ChakraProps,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { HEXToRGB } from 'utils/rgb-hex';

import { ColorPickerContent } from './color-picker-content';
import { APLHA_OPTIONS, COLOR_PICKER_OPTIONS } from './constants';

export type Color = (typeof COLOR_PICKER_OPTIONS)[0];

export interface ColorPickerProps {
  wrapperProps?: ChakraProps;
  onColorChange: (color: Color) => void;
  value: Color;
  disabled?: boolean;
  onBlur?: () => void;
  isTablePicker?: boolean;
  isAnnotations?: boolean;
}

const ColorPicker = ({
  onColorChange,
  wrapperProps,
  disabled,
  onBlur,
  isTablePicker,
  isAnnotations = false,
  value = COLOR_PICKER_OPTIONS[0],
}: ColorPickerProps) => {
  const handleSelectColor = (color: (typeof COLOR_PICKER_OPTIONS)[0]) => () => {
    onColorChange(color);
  };

  const handleSelectAlpha = (alpha: number) => () => {
    onColorChange({
      ...value,
      alpha,
    });
  };

  const viewPicker = useMemo(() => {
    if (isTablePicker) {
      return (
        <Flex w='full' h='full' alignItems='center'>
          <ColorPickerContent value={value} />
        </Flex>
      );
    }
    if (isAnnotations) {
      return (
        <Box>
          <Button
            w='full'
            display='flex'
            justifyContent='flex-start'
            fontWeight={400}
            fontSize='7.5px'
            lineHeight='16.5px'
            color='black.50'
            mb='4px'
            disabled={disabled}
            {...wrapperProps}
          >
            <ColorPickerContent value={value} />
          </Button>
        </Box>
      );
    }
    return (
      <Box>
        <Button
          p={2}
          w='full'
          display='flex'
          justifyContent='flex-start'
          disabled={disabled}
          {...wrapperProps}
        >
          <Box
            boxSize='22.5px'
            borderRadius='2'
            bgColor={HEXToRGB(value?.color, value?.alpha?.toString())}
            boxShadow='0px 0px 3px #aaa'
          />
          <Text ml={2} fontSize='sm'>
            {value.color} (Opacity: {value.alpha * 100}%)
          </Text>
        </Button>
      </Box>
    );
  }, [disabled, isAnnotations, isTablePicker, value, wrapperProps]);

  return (
    <Popover onClose={onBlur} defaultIsOpen={isTablePicker}>
      <PopoverTrigger>{viewPicker}</PopoverTrigger>
      <PopoverContent maxW='187.5px'>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Flex wrap='wrap'>
            {COLOR_PICKER_OPTIONS.map((option) => {
              return (
                <Box
                  key={option.color}
                  boxSize='18.75px'
                  borderRadius='2'
                  bgColor={option.color}
                  mr='2'
                  mb='2'
                  boxShadow='0px 0px 3px #aaa'
                  cursor='pointer'
                  onClick={handleSelectColor(option)}
                />
              );
            })}
          </Flex>
        </PopoverBody>
        <PopoverFooter>
          <Text mb='2' fontSize='sm'>
            Alpha (opacity):
          </Text>
          <Flex wrap='wrap'>
            {APLHA_OPTIONS.map((alpha) => (
              <Box
                key={alpha}
                boxSize='18.75px'
                borderRadius='2'
                bgColor={HEXToRGB(value.color, alpha?.toString())}
                mr='2'
                mb='2'
                boxShadow='0px 0px 3px #aaa'
                cursor='pointer'
                onClick={handleSelectAlpha(alpha)}
              />
            ))}
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default ColorPicker;
