import { pxToRem } from 'utils';

export const styles = {
  sidebarWrapper: {
    height: `100%`,
    padding: '13.5px',
    maxWidth: '176.25px',
    marginLeft: '9px',
    width: '203.25px',
  },
  loaderStyles: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'gray',
    opacity: 0.5,
    display: 'flex',
    borderRadius: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    top: 0,
    left: 0,
  },
  formDivider: {
    marginBottom: '9px',
    marginTop: '9px',
  },
  formSectionHeadingText: {
    color: 'black.50',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '12px',
  },

  selectCategoryItem: {
    padding: '7.5px 6px',
    fontSize: '10.5px',
    fontWeight: 400,
    color: 'black.50',
    justifyContent: 'space-between',
    borderRadius: pxToRem(6),
  },

  formDateText: {
    fontSize: '10.5px',
    fontWeight: 400,
    color: 'gray.200',
  },
  formSection: {
    marginBottom: '33px',
  },
  submitButton: {
    backgroundColor: 'purple.100',
    width: '100%',
    paddingTop: '9px',
    paddingBottom: '9px',
    color: 'white',
    marginTop: '33px',
  },
  drawingsCount: {
    color: '#000000',
    fontWeight: 600,
  },
};
