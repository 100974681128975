export const Button = {
  variants: {
    primary: {
      borderRadius: '6px',
      background: 'purple.100',
      color: 'white',
      transition: '0s',
      fontSize: '10.5px',
      fontWeight: 500,
      lineHeight: '18px',
      _hover: {
        boxShadow: '2px 8px 24px -8px rgba(115, 103, 240, 1)',
      },
    },
    outline: {
      borderRadius: '6px',
      border: '2px solid',
      borderColor: 'purple.100',
      background: 'white',
      color: 'purple.100',
      transition: '0s',
      fontSize: '10.5px',
      fontWeight: 500,
      lineHeight: '18px',
      _hover: {
        boxShadow: '2px 8px 24px -8px rgba(115, 103, 240, 1)',
      },
    },
    secondary: {
      borderRadius: '6px',
      background: 'gray.100',
      color: 'gray.500',
      transition: '0s',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      _hover: {
        boxShadow: '2px 8px 24px -8px rgba(200, 200, 200, 1)',
      },
    },
    success: {
      borderRadius: '6px',
      background: 'green.100',
      color: 'white',
      transition: '0s',
      _hover: {
        boxShadow: '2px 8px 24px -8px rgba(40, 199, 111, 1)',
      },
    },
    borderless: {
      // background: 'red',
    },
  },
};
