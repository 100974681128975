import { Flex, Text } from '@chakra-ui/react';
import ReactSelectRHF from 'components/react-hook-form/react-select-rhf';
import TextFieldRHF from 'components/react-hook-form/textfield-rhf';
import { GroupBase, StylesConfig } from 'react-select';
import { colors } from 'theme/foundations/colors.theme';

const REACT_SELECT_STYLES: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
  control: (base) => ({
    ...base,
    height: '35.25px',
    borderColor: colors.purple[100],
  }),
  container: (base) => ({
    ...base,
    flexGrow: 1,
    maxWidth: '184.5px',
    width: '100%',
  }),
};

const SLDInputs = () => {
  return (
    <Flex>
      <ReactSelectRHF
        name='element_1'
        placeholder='Choose element...'
        styles={REACT_SELECT_STYLES}
        options={[]}
      />
      <Flex alignItems='center' justifyContent='center' flexBasis='30px'>
        <Text color='purple.100' fontWeight={500} lineHeight='16.5px' fontSize='10.5px'>
          -
        </Text>
      </Flex>
      <TextFieldRHF
        name='tag'
        placeholder='Tag'
        wrapperProps={{ h: '35.25px' }}
        formControlStyles={{ maxW: '39.75px' }}
        inputStyles={{
          borderColor: 'purple.100',
          bg: 'white',
          _hover: {
            borderColor: 'purple.100',
          },
          _placeholder: {
            color: '#808080',
            fontSize: '8.25px',
          },
        }}
      />
      <Flex alignItems='center' justifyContent='center' flexBasis='30px'>
        <Text color='purple.100' fontWeight={500} lineHeight='16.5px' fontSize='10.5px'>
          -
        </Text>
      </Flex>
      <ReactSelectRHF
        name='element_2'
        placeholder='Choose element...'
        styles={REACT_SELECT_STYLES}
        options={[]}
      />
    </Flex>
  );
};

export default SLDInputs;
