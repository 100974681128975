const UserIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#B9B9C3'
        d='M9.167 12.218v4.949h1.666v-4.949a6.668 6.668 0 015.834 6.615H3.333a6.667 6.667 0 015.834-6.615zm.833-.885c-2.762 0-5-2.237-5-5 0-2.762 2.238-5 5-5s5 2.238 5 5c0 2.763-2.238 5-5 5z'
      ></path>
    </svg>
  );
};

export default UserIcon;
