import { useGetUrlSearchParams } from 'hooks/useGetUrlSearchParams';
import { useUpdateUrlSearchParams } from 'hooks/useSetUpdateUrlSearchParams';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type TUrlDefaultParam = {
  key: string;
  defaultValue: string | number;
  possibleValues?: string[];
};

export const useSetDefaultUrlSearchParams = (params: TUrlDefaultParam[]) => {
  const { urlSearchParam } = useGetUrlSearchParams();
  const { setSearchParam } = useUpdateUrlSearchParams();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams();

    params.forEach(({ key, defaultValue, possibleValues }) => {
      const paramValue = urlSearchParam.get(key);
      const setToDefault = !paramValue || (paramValue && !possibleValues?.includes(paramValue));
      if (setToDefault) {
        searchParams.set(key, defaultValue.toString());
        setSearchParam(key, defaultValue.toString());
      }
    });
  }, [location]);
};
