export const ProjectsLoadingErrorIcon = () => {
  return (
    <svg
      width='266'
      height='197'
      viewBox='0 0 266 197'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M77.105 32.0114C77.105 32.0114 107.129 54.7954 152.773 12.1934C193.309 -25.6403 226.407 34.0528 226.639 62.6389C226.939 99.6689 186.103 129.299 205.92 153.621C225.738 177.942 166.619 218.106 134.757 183.347C95.121 140.108 84.3833 175.24 61.7912 175.24C45.5766 175.24 12.2864 134.951 34.7669 104.977C53.684 79.7541 43.367 71.3833 38.3708 62.6389C31.1637 50.0275 48.2792 15.7969 77.105 32.0114Z'
        fill='#EFEEFF'
      />
      <path
        d='M197.642 82.3609C199.255 82.9605 201.019 83.0004 202.697 82.6153C209.727 81.0016 233.9 76.7291 229.699 95.4608C228.785 99.1773 214.627 119.99 234.789 127.588C236.266 128.152 237.829 128.459 239.41 128.498C245.617 128.626 257.919 127.16 263.067 121.919C269.286 115.589 262.492 118.591 262.492 118.591C262.492 118.591 238.848 128.909 230.496 117.962C229.566 116.744 228.975 114.975 228.805 113.452C228.511 110.812 229.684 107.322 231.224 104.285C233.308 100.174 243.316 81.6813 221.588 76.2941C216.583 75.1022 209.313 74.0315 199.939 77.9781C194.472 80.2799 195.683 81.633 197.642 82.3609Z'
        fill='#E8E7EF'
      />
      <path
        d='M202.48 75.4567L196.186 77.812C194.014 78.6251 192.912 81.0453 193.725 83.2177L194.037 84.0512C194.85 86.2236 197.27 87.3256 199.443 86.5126L205.736 84.1572C207.908 83.3442 209.01 80.924 208.197 78.7515L207.885 77.918C207.072 75.7456 204.652 74.6436 202.48 75.4567Z'
        fill='#6C63FE'
      />
      <path
        d='M163.85 82.2725L143.095 90.0403C142.008 90.4468 141.457 91.6569 141.864 92.7431L141.946 92.9616C142.352 94.0478 143.562 94.5988 144.649 94.1922L165.403 86.4245C166.49 86.018 167.041 84.8079 166.634 83.7217L166.552 83.5032C166.146 82.417 164.936 81.866 163.85 82.2725Z'
        fill='white'
      />
      <path
        d='M169.643 97.749L148.888 105.517C147.801 105.923 147.25 107.133 147.657 108.22L147.739 108.438C148.145 109.524 149.355 110.075 150.442 109.669L171.197 101.901C172.283 101.494 172.834 100.284 172.427 99.1982L172.345 98.9797C171.939 97.8935 170.729 97.3425 169.643 97.749Z'
        fill='white'
      />
      <path
        d='M188.804 71.7107L171.982 78.0063C169.81 78.8194 168.708 81.2396 169.521 83.412L175.264 98.7572C176.077 100.93 178.497 102.032 180.67 101.219L197.491 94.9229C199.664 94.1098 200.766 91.6896 199.953 89.5172L194.21 74.1721C193.396 71.9997 190.976 70.8977 188.804 71.7107Z'
        fill='#4339F2'
      />
      <path
        d='M173.721 75.7361L156.899 82.0317C154.727 82.8448 153.625 85.265 154.438 87.4374L161.29 105.745C162.103 107.917 164.523 109.019 166.695 108.206L183.517 101.911C185.689 101.097 186.791 98.6772 185.978 96.5048L179.126 78.1975C178.313 76.025 175.893 74.9231 173.721 75.7361Z'
        fill='#7368F0'
      />
      <path
        d='M176.354 72.0209L176.222 72.0703C174.774 72.6123 174.039 74.2258 174.581 75.6741L184.18 101.321C184.722 102.769 186.335 103.504 187.783 102.962L187.915 102.912C189.364 102.37 190.098 100.757 189.556 99.3085L179.958 73.6618C179.416 72.2136 177.802 71.4789 176.354 72.0209Z'
        fill='#AAA5FB'
      />
      <path
        d='M4.83078 88.4183C4.83078 88.4183 38.6139 78.9243 33.7091 100.797C32.8574 104.258 20.5963 122.079 35.2011 130.635C38.3371 132.472 41.9561 133.286 45.5904 133.267C52.1064 133.233 62.4363 131.979 67.0769 127.256C73.2962 120.924 66.5018 123.927 66.5018 123.927C66.5018 123.927 47.0996 131.908 37.1179 125.546C33.7096 123.374 32.1647 119.168 33.0902 115.233C33.5633 113.279 34.2836 111.393 35.2339 109.621C37.4047 105.555 47.326 87.0173 25.598 81.6301C20.5927 80.4381 13.3229 79.3674 3.9492 83.314C-5.4245 87.2606 4.83078 88.4183 4.83078 88.4183Z'
        fill='#E8E7EF'
      />
      <path
        d='M71.6194 119.032L65.3261 121.387C63.1537 122.2 62.0517 124.62 62.8648 126.793L63.1767 127.626C63.9898 129.799 66.41 130.901 68.5824 130.088L74.8757 127.732C77.0481 126.919 78.1501 124.499 77.337 122.327L77.0251 121.493C76.212 119.321 73.7918 118.219 71.6194 119.032Z'
        fill='#6C63FE'
      />
      <path
        d='M82.2526 108.678L72.9257 112.168C70.7533 112.982 69.6513 115.402 70.4644 117.574L74.9618 129.591C75.7748 131.763 78.195 132.865 80.3675 132.052L89.6944 128.562C91.8668 127.748 92.9688 125.328 92.1557 123.156L87.6583 111.139C86.8453 108.967 84.4251 107.865 82.2526 108.678Z'
        fill='#4339F2'
      />
      <path
        d='M97.7801 99.4257L83.871 104.631C81.6986 105.444 80.5966 107.865 81.4097 110.037L88.2614 128.344C89.0745 130.517 91.4947 131.619 93.6671 130.806L107.576 125.6C109.749 124.787 110.851 122.367 110.038 120.194L103.186 101.887C102.373 99.7146 99.9525 98.6126 97.7801 99.4257Z'
        fill='#7368F0'
      />
      <path
        d='M100.725 95.6352L100.593 95.6846C99.1448 96.2266 98.4101 97.8401 98.9522 99.2884L108.551 124.935C109.093 126.383 110.706 127.118 112.155 126.576L112.286 126.526C113.735 125.984 114.469 124.371 113.927 122.923L104.329 97.2761C103.787 95.8278 102.173 95.0932 100.725 95.6352Z'
        fill='#AAA5FB'
      />
      <path
        d='M136.244 79.2958C135.743 79.2402 135.283 78.9925 134.961 78.6047C134.64 78.2169 134.481 77.7194 134.518 77.2168L135.556 58.2608C135.618 57.1285 136.574 56.2682 137.659 56.3683L139.171 56.5083C140.256 56.6084 141.033 57.6283 140.881 58.7526L138.331 77.5686C138.272 78.0698 138.023 78.5291 137.634 78.8509C137.245 79.1726 136.747 79.332 136.244 79.2958V79.2958Z'
        fill='#4339F2'
      />
      <path
        d='M127.282 80.9301C127.126 80.9759 126.963 80.9905 126.802 80.9732C126.64 80.9558 126.484 80.9069 126.341 80.8291C126.199 80.7513 126.073 80.6461 125.971 80.5197C125.869 80.3933 125.793 80.248 125.747 80.0922L122.041 68.3006C121.934 67.9652 121.963 67.6008 122.124 67.2873C122.284 66.9738 122.563 66.7368 122.898 66.6283L123.857 66.3388C124.022 66.2907 124.195 66.2773 124.365 66.2994C124.535 66.3215 124.699 66.3786 124.846 66.4672C124.992 66.5557 125.119 66.6737 125.218 66.8138C125.317 66.9539 125.386 67.1129 125.421 67.281L128.167 79.3622C128.244 79.687 128.195 80.0287 128.031 80.3192C127.867 80.6097 127.6 80.8279 127.282 80.9301V80.9301Z'
        fill='#4339F2'
      />
      <path
        d='M122.281 87.7306C121.617 88.5534 120.282 88.5436 119.254 87.7083L101.002 72.8793C99.912 71.9935 99.5903 70.5273 100.295 69.6551L101.277 68.4388C101.981 67.5662 103.428 67.6377 104.46 68.5961L121.73 84.6416C122.702 85.5452 122.946 86.9078 122.281 87.7306Z'
        fill='#4339F2'
      />
      <path
        d='M130.717 126.032C131.187 126.214 131.569 126.571 131.781 127.028C131.993 127.485 132.02 128.006 131.855 128.483L126.011 146.545C125.661 147.624 124.517 148.211 123.494 147.837L122.067 147.316C121.043 146.942 120.552 145.757 120.987 144.709L128.258 127.169C128.442 126.699 128.801 126.318 129.259 126.107C129.718 125.895 130.24 125.868 130.717 126.032V126.032Z'
        fill='#4339F2'
      />
      <path
        d='M139.799 126.74C139.962 126.735 140.123 126.763 140.275 126.821C140.427 126.879 140.565 126.966 140.683 127.078C140.801 127.189 140.896 127.323 140.962 127.471C141.028 127.62 141.065 127.779 141.069 127.942L141.641 140.289C141.66 140.641 141.538 140.985 141.303 141.248C141.067 141.51 140.738 141.668 140.386 141.687L139.384 141.722C139.213 141.726 139.042 141.695 138.883 141.63C138.724 141.566 138.581 141.469 138.461 141.345C138.342 141.222 138.249 141.076 138.19 140.915C138.13 140.754 138.104 140.583 138.113 140.412L138.544 128.03C138.552 127.696 138.687 127.378 138.919 127.139C139.152 126.9 139.466 126.758 139.799 126.74V126.74Z'
        fill='#4339F2'
      />
      <path
        d='M146.37 121.443C147.222 120.817 148.511 121.167 149.292 122.237L163.151 141.235C163.979 142.37 163.915 143.87 163.011 144.533L161.751 145.459C160.847 146.123 159.466 145.684 158.714 144.494L146.114 124.57C145.405 123.448 145.518 122.069 146.37 121.443Z'
        fill='#4339F2'
      />
    </svg>
  );
};
