export const styles = {
  inputWrapper: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    height: '100%',
  },
  groupStyle: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  inputStyle: {
    paddingLeft: '9px',
    background: 'gray.100',
    fontSize: '12px',
    border: '1px #625F6E solid',
    borderRadius: '6px',
    _placeholder: {
      fontWeight: 500,
      color: 'gray.300',
      fontSize: '12px',
    },
    _hover: {
      borderColor: 'gray.500',
    },
    _focus: {
      borderColor: 'purple.100',
      boxShadow: '0 3px 10px 0 rgba(0,0,0, 0.1)',
      _placeholder: {
        transform: 'translate(5px)',
        transition: '0.2s ease all',
      },
    },
  },
  changeVisibilityBtn: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    maxHeight: '15px',
    minWidth: '15px',
    svg: {
      width: '15px',
      height: '15px',
    },
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '9px',
    fontWeight: 500,
    lineHeight: '15px',
    mt: '3px',

    svg: {
      minWidth: '12.75px',
      marginRight: '3px',
    },
  },
};
