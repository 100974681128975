export const COLOR_PICKER_OPTIONS = [
  { color: '#FF6900', alpha: 1 },
  { color: '#FCB900', alpha: 1 },
  { color: '#7BDCB5', alpha: 1 },
  { color: '#00D084', alpha: 1 },
  { color: '#8ED1FC', alpha: 1 },
  { color: '#0693E3', alpha: 1 },
  { color: '#ABB8C3', alpha: 1 },
  { color: '#EB144C', alpha: 1 },
  { color: '#F78DA7', alpha: 1 },
  { color: '#9900EF', alpha: 1 },
  { color: '#FFFFFF', alpha: 1 },
  { color: '#000000', alpha: 1 },
];

export const APLHA_OPTIONS = [0, 0.25, 0.5, 0.75, 1];
