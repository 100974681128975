import { Box, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from '@chakra-ui/react';
import DeleteIcon from 'assets/icons/DeleteIcon';
import DotsIcon from 'assets/icons/DotsIcon';
import DownloadIcon from 'assets/icons/DownloadIcon';
import EditIcon from 'assets/icons/EditIcon';
import GalleryIcon from 'assets/icons/GalleryIcon';
import { styles } from 'pages/projects/styles';
import React from 'react';

import { DeleteModal } from './delete-modal';
import { ProjectCardMenuProps } from './types';

export const ProjectCardMenu = ({
  addCover = false,
  handleEdit,
  handleAddCover,
  handleDownload,
}: ProjectCardMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton as={Box} sx={styles.cardMenuBtn}>
          <DotsIcon size='15px' />
        </MenuButton>
        <MenuList sx={styles.projectCardMenuList}>
          <MenuItem sx={styles.projectCardMenuItem} onClick={handleEdit}>
            <EditIcon size='15px' />
            <Text ml='4.5px'>Edit project</Text>
          </MenuItem>
          {addCover && (
            <MenuItem sx={styles.projectCardMenuItem} onClick={handleAddCover}>
              <GalleryIcon size='15px' />
              <Text ml='4.5px'>Add cover</Text>
            </MenuItem>
          )}
          <MenuItem sx={styles.projectCardMenuItem} onClick={handleDownload}>
            <DownloadIcon size='15px' />
            <Text ml='4.5px'>Download</Text>
          </MenuItem>
          <Box sx={styles.divider} />
          <MenuItem sx={styles.projectCardMenuItem} onClick={onOpen}>
            <DeleteIcon size='15px' />
            <Text ml='4.5px' color='red.100'>
              Delete
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <DeleteModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
