import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as process from 'process';

enum EView3DApiUrls {
  IMPORT_JSON = 'api/models/import',
}

export const view3DApiSlice = createApi({
  reducerPath: 'view3DApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_3D_VIEW_API_PREFIX }),
  endpoints: (builder) => ({
    importJson: builder.mutation({
      query: (formData: FormData) => {
        return {
          url: EView3DApiUrls.IMPORT_JSON,
          headers: {
            Connection: 'keep-alive',
          },
          method: 'POST',
          body: formData,
        };
      },
    }),
  }),
});

export const { useImportJsonMutation } = view3DApiSlice;
