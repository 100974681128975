import { useCallback, useRef } from 'react';

export type TCoordinates = { x: number; y: number };

export const useMouseCoordsEventListener = (): {
  getMouseCoords: () => TCoordinates;
  mouseEventHandler: (event: MouseEvent) => void;
} => {
  const coordsReference = useRef<TCoordinates>({ x: 0, y: 0 });

  const mouseEventHandler = useCallback(({ clientX, clientY }: MouseEvent) => {
    coordsReference.current = { x: clientX, y: clientY };
  }, []);

  const getMouseCoords = useCallback(() => coordsReference.current, []);

  return { mouseEventHandler, getMouseCoords };
};
