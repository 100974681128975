import { useMemo } from 'react';

import jHookHangers from '../mock/j-hook-hangers.json';
import { getColumns } from '../utils';
import { getData } from '../utils/get-data';
import Table from './table';

export const JHookHangersTable = () => {
  const data = useMemo(() => {
    return getData(jHookHangers);
  }, []);

  const columns = useMemo(() => {
    return getColumns(jHookHangers);
  }, []);

  return <Table data={data} columns={columns} />;
};
