import { Box, Button, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FolderFillIcon } from 'assets/icons';
import { EUrlSearchParamKeys } from 'components/page-header/types';
import { ERoutes } from 'components/router/types';
import { COMMON_DOCUMENT_PROPS } from 'constants/index';
import { useFileUpload } from 'hooks';
import { useUpdateUrlSearchParams } from 'hooks/useSetUpdateUrlSearchParams';
import { AddFile } from 'pages/add-project/components/add-file/add-file';
import { styles } from 'pages/add-project/components/add-project-form/add-project-form.styles';
import { useToastIfError } from 'pages/add-project/components/add-project-form/hooks/hooks';
import { FormTextField } from 'pages/add-project/components/form-textfield/form-textfield';
import { FORM_VALIDATION, formFields } from 'pages/add-project/constants';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUploadDocuments } from 'redux/selectors';
import {
  documentActions,
  mockedProjectsActions,
  useCreateProjectMutation,
  useUploadFilesMutation,
} from 'redux/slices';
import { Document } from 'types/redux';

export function AddProjectForm(): JSX.Element {
  const [createProject] = useCreateProjectMutation();
  const [uploadFiles] = useUploadFilesMutation();
  const { selectedFiles, getRootProps, getInputProps } = useFileUpload();
  const { setSearchParam } = useUpdateUrlSearchParams();
  const dispatch = useDispatch();
  const [isFileLoading, setIsFileLoading] = useState(true);
  const loadedDocuments = useSelector(getUploadDocuments);
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(FORM_VALIDATION),
    defaultValues: {
      name: '',
    },
  });
  const {
    formState: { errors, isDirty, isValid },
  } = form;
  const nameFieldValue = form.watch('name');
  const navigate = useNavigate();
  const checkForErrors = useToastIfError();

  const disableButton =
    Object.keys(errors).length ||
    isFileLoading ||
    !Object.keys(loadedDocuments).length ||
    !isDirty ||
    !isValid;

  const onSubmit = async (data: { name: string }) => {
    const id = Date.now().toString();
    dispatch(mockedProjectsActions.createProject({ id, name: nameFieldValue }));
    const createdProjectMutationResult: any = await createProject(data);
    const isErrorWhileCreatingProject = checkForErrors(createdProjectMutationResult?.error?.data);
    if (!isErrorWhileCreatingProject) {
      selectedFiles.forEach((file, idx) => {
        dispatch(
          documentActions.openDocument({
            ...COMMON_DOCUMENT_PROPS,
            id: new Date().getTime() * (idx + 1),
            name: file.name,
            preview: file.preview,
            lastModified: file.lastModified,
            size: file.size,
            type: file.type,
            scale: null,
          }),
        );
      });
      const formData = new FormData();
      const files = new Blob(selectedFiles as unknown as Blob[], {
        type: 'application/pdf',
      });
      formData.set('files', files);
      const uploadFileMutationResult: any = uploadFiles({
        id: createdProjectMutationResult?.data.id,
        files: formData,
      });
      const isErrorWhileUploadingSource = checkForErrors(uploadFileMutationResult?.error?.data);
      if (!isErrorWhileUploadingSource) {
        navigate(
          `${ERoutes.PROJECTS}/${createdProjectMutationResult?.data.id}${
            ERoutes.DRAWINGS
          }/${'all'}/1`,
        );
      }
    }
  };

  useEffect(() => {
    return setSearchParam(EUrlSearchParamKeys.PROJECT_NAME, nameFieldValue || '');
  }, [nameFieldValue, setSearchParam]);

  useEffect(() => {
    const documents = Object.values(loadedDocuments);
    if (documents.length) {
      documents.forEach((file: Document) => {
        setIsFileLoading(file.isProcessing);
      });
    }
  }, [loadedDocuments]);

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          <Box sx={styles.formFieldsWrapper}>
            {formFields.map(({ key, label, placeholder }) => (
              <FormTextField name={key} label={label} placeholder={placeholder} key={key} />
            ))}
            <Button
              sx={styles.formSubmitButton}
              background={disableButton ? 'gray.200' : 'purple.100'}
              isDisabled={!!disableButton}
              type='submit'
            >
              <FolderFillIcon width='15.75px' height='15px' />
              <Text marginLeft='5.25px'>Create Project</Text>
            </Button>
          </Box>
        </form>
      </FormProvider>
      <AddFile {...{ selectedFiles, getRootProps, getInputProps }} />
    </>
  );
}
