import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { ListSettingsIcon, ShapeIcon } from 'assets/icons';
import Card from 'components/card';
import { HeaderBreadcrumbs } from 'components/header-breadcrums';
import { useGetBreadcrumbs } from 'components/header-breadcrums/hooks/useGetBreadcrumbs';
import { EUrlSearchParamKeys } from 'components/page-header/types';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useGetUrlSearchParams } from 'hooks';
import { EViewMods } from 'pages/sheet/types';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAnnotations, getWorkMode, isAnnotationListOpen } from 'redux/selectors';
import { getProject } from 'redux/selectors/project.selector';
import { getFormDimensionValues } from 'redux/selectors/redline-form.selector';
import { annotationActions as AA, applicationActions, useImportJsonMutation } from 'redux/slices';
import { EFixedCachedKeys, IProjectPageHeaderProps } from 'types';
import { State, WORK_MODES } from 'types/redux';

import { detectCircuits } from './components/apryse-block/utils/detectCircuits';
import ExportToCsvButton from './components/export-button';
import { styles } from './styles';

const SheetHeader = ({ path }: IProjectPageHeaderProps) => {
  const dispatch = useDispatch();
  const annotationEntities = useSelector(getAnnotations);
  const workMode = useSelector(getWorkMode);
  const documentDimensions = useSelector(getFormDimensionValues);
  const { instance } = useContext(WebviewerContext);
  const itemsToSkipProjectPrefixWithId = 2;
  const breadcrumbs = useGetBreadcrumbs(path, itemsToSkipProjectPrefixWithId);
  const { urlSearchParamsObject, setSearchParam } = useGetUrlSearchParams();
  const { id: projectId } = useParams();
  const [, { data, isError, isLoading }] = useImportJsonMutation({
    fixedCacheKey: EFixedCachedKeys.IMPORT_JSON,
  });
  const projectName = useSelector((state: State) => getProject(state, projectId || ''));
  const enableShow3DViewButton = !!data && !isError;

  const isAnnotationSummaryListOpen = useSelector(isAnnotationListOpen);

  const toggleAnnotationSummaryListVisibility = () => {
    dispatch(AA.setAnnotationsListOpen({ isAnnotationsListOpen: !isAnnotationSummaryListOpen }));
  };
  const toggle3DModeView = () => {
    if (urlSearchParamsObject[EUrlSearchParamKeys.VIEW_MODE] !== EViewMods._3D) {
      return setSearchParam(EUrlSearchParamKeys.VIEW_MODE, EViewMods._3D);
    }
    return setSearchParam(EUrlSearchParamKeys.VIEW_MODE, EViewMods._2D);
  };

  const handleDetectCircuits = () => {
    detectCircuits({ annotationEntities, instance, documentDimensions });
    dispatch(applicationActions.submitAnnotationsDetection({ areAnnotationsDetected: true }));
  };
  const viewToggleButtonText =
    urlSearchParamsObject[EUrlSearchParamKeys.VIEW_MODE] === EViewMods._3D && enableShow3DViewButton
      ? 'Redliner'
      : '3D View ';

  return (
    <Card
      wrapperProps={{
        padding: `13.5px 10.5px 13.5px 15px`,
        height: '48px',
        minHeight: '48px',
        width: '100%',
      }}
    >
      <Flex sx={styles.headBlockInnerWrapper}>
        <Flex sx={styles.leftHeadBlock}>
          <Text sx={styles.sheetNameText}>{projectName || 'Sheet Name'}</Text>
          {!!breadcrumbs?.length && <HeaderBreadcrumbs breadcrumbs={breadcrumbs} />}
        </Flex>
        {workMode === WORK_MODES.BRANCH ? (
          <Flex>
            <Button variant='secondary' sx={styles.secondaryButton}>
              Detect tags
            </Button>
            <Button variant='secondary' sx={styles.secondaryButton} onClick={handleDetectCircuits}>
              Detect receptacles
            </Button>
            <ExportToCsvButton />
            <Button
              onClick={toggle3DModeView}
              variant='primary'
              sx={styles.toggleButton}
              leftIcon={<ShapeIcon size='15px' />}
              isDisabled={!enableShow3DViewButton}
              _hover={{ background: 'rgba(115, 103, 240, 1)' }}
            >
              {isLoading && <Spinner sx={{ marginRight: '4.5px' }} />}
              {viewToggleButtonText}
            </Button>
            <Button
              onClick={toggleAnnotationSummaryListVisibility}
              variant='primary'
              sx={styles.toggleButton}
              leftIcon={<ListSettingsIcon size='15px' />}
            >
              Element List
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </Card>
  );
};

export default SheetHeader;
