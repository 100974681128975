export const styles = {
  labelText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10.5px',
    lineHeight: '160%',
    color: '#6E6B7B',
    marginRight: '3px',
  },
};
