import React from 'react';

const FilePlusIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 81 82'
    >
      <path
        fill='#7368F0'
        d='M50.625 13.667h-33.75v54.666h47.25v-41h-13.5V13.667zm-40.5-3.444a3.38 3.38 0 013.372-3.39H54l16.875 17.084v47.81a3.454 3.454 0 01-.972 2.422c-.311.32-.681.574-1.09.748a3.341 3.341 0 01-1.29.27H13.477a3.355 3.355 0 01-2.361-1 3.438 3.438 0 01-.99-2.39V10.223zm27 27.36v-10.25h6.75v10.25H54v6.834H43.875v10.25h-6.75v-10.25H27v-6.834h10.125z'
      ></path>
    </svg>
  );
};

export default FilePlusIcon;
