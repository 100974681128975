import { Box } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import { styles } from './styles';

const Card = forwardRef(({ children, wrapperProps }: any, ref) => {
  return (
    <Box
      ref={ref}
      borderRadius='6px'
      overflow='auto'
      background='white'
      sx={styles.cardWrapper}
      {...wrapperProps}
    >
      {children}
    </Box>
  );
});

export default Card;
