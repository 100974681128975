interface AddIconProps {
  fill?: string;
  size?: string;
}

const AddIcon = ({ fill = '#fff', size = '20px' }: AddIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M9.167 9.167V5.833h1.666v3.334h3.334v1.666h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334zM10 18.333a8.333 8.333 0 110-16.666A8.333 8.333 0 0118.333 10 8.333 8.333 0 0110 18.333zm0-1.666a6.667 6.667 0 100-13.334 6.667 6.667 0 000 13.334z'
      ></path>
    </svg>
  );
};

export default AddIcon;
