import { Flex, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLayer } from 'redux/selectors';
import { layerActions } from 'redux/slices';
import { Layer } from 'types/redux';
import { funcWithPreventLogic } from 'utils/functWithPrevenLogicUtil';

import LayerMenu from './layer-menu';
import { styles } from './styles';

interface LayerItemProps {
  documentId: number;
  layer: Layer;
}

export const LayerItem = ({ documentId, layer }: LayerItemProps) => {
  const dispatch = useDispatch();
  const activeLayer = useSelector(getActiveLayer);

  const handleToggleActiveLayer = () => {
    if (activeLayer?.layerId === layer.id) {
      dispatch(
        layerActions.setActiveLayer({
          layerInfo: null,
        }),
      );
    } else {
      dispatch(
        layerActions.setActiveLayer({
          layerInfo: {
            layerId: layer.id,
            layerName: layer.name,
          },
        }),
      );
    }
  };

  return (
    <Flex sx={styles.layerInfoWrapper}>
      <Text
        sx={styles.layerNameText}
        color={activeLayer?.layerId === layer.id ? 'purple.100' : 'black.50'}
        onClick={funcWithPreventLogic(handleToggleActiveLayer)}
      >
        {layer.name}
        <LayerMenu currentDocumentId={documentId} layerToEdit={layer} activeLayer={activeLayer} />
      </Text>
    </Flex>
  );
};
