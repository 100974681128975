import { createPathPoints } from '../../apryse-block/utils';
import { constructColorString } from '../utils';
import { PrepareAnnotationProps } from './types';

export const prepareAnnotation = ({ annotation, data, instance }: PrepareAnnotationProps) => {
  annotation.type = data.type;

  const annotationData = {
    ...annotation.data,
    ...data,
  };

  annotation.data = {
    ...annotationData,
    fillColor: constructColorString(annotationData.fillColor),
    strokeColor: constructColorString(annotationData.strokeColor),
  };

  annotation.id = annotation.id || annotation.Id;

  annotation.pathPoints = createPathPoints({
    viewerInstance: instance,
    annotation,
    isModify: true,
  });
};
