import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useToastIfError = () => {
  const toast = useToast();
  return useCallback(
    (callResult: any, type?: string) => {
      const errorTitle =
        type === 'query' ? callResult?.error?.data?.error : callResult?.errors?.[0].title;
      if (errorTitle) {
        toast({
          title: 'Error',
          description: errorTitle,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return true;
      }
      return false;
    },
    [toast],
  );
};
