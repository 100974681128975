export const styles = {
  tab: {
    maxWidth: '242.25px',
    w: 'full',
    bg: 'gray.25',
    borderRadius: 0,
    border: 'none',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '16.5px',
    color: 'gray.350',
    maxHeight: '29.25px',
    mt: 'auto',
    '&[aria-selected="true"]': {
      fontSize: '13.5px',
      lineHeight: '20.25px',
      color: 'purple.100',
      fontWeight: 600,
      bg: 'white',
      py: '9px',
      maxHeight: 'unset',
      border: '1px solid',
      borderBottom: 'none',
      borderColor: 'purple.100',
      borderTopLeftRadius: '9px',
      borderTopRightRadius: '9px',
    },
  },
  tabList: {
    pl: '9.75px',
    pr: '98.25px',
  },
  tabPanels: {
    h: '141.75px',
    background: 'white',
    maxHeight: '141.75px',
    borderTop: '1px solid',
    borderColor: 'purple.50',
  },
  tabPanel: {
    p: 0,
  },
  zoomWrapper: {
    display: 'flex',
    position: 'absolute',
    top: '11.25px',
    right: '16.5px',
    button: {
      minWidth: '24px',
      width: '24px',
      height: '24px',
      background: 'white',
      border: '1px solid #E5E7EB',
      boxShadow: '0px 1px 2px 0px #1F293714',
      borderRadius: '50%',
      padding: '0px',
      svg: {
        minW: '12px',
        minH: '12px',
      },
    },
  },
};
