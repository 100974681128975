import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { getModalData } from 'redux/selectors';

import FormContent from '../annotation-info-block/form-content';

interface CustomColumnAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

const CustomColumnAlert = ({ isOpen, onClose }: CustomColumnAlertProps) => {
  const cancelRef = useRef<HTMLButtonElement>(null);

  const modalData = useSelector(getModalData);

  const heading = modalData?.label ? `Editing "${modalData.label}" column` : 'Add custom column';

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {heading}
          </AlertDialogHeader>
          <FormContent ref={cancelRef} onClose={onClose} modalData={modalData} />
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomColumnAlert;
