import React from 'react';

export const CloseIcon = ({ size = '20px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0006 8.82167L14.1256 4.69667L15.3039 5.875L11.1789 10L15.3039 14.125L14.1256 15.3033L10.0006 11.1783L5.8756 15.3033L4.69727 14.125L8.82227 10L4.69727 5.875L5.8756 4.69667L10.0006 8.82167Z'
        fill='#7368F0'
      />
    </svg>
  );
};
