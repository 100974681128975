import { Box } from '@chakra-ui/react';
import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react';
import Select, { GroupBase, OptionsOrGroups, PropsValue, StylesConfig } from 'react-select';
import { Row } from 'react-table';

interface SelectOption {
  label: ReactNode;
  value: string;
}

interface ColorColumnFilterProps {
  column: {
    filterValue?: string;
    setFilter: Dispatch<SetStateAction<string | undefined>>;
    preFilteredRows: Row<{ color: string }>[];
  };
}

type OptionType = OptionsOrGroups<string, GroupBase<string>> | undefined;

export function filterByColor(rows: Row<{ color: string }>[], id: string, filterValue: string) {
  return rows.filter((row) => {
    const rowValue = row.original.color;

    return rowValue === filterValue;
  });
}

const STYLES: StylesConfig<string | GroupBase<string>, false, GroupBase<string>> | undefined = {
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: 30,
    height: 30,
    borderColor: '#625F6E',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '11px',
    fontWeight: '300',
    marginTop: 2,
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (baseStyle) => ({
    ...baseStyle,
    fontSize: 11,
    fontWeight: 300,
    marginTop: 2,
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    margin: 0,
  }),
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    width: 15,
    marginRight: 5,
    marginLeft: 5,
  }),
};

export default function ColorColumnFilter({
  column: { filterValue, setFilter, preFilteredRows },
}: ColorColumnFilterProps) {
  const colorOptions = useMemo(() => {
    const options = new Set<string>();

    preFilteredRows.forEach((row) => {
      options.add(row.original.color);
    });

    const optionsList: SelectOption[] = [...options.values()].map((color) => {
      return { label: <Box h='15px' w='15px' bgColor={color} />, value: color };
    });

    optionsList.unshift({ label: 'All', value: '' });

    return optionsList;
  }, [preFilteredRows]);

  const _value = useMemo(() => {
    return colorOptions?.find((option) => (option as SelectOption).value === filterValue);
  }, [colorOptions, filterValue]);

  return (
    <Select
      styles={STYLES}
      defaultValue={colorOptions[0] as unknown as OptionType}
      value={_value as PropsValue<string | GroupBase<string>> | undefined}
      onChange={(data) => {
        setFilter((data as unknown as SelectOption)?.value || undefined);
      }}
      options={colorOptions as unknown as OptionType}
    ></Select>
  );
}
