import { DEFAULT_HEADER_HEIGHT } from 'constants/styles';

export const styles = {
  projectsWrapper: {
    padding: '3px 13.5px 0px 13.5px',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: `calc(100vh - ${DEFAULT_HEADER_HEIGHT}px)`,
  },
  pageContentWrapper: {
    marginTop: '3px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexGrow: 1,
    overflow: 'auto',
  },
};
