type parserProps = {
  colorType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  annotation: any;
};

export const parseApryseAnnotationColor = ({ colorType, annotation }: parserProps) => {
  switch (colorType) {
    case 'fill':
      return `rgba(${annotation.FillColor.R},${annotation.FillColor.G},${annotation.FillColor.B},${annotation.FillColor.A})`;

    case 'stroke':
      return `rgba(${annotation.StrokeColor.R},${annotation.StrokeColor.G},${annotation.StrokeColor.B},${annotation.StrokeColor.A})`;

    default:
      return 'rgba(0,0,0,0)';
  }
};
