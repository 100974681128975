import React from 'react';

interface IconProps {
  fill?: string;
  size?: string;
}

const FileListIcon = ({ fill = '#000', size = '16px' }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 17 16'
      className='file-list-icon'
    >
      <path
        fill={fill}
        d='M13.833 14.667H3.167A.666.666 0 012.5 14V2a.667.667 0 01.667-.667h10.666A.667.667 0 0114.5 2v12a.666.666 0 01-.667.667zm-.666-1.334V2.667H3.833v10.666h9.334zM5.833 4.667h5.334V6H5.833V4.667zm0 2.666h5.334v1.334H5.833V7.333zm0 2.667h5.334v1.333H5.833V10z'
      ></path>
    </svg>
  );
};

export default FileListIcon;
