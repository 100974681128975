import { ANNOTATION_TYPES, ELEMENT_TYPES } from 'constants/apryse';
import { Annotation } from 'types/redux';

import { fitScaleForCoords } from './fitScaleForCoords';
import { fitScaleForDimensions } from './fitScaleForDimensions';
import { fitScaleForPathPoints } from './fitScaleForPathPoints';

export interface ExtendedAnnotation extends Annotation {
  x: number;
  y: number;
}

interface ConvertCoordsByElementTypeArguments {
  annotation: ExtendedAnnotation;
  docDimensions: {
    width: number;
    height: number;
  };
}

export const convertCoordsByElementType = ({
  annotation,
  docDimensions,
}: ConvertCoordsByElementTypeArguments) => {
  if ([ANNOTATION_TYPES.CONDUIT, ANNOTATION_TYPES.MC_CABLE].includes(annotation.elementType)) {
    const convertedPathPoints = fitScaleForPathPoints({
      docDimensions,
      pathPoints: annotation.pathPoints as number[][],
      direction: 'apryse',
    });

    return { ...annotation, pathPoints: convertedPathPoints };
  }

  if (ANNOTATION_TYPES.HOMERUN_BOX === annotation.elementType) {
    const convertedXY = fitScaleForCoords({
      docDimensions,
      coords: {
        x: annotation.x,
        y: annotation.y,
      },
      direction: 'apryse',
    });

    const convertedWidthHeight = fitScaleForDimensions({
      docDimensions,
      dimensions: {
        // @ts-ignore
        width: annotation.width,
        // @ts-ignore
        height: annotation.height,
      },
      direction: 'apryse',
    });

    return { ...annotation, ...convertedXY, ...convertedWidthHeight };
  }

  if (ANNOTATION_TYPES.CIRCUIT === annotation.elementType) {
    const convertedXY = fitScaleForCoords({
      docDimensions,
      coords: {
        x: annotation.x,
        y: annotation.y,
      },
      direction: 'apryse',
    });

    return { ...annotation, ...convertedXY };
  }

  return annotation;
};
