import { WebViewerInstance } from '@pdftron/webviewer';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { annotationActions, layerActions } from 'redux/slices';
import { Layer } from 'types/redux';
import { hideAnnotation, showAnnotation } from 'utils';

type ToggleVisibilityHookProps = {
  documentId: number;
  layer: Layer;
};
export const useLayer = ({ documentId, layer }: ToggleVisibilityHookProps) => {
  const { instance } = useContext(WebviewerContext);
  const dispatch = useDispatch();

  const handleToggleVisibility = () => {
    dispatch(
      layerActions.toggleVisibility({
        documentId,
        layerId: layer.id,
        visible: !layer.visible,
      }),
    );
    layer.annotations.forEach((annotation) => {
      dispatch(
        annotationActions.toggleAnnotationVisibility({
          documentId,
          annotationId: annotation.id,
          _isVisible: !layer.visible,
        }),
      );
      if (layer.visible) {
        hideAnnotation({
          annotationId: annotation.id,
          viewerInstance: instance as WebViewerInstance,
        });
      } else {
        showAnnotation({
          annotationId: annotation.id,
          viewerInstance: instance as WebViewerInstance,
        });
      }
    });
  };

  const handleDelete = () => {
    dispatch(layerActions.removeLayer({ documentId, layerId: layer.id }));
    layer.annotations.forEach((annotation) => {
      const updatedAnnotation = { ...annotation };
      Object.assign(updatedAnnotation, { data: { layer: '' } });
      dispatch(
        annotationActions.modifyAnnotations({
          documentId,
          annotations: [updatedAnnotation],
        }),
      );
    });
  };

  return { handleToggleVisibility, handleDelete };
};
