import { Flex, Tag } from '@chakra-ui/react';
import { SearchIcon } from 'assets/icons';
import TextField from 'components/text-field';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { ChangeEventHandler, Dispatch, SetStateAction, useContext, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

interface GlobalFilterProps {
  globalFilter?: string;
  setGlobalFilter: Dispatch<SetStateAction<string | undefined>>;
}

function GlobalFilter({ globalFilter, setGlobalFilter }: GlobalFilterProps) {
  const { instance } = useContext(WebviewerContext);
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value: string) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const selectedAmount = instance?.Core?.annotationManager?.getSelectedAnnotations().length;

  return (
    <Flex w='full' alignItems='center'>
      <SearchIcon
        fill='#6E6B7B'
        size='12px'
        style={{ marginLeft: '19.5px', marginRight: '24px' }}
      />
      <TextField
        wrapperProps={{ h: '24px', w: 'full' }}
        value={value || ''}
        onChange={handleChange}
        placeholder={`Global search`}
        inputStyles={{
          border: 'none',
          borderRadius: 0,
          background: 'white',
          _placeholder: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '9px',
            color: '#6E6B7B',
          },
          _focus: { boxShadow: 'none' },
        }}
      />
      {Boolean(selectedAmount) && (
        <Tag
          alignSelf='center'
          pos='absolute'
          left='calc(100% - 157.5px)'
          variant='subtle'
          colorScheme='purple'
          color='white'
          fontSize='10.5px'
        >
          Selected annotations: {selectedAmount}
        </Tag>
      )}
    </Flex>
  );
}

interface GlobalFilterSectionProps {
  globalFilter?: string;
  setGlobalFilter: Dispatch<SetStateAction<string | undefined>>;
  onFilterClick?: () => void;
}

function GlobalFilterSection({
  onFilterClick,
  globalFilter,
  setGlobalFilter,
}: GlobalFilterSectionProps) {
  return (
    <>
      {/* <IconButton aria-label='filter' bg='purple.100' borderRadius={0} onClick={onFilterClick}>
        <Icon as={BiFilterAlt} w={'25px'} h='25px' color='white' />
      </IconButton> */}
      <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
    </>
  );
}
export default GlobalFilterSection;
