import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { POLYLINE_MIN_LENGTH } from 'pages/sheet/components/apryse-block/constants';
import { EAnnotationSubject } from 'types';

interface CreatePathPoints {
  annotation: Core.Annotations.Annotation;
  viewerInstance: WebViewerInstance;
  isSameCoords?: boolean;
  isModify?: boolean;
}

export const createRectanglePathPoints = (annotation: Core.Annotations.RectangleAnnotation) => {
  const { X, Y, Width, Height } = annotation;
  const leftTop = [X, Y];
  const rightTop = [X + Width, Y];
  const rightBottom = [X + Width, Y + Height];
  const leftBottom = [X, Y + Height];

  return [leftTop, rightTop, rightBottom, leftBottom];
};

interface CreatePolylinePathPoints extends CreatePathPoints {
  annotation: Core.Annotations.IPathAnnotation;
}

const createPolylinePathPoints = ({
  annotation,
  viewerInstance,
  isSameCoords,
}: CreatePolylinePathPoints): number[][] => {
  const shouldPopLastPathPoint = annotation.getPath().length > POLYLINE_MIN_LENGTH && !isSameCoords;
  if (shouldPopLastPathPoint) {
    annotation.popPath();
    viewerInstance.Core.annotationManager.redrawAnnotation(annotation);
  }
  return annotation.getPath().map((pathPoint: { x: number; y: number }) => {
    return [pathPoint.x, pathPoint.y];
  });
};

export const createPathPoints = ({
  annotation,
  viewerInstance,
  isSameCoords,
  isModify,
}: CreatePathPoints): number[][] => {
  if (annotation.Subject === EAnnotationSubject.POLYLINE) {
    return createPolylinePathPoints({
      annotation,
      viewerInstance,
      isSameCoords: isModify || isSameCoords,
    } as CreatePolylinePathPoints);
  }
  if (annotation.Subject === EAnnotationSubject.RECTANGLE) {
    return createRectanglePathPoints(annotation as Core.Annotations.RectangleAnnotation);
  }
  return [];
};
