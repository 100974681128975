import { Core } from '@pdftron/webviewer';
import { ANNOTATION_LABEL, ANNOTATION_TYPES } from 'constants/apryse';

import { ZONES, ZONES_NAME } from '../constants';
import { ZoneType } from './types';

export const createZoneTool = (instanceCore: typeof Core, zoneType: ZoneType) => {
  const { Annotations, Tools, documentViewer, annotationManager } = instanceCore;

  const ZoneAnnotation = Annotations.CustomAnnotation.createFromClass(
    ANNOTATION_TYPES[zoneType],
    Annotations.RectangleAnnotation,
  );

  annotationManager.registerAnnotationType(ZoneAnnotation.prototype.elementName, ZoneAnnotation);

  Annotations.setCustomDrawHandler(
    ZoneAnnotation,
    function drawHandler(ctx, pageMatrix, rotation, options) {
      // @ts-ignore
      options.annotation.elementType = ANNOTATION_TYPES[zoneType];
      options.originalDraw(ctx, pageMatrix, rotation);

      let fontSize;

      // @ts-ignore
      if (this.Height > this.Width) {
        // @ts-ignore
        fontSize = this.Width * 0.1;
      } else {
        // @ts-ignore
        fontSize = this.Height * 0.1;
      }

      ctx.save();
      ctx.fillStyle = '#FFFFFF';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = `100 ${fontSize || 0}px sans-serif`;
      // @ts-ignore
      const X = this.X + this.Width / 2;
      // @ts-ignore
      const Y = this.Y + this.Height / 2;
      ctx.fillText(ANNOTATION_LABEL[zoneType], X, Y);
      ctx.restore();
    },
  );

  const ZoneCreateTool = new Tools.GenericAnnotationCreateTool(documentViewer, ZoneAnnotation);

  const defaults = {
    StrokeColor:
      zoneType === ZONES.NO_FLY_ZONE
        ? new Annotations.Color(255, 165, 0, 1)
        : new Annotations.Color(0, 155, 119, 1),
    FillColor:
      zoneType === ZONES.NO_FLY_ZONE
        ? new Annotations.Color(255, 165, 0, 0.7)
        : new Annotations.Color(0, 155, 119, 0.7),
    Opacity: 1,
    StrokeThickness: 1,
  };

  ZoneCreateTool.mouseDoubleClick = function dbClick() {
    const annotation = new ZoneAnnotation({
      ...defaults,
      Width: 300,
      Height: 100,
      X: this.pageCoordinates[0].x,
      Y: this.pageCoordinates[0].y,
      ToolName: ZONES_NAME[zoneType],
      elementType: ANNOTATION_TYPES[zoneType],
    });
    this.getDocumentViewer().getAnnotationManager().addAnnotation(annotation);
    this.getDocumentViewer().getAnnotationManager().redrawAnnotation(annotation);
  };

  ZoneCreateTool.defaults = defaults;

  return ZoneCreateTool;
};
