import { TAuthConfig } from 'auth-service';
import { authPath, logoutPath, tokenPath } from 'constants/auth.constants';
import * as process from 'process';

const isLocalhost = window.location.hostname === 'localhost';
const redirectUri = isLocalhost
  ? process.env.REACT_APP_AUTH_LOCALHOST_URI
  : process.env.REACT_APP_AUTH_MVP_URI;

export const authConfig: TAuthConfig = {
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  authorizationEndpoint: process.env.REACT_APP_AUTH_DOMAIN + authPath,
  tokenEndpoint: process.env.REACT_APP_AUTH_DOMAIN + tokenPath,
  logoutEndpoint: process.env.REACT_APP_AUTH_DOMAIN + logoutPath,
  redirectUri: redirectUri as string,
  scope: 'openid profile phone',

  extraAuthParameters: {
    client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
  },
  extraTokenParameters: {
    client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
  },
  clearURL: false,
};
