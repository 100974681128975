type NumberString = number | string;

const DEFAULT_HEX = '#000000';
const DEFAULT_RGB = 'rgba(0, 0, 0, 1)';

export function RGBToHex(r: NumberString, g: NumberString, b: NumberString) {
  r = Number(r).toString(16);
  g = Number(g).toString(16);
  b = Number(b).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;

  return `#${r}${g}${b}`;
}

export const extractRGB = (rgbaString: string): NumberString[] => {
  return rgbaString?.replace(/[^\d.*,]/g, '').split(',');
};

export const convertRGBtoHex = (rgbaString: string): string => {
  if (!rgbaString) return DEFAULT_HEX;

  const [red, green, blue] = extractRGB(rgbaString);

  return RGBToHex(red, green, blue);
};

export function HEXToRGB(hex = '', alpha = '1') {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const rgbSchema = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  if (rgbSchema) {
    return `rgba(${rgbSchema.r},${rgbSchema.g},${rgbSchema.b},${alpha})`;
  }

  return DEFAULT_RGB;
}

export function isInHexFormat(input: string) {
  return /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(input);
}

export function isInRGBAFormat(input: string) {
  return /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.test(input);
}

export function isColorLikeString(input: string) {
  return isInHexFormat(input) || isInRGBAFormat(input);
}

export function getAlphaValue(input: string) {
  if (isInRGBAFormat(input)) {
    const rgba = extractRGB(input);

    return typeof rgba[3] === 'undefined' ? 1 : Number.parseFloat(rgba[3].toString());
  }

  return 1;
}
