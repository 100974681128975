export const REPORTS = [
  {
    id: 1,
    name: 'Name Report',
    created: '09.08.2022',
    user: 'Admin',
    status: 'In progress',
    progress: 80,
  },
  {
    id: 2,
    name: 'Name Report 2 Name Report',
    created: '09.08.2022',
    user: 'Admin',
    status: 'Ready',
    progress: 100,
  },
];
