import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTool } from 'redux/selectors';

import { isPolyline } from '../utils';

interface PolylineHandlers {
  mouseMoveHandler: ({ clientX, clientY }: MouseEvent) => void;
  mouseClickHandler: ({ clientX, clientY }: MouseEvent) => void;
}

export const useSetupPolylineListeners = (
  mouseMoveHandler: PolylineHandlers['mouseMoveHandler'],
  mouseClickHandler: PolylineHandlers['mouseClickHandler'],
) => {
  const { instance } = useContext(WebviewerContext);

  const selectedTool = useSelector(getSelectedTool);

  useEffect(() => {
    if (instance) {
      if (isPolyline(selectedTool)) {
        instance.Core.documentViewer.addEventListener('mouseMove', mouseMoveHandler);
        instance.Core.documentViewer.addEventListener('click', mouseClickHandler);
      }
    }

    return () => {
      if (instance) {
        if (isPolyline(selectedTool)) {
          instance.Core.documentViewer.removeEventListener('mouseMove', mouseMoveHandler);
          instance.Core.documentViewer.removeEventListener('click', mouseClickHandler);
        }
      }
    };
  }, [selectedTool, instance, mouseMoveHandler, mouseClickHandler]);
};
