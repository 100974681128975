const DownloadIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#000'
        d='M2.5 15.833h15V17.5h-15v-1.667zm8.333-4.856l5.06-5.06 1.178 1.178L10 14.167 2.93 7.096l1.177-1.18 5.06 5.059V1.667h1.666v9.31z'
      ></path>
    </svg>
  );
};

export default DownloadIcon;
