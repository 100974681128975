import React from 'react';

export const TableLineIcon = () => {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.66732 5.83333H13.334V3.83333H2.66732V5.83333ZM9.33398 13.1667V7.16667H6.66732V13.1667H9.33398ZM10.6673 13.1667H13.334V7.16667H10.6673V13.1667ZM5.33398 13.1667V7.16667H2.66732V13.1667H5.33398ZM2.00065 2.5H14.0007C14.1775 2.5 14.347 2.57024 14.4721 2.69526C14.5971 2.82029 14.6673 2.98986 14.6673 3.16667V13.8333C14.6673 14.0101 14.5971 14.1797 14.4721 14.3047C14.347 14.4298 14.1775 14.5 14.0007 14.5H2.00065C1.82384 14.5 1.65427 14.4298 1.52925 14.3047C1.40422 14.1797 1.33398 14.0101 1.33398 13.8333V3.16667C1.33398 2.98986 1.40422 2.82029 1.52925 2.69526C1.65427 2.57024 1.82384 2.5 2.00065 2.5Z'
        fill='#868592'
      />
    </svg>
  );
};
