import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { annotationActions } from 'redux/slices';

export const useDispatchSelectAnnotation = () => {
  const { instance } = useContext(WebviewerContext);
  const dispatch = useDispatch();
  return useCallback(
    (annotationId: string) => {
      if (instance) {
        dispatch(
          annotationActions.selectAnnotation({ documentId: 1, annotationIds: [annotationId] }),
        );
        instance?.Core.annotationManager.selectAnnotation(
          instance?.Core.annotationManager.getAnnotationById(annotationId) as any,
        );
      }
    },
    [instance, dispatch],
  );
};
