import { Flex } from '@chakra-ui/react';
import { WebViewerInstance } from '@pdftron/webviewer';
import { EyeCloseIcon, EyeIcon } from 'assets/icons';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnotations } from 'redux/selectors';
import { annotationActions } from 'redux/slices';
import { hideAnnotation, showAnnotation } from 'utils';

interface VisibiltyTogglerProps {
  id: string;
}

const VisibilityToggler = ({ id }: VisibiltyTogglerProps) => {
  const annotationEntities = useSelector(getAnnotations);
  const { instance } = useContext(WebviewerContext);
  const dispatch = useDispatch();

  const annotationToToggleVisibility = annotationEntities[1].find(
    (annotation) => annotation.id === id,
  )?._isVisible;

  const handleToggleAnnotationVisibility = () => {
    if (!annotationToToggleVisibility) {
      showAnnotation({
        viewerInstance: instance as WebViewerInstance,
        annotationId: id,
      });
    } else {
      hideAnnotation({
        viewerInstance: instance as WebViewerInstance,
        annotationId: id,
      });
    }
    dispatch(
      annotationActions.toggleAnnotationVisibility({
        documentId: 1,
        annotationId: id,
        _isVisible: !annotationToToggleVisibility,
      }),
    );
  };

  let content;
  if (annotationToToggleVisibility) {
    content = <EyeIcon onClick={handleToggleAnnotationVisibility} size='12px' fill='#6E6B7B' />;
  } else {
    content = (
      <EyeCloseIcon onClick={handleToggleAnnotationVisibility} size='12px' fill='#6E6B7B' />
    );
  }

  return (
    <Flex justifyContent='center' h='full' alignItems='center'>
      {content}
    </Flex>
  );
};

export default VisibilityToggler;
