export const styles = {
  menuBtn: {
    position: 'relative',
    cursor: 'pointer',
    marginLeft: '36px',
  },
  notificationDot: {
    background: 'red.100',
    height: '14.25px',
    width: '14.25px',
    borderRadius: 'full',
    position: 'absolute',
    top: '-6px',
    left: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '9px',
  },
};
