const BellIcon = ({ size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 21 22'
    >
      <path
        stroke='#6E6B7B'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.75'
        d='M15.75 7.5a5.25 5.25 0 10-10.5 0c0 6.125-2.625 7.875-2.625 7.875h15.75S15.75 13.625 15.75 7.5zM12.014 18.875a1.75 1.75 0 01-3.028 0'
      ></path>
    </svg>
  );
};

export default BellIcon;
