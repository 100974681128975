import React from 'react';

export const BasketballIcon = ({ size = '18px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.0003 10.9999C7.72566 10.9999 9.30523 10.3757 10.5255 9.3408C10.7902 9.11632 10.7096 8.70403 10.383 8.58665C9.3265 8.20693 8.18756 8.00002 7.00029 8.00002C5.91467 8.00002 4.86946 8.17301 3.89071 8.49294C3.75558 8.53711 3.64741 8.63955 3.59641 8.77226C3.44297 9.17151 3.31716 9.58447 3.22133 10.0088C3.17068 10.233 3.29023 10.4601 3.50503 10.5421C4.28012 10.8379 5.12129 10.9999 6.0003 10.9999Z'
        fill='#AEADBC'
      />
      <path
        d='M7.00029 6.00002C8.70135 6.00002 10.3197 6.35397 11.7858 6.99223C12.0349 7.10064 12.3306 7.00409 12.4375 6.75446C12.7997 5.90905 13.0003 4.97792 13.0003 3.99994C13.0003 3.8111 12.9928 3.624 12.9781 3.43893C12.9599 3.20893 12.7746 3.0299 12.5443 3.01617C12.3643 3.00544 12.1829 3 12.0003 3C9.75407 3 7.70005 3.82289 6.12322 5.18364C5.77191 5.48682 6.05011 6.02819 6.51378 6.0097C6.67515 6.00327 6.83734 6.00002 7.00029 6.00002Z'
        fill='#AEADBC'
      />
      <path
        d='M6.0003 12.9999C8.56374 12.9999 10.8769 11.9282 12.5161 10.2084C12.6948 10.0209 12.9855 9.98404 13.1889 10.1445C13.6138 10.4797 14.0109 10.8487 14.3761 11.2473C14.5518 11.4391 14.5354 11.7332 14.3598 11.925C12.8944 13.526 12.0003 15.6586 12.0003 18C12.0003 18.8685 12.1233 19.7082 12.3529 20.5027C12.4233 20.7466 12.2541 21 12.0003 21C7.44945 21 3.68749 17.6223 3.08463 13.2371C3.03612 12.8843 3.3838 12.6211 3.72853 12.7108C4.45422 12.8995 5.21553 12.9999 6.0003 12.9999Z'
        fill='#AEADBC'
      />
      <path
        d='M19.8302 9.07537C20.1605 9.03248 20.3826 8.69977 20.2491 8.39463C19.3192 6.27015 17.5926 4.57389 15.4472 3.68366C15.2304 3.59371 15.0003 3.76523 15.0003 3.99994C15.0003 5.36794 14.6951 6.66465 14.149 7.82569C14.0422 8.05287 14.1063 8.32603 14.3054 8.479C14.9061 8.94062 15.462 9.45783 15.9652 10.0229C16.1316 10.2098 16.4075 10.2544 16.6261 10.1326C17.5977 9.59129 18.6799 9.22471 19.8302 9.07537Z'
        fill='#AEADBC'
      />
      <path
        d='M17.6316 12.4292C17.5048 12.1877 17.5808 11.8845 17.8238 11.7606C18.6353 11.3466 19.5384 11.0863 20.4945 11.018C20.7502 10.9997 20.9683 11.1939 20.9838 11.4498C20.9947 11.6318 21.0003 11.8152 21.0003 12C21.0003 13.6614 20.5501 15.2177 19.765 16.5535C19.5299 16.9537 18.9477 16.7767 18.8832 16.317C18.6887 14.931 18.2574 13.6211 17.6316 12.4292Z'
        fill='#AEADBC'
      />
      <path
        d='M15.3736 13.8351C15.5793 13.5577 15.9937 13.6088 16.1346 13.924C16.691 15.1689 17.0003 16.5483 17.0003 18C17.0003 18.4555 16.9698 18.9038 16.9109 19.3432C16.8919 19.4842 16.8107 19.6093 16.6893 19.6835C16.1437 20.0172 15.5597 20.2944 14.9456 20.507C14.7038 20.5907 14.4413 20.4642 14.3601 20.2214C14.1267 19.5235 14.0003 18.7765 14.0003 18C14.0003 16.4399 14.5107 14.9989 15.3736 13.8351Z'
        fill='#AEADBC'
      />
    </svg>
  );
};
