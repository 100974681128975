import { useMemo } from 'react';

import trapezeHangers from '../mock/trapeze-hangers.json';
import { getColumns } from '../utils';
import { getData } from '../utils/get-data';
import Table from './table';

export const TrapezeHangersTable = () => {
  const data = useMemo(() => {
    return getData(trapezeHangers);
  }, []);

  const columns = useMemo(() => {
    return getColumns(trapezeHangers);
  }, []);

  return <Table data={data} columns={columns} />;
};
