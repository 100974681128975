import { Box, Checkbox } from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IdType } from 'react-table';
import { columnActions } from 'redux/slices';
import { ColumnData } from 'types/redux';

import SortableItem from './sortable-item';
import { styles } from './styles';

interface ManageColumnsProps<TData extends object> {
  columns: ColumnData[];
  setColumnOrder: (
    updater: ((columnOrder: IdType<TData>[]) => IdType<TData>[]) | IdType<TData>[],
  ) => void;
}

const ManageColumns = ({ columns, setColumnOrder }: ManageColumnsProps<any>) => {
  const [items, setItems] = useState(columns);
  const dispatch = useDispatch();

  useEffect(() => {
    setItems(columns);
  }, [columns]);
  //
  // const handleToggleColumnExportPermission = (id: string) => {
  //   if (id !== '_isVisible' && id !== 'annotationId') {
  //     dispatch(columnActions.toggleColumnExportPermission({ columnId: id }));
  //   }
  // };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setItems((items) => {
        const ids = items.map((el) => el.id) as UniqueIdentifier[];
        const oldIndex = ids.indexOf(active.id);
        const newIndex = ids.indexOf(over?.id as UniqueIdentifier);

        const sorted = arrayMove(items, oldIndex, newIndex);
        const sortedIds = sorted.map((el) => el.id);

        setColumnOrder(sortedIds);

        return sorted;
      });
    }
  };
  const toggleHiddenHandler = (id: string) => {
    dispatch(columnActions.toggleHidden({ id }));
  };
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <Box zIndex={10} overflow='auto' sx={styles.scrollbar} marginBottom='22.5px'>
          {items.map((item) => {
            return (
              <SortableItem key={item.id} onClick={() => toggleHiddenHandler(item.id)} id={item.id}>
                {item.label}
                <Checkbox
                  isChecked={Boolean(!item.isHidden)}
                  onChange={() => toggleHiddenHandler(item.id)}
                />
              </SortableItem>
            );
          })}
        </Box>
      </SortableContext>
    </DndContext>
  );
};

export default ManageColumns;
