import { Flex, Progress, Text, VStack } from '@chakra-ui/react';
import { CheckboxCircleIcon, DeleteIcon, FilePlusIcon, PencilIcon } from 'assets/icons';
import Card from 'components/card';
import { useDispatch, useSelector } from 'react-redux';
import { getUploadDocuments } from 'redux/selectors';
import { documentActions } from 'redux/slices';
import { Document } from 'types/redux';
import { pxToRem } from 'utils';

import { styles } from './styles';

type FileCardProps = {
  file: Document;
  id: number;
};

export const FileCard = ({ file, id }: FileCardProps) => {
  const fileEntities = useSelector(getUploadDocuments);
  const dispatch = useDispatch();

  const handleDeleteFile = () => {
    dispatch(documentActions.deleteUploadedDocument(fileEntities[id]));
  };

  return (
    <Card
      wrapperProps={{
        flexDirection: 'column',
        padding: '9px',
        display: 'flex',
        width: '100%',
      }}
    >
      <Flex sx={styles.fileCardInnerWrapper}>
        <Flex>
          <FilePlusIcon size='15px' />
          <VStack spacing={pxToRem(2)} align='flex-start' ml='4.5px'>
            <Flex alignItems='center' sx={styles.pencilWrapper}>
              <Text sx={styles.fileNameText}>{file.name}</Text>
              <PencilIcon />
            </Flex>
            <Text sx={styles.fileSizeText}>2.0 mb</Text>
          </VStack>
        </Flex>
        <Flex sx={{ width: '37.5px', justifyContent: 'space-between' }}>
          <DeleteIcon onClick={handleDeleteFile} size='12.75px' />
          <CheckboxCircleIcon size='12.75px' color='green.100' />
        </Flex>
      </Flex>
      <Flex alignItems='center'>
        <Progress value={100} sx={styles.progressBar} colorScheme='pink' />
        <Text sx={styles.progressValueText}>100%</Text>
      </Flex>
    </Card>
  );
};
