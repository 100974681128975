import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Annotation, Layer, LayersState } from '../../types/redux';

export const layerSlice = createSlice({
  name: 'layers',
  initialState: {
    activeLayer: null,
    layerList: {
      '1': {},
    },
  },
  reducers: {
    setActiveLayer(
      state: LayersState,
      action: PayloadAction<{ layerInfo: { layerId: number; layerName: string } | null }>,
    ) {
      state.activeLayer = action.payload.layerInfo;
    },
    createLayer(state: LayersState, action: PayloadAction<{ documentId: number; layer: Layer }>) {
      if (Object.hasOwn(state.layerList, `${action.payload.documentId}`)) {
        state.layerList[action.payload.documentId][action.payload.layer.id] = action.payload.layer;
      } else {
        state.layerList[action.payload.documentId] = {
          [action.payload.layer.id]: action.payload.layer,
        };
      }
    },
    renameLayer(
      state: LayersState,
      action: PayloadAction<{ documentId: number; layerId: Layer['id']; name: Layer['name'] }>,
    ) {
      state.layerList[action.payload.documentId][action.payload.layerId].name = action.payload.name;
    },
    removeLayer(
      state: LayersState,
      action: PayloadAction<{ documentId: number; layerId: Layer['id'] }>,
    ) {
      delete state.layerList[action.payload.documentId][action.payload.layerId];
    },
    addAnnotationsToLayer(
      state: LayersState,
      action: PayloadAction<{
        documentId: number;
        layerId: Layer['id'];
        annotations: Annotation[];
      }>,
    ) {
      action.payload.annotations.forEach((annotation) => {
        if (
          !state.layerList[action.payload.documentId][action.payload.layerId].annotations.find(
            (layerAnnotation) => layerAnnotation.id === annotation.id,
          )
        ) {
          state.layerList[action.payload.documentId][action.payload.layerId].annotations =
            state.layerList[action.payload.documentId][action.payload.layerId].annotations.concat(
              action.payload.annotations,
            );
        }
      });
    },
    removeAnnotationFromLayer(
      state: LayersState,
      action: PayloadAction<{
        documentId: number;
        layerId: Layer['id'];
        annotationIds: Annotation['id'][];
      }>,
    ) {
      state.layerList[action.payload.documentId][action.payload.layerId].annotations =
        state.layerList[action.payload.documentId][action.payload.layerId].annotations.filter(
          (annot) => !action.payload.annotationIds.includes(annot.id),
        );
    },
    toggleVisibility(
      state: LayersState,
      action: PayloadAction<{
        documentId: number;
        layerId: Layer['id'];
        visible: Layer['visible'];
      }>,
    ) {
      state.layerList[action.payload.documentId][action.payload.layerId].visible =
        action.payload.visible;
      state.layerList[action.payload.documentId][action.payload.layerId].annotations.forEach(
        (annotation) => {
          annotation._isVisible = action.payload.visible;
        },
      );
    },
  },
});

export const layerActions = layerSlice.actions;
