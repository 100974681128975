import { APRYSE_DOC_DIMENSIONS } from '../constants';

interface FitScaleForCoordsArguments {
  docDimensions: { width: number; height: number };
  coords: { x: number; y: number };
  direction?: 'backend' | 'apryse';
}

export const fitScaleForCoords = ({
  docDimensions,
  coords,
  direction = 'backend',
}: FitScaleForCoordsArguments) => {
  const transformCoef = {
    xCoef: docDimensions.width / APRYSE_DOC_DIMENSIONS.WIDTH,
    yCoef: docDimensions.height / APRYSE_DOC_DIMENSIONS.HEIGHT,
  };

  if (direction === 'backend')
    return {
      x: coords.x * transformCoef.xCoef,
      y: coords.y * transformCoef.yCoef,
    };

  return {
    x: coords.x / transformCoef.xCoef,
    y: coords.y / transformCoef.yCoef,
  };
};
