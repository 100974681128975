import { WebViewerInstance } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';
import { ApryseAnnotation } from 'types';

import { drawCircuit } from './draw-circuit';
import { drawConduit } from './draw-conduit';
import { drawHomerunBox } from './draw-homerun-box';
import { drawMcCable } from './draw-mc-cable';
import { drawNoFlyZone } from './draw-no-fly-zone';
import { drawPanel } from './draw-panel';
import { drawPolyline } from './draw-polyline';
import { drawPreferedZone } from './draw-prefered-zone';
import { drawRectangle } from './draw-rectangle';

interface drawAnnotationsProps {
  viewerInstance: WebViewerInstance;
  annotationList: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawAnnotations = ({
  viewerInstance,
  annotationList,
  redraw = false,
}: drawAnnotationsProps) => {
  const annotationsToDraw = annotationList?.reduce(
    (list: any, annotation: any) => {
      switch (annotation.elementType) {
        case 'Polyline':
          list.polylines.push(annotation);
          break;

        case 'Rectangle':
          list.rectangles.push(annotation);
          break;

        case ANNOTATION_TYPES.HOMERUN_BOX:
          list.homerunBoxes.push(annotation);
          break;

        case ANNOTATION_TYPES.MC_CABLE:
          list.mcCables.push(annotation);
          break;

        case ANNOTATION_TYPES.CONDUIT:
          list.conduits.push(annotation);
          break;

        case ANNOTATION_TYPES.CRITICAL_PANEL:
        case ANNOTATION_TYPES.CRITICAL_PANEL_HORIZONTAL:
        case ANNOTATION_TYPES.NORMAL_PANEL:
        case ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL:
          list.panels.push(annotation);
          break;

        case ANNOTATION_TYPES.NO_FLY_ZONE:
          list.noFlyZones.push(annotation);
          break;

        case ANNOTATION_TYPES.PREFERRED_ZONE:
          list.preferredZones.push(annotation);
          break;

        case ANNOTATION_TYPES.CIRCUIT:
          list.circuits.push(annotation);
          break;

        default:
          break;
      }
      return list;
    },
    {
      polylines: [],
      rectangles: [],
      homerunBoxes: [],
      mcCables: [],
      conduits: [],
      panels: [],
      noFlyZones: [],
      preferredZones: [],
      circuits: [],
    },
  );

  if (annotationsToDraw.homerunBoxes.length)
    drawHomerunBox({ viewerInstance, annotations: annotationsToDraw.homerunBoxes, redraw });

  if (annotationsToDraw.circuits.length)
    drawCircuit({ viewerInstance, annotations: annotationsToDraw.circuits, redraw });

  if (annotationsToDraw.polylines.length)
    drawPolyline({ viewerInstance, annotations: annotationsToDraw.polylines, redraw });

  if (annotationsToDraw.rectangles.length)
    drawRectangle({ viewerInstance, annotations: annotationsToDraw.rectangles, redraw });

  if (annotationsToDraw.mcCables.length)
    drawMcCable({ viewerInstance, annotations: annotationsToDraw.mcCables, redraw });

  if (annotationsToDraw.conduits.length)
    drawConduit({ viewerInstance, annotations: annotationsToDraw.conduits, redraw });

  if (annotationsToDraw.panels.length)
    drawPanel({ viewerInstance, annotations: annotationsToDraw.panels, redraw });

  if (annotationsToDraw.noFlyZones.length)
    drawNoFlyZone({ viewerInstance, annotations: annotationsToDraw.noFlyZones, redraw });

  if (annotationsToDraw.preferredZones.length)
    drawPreferedZone({ viewerInstance, annotations: annotationsToDraw.preferredZones, redraw });
};
