import { Button } from '@chakra-ui/react';
import { PageHeader } from 'components/page-header';
import { useNavigate, useParams } from 'react-router-dom';

export const PageHeaderReportId = () => {
  const navigate = useNavigate();
  const params = useParams();

  const handleCreateReport = () => {
    navigate(`/projects/${params.id}/reports/:reportId/:sheetId`);
  };
  return (
    <PageHeader
      title='Projects'
      headerHeight='48px'
      actionButtons={
        <Button variant='primary' onClick={handleCreateReport} height='36px'>
          Create report
        </Button>
      }
      withoutToggle
    />
  );
};
