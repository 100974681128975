import { WebViewerInstance } from '@pdftron/webviewer';
import { Document } from 'types/redux';
import { checkAnnotationTypeNaming, parseApryseAnnotationColor } from 'utils';
import { calculateMeasurments } from 'utils/measurments/calculate-measurments';

interface SerializeAnnotationsProps {
  annotation: any;
  pathPoints?: number[][];
  activeLayer: { layerId: number; layerName: string } | null;
  fileEntities: Record<number, Document>;
  viewerInstance: WebViewerInstance;
  action?: string;
}

export const serializeAnnotations = ({
  annotation,
  pathPoints,
  activeLayer,
  fileEntities,
  viewerInstance,
  action,
}: SerializeAnnotationsProps) => {
  return {
    id: annotation.Id,
    type: annotation.Subject,
    elementType: annotation.elementName,
    _isLocked: annotation.Locked,
    _isVisible: action === 'modify' ? annotation._isVisible : !annotation.Invisible,
    isReadOnly: false,
    pathPoints,

    data: {
      ...annotation.data,
      name: checkAnnotationTypeNaming({
        elementType: annotation.elementName,
        annotation,
      }),
      fillColor: parseApryseAnnotationColor({ annotation, colorType: 'fill' }),
      updatedAt: Date.now(),
      strokeColor: parseApryseAnnotationColor({ annotation, colorType: 'stroke' }),
      measurments: Object.values(fileEntities)[0]?.scale
        ? (calculateMeasurments({
            viewerInstance,
            annotation,
            scale: Object.values(fileEntities)[0]?.scale as string,
          }) as string)
        : '',
      layer:
        annotation.elementName === 'Circuit' ? 'Ciruits' : (activeLayer?.layerName as string) || '',
      author: annotation.Author,
    },
  };
};
