import { ChakraProps, FormControl, FormErrorMessage, Select, SelectProps } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { forwardRef, ReactNode } from 'react';

import { styles } from './styles';

export interface SelectFieldProps extends SelectProps {
  name?: string;
  children: ReactNode;
  wrapperProps?: ChakraProps;
  disabled?: boolean;
  hasError?: boolean;
  errors?: Record<string, unknown>;
}

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ children, hasError, wrapperProps, ...rest }, ref) => {
    return (
      <FormControl isInvalid={hasError} sx={styles.inputWrapper}>
        <Select sx={{ ...styles.inputStyle, ...wrapperProps }} {...rest} ref={ref}>
          {children}
        </Select>
        {Boolean(rest.name) && (
          <ErrorMessage
            errors={rest.errors}
            name={rest.name as string}
            as={FormErrorMessage}
            fontSize='12px'
            pos='absolute'
            top='calc(100% + 3.75px)'
            margin={0}
          />
        )}
      </FormControl>
    );
  },
);

export default SelectField;
