import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface DeleteToastProps {
  description: ReactNode;
  icon: ReactNode;
}

const DeleteToast = ({ description, icon, ...props }: DeleteToastProps) => (
  <Box
    {...props}
    sx={{
      marginTop: '21px',
      bg: 'purple.100',
      height: '48px',
      borderRadius: '8px',
      padding: '12px 15px',
      maxW: '214px',
      minW: '214px',
    }}
  >
    <Flex alignItems='center'>
      <Box mr='15px'>{icon}</Box>
      <Text color='white' fontWeight='medium' fontSize='13.5px'>
        {description}
      </Text>
    </Flex>
  </Box>
);

export default DeleteToast;
