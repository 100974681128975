import { ChakraProps, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { styles } from './styles';
import { FeederSchedule, SLD } from './tab-panels';

interface DrawingTabsProps {
  styleProps: ChakraProps;
}

const DrawingTabs = ({ styleProps }: DrawingTabsProps) => {
  return (
    <Tabs variant='enclosed' zIndex={1} pos='relative' {...styleProps}>
      <TabList sx={styles.tabList}>
        <Tab sx={styles.tab}>SLD</Tab>
        <Tab sx={styles.tab}>Feeder Sched</Tab>
        <Tab sx={styles.tab}>Grid Lines</Tab>
        <Tab sx={styles.tab}>Panels Location</Tab>
        <Tab sx={styles.tab}>Floor Height</Tab>
      </TabList>
      <TabPanels sx={styles.tabPanels}>
        <TabPanel sx={styles.tabPanel}>
          <SLD />
        </TabPanel>
        <TabPanel sx={styles.tabPanel}>
          <FeederSchedule />
        </TabPanel>
        <TabPanel sx={styles.tabPanel}>
          <p>Grid Lines</p>
        </TabPanel>
        <TabPanel sx={styles.tabPanel}>
          <p>Panels Location</p>
        </TabPanel>
        <TabPanel sx={styles.tabPanel}>
          <p>Floor Height</p>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default DrawingTabs;
