export const styles = {
  listWrapper: {
    width: '100%',
    '& .chakra-accordion__button': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16.5px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .icon-wrapper, & .sources-icon-wrapper': {
      width: '30px',
      minWidth: '30px',
      height: '30px',
      borderRadius: '6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '10.5px',
    },
    '& .navlink': {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '9px',
      borderBottom: '1px solid #E8E7EF',
      marginBottom: '24px',
      width: '100%',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16.5px',
      color: 'gray.700',
      cursor: 'pointer',
      '& .icon-wrapper, & .sources-icon-wrapper': {
        backgroundColor: 'gray.600',
      },
      '& .chakra-collapse': {
        margin: '7.5px -24px 0px -24px',
      },
    },
    '& .sub-navlink': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '10.5px',
      fontWeight: 500,
      lineHeight: '16.5px',
      color: 'black.50',
      padding: '7.5px 24px',
      '&:hover': {
        backgroundColor: '#EFEEFF',
        color: 'purple.100',
        '& svg path': {
          fill: 'purple.100',
        },
      },
      '& .folder-icon path, & .folder-open-icon path, & .file-list-icon path': {
        fill: 'black.100',
      },
      svg: {
        minWidth: '12px',
        minHeight: '12px',
        marginRight: '4.5px',
        marginTop: '-2.25px',
      },
      '& .icon-wrapper, & .sources-icon-wrapper': {
        backgroundColor: 'gray.600',
      },
    },
    '& .navlink-active, & .sub-navlink-active': {
      color: 'purple.100',
      borderColor: 'purple.100',
      '& .icon-wrapper': {
        backgroundColor: '#f1f0fd',
        '& svg path, & svg rect': {
          fill: 'purple.100',
        },
      },
      '& .sources-icon-wrapper': {
        backgroundColor: '#f1f0fd',
      },
    },
    '& .sub-navlink-active': {
      '& .folder-icon path, & .folder-open-icon path, & .file-list-icon path': {
        fill: 'purple.100',
      },
    },
  },
};
