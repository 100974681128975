import { COMMON_DOCUMENT_PROPS } from 'constants/index';
import { useCreateImageUrl } from 'hooks/useCreateImageUrl';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { documentActions, ProjectsApiUrl } from 'redux/slices';

export const useGetSource = () => {
  const { id = '', sourcesId = '' } = useParams();

  const sourceRequestUrl = ProjectsApiUrl.SOURCE({
    projectId: id,
    sourcesId,
  }).substring(1);
  const requestUrl = `${process.env.REACT_APP_PROJECTS_API}${sourceRequestUrl}`;
  const { file, isImageLoading } = useCreateImageUrl(requestUrl);
  const dispatch = useDispatch();

  useEffect(() => {
    if (file) {
      dispatch(
        documentActions.openDocument({
          ...COMMON_DOCUMENT_PROPS,
          id: new Date().getTime(),
          name: file.name,
          preview: file.preview,
          lastModified: file.lastModified,
          size: file.size,
          type: file.type,
          scale: null,
        }),
      );
    }
  }, [dispatch, file]);
  return { file, isLoading: isImageLoading };
};
