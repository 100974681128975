import { Icon } from '@chakra-ui/react';
import React from 'react';

const CheckboxCircleIcon = ({ size, ...rest }) => {
  return (
    <Icon
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      {...rest}
    >
      <path
        fill='currentColor'
        d='M8 14.667A6.667 6.667 0 118 1.334a6.667 6.667 0 010 13.333zm0-1.334A5.333 5.333 0 108 2.667a5.333 5.333 0 000 10.666zm-.665-2.666L4.507 7.838l.942-.943 1.886 1.886 3.771-3.771.943.943-4.714 4.714z'
      ></path>
    </Icon>
  );
};

export default CheckboxCircleIcon;
