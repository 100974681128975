import { ERoutes } from 'components/router/types';
import ProjectLayout from 'layouts/project-layout';
import { DrawingsPage } from 'pages/drawings-page';
import ProjectDrawings from 'pages/projects/project/drawings';
import { ProjectPageHeader } from 'pages/projects/project/headers';
import { Route } from 'react-router-dom';

export const drawingPathName =
  ERoutes.PROJECTS + ERoutes.ID + ERoutes.DRAWINGS + ERoutes.DRAWINGS_TYPE;

export const DrawingRoutes = [
  <Route key='project-drawing' path={drawingPathName} element={<DrawingsPage />} />,
  <Route
    key='project-drawingId'
    path={drawingPathName + ERoutes.DRAWING_ID}
    element={<DrawingsPage />}
  />,
  <Route
    key='project-drawing-sheetId'
    path={drawingPathName + ERoutes.DRAWING_ID + ERoutes.SHEET_ID}
    element={
      <ProjectLayout
        header={
          <ProjectPageHeader path={drawingPathName + ERoutes.DRAWING_ID + ERoutes.SHEET_ID} />
        }
        sidebarProps={{ minimizedEnabled: true }}
      >
        <ProjectDrawings />
      </ProjectLayout>
    }
  />,
];
