export const styles = {
  fileCardInnerWrapper: {
    justifyContent: 'space-between',
    width: '100%',
  },
  fileNameText: {
    marginRight: '4.5px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '105%',
  },
  fileSizeText: { color: 'gray.500', fontSize: '12px', fontWeight: 600, lineHeight: '105%' },
  progressBar: {
    width: '75%',
    height: '6px',
    marginLeft: '19.5px',
    borderRadius: '5%',
  },
  progressValueText: {
    marginLeft: '4.5px',
    fontSize: '9px',
    fontWeight: 600,
    lineHeight: '139.5%',
    color: 'purple.100',
  },
  pencilWrapper: {
    '& svg': {
      minWidth: '12px',
      minHeight: '12px',
    },
  },
};
