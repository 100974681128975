import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RedlineFormState } from 'types/redux';

const INITIAL_STATE = {
  grouping_distance: 80,
  cable_count: 9,
  grouping_reward: 100,
  turning_penalty: 0,
  iterations: 50,
  preferred_zones_reward: 50,
  width: 10668,
  height: 7620,
  pixels_per_ft: 15.873,
  conduits_spacing: 1.0,
};

export const redlineFormSlice = createSlice({
  name: 'mockedProjects',
  initialState: INITIAL_STATE,
  reducers: {
    updateDocumentDimensions(
      state: RedlineFormState,
      action: PayloadAction<{ width: number; height: number }>,
    ) {
      state.width = action.payload.width;
      state.height = action.payload.height;
    },
  },
});

export const redlineFormActions = { ...redlineFormSlice.actions };
