import { WebViewerInstance } from '@pdftron/webviewer';
import { AnyAction } from '@reduxjs/toolkit';
import { DOCUMENT_ID } from 'constants/apryse';
import { Dispatch } from 'react';
import { layerActions } from 'redux/slices';
import { Annotation, Document, Layer } from 'types/redux';

import { createPathPoints } from './pathPointUtils';
import { serializeAnnotations } from './serializeAnnotations';

interface ProcessCircuitAnnotationBeforeDrawingProps {
  annotations: any[];
  isSameCoords: boolean;
  dispatch: Dispatch<AnyAction>;
  activeLayer: { layerId: number; layerName: string } | null;
  layerEntities: { [layerId: number]: Layer };
  viewerInstance: WebViewerInstance;
  annotationEntities: { [documentId: number]: Annotation[] };
  fileEntities: Record<number, Document>;
  serializedAnnotations: Annotation[];
}

export const processCircuitAnnotationBeforeDrawing = ({
  annotations,
  isSameCoords,
  dispatch,
  activeLayer,
  layerEntities,
  viewerInstance,
  annotationEntities,
  fileEntities,
  serializedAnnotations,
}: ProcessCircuitAnnotationBeforeDrawingProps) => {
  annotations.forEach((annotation) => {
    const pathPoints = createPathPoints({ viewerInstance, annotation, isSameCoords });
    if (
      annotation.elementName === 'Circuit' &&
      !Object.values(layerEntities).find((layer) => layer.name === 'Circuits')
    ) {
      const layerId = new Date().getTime();

      dispatch(
        layerActions.createLayer({
          documentId: DOCUMENT_ID,
          layer: {
            id: layerId,
            name: 'Circuits',
            visible: true,
            annotations: [
              serializeAnnotations({
                annotation,
                activeLayer,
                fileEntities,
                pathPoints,
                viewerInstance,
              }),
            ],
          },
        }),
      );
    }

    if (
      annotation.Subject !== 'Annotation' &&
      !annotationEntities[DOCUMENT_ID].find((oldAnnotation) => oldAnnotation.id === annotation.Id)
    ) {
      serializedAnnotations.push(
        serializeAnnotations({
          annotation,
          activeLayer,
          fileEntities,
          pathPoints,
          viewerInstance,
        }),
      );
    }
  });
};
