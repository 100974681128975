import React from 'react';

export const FolderFillIcon = ({ width = '21px', height = '20px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6778 4.16667H17.1662C17.3872 4.16667 17.5991 4.25446 17.7554 4.41074C17.9117 4.56702 17.9995 4.77899 17.9995 5V5.83333H2.99949V3.33333C2.99949 3.11232 3.08729 2.90036 3.24357 2.74408C3.39985 2.5878 3.61181 2.5 3.83282 2.5H10.0112L11.6778 4.16667ZM3.07199 7.5H17.927C18.0423 7.49995 18.1563 7.52382 18.2619 7.57011C18.3675 7.6164 18.4624 7.6841 18.5405 7.76892C18.6185 7.85375 18.6782 7.95385 18.7156 8.06291C18.753 8.17197 18.7674 8.2876 18.7578 8.4025L18.0628 16.7358C18.0455 16.9441 17.9505 17.1382 17.7968 17.2797C17.6431 17.4213 17.4418 17.4999 17.2328 17.5H3.76616C3.5572 17.4999 3.3559 17.4213 3.20217 17.2797C3.04845 17.1382 2.9535 16.9441 2.93616 16.7358L2.24116 8.4025C2.23159 8.2876 2.24596 8.17197 2.28338 8.06291C2.32079 7.95385 2.38043 7.85375 2.45852 7.76892C2.53661 7.6841 2.63145 7.6164 2.73705 7.57011C2.84264 7.52382 2.95669 7.49995 3.07199 7.5Z'
        fill='white'
      />
    </svg>
  );
};
