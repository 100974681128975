import { IconButton } from '@chakra-ui/react';
import ListIcon from 'assets/icons/ListIcon';
import TileIcon from 'assets/icons/TileIcon';
import React from 'react';

export function ToggleView(): JSX.Element {
  return (
    <>
      <IconButton icon={<ListIcon size='15px' />} variant='unstyled' size='15px' aria-label={''} />
      <IconButton
        icon={<TileIcon size='15px' />}
        variant='ustyled'
        ml='14.4px'
        size='15px'
        aria-label={''}
      />
    </>
  );
}
