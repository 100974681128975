import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext } from 'react';

export const useEnableCreationOverAnnotation = () => {
  const { instance } = useContext(WebviewerContext);

  return useCallback(() => {
    if (instance) {
      const { documentViewer } = instance.Core;

      const allTools = Object.values(documentViewer.getToolModeMap());

      for (const tool of allTools) {
        if (typeof (tool as any).enableCreationOverAnnotation === 'function') {
          (tool as any).enableCreationOverAnnotation();
        }
      }
    }
  }, [instance]);
};
