import { NavLink, NavLinkProps } from 'react-router-dom';

export enum ENavLinkTypes {
  NAV_LINK = 'navLink',
  SUB_LINK = 'subLink',
}

type TLinkStyles = { [key in ENavLinkTypes]: { default: string; active: string } };

interface SidebarNavLinkProps extends NavLinkProps {
  linkType: ENavLinkTypes;
}

const SidebarNavLink = ({
  children,
  linkType = ENavLinkTypes.NAV_LINK,
  ...rest
}: SidebarNavLinkProps) => {
  const linksStyles: TLinkStyles = {
    navLink: { active: 'navlink navlink-active', default: 'navlink' },
    subLink: { active: 'sub-navlink sub-navlink-active', default: 'sub-navlink' },
  };

  const styles = ({ isActive }: { isActive: boolean }) =>
    isActive ? linksStyles[linkType].active : linksStyles[linkType].default;

  return (
    <NavLink {...rest} className={styles}>
      {children}
    </NavLink>
  );
};

export default SidebarNavLink;
