import React from 'react';

export const ReportsIcon = ({ width = '22px', height = '19px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.8'
        x='8.04492'
        y='4.11523'
        width='5.90909'
        height='14.4444'
        rx='1'
        fill='#AEADBC'
      />
      <rect
        opacity='0.5'
        x='0.166016'
        y='7.39844'
        width='5.90909'
        height='11.1616'
        rx='1'
        fill='#AEADBC'
      />
      <rect x='15.9238' y='0.833008' width='5.90909' height='17.7273' rx='1' fill='#AEADBC' />
    </svg>
  );
};
