import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useUpdateUrlSearchParams = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const setSearchParam = useCallback(
    (key: string, value: string) => {
      searchParams.set(key, value);
      navigate({ pathname, search: searchParams.toString() }, { replace: true });
    },
    [searchParams, navigate],
  );

  const setMultipleSearchParams = useCallback(
    (urlSearchParams: URLSearchParams) => {
      navigate({ pathname, search: urlSearchParams.toString() }, { replace: true });
    },
    [searchParams, navigate],
  );
  return { setSearchParam, setMultipleSearchParams };
};
