import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { TableLineIcon } from 'assets/icons';
import React from 'react';

import { styles } from './styles';

export const ReportSheetTable = () => {
  const headerArray = [
    'Circuit symbol',
    'Conductors (1 PH. 2W) with ground',
    'Conduit size',
    'Conductors (1 OR, 3 PH, 3 WIRE) with ground',
    'Conduit size',
    'Conductors (3 PH, 4 WIRE) with ground',
    'Conduit size',
    'Overcurrent rating',
  ];

  const bodyArray = [
    ['Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text'],
    ['Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text'],
    ['Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text', 'Text'],
  ];
  return (
    <TableContainer sx={styles.reportTableContainer}>
      <Table variant='striped' sx={styles.reportTable}>
        <Thead sx={styles.reportThead}>
          <Tr>
            <Th>
              <TableLineIcon />
            </Th>
            {headerArray.map((item) => {
              return <Th key={item}>{item}</Th>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {bodyArray.map((item, index) => {
            return (
              <Tr key={index}>
                <Td></Td>
                {item.map((i, index) => {
                  return <Td key={index}>{i}</Td>;
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
