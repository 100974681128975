import { Box } from '@chakra-ui/react';
import LogoIcon from 'assets/icons/LogoIcon';
import ModeSwitch from 'components/mode-switch';
import NotificationsMenu from 'components/notifications-menu';
import ProfileMenu from 'components/profile-menu';
import { ERoutes } from 'components/router/types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { styles } from './styles';

const Header = () => {
  const navigate = useNavigate();

  const handleNavigateToProjects = () => {
    navigate(ERoutes.PROJECTS);
  };
  return (
    <Box sx={styles.headerWrapper}>
      <LogoIcon onClick={handleNavigateToProjects} size='144px' />
      <Box sx={styles.headerFunctionalWrapper}>
        <ModeSwitch />
        <NotificationsMenu />
        <ProfileMenu />
      </Box>
    </Box>
  );
};

export default Header;
