import { ERoutes } from 'components/router/types';
import ProjectLayout from 'layouts/project-layout';
import { ProjectPageHeader } from 'pages/projects/project/headers';
import { SourcesPage } from 'pages/sources-page';
import { SourcesSidebar } from 'pages/sources-page/components/sidebar';
import React from 'react';
import { Route } from 'react-router-dom';

const projectSourcesPath = ERoutes.PROJECTS + ERoutes.ID + ERoutes.SOURCES;

export const SourcesRoutes = [
  <Route
    key='project-sources'
    path={projectSourcesPath}
    element={
      <ProjectLayout
        header={<ProjectPageHeader path={projectSourcesPath} />}
        sidebarProps={{ minimizedEnabled: true }}
        sidebar={<SourcesSidebar />}
      >
        <></>
      </ProjectLayout>
    }
  />,
  <Route
    key='project-sourcesId'
    path='projects/:id/sources/:sourcesId'
    element={
      <ProjectLayout
        header={<ProjectPageHeader path={projectSourcesPath + ERoutes.SOURCES_ID} />}
        sidebarProps={{ minimizedEnabled: true }}
        sidebar={<SourcesSidebar />}
      >
        <SourcesPage />
      </ProjectLayout>
    }
  />,
  <Route
    key='project-sources-sheetId'
    path={projectSourcesPath + ERoutes.SOURCES_ID + ERoutes.SHEET_ID}
    element={
      <ProjectLayout
        header={
          <ProjectPageHeader path={projectSourcesPath + ERoutes.SOURCES_ID + ERoutes.SHEET_ID} />
        }
        sidebarProps={{ minimizedEnabled: true }}
      >
        Sources - sheet
      </ProjectLayout>
    }
  />,
];
