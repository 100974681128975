import { Flex, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ColumnData } from 'types/redux';

import { styles } from './styles';

interface AnnotationFieldWrapperProps {
  column: ColumnData;
  children: ReactNode;
  updatable?: boolean;
  makeUpdatable?: (key: string) => void;
}

const AnnotationFieldWrapper = ({
  children,
  column,
  updatable,
  makeUpdatable,
}: AnnotationFieldWrapperProps) => {
  // const _makeUpdatable = () => {
  //   makeUpdatable?.(column.id);
  // };

  return (
    <Flex sx={styles.innerScrollWrapper}>
      <Text sx={{ ...styles.textFieldLabel, ...(column.readonly && { color: 'gray.200' }) }}>
        {column.label}:{' '}
        {/* <Flex ml='auto' as='span'>
          <Text mr='2' fontSize='xs'>
            Update:
          </Text>
          <Switch isChecked={updatable} onChange={_makeUpdatable} disabled={column.readonly} />
        </Flex> */}
      </Text>
      {children}
    </Flex>
  );
};

export default AnnotationFieldWrapper;
