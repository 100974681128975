import { SyntheticEvent } from 'react';

interface EyeIconProps {
  fill?: string;
  size?: string;
  onClick?: (event: SyntheticEvent | never) => void;
}

const EyeIcon = ({ fill = '#6E6B7B', size = '20px', onClick }: EyeIconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill={fill}
      onClick={onClick}
      cursor='pointer'
    >
      <path d='M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.82 9-5.391 0-9.877-3.88-10.818-9C2.12 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 008.777-7 9.005 9.005 0 00-17.554 0A9.005 9.005 0 0012 19zm0-2.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-2a2.5 2.5 0 100-5 2.5 2.5 0 000 5z'></path>
    </svg>
  );
};

export default EyeIcon;
