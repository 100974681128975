import { Box, SimpleGrid } from '@chakra-ui/react';
import { useGetDrawingsQuery } from 'pages/projects/project/drawings/card-list/hooks/useGetDrawingsQuery';
import { TDrawing } from 'types/drawing';

import { SheetCard } from './sheet-card';
import { styles } from './styles';

interface IDrawingsBlockProps {
  onSelectDrawingHandler?: (drawing: TDrawing) => void;
  selectedDrawingId?: number;
}

const DrawingsBlock = ({ onSelectDrawingHandler, selectedDrawingId }: IDrawingsBlockProps) => {
  const { drawingListData } = useGetDrawingsQuery();

  return (
    <Box sx={styles.drawingsBlockWrapper} id='scrollableId'>
      <Box sx={styles.scrollWrapper}>
        <SimpleGrid
          minChildWidth='195px'
          spacingY='18px'
          gridTemplateColumns='repeat(auto-fit, 195px)'
          spacingX='27px'
          w='99%'
        >
          {drawingListData?.map((drawing) => (
            <Box key={drawing.id} onClick={() => onSelectDrawingHandler?.(drawing)}>
              <SheetCard sheetData={drawing} isSelected={selectedDrawingId === drawing.id} />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default DrawingsBlock;
