import { useUpdateUrlSearchParams } from 'hooks/useSetUpdateUrlSearchParams';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useGetUrlSearchParams = () => {
  const [urlSearchParam, setUrlSearchParam] = useSearchParams();
  const { setSearchParam } = useUpdateUrlSearchParams();
  const urlSearchParamsObject = useMemo(
    () => Object.fromEntries(urlSearchParam.entries()),
    [urlSearchParam],
  );

  return { urlSearchParamsObject, urlSearchParam, setUrlSearchParam, setSearchParam };
};
