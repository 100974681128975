import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const baseStyle = definePartsStyle({
  field: {
    _invalid: {
      borderColor: 'red.100',
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
