import { Box, Button, Card, Divider, Flex, Text } from '@chakra-ui/react';
import { CheckIcon } from 'assets/icons';
import { FormSection } from 'pages/drawings-page/components/sidebar/form-section';
import { styles } from 'pages/drawings-page/components/sidebar/styles';
import { DRAWINGS_SIDEBAR_CATEGORIES } from 'pages/drawings-page/constants';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useUpdateDrawingMutation } from 'redux/slices';
import { MutationUpdateDrawingBody } from 'redux/slices/projects-api/projects-api.types';
import { TDrawing } from 'types/drawing';

interface IDrawingsSidebarProps {
  drawingData: TDrawing;
}

export const DrawingsSidebar = ({ drawingData }: IDrawingsSidebarProps) => {
  const { id: projectId = '' } = useParams();
  const defaultValues = useMemo(
    () => ({
      category: drawingData.category,
      sheetName: drawingData.sheetName,
      sheetNumber: drawingData.sheetNumber,
    }),
    [drawingData],
  );

  const { register, handleSubmit, watch, getValues, setValue, reset } = useForm({
    defaultValues,
  });
  const [updateDrawingMutation] = useUpdateDrawingMutation();
  const onSubmit = (data: MutationUpdateDrawingBody) => {
    updateDrawingMutation({ projectId, drawingId: drawingData.id, body: data });
  };
  const selectedCategory = getValues('category');
  watch('category');

  useEffect(() => {
    reset(drawingData);
  }, [reset, drawingData]);

  return (
    <Card sx={styles.sidebarWrapper}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Box sx={{ ...styles.formSection, ...styles.selectCategorySection }}>
            <Text sx={styles.formSectionHeadingText}>Categories</Text>
            <Divider sx={styles.formDivider} />
            <Box sx={styles.formSection}>
              {DRAWINGS_SIDEBAR_CATEGORIES.map(({ key, label }) => (
                <Flex
                  cursor='pointer'
                  key={key}
                  onClick={() => setValue('category', key)}
                  sx={{
                    ...styles.selectCategoryItem,
                    ...(selectedCategory === key ? styles.selected : {}),
                  }}
                >
                  <Text>{label}</Text>
                  {selectedCategory === key && <CheckIcon fill='dark.100' size='12px' />}
                </Flex>
              ))}
            </Box>
          </Box>
          <FormSection headingText='Sheets Name' registered={register('sheetName')} />
          <FormSection
            headingText='Sheets Number'
            registered={register('sheetNumber')}
            type='number'
          />

          <Box sx={styles.formSection}>
            <Text sx={styles.formSectionHeadingText}>Added Date</Text>
            <Divider sx={styles.formDivider} />
            <Text sx={styles.formDateText}>01 May 2022</Text>
          </Box>
          <Box sx={styles.formSection}>
            <Text sx={styles.formSectionHeadingText}>Updated Date</Text>
            <Divider sx={styles.formDivider} />
            <Text sx={styles.formDateText}>28 May 2022</Text>
          </Box>
        </Box>
        <Button type='submit' sx={styles.submitButton}>
          Save
        </Button>
      </form>
    </Card>
  );
};
