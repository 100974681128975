import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTabIndex, isTabOpen } from 'redux/selectors';
import { branchTabActions } from 'redux/slices';

import BomBlock from '../bom-block';
import { tabListArray } from './constants';
import { styles } from './styles';

export const TabList = () => {
  const { isOpen: isBOMOpen, onOpen: onBOMOpen, onClose: onBOMClose } = useDisclosure();
  const activeTabIndex = useSelector(getActiveTabIndex);
  const isOpen = useSelector(isTabOpen);
  const dispatch = useDispatch();

  const handleTabButtonClick = (id: number) => {
    if (activeTabIndex === id && isOpen) {
      dispatch(branchTabActions.toggleTab({ isOpen: false }));
    } else {
      dispatch(branchTabActions.setActiveTabIndex({ activeTabIndex: id }));
      dispatch(branchTabActions.toggleTab({ isOpen: true }));
    }
  };

  useEffect(() => {
    if (activeTabIndex === 8) {
      onBOMOpen();
    }
  }, [activeTabIndex, onBOMOpen]);

  return (
    <Flex sx={styles.tabList}>
      <VStack spacing='18px'>
        {tabListArray.map(({ id, Icon, disabled }) => {
          return (
            <Flex key={id} direction='column'>
              <Button
                sx={styles.tabListButton(id === activeTabIndex)}
                onClick={() => {
                  handleTabButtonClick(id);
                }}
                isDisabled={disabled}
                variant='unstyled'
              >
                <Icon />
              </Button>
              <Divider sx={styles.tabListDivider(id === activeTabIndex)} />
            </Flex>
          );
        })}
      </VStack>
      <Modal isOpen={isBOMOpen} onClose={onBOMClose} isCentered>
        <ModalOverlay />

        <ModalContent w='60%' maxW='unset' h='60%'>
          <ModalHeader>Billing of Material</ModalHeader>
          <BomBlock />
        </ModalContent>
      </Modal>
    </Flex>
  );
};
