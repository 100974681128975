import { useCallback, useEffect, useRef, useState } from 'react';

interface IGetColumnsCount<T> {
  gridTemplateColumns: string;
  setRef: (element: T) => void;
  columnsCount: number;
}

export const useGetColumnsCount = <T>(): IGetColumnsCount<T> => {
  const wrapperRef = useRef<any | null>(null);
  const [refMounted, setRefMounted] = useState(false);
  const [columnsCount, setColumnsCount] = useState(0);
  const [gridTemplateColumns, setGridTemplateColumns] = useState('1fr 1fr 1fr 1fr');
  const containerPaddings = 60;
  const cardWidthWithPadding = 300;

  const setRef = useCallback((elem: T) => {
    wrapperRef.current = elem;
    setRefMounted(true);
  }, []);

  useEffect(() => {
    if (refMounted) {
      const itemsInRow = Math.floor(
        ((wrapperRef.current?.clientWidth || 0) - containerPaddings) / cardWidthWithPadding,
      );
      setColumnsCount(itemsInRow);
      setGridTemplateColumns(() => '1fr '.repeat(itemsInRow).trim());
    }
  }, [refMounted, setGridTemplateColumns]);

  return { gridTemplateColumns, setRef, columnsCount };
};
