import { Box } from '@chakra-ui/react';
import Header from 'components/header';
import { ReactNode } from 'react';

import { styles } from './styles';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box>
      <Header />
      <main style={styles.layoutWrapper}>{children}</main>
    </Box>
  );
};
export default Layout;
