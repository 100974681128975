import React from 'react';

export const InfoCircleIcon = ({ fill = '#EE4025', width = '17px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.16667 12.8348C8.44281 12.8348 8.66667 12.611 8.66667 12.3348V8.33484C8.66667 8.0587 8.44281 7.83484 8.16667 7.83484C7.89052 7.83484 7.66667 8.0587 7.66667 8.33484V12.3348C7.66667 12.611 7.89052 12.8348 8.16667 12.8348Z'
        fill={fill}
      />
      <path
        d='M8.16667 5.66817C8.53486 5.66817 8.83333 5.96665 8.83333 6.33484C8.83333 6.70303 8.53486 7.00151 8.16667 7.00151C7.79848 7.00151 7.5 6.70303 7.5 6.33484C7.5 5.96665 7.79848 5.66817 8.16667 5.66817Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 9.00151C1 5.04347 4.20863 1.83484 8.16667 1.83484C12.1247 1.83484 15.3333 5.04347 15.3333 9.00151C15.3333 12.9595 12.1247 16.1682 8.16667 16.1682C4.20863 16.1682 1 12.9595 1 9.00151ZM8.16667 2.83484C4.76091 2.83484 2 5.59575 2 9.00151C2 12.4073 4.76091 15.1682 8.16667 15.1682C11.5724 15.1682 14.3333 12.4073 14.3333 9.00151C14.3333 5.59575 11.5724 2.83484 8.16667 2.83484Z'
        fill={fill}
      />
    </svg>
  );
};
