import { Annotation, ColumnData } from 'types/redux';

interface ColumnsDefaultValues {
  [columnId: ColumnData['id']]: string | boolean;
}

export const getAnnotationColumnsInReactTableFormat = ({
  annotationEntities,
  columns,
}: {
  annotationEntities: Annotation[];
  columns: ColumnData[];
}) => {
  const defaultValues: ColumnsDefaultValues = {};

  columns.forEach((column) => {
    // @ts-ignore
    defaultValues[column.id] = column.defaultValue;
  });

  return annotationEntities.map((annotation) => {
    const annotationId = annotation.id;
    const result: ColumnsDefaultValues = {
      ...defaultValues,
      ...annotation.data,
      annotationId,
      id: annotation.id,
      _isVisible: annotation._isVisible,
      type: annotation.type,
    };

    return result;
  });
};
