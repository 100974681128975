import Card from 'components/card';
import { Resizable } from 're-resizable';
import { useSelector } from 'react-redux';
import { getWorkMode, isAnnotationListOpen } from 'redux/selectors';
import { WORK_MODES } from 'types/redux';

import { styles } from '../../styles';
import AnnotationsListTable from '../annotations-list-table';

const ElementListBlock = () => {
  const isAnnotationSummaryListOpen = useSelector(isAnnotationListOpen);
  const workMode = useSelector(getWorkMode);

  if (workMode === WORK_MODES.BRANCH && isAnnotationSummaryListOpen) {
    return (
      <Resizable
        defaultSize={{ width: '100%', height: '35%' }}
        minHeight={'7.5px'}
        maxHeight={'90%'}
        maxWidth={'100%'}
        minWidth={'7.5%'}
        enable={{
          top: true,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          topLeft: false,
          bottomRight: false,
          bottomLeft: false,
        }}
        style={styles.resizableTop}
        handleStyles={{ top: styles.resizableTopComponent }}
      >
        <Card
          wrapperProps={{
            height: '100%',
            width: 'calc(100% - 54px)',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <AnnotationsListTable />
        </Card>
      </Resizable>
    );
  }

  return null;
};

export default ElementListBlock;
