import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import CheckIcon from 'assets/icons/CheckIcon';
import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import { styles } from 'components/page-header/components/sorting-menu/sorting-menu.styles';
import { TUrlDefaultParam, useGetUrlSearchParams, useSetDefaultUrlSearchParams } from 'hooks';
import React, { useMemo } from 'react';

interface ISortingMenuProps {
  sortVariants: { [key: string]: string };
  defaultValue: TUrlDefaultParam;
}

export const SortingMenu = ({ sortVariants, defaultValue }: ISortingMenuProps) => {
  useSetDefaultUrlSearchParams([defaultValue]);
  const { urlSearchParamsObject, setSearchParam } = useGetUrlSearchParams();
  const { sort } = urlSearchParamsObject as { sort: keyof typeof sortVariants };

  const menuItems = useMemo(() => {
    const keys = Object.keys(sortVariants) as Array<keyof typeof sortVariants>;
    return keys.map((key: keyof typeof sortVariants) => (
      <MenuItem
        sx={styles.menuListItem(sort === key)}
        key={key}
        onClick={() => setSearchParam(defaultValue.key, key.toString())}
      >
        {sortVariants[key]}
        {sort === key && <CheckIcon fill='dark.100' />}
      </MenuItem>
    ));
  }, [sort, setSearchParam]);

  return (
    <Flex sx={styles.sortWrapper}>
      <Menu>
        <Text sx={styles.sortByText}>Sort by:</Text>
        <MenuButton as={Box} sx={styles.sortMenuBtn}>
          <Flex sx={styles.sortMenuBtnContent}>
            <Text sx={styles.sortMenuBtnText}>{sortVariants[sort]}</Text>
            <ChevronDownIcon size='15px' />
          </Flex>
        </MenuButton>
        <MenuList sx={styles.menuList}>{menuItems}</MenuList>
      </Menu>
    </Flex>
  );
};
