import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';

export const styles = {
  infoWrapper: {
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...WEBKIT_SCROLLBAR_STYLES,
  },
  headerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '7.5px',
  },
  titleText: {
    fontSize: '13.5px',
    lineHeight: '93%',
    fontWeight: 600,
    color: 'gray.400',
    whiteSpace: 'nowrap',
  },
};
