import { Flex } from '@chakra-ui/react';
import ManageColumns from 'pages/sheet/components/annotations-list-table/manage-columns';
import { useDispatch, useSelector } from 'react-redux';
import { getColumnsList, isTabModalOpen } from 'redux/selectors';
import { branchTabActions, columnActions, modalActions } from 'redux/slices';

import CustomColumnAlert from './custom-column-alert';
import { styles } from './styles';

const ColumnsInfoBlock = () => {
  const isModalOpen = useSelector(isTabModalOpen);
  // const {
  //   isOpen: isConfirmDeleteOpen,
  //   onOpen: onConfirmDeleteOpen,
  //   onClose: onConfirmDeleteClose,
  // } = useDisclosure();

  const columns = useSelector(getColumnsList);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(branchTabActions.toggleTabModal({ isTabModalOpen: false }));
  };

  const onAddColumnAlertClose = () => {
    dispatch(modalActions.setModalData(undefined));
    handleCloseModal();
  };

  // const onConfirmDeleteAlertClose = () => {
  //   dispatch(modalActions.setModalData(undefined));
  //   onConfirmDeleteClose();
  // };

  return (
    <Flex direction='column' sx={styles.infoWrapper}>
      <ManageColumns
        columns={columns}
        setColumnOrder={(columnsOrder) =>
          dispatch(columnActions.reorderColumns({ columnsOrder } as any))
        }
      />
      {/* {Object.values(allColumns).map((column) => ( */}
      {/*  <ColumnRow */}
      {/*    key={column.id} */}
      {/*    column={column} */}
      {/*    handleDeleteColumn={handleDeleteColumn} */}
      {/*    handleEditColumn={handleEditColumn} */}
      {/*  /> */}
      {/* ))} */}
      {/* <IconButton */}
      {/*  mt={pxToRem(10)} */}
      {/*  onClick={onOpen} */}
      {/*  icon={ */}
      {/*    <> */}
      {/*      <IoAdd /> */}
      {/*      <Text ml={pxToRem(8)}>Custom Column</Text> */}
      {/*    </> */}
      {/*  } */}
      {/*  aria-label='Add custom' */}
      {/* /> */}
      <CustomColumnAlert isOpen={isModalOpen} onClose={onAddColumnAlertClose} />
      {/* <ConfirmDeleteAlert isOpen={isConfirmDeleteOpen} onClose={onConfirmDeleteAlertClose} /> */}
    </Flex>
  );
};

export default ColumnsInfoBlock;
