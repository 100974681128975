export const styles = {
  sidebarWrapper: {
    mt: '31.5px',
  },
  sidebarContent: {
    display: 'flex',
    alignItems: 'center',
    mb: '18px',
    cursor: 'pointer',
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '12px',
    svg: {
      minWidth: '12px',
      minHeight: '12px',
      mr: '4.5px',
    },
  },
  sidebarContentActive: {
    color: 'purple.100',
    '& svg path': {
      fill: 'purple.100',
    },
  },
};
