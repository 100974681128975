import { VStack } from '@chakra-ui/react';
import AddCircleIcon from 'assets/icons/AddCircleIcon';
import dayjs from 'dayjs';
import { ProjectInfoSectionItem } from 'pages/projects/components/project-info-section/project-info-section-item';
import React from 'react';
import { DrawerProject } from 'types';

export function ProjectInfoSection({
  handleLinkClick,
  projectData,
}: {
  projectData: DrawerProject;
  handleLinkClick: VoidFunction;
}) {
  const creationDate = dayjs(projectData?.createdAt).format('DD.MM.YYYY, HH:MM');

  return (
    <VStack spacing='6px' align='flex-start'>
      <ProjectInfoSectionItem
        handleLinkClick={handleLinkClick}
        text={creationDate}
        icon={<AddCircleIcon size='15px' />}
      />
    </VStack>
  );
}
