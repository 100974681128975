const AddressIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#B9B9C3'
        d='M14.167 16.333h1.666V9.667h-5v6.666H12.5v-5h1.667v5zm-11.667 0v-12.5A.833.833 0 013.333 3H15a.833.833 0 01.833.833V8H17.5v8.333h.833V18H1.667v-1.667H2.5zm3.333-6.666v1.666H7.5V9.667H5.833zm0 3.333v1.667H7.5V13H5.833zm0-6.667V8H7.5V6.333H5.833z'
      ></path>
    </svg>
  );
};

export default AddressIcon;
