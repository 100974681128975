import { Core } from '@pdftron/webviewer';

import { getAnnotationCurrentColor } from '../utils';
import { CheckUptatingStatusProps } from './types';

export const checkUptatingStatus = ({ data, updateMap, annotation }: CheckUptatingStatusProps) => {
  for (const key in updateMap) {
    if (updateMap[key] === false) {
      if (['fillColor', 'strokeColor'].includes(key)) {
        // dirty hack. need to refactor this in the future
        data[key] = getAnnotationCurrentColor(
          annotation as unknown as Core.Annotations.Annotation,
          key,
        );
      } else {
        delete data?.[key];
      }
    }
  }
};
