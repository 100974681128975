import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ChakraProps,
  List,
  ListItem,
} from '@chakra-ui/react';
import { BasketballIcon, DrawingsIcon, FolderIcon, FolderOpen, ReportsIcon } from 'assets/icons';
import Card from 'components/card';
import { ERoutes } from 'components/router/types';
import SidebarNavLink, { ENavLinkTypes } from 'components/sidebar-link';
import { useHover } from 'hooks';
import { SourcesSidebarLink } from 'pages/projects/components/sidebar/components/Sources';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { pxToRem } from 'utils';

import { styles } from './styles';

const drawingsArray = [
  { label: 'All categories', to: 'all' },
  { label: 'General', to: 'general' },
  { label: 'Architectural', to: 'architectural' },
  { label: 'Electrical', to: 'electrical' },
  { label: 'Unknown', to: 'unknown' },
];

interface ProjectSidebarProps {
  minimizedEnabled?: boolean;
  styleProps?: ChakraProps;
}

export const ProjectSidebar = ({ minimizedEnabled = false, styleProps }: ProjectSidebarProps) => {
  const params = useParams();
  const targetReference = useRef<HTMLElement>(null);
  const expanded = useHover(targetReference);

  return (
    <Card
      ref={targetReference}
      wrapperProps={{
        width: pxToRem(minimizedEnabled && expanded ? 232.5 : 45),
        padding: `${pxToRem(18)} ${pxToRem(minimizedEnabled && expanded ? 24 : 7.5)}`,
        overflowX: 'hidden',
        transition: 'all .4s .3s ease-out',
        ...styleProps,
      }}
    >
      <Accordion allowToggle>
        <List sx={styles.listWrapper}>
          <ListItem
            as={SidebarNavLink}
            to={`${ERoutes.PROJECTS}/${params.id}${ERoutes.REPORTS}`}
            linkType={ENavLinkTypes.NAV_LINK}
          >
            <Box className='icon-wrapper'>
              <ReportsIcon width='16.5px' height='14.25' />
            </Box>
            Reports
          </ListItem>
          <ListItem
            as={SidebarNavLink}
            to={`${ERoutes.PROJECTS}/${params.id}${ERoutes.DRAWINGS}/all`}
            linkType={ENavLinkTypes.NAV_LINK}
          >
            <AccordionItem
              sx={{
                width: '100%',
                '& .chakra-collapse': {
                  maxHeight: '375px',
                  transition: 'max-height .4s .3s ease-out',
                },
                ...(!expanded && {
                  '& [aria-expanded=true]~.chakra-collapse': {
                    maxHeight: '0',
                  },
                }),
              }}
            >
              <AccordionButton>
                <Box className='icon-wrapper'>
                  <DrawingsIcon size='16.5px' />
                </Box>
                <Box as='span' flex='1' textAlign='left'>
                  Drawings
                </Box>
                <AccordionIcon width='15px' height='15px' />
              </AccordionButton>
              <AccordionPanel pb={3}>
                {drawingsArray.map(({ label, to }) => {
                  return (
                    <SidebarNavLink
                      to={`${ERoutes.PROJECTS}/${params.id}${ERoutes.DRAWINGS}/${to}`}
                      linkType={ENavLinkTypes.SUB_LINK}
                      key={to}
                    >
                      {({ isActive }: { isActive: boolean }) => (
                        <>
                          {isActive ? (
                            <FolderOpen size='12px' />
                          ) : (
                            <FolderIcon fill='black.100' size='12px' />
                          )}
                          {label}
                        </>
                      )}
                    </SidebarNavLink>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </ListItem>
          <SourcesSidebarLink expanded={expanded} />
          <ListItem
            as={SidebarNavLink}
            to={`${ERoutes.PROJECTS}/${params.id}${ERoutes._3D_MODELS}`}
            linkType={ENavLinkTypes.NAV_LINK}
          >
            <Box className='icon-wrapper'>
              <BasketballIcon size='18px' />
            </Box>
            3D models
          </ListItem>
        </List>
      </Accordion>
    </Card>
  );
};
