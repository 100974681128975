import { useMemo } from 'react';

import homerunBoxSchedule from '../mock/homerun-box-schedule.json';
import { getColumns } from '../utils';
import { getData } from '../utils/get-data';
import Table from './table';

export const HomerunBoxScheduleTable = () => {
  const data = useMemo(() => {
    return getData(homerunBoxSchedule);
  }, []);

  const columns = useMemo(() => {
    return getColumns(homerunBoxSchedule);
  }, []);

  return <Table data={data} columns={columns} />;
};
