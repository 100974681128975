import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import EyeIcon from 'assets/icons/EyeIcon';
import EyeInvisibleIcon from 'assets/icons/EyeInvisibleIcon';
import { LayersModal } from 'pages/sheet/components/layers-block/layers-modal';
import { SyntheticEvent } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaTrashAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { layerActions } from 'redux/slices';
import { Layer } from 'types/redux';
import { funcWithPreventLogic } from 'utils/functWithPrevenLogicUtil';

import { useLayer } from './use-layer';

interface LayerMenuProps {
  activeLayer: {
    layerId: number;
    layerName: string;
  } | null;
  currentDocumentId: number;
  layerToEdit: Layer;
}

const LayerMenu = ({ currentDocumentId, activeLayer, layerToEdit }: LayerMenuProps) => {
  const { handleDelete, handleToggleVisibility } = useLayer({
    documentId: currentDocumentId,
    layer: layerToEdit,
  });
  const dispatch = useDispatch();

  const handleDeleteLayer = () => {
    if (activeLayer?.layerId === layerToEdit.id) {
      dispatch(
        layerActions.setActiveLayer({
          layerInfo: null,
        }),
      );
    }
    handleDelete();
  };

  const handleStopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  return (
    <Menu>
      <MenuButton onClick={handleStopPropagation}>
        <Icon as={AiOutlineEdit} fontSize='12px' mt={1} />
      </MenuButton>
      <MenuList>
        <LayersModal isEditMode currentDocumentId={currentDocumentId} layerToEdit={layerToEdit} />
        <MenuItem
          onClick={handleDeleteLayer}
          icon={<Icon as={FaTrashAlt} fontSize='18px' color='red.700' />}
        >
          Delete layer
        </MenuItem>
        <MenuItem
          onClick={funcWithPreventLogic(handleToggleVisibility)}
          icon={
            layerToEdit.visible ? (
              <EyeIcon size='18px' fill='#000' />
            ) : (
              <EyeInvisibleIcon size='18px' fill='#000' />
            )
          }
        >
          {layerToEdit.visible ? 'Is visible' : 'Is invisible'}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LayerMenu;
