export const styles = {
  inputWrapper: {
    fontSize: '12px',
    fontFamily: 'Poppins',
    '& [aria-invalid=true]': {
      boxShadow: 'initial',
    },
  },
  inputStyle: {
    height: 'inherit',
    paddingLeft: '9px',
    background: 'gray.100',
    fontSize: '12px',
    border: '1px #625F6E solid',
    borderRadius: '6px',
    _placeholder: {
      transition: '0.2s ease all',
      fontWeight: 500,
      color: 'gray.300',
      fontSize: '12px',
    },
    _hover: {
      borderColor: 'gray.500',
    },
    _focus: {
      borderColor: 'purple.100',
      boxShadow: '0 3px 10px 0 rgba(0,0,0, 0.1)',
      _placeholder: {
        transform: 'translate(5px)',
        transition: '0.2s ease all',
      },
    },
  },
};
