import { APIDrawingCategories } from 'pages/projects/project/drawings/card-list/constants';
import { TAPIDrawingCategories } from 'types/drawing';

export const SOURCE_STATISTICS_CATEGORIES: { key: TAPIDrawingCategories; label: string }[] = [
  {
    key: APIDrawingCategories.unknown,
    label: 'Unknown',
  },
  {
    key: APIDrawingCategories.general,
    label: 'General',
  },
  {
    key: APIDrawingCategories.electrical,
    label: 'Electrical',
  },
  {
    key: APIDrawingCategories.architectural,
    label: 'Architectural',
  },
];
