import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

interface drawHomerunBoxProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawHomerunBox = ({
  viewerInstance,
  annotations,
  redraw = false,
}: drawHomerunBoxProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;
  const annotationsToDraw = annotations.map((annotation) => {
    const annotationFillColor = extractRGB(annotation?.data?.fillColor || 'rgba(146, 232, 232, 1)');
    const annotationStrokeColor = extractRGB(
      annotation?.data?.strokeColor || 'rgba(153, 153, 153, 1)',
    );
    const HomerunBoxAnnotation = viewerInstance.Core.Annotations.CustomAnnotation.createFromClass(
      ANNOTATION_TYPES.HOMERUN_BOX,
      viewerInstance.Core.Annotations.RectangleAnnotation,
    );
    viewerInstance.Core.annotationManager.registerAnnotationType(
      HomerunBoxAnnotation.prototype.elementName,
      HomerunBoxAnnotation,
    );
    viewerInstance.Core.Annotations.setCustomDrawHandler(
      HomerunBoxAnnotation,
      function drawHandler(ctx, pageMatrix, rotation, options) {
        options.annotation.MaintainAspectRatio = true;
        // @ts-ignore
        options.annotation.elementType = ANNOTATION_TYPES.HOMERUN_BOX;
        options.originalDraw(ctx, pageMatrix, rotation);
        // @ts-ignore
        ctx.fillStyle = `rgba(${options.annotation.StrokeColor.R},${
          // @ts-ignore
          options.annotation.StrokeColor.G
          // @ts-ignore
        },${options.annotation.StrokeColor.B}, ${options.annotation.StrokeColor.A})`;
        // @ts-ignore
        ctx.font = `${this.Width / 5}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        // @ts-ignore
        ctx.fillText('HR', this.X + this.Width / 2, this.Y + this.Height / 2);
        ctx.restore();
      },
    );
    const homerunBoxAnnotation = new HomerunBoxAnnotation({
      Id: annotation.id || annotation.Id,
      PageNumber: annotation.pageNumber || annotation.PageNumber,
      ...(annotationStrokeColor && {
        StrokeColor: new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        ),
      }),
      ...(annotationFillColor && {
        FillColor: new viewerInstance.Core.Annotations.Color(
          annotationFillColor[0] as number,
          annotationFillColor[1] as number,
          annotationFillColor[2] as number,
          annotationFillColor[3] as number,
        ),
      }),
      Style: annotation?.style,
      Dashes: annotation?.dashes,
      Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
      X: annotation?.x || annotation.X,
      Y: annotation?.y || annotation.Y,
      Width: annotation?.width || annotation.Width,
      Height: annotation?.height || annotation.Height,
      elementType: ANNOTATION_TYPES.HOMERUN_BOX,
    });

    return homerunBoxAnnotation;
  });
  annotationManager.addAnnotations(annotationsToDraw);
  annotationManager.drawAnnotations({ pageNumber: 1 });

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(
        annotation?.data?.fillColor || 'rgba(146, 232, 232, 1)',
      );
      const annotationStrokeColor = extractRGB(
        annotation?.data?.strokeColor || 'rgba(153, 153, 153, 1)',
      );
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.RectangleAnnotation;
      if (typeof annotation.x !== 'undefined') annotationToUpdate.setX(annotation.x);
      if (typeof annotation.y !== 'undefined') annotationToUpdate.setY(annotation.y);
      if (typeof annotation.width !== 'undefined') annotationToUpdate.setWidth(annotation.width);
      if (typeof annotation.height !== 'undefined') annotationToUpdate.setHeight(annotation.height);

      if (annotationFillColor) {
        annotationToUpdate.FillColor = new viewerInstance.Core.Annotations.Color(
          annotationFillColor[0] as number,
          annotationFillColor[1] as number,
          annotationFillColor[2] as number,
          annotationFillColor[3] as number,
        );
      }
      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }
      // @ts-ignore
      annotationToUpdate.data = {
        // @ts-ignore
        ...annotationToUpdate.data,
        ...annotation.data,
      };

      return annotationToUpdate;
    });
    annotationManager.addAnnotations(annotationsToRedraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
