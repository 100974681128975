import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnotations, getDocuments } from 'redux/selectors';
import { annotationActions, applicationActions, documentActions } from 'redux/slices';
import { calculateMeasurments } from 'utils/measurments/calculate-measurments';

export const useToolModeUpdatedListener = () => {
  const { instance } = useContext(WebviewerContext);

  const dispatch = useDispatch();

  const fileEntities = useSelector(getDocuments);
  const annotationEntities = useSelector(getAnnotations);

  const handler = useCallback(
    (tool: { Measure: { scale: string }; name: string }) => {
      if (instance) {
        dispatch(applicationActions.setSelectedTool({ tool: tool.name }));

        if (tool.Measure) {
          dispatch(
            documentActions.setDocumentScale({
              id: Number.parseInt(Object.keys(fileEntities)[0], 10),
              scale: tool.Measure.scale,
            }),
          );

          const serializedAnnotations = annotationEntities[1].map((annotation) => {
            return {
              id: annotation.id,
              data: {
                measurments: calculateMeasurments({
                  viewerInstance: instance,
                  annotation,
                  isReduxAnnotation: true,
                  scale: tool.Measure.scale as string,
                }),
              },
            };
          });

          dispatch(
            annotationActions.modifyAnnotations({
              documentId: 1,
              annotations: serializedAnnotations,
            }),
          );
        }
      }
    },
    [annotationEntities, dispatch, fileEntities, instance],
  );

  useEffect(() => {
    if (instance) {
      instance.Core.documentViewer.addEventListener('toolModeUpdated', handler);
    }

    return () => {
      if (instance) {
        instance.Core.documentViewer.removeEventListener('toolModeUpdated', handler);
      }
    };
  }, [instance, handler]);
};
