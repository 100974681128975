export const colors = {
  gray: {
    25: '#F3F2FA',
    50: '#ECECEC',
    75: '#E8E7EF',
    100: '#F8F8F8',
    200: '#B9B9C3',
    300: '#C0BDCA',
    350: '#AEADBC',
    375: '#868592',
    400: '#6E6B7B',
    500: '#625F6E',
    600: '#f5f5f9',
    700: '#AEADBC',
  },
  purple: {
    25: '#eeecff',
    50: '#D4D1FF',
    75: '#d3cff6',
    100: '#7368F0',
  },
  green: {
    100: '#28c76f',
    200: '#34B53A',
  },
  blue: {
    10: '#EFEEFF',
    100: '#00cfe8',
  },
  orange: {
    100: '#ff9f43',
  },
  red: {
    100: '#ea5455',
    200: '#FF434E',
  },
  black: {
    50: '#24202D',
    100: '#000000',
  },
};
