import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { SaveIcon } from 'assets/icons';
import { useSelector } from 'react-redux';
import { getAnnotations, getColumns, getNonExportableColumnsIds } from 'redux/selectors';
import { Annotation } from 'types/redux';
import * as XLSX from 'xlsx';

import { styles } from './styles';

const ExportToCsvButton = () => {
  const annotationEntities = useSelector(getAnnotations);
  const columnEntities = useSelector(getColumns);
  const nonExportableColumnsList = useSelector(getNonExportableColumnsIds);

  const parseAnnotationsForExcel = () => {
    const result: { ID: string; Annotation: string }[] = [];
    annotationEntities[1]?.forEach((annotation: Annotation) => {
      const annotationObject = { ID: annotation.id, Annotation: annotation.type };
      Object.values(columnEntities).forEach((column) => {
        if (!nonExportableColumnsList.find((id) => id === column.id)) {
          if (column.id === 'updatedAt') {
            Object.assign(annotationObject, {
              [column.id]: new Date(annotation?.data[column.id])?.toLocaleDateString('en-US'),
            });
          } else {
            Object.assign(annotationObject, {
              [column.id]: annotation.data[column.id],
            });
          }
        }
      });
      result.push(annotationObject);
    });
    return result;
  };

  const downloadXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(parseAnnotationsForExcel());
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Annotations.xlsx');
  };

  const downloadCSV = () => {
    const worksheet = XLSX.utils.json_to_sheet(parseAnnotationsForExcel());
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'Annotations.csv');
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<SaveIcon size='15px' />}
        sx={styles.exportMenu}
        variant='outline'
      >
        Save as
      </MenuButton>
      <MenuList>
        <MenuItem onClick={downloadCSV}>Expost as CSV</MenuItem>
        <MenuItem onClick={downloadXLSX}>Export as XLSX</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ExportToCsvButton;
