import { Box } from '@chakra-ui/react';
import { FileListIcon } from 'assets/icons';
import React from 'react';

import { styles } from './styles';

interface SourceItemProps {
  isActive: boolean;
  handleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  index: number;
  item: {
    to: string;
    label: string;
  };
}

export const SourceItem = ({ isActive, handleClick, item, index }: SourceItemProps) => {
  return (
    <Box
      key={item.to}
      sx={{
        ...styles.sidebarContent,
        ...(isActive && styles.sidebarContentActive),
      }}
      onClick={handleClick}
      data-index={index}
    >
      <FileListIcon />
      {item.label}
    </Box>
  );
};
