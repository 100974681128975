const ListIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#B9B9C3'
        d='M6.667 3.333H17.5V5H6.667V3.333zM2.5 2.917H5v2.5H2.5v-2.5zm0 5.833H5v2.5H2.5v-2.5zm0 5.833H5v2.5H2.5v-2.5zm4.167-5.416H17.5v1.666H6.667V9.167zm0 5.833H17.5v1.667H6.667V15z'
      ></path>
    </svg>
  );
};

export default ListIcon;
