import React from 'react';

export const CheckboxCircleFillWhiteIcon = ({ size = '32px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0003 29.3332C8.63633 29.3332 2.66699 23.3638 2.66699 15.9998C2.66699 8.63584 8.63633 2.6665 16.0003 2.6665C23.3643 2.6665 29.3337 8.63584 29.3337 15.9998C29.3337 23.3638 23.3643 29.3332 16.0003 29.3332ZM14.671 21.3332L24.0977 11.9052L22.2123 10.0198L14.671 17.5625L10.899 13.7905L9.01366 15.6758L14.671 21.3332Z'
        fill='white'
      />
    </svg>
  );
};
