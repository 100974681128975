import React from 'react';

const PaperSheetIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 21'
    >
      <path
        fill='#B9B9C3'
        d='M7.5 2.17v-.003h9.165c.46 0 .835.379.835.826v15.014a.827.827 0 01-.828.826H3.329a.834.834 0 01-.828-.839V7.167l5-4.998zM4.858 7.166H7.5V4.525L4.858 7.167zm4.309-3.334V8a.833.833 0 01-.834.833H4.167v8.334h11.666V3.833H9.167z'
      ></path>
    </svg>
  );
};

export default PaperSheetIcon;
