export const INPUT_TYPES_OPTIONS = {
  text: {
    label: 'Text',
    value: 'text',
  },
  color: {
    label: 'Color',
    value: 'color',
  },
  select: {
    label: 'Select',
    value: 'select',
  },
};

export const DEFAULT_COLUMN_VALUES = {
  label: '',
  defaultValue: '',
  readonly: false,
  inputType: INPUT_TYPES_OPTIONS.text.value,
};
