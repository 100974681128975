type checkerProps = {
  elementType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  annotation: any;
};

export const checkAnnotationTypeNaming = ({ elementType, annotation }: checkerProps) => {
  if (elementType === 'Homerun Box') {
    return annotation.elementName;
  }
  return annotation.Subject;
};
