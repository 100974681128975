import { Box } from '@chakra-ui/react';
import { useIntersection } from 'hooks/useIntersection';
import React, { ReactElement, ReactNode, useEffect } from 'react';

interface IInfiniteScrollProps<T> {
  lastItemRef: T;
  topOffset?: string;
  hasNext: boolean;
  fetchMore?: VoidFunction;
  sx?: object;
  children: ReactNode;
  isLoading: boolean;
  loader: ReactElement;
}

export const InfiniteScroll = <T,>({
  lastItemRef,
  topOffset,
  hasNext,
  fetchMore,
  sx,
  children,
  isLoading,
  loader,
}: IInfiniteScrollProps<T>): ReactElement => {
  const inViewport = useIntersection(lastItemRef, topOffset, children);
  useEffect(() => {
    if (inViewport && hasNext && fetchMore && !isLoading) {
      fetchMore();
    }
  }, [inViewport, hasNext]);

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box sx={sx}>{children}</Box>
      {isLoading && loader}
    </Box>
  );
};
