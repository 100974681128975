import { Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import FilePlusIcon from '../../assets/icons/FilePlusIcon';
import { styles } from './styles';

const AddFileWindow = () => {
  return (
    <Flex sx={styles.fileWindowWrapper}>
      <Flex alignItems='center'>
        <FilePlusIcon size='61.5px' />
        <VStack spacing='13.5px' align='flex-start' sx={styles.fileWindowTextWrapper}>
          <Text sx={styles.addFileTitle}>Select files to upload</Text>
          <Text sx={styles.addFileSubtitle}>or drag and drop it here</Text>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default AddFileWindow;
