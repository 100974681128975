import SelectField from 'components/select';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';
import { Row } from 'react-table';

interface VisibilityColumnFilterProps {
  column: {
    filterValue?: string;
    setFilter: Dispatch<SetStateAction<string | undefined>>;
  };
}

export function filterByVisibility(
  rows: Row<{ [key: string]: boolean }>[],
  id: string | string[],
  filterValue: string,
) {
  return rows.filter((row) => {
    const rowValue = row.original._isVisible;

    if (filterValue === 'VISIBLE') {
      return rowValue;
    }

    if (filterValue === 'HIDDEN') {
      return !rowValue;
    }

    return false;
  });
}

const VISIBILITY_OPTIONS = ['VISIBLE', 'HIDDEN'];

export default function VisibilityColumnFilter({
  column: { filterValue, setFilter },
}: VisibilityColumnFilterProps) {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setFilter(e.target.value || undefined);
  };

  return (
    <SelectField wrapperProps={{ h: 30 }} value={filterValue} onChange={handleChange}>
      <option value=''>All</option>
      {VISIBILITY_OPTIONS.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </SelectField>
  );
}
