import { WORK_MODES } from 'types/redux';
import { pxToRem } from 'utils';

/* ApryseBlock styles */
export const styles = {
  blueprintWrapper: (workMode: string) => ({
    position: 'relative',
    width: '100%',
    height: workMode === WORK_MODES.BRANCH ? '100%' : '74%',
    borderRadius: pxToRem(6),
    overflow: 'hidden',
    background: 'gray.50',
  }),
  iframeWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 100,
    background: '#EDEDED',
  },
  webviewer: {
    borderRadius: pxToRem(6),
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  zoomWrapper: {
    display: 'flex',
    position: 'absolute',
    bottom: '7.5px',
    right: '16.5px',
    button: {
      minWidth: '24px',
      width: '24px',
      height: '24px',
      background: 'white',
      border: '1px solid #E5E7EB',
      boxShadow: '0px 1px 2px 0px #1F293714',
      borderRadius: '50%',
      padding: '0px',
      svg: {
        minW: '12px',
        minH: '12px',
      },
    },
  },
  apryseLoader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    bg: 'white',
    borderRadius: '6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
