import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

interface drawPanelProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawPanel = ({ viewerInstance, annotations, redraw = false }: drawPanelProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(annotation.data.fillColor || 'rgba(146, 232, 232, 1)');
      const annotationStrokeColor = extractRGB(
        annotation.data.strokeColor || 'rgba(153, 153, 153, 1)',
      );
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.RectangleAnnotation;

      // @ts-ignore
      annotationToUpdate.data = {
        // @ts-ignore
        ...annotationToUpdate.data,
        ...annotation.data,
      };
      if (annotationFillColor) {
        annotationToUpdate.FillColor = new viewerInstance.Core.Annotations.Color(
          annotationFillColor[0] as number,
          annotationFillColor[1] as number,
          annotationFillColor[2] as number,
          annotationFillColor[3] as number,
        );
      }

      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }
      return annotationToUpdate;
    });
    annotationManager.addAnnotations(annotationsToRedraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  } else {
    const annotationsToDraw = annotations.map((annotation) => {
      const PanelAnnotation = viewerInstance.Core.Annotations.CustomAnnotation.createFromClass(
        annotation.data.panelType === 'CRITICAL'
          ? ANNOTATION_TYPES.CRITICAL_PANEL
          : ANNOTATION_TYPES.NORMAL_PANEL,
        viewerInstance.Core.Annotations.RectangleAnnotation,
      );

      viewerInstance.Core.Annotations.setCustomDrawHandler(
        PanelAnnotation,
        function (ctx, pageMatrix, rotation, options) {
          options.originalDraw(ctx, pageMatrix, rotation);

          // @ts-ignore
          const fontSize = this.Width * 0.1;

          ctx.save();
          ctx.fillStyle = '#FFFFFF';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = `100 ${fontSize || 0}px sans-serif`;
          // @ts-ignore
          const X = this.X + this.Width / 2;
          // @ts-ignore
          const Y = this.Y + this.Height / 2;
          ctx.fillText(annotation.data.panelType, X, Y);
          ctx.restore();
        },
      );

      const panelAnnotation = new PanelAnnotation({
        Id: annotation.id || annotation.Id,
        PageNumber: annotation.pageNumber || annotation.PageNumber,
        StrokeColor:
          annotation.data.panelType === 'CRITICAL'
            ? new viewerInstance.Core.Annotations.Color(255, 0, 0, 1)
            : new viewerInstance.Core.Annotations.Color(0, 0, 255, 1),
        FillColor:
          annotation.data.panelType === 'CRITICAL'
            ? new viewerInstance.Core.Annotations.Color(255, 0, 0, 0.5)
            : new viewerInstance.Core.Annotations.Color(0, 0, 255, 0.5),
        Style: annotation?.style,
        Dashes: annotation?.dashes,
        Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
        X: annotation?.x || annotation.X,
        Y: annotation?.y || annotation.Y,
        Width: annotation?.width || annotation.Width,
        Height: annotation?.height || annotation.Height,
      });
      // @ts-ignore
      panelAnnotation.data = annotation.data;
      return panelAnnotation;
    });
    annotationManager.addAnnotations(annotationsToDraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
