import { extendTheme } from '@chakra-ui/react';
import { Checkbox } from 'theme/components/checkbox.theme';

import { Accordion } from './components/accordion.theme';
import { Button } from './components/button.theme';
import { Divider } from './components/divider.theme';
import Input from './components/input.theme';
import { MenuButton } from './components/menu.theme';
import { Progress } from './components/progress.theme';
import { colors } from './foundations/colors.theme';

const overrides = {
  colors,
  components: {
    Accordion,
    Divider,
    Button,
    MenuButton,
    Progress,
    Input,
    Checkbox,
  },
};

export default extendTheme(overrides);
