import React from 'react';

export const ListSettingsIcon = ({ size = '20px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.66602 15H7.49935V16.6667H1.66602V15ZM1.66602 9.16668H9.16602V10.8333H1.66602V9.16668ZM1.66602 3.33334H18.3327V5.00001H1.66602V3.33334ZM17.2277 10.8542L18.191 10.5283L19.0243 11.9717L18.261 12.6425C18.3571 13.0981 18.3571 13.5686 18.261 14.0242L19.0243 14.695L18.191 16.1383L17.2277 15.8125C16.886 16.1208 16.4794 16.3583 16.0318 16.5042L15.8327 17.5H14.166L13.966 16.5033C13.5237 16.3588 13.1167 16.1232 12.771 15.8117L11.8077 16.1383L10.9743 14.695L11.7377 14.0242C11.6416 13.5686 11.6416 13.0981 11.7377 12.6425L10.9743 11.9717L11.8077 10.5283L12.771 10.8542C13.1127 10.5458 13.5193 10.3083 13.9668 10.1625L14.166 9.16668H15.8327L16.0327 10.1633C16.4793 10.3083 16.886 10.5467 17.2277 10.855V10.8542ZM14.9993 15C15.4414 15 15.8653 14.8244 16.1779 14.5119C16.4904 14.1993 16.666 13.7754 16.666 13.3333C16.666 12.8913 16.4904 12.4674 16.1779 12.1548C15.8653 11.8423 15.4414 11.6667 14.9993 11.6667C14.5573 11.6667 14.1334 11.8423 13.8208 12.1548C13.5083 12.4674 13.3327 12.8913 13.3327 13.3333C13.3327 13.7754 13.5083 14.1993 13.8208 14.5119C14.1334 14.8244 14.5573 15 14.9993 15Z'
        fill='white'
      />
    </svg>
  );
};
