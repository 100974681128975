import { drawingPathName } from 'components/router/drawing-routes';
import ProjectLayout from 'layouts/project-layout';
import { DrawingsSidebar } from 'pages/drawings-page/components/sidebar';
import DrawingsBlock from 'pages/projects/project/drawings/card-list';
import { ProjectPageHeader } from 'pages/projects/project/headers';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TDrawing } from 'types/drawing';

export function DrawingsPage(): ReactElement {
  const { drawingsType = '' } = useParams();
  const [selectedDrawingsType, setSelectedDrawingsType] = useState(drawingsType);

  const [selectedDrawing, setSelectedDrawing] = useState<TDrawing | null>(null);
  const onSelectDrawingHandler = (drawing: TDrawing) => {
    if (selectedDrawing?.id === drawing.id) {
      setSelectedDrawing(null);
    } else setSelectedDrawing(drawing);
  };
  useEffect(() => {
    if (drawingsType !== selectedDrawingsType) {
      setSelectedDrawing(null);
      setSelectedDrawingsType(drawingsType);
    }
  }, [selectedDrawingsType, setSelectedDrawing, setSelectedDrawingsType, drawingsType]);

  return (
    <ProjectLayout
      header={<ProjectPageHeader path={drawingPathName} />}
      sidebarProps={{ minimizedEnabled: true }}
      sidebar={selectedDrawing && <DrawingsSidebar drawingData={selectedDrawing} />}
    >
      <DrawingsBlock
        onSelectDrawingHandler={onSelectDrawingHandler}
        selectedDrawingId={selectedDrawing?.id}
      />
    </ProjectLayout>
  );
}
