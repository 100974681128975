import React from 'react';

export const ZoomInLineIcon = ({ size = '16px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0213 11.0777L14.8767 13.9323L13.9333 14.8757L11.0787 12.0203C10.0165 12.8718 8.69532 13.3349 7.33398 13.333C4.02198 13.333 1.33398 10.645 1.33398 7.33301C1.33398 4.02101 4.02198 1.33301 7.33398 1.33301C10.646 1.33301 13.334 4.02101 13.334 7.33301C13.3359 8.69434 12.8728 10.0155 12.0213 11.0777ZM10.684 10.583C11.5301 9.71293 12.0026 8.54663 12.0007 7.33301C12.0007 4.75434 9.91198 2.66634 7.33398 2.66634C4.75532 2.66634 2.66732 4.75434 2.66732 7.33301C2.66732 9.91101 4.75532 11.9997 7.33398 11.9997C8.5476 12.0016 9.71391 11.5291 10.584 10.683L10.684 10.583ZM6.66732 6.66634V4.66634H8.00065V6.66634H10.0007V7.99967H8.00065V9.99967H6.66732V7.99967H4.66732V6.66634H6.66732Z'
        fill='#7368F0'
      />
    </svg>
  );
};
