import { APRYSE_DOC_DIMENSIONS } from '../constants';

interface FitScaleForPathPointsArguments {
  docDimensions: { width: number; height: number };
  pathPoints: number[][];
  direction?: 'backend' | 'apryse';
}

export const fitScaleForPathPoints = ({
  docDimensions,
  pathPoints,
  direction = 'backend',
}: FitScaleForPathPointsArguments) => {
  const transformCoef = {
    xCoef: docDimensions.width / APRYSE_DOC_DIMENSIONS.WIDTH,
    yCoef: docDimensions.height / APRYSE_DOC_DIMENSIONS.HEIGHT,
  };

  if (direction === 'backend')
    return pathPoints.map(([x, y]) => {
      return [x * transformCoef.xCoef, y * transformCoef.yCoef];
    });

  return pathPoints.map(([x, y]) => {
    return [x / transformCoef.xCoef, y / transformCoef.yCoef];
  });
};
