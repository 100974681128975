import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';

import { DrawingItem } from './drawing-item';
import { styles } from './styles';

const drawingsArray = [
  { label: 'All categories', to: 'all' },
  { label: 'General', to: 'general' },
  { label: 'Architectural', to: 'architectural' },
  { label: 'Electrical', to: 'electrical' },
  { label: 'Unknown', to: 'unknown' },
];

export const DrawingsInfoBlock = () => {
  const [activeIndex, setActiveIndex] = useState<string>('0');

  const handleClickDrawingItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setActiveIndex(e.currentTarget.getAttribute('data-index') || '0');
  };
  return (
    <Box sx={styles.sidebarWrapper}>
      {drawingsArray.map((item, index) => {
        return (
          <DrawingItem
            key={item.to}
            item={item}
            handleClick={handleClickDrawingItem}
            isActive={activeIndex === index.toString()}
            index={index}
          />
        );
      })}
    </Box>
  );
};
