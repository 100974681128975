import TextField, { TextFieldProps } from 'components/text-field';
import { useFormContext } from 'react-hook-form';

interface TextFieldRHFProps extends TextFieldProps {
  name: string;
}

const TextFieldRHF = ({ name, ...rest }: TextFieldRHFProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const hasError = Boolean(errors[name]);

  return <TextField hasError={hasError} errors={errors} {...register(name)} {...rest} />;
};

export default TextFieldRHF;
