import {
  AnnotationsIcon,
  AnnotationsSettingsIcon,
  BasketballIcon,
  ColumnListIcon,
  DolarIcon,
  DrawingsIcon,
  LayerListIcon,
  PanelsIcon,
  ScheduleIcon,
  SourcesIcon,
} from 'assets/icons';

import ElementInfoBlock from '../annotation-info-block';
import BomBlock from '../bom-block';
import ColumnsInfoBlock from '../columns-info-block';
import { DrawingsInfoBlock } from '../drawings-info-block';
import LayersInfoBlock from '../layers-block';
import { SettingsForm } from '../settings-block/settings-form';
import { SourcesInfoBlock } from '../sources-info-block';

export const tabListArray = [
  { id: 1, Icon: DrawingsIcon },
  { id: 2, Icon: SourcesIcon },
  { id: 3, Icon: BasketballIcon, disabled: true },
  { id: 4, Icon: AnnotationsIcon },
  { id: 5, Icon: LayerListIcon },
  { id: 6, Icon: ColumnListIcon },
  { id: 7, Icon: AnnotationsSettingsIcon },
  { id: 8, Icon: DolarIcon },
  { id: 9, Icon: PanelsIcon, disabled: true },
  { id: 10, Icon: ScheduleIcon, disabled: true },
];

export const tabPanelArray = [
  { id: 1, title: 'Drawings', content: <DrawingsInfoBlock /> },
  { id: 2, title: 'Sources', content: <SourcesInfoBlock /> },
  { id: 3, title: '3D models', content: 'Content' },
  {
    id: 4,
    title: 'Annotations',
    content: <ElementInfoBlock />,
  },
  {
    id: 5,
    title: 'Layer List',
    content: <LayersInfoBlock />,
    withModal: true,
  },
  {
    id: 6,
    title: 'Columns list',
    content: <ColumnsInfoBlock />,
    withModal: true,
  },
  {
    id: 7,
    title: 'Settings',
    content: <SettingsForm />,
  },
  {
    id: 8,
    title: 'Billing of Material',
    content: <BomBlock />,
  },
];
