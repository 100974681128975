import { Breadcrumb, BreadcrumbItem, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export type TBreadcrumb = {
  label: string;
  link: string;
  disabled: boolean;
};

interface IHeaderBreadcrumbsProps {
  breadcrumbs: TBreadcrumb[];
}

export const HeaderBreadcrumbs = ({ breadcrumbs }: IHeaderBreadcrumbsProps): ReactElement => {
  return (
    <Breadcrumb display='flex'>
      {breadcrumbs.map(({ label, link, disabled }) => (
        <BreadcrumbItem
          key={link}
          fontSize='13.5px'
          fontWeight={500}
          color='gray.200'
          lineHeight='0px'
        >
          {!disabled ? <Link to={link}>{label}</Link> : <Text color='purple.100'>{label}</Text>}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
