import { Box, Divider, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { styles } from 'pages/drawings-page/components/sidebar/styles';
import { MdOutlineModeEdit } from 'react-icons/md';

interface IFormSectionProps<T> {
  headingText: string;
  registered: T;
  type?: string;
}

export function FormSection<T>({ headingText, registered, type }: IFormSectionProps<T>) {
  return (
    <Box sx={styles.formSection}>
      <Text sx={styles.formSectionHeadingText}>{headingText}</Text>
      <Divider sx={styles.formDivider} />
      <InputGroup>
        <Input {...registered} sx={styles.formInput} type={type} />
        <InputRightElement>
          <MdOutlineModeEdit size='12px' />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}
