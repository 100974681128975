import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';

import { SourceItem } from './source-item';
import { styles } from './styles';

const sourcesArray = [
  { label: 'Project Name', to: 'project1' },
  { label: 'Project Name', to: 'project2' },
  { label: 'Project Name', to: 'project3' },
];

export const SourcesInfoBlock = () => {
  const [activeIndex, setActiveIndex] = useState<string>('0');
  const handleClickSourceItem = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setActiveIndex(e.currentTarget.getAttribute('data-index') || '0');
  };
  return (
    <Box sx={styles.sidebarWrapper}>
      {sourcesArray.map((item, index) => {
        return (
          <SourceItem
            key={item.to}
            item={item}
            handleClick={handleClickSourceItem}
            isActive={activeIndex === index.toString()}
            index={index}
          />
        );
      })}
    </Box>
  );
};
