import React from 'react';

export const CheckboxCircleFillIcon = ({ width = '20px', height = '21px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99935 18.8332C5.39685 18.8332 1.66602 15.1023 1.66602 10.4998C1.66602 5.89734 5.39685 2.1665 9.99935 2.1665C14.6018 2.1665 18.3327 5.89734 18.3327 10.4998C18.3327 15.1023 14.6018 18.8332 9.99935 18.8332ZM9.16852 13.8332L15.0602 7.94067L13.8819 6.76234L9.16852 11.4765L6.81102 9.119L5.63268 10.2973L9.16852 13.8332Z'
        fill='#7368F0'
      />
    </svg>
  );
};
