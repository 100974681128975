import React from 'react';

export const LayerListIcon = ({ width = '13.5px', height = '16.5px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.8'
        x='16'
        y='8'
        width='6'
        height='14'
        rx='1'
        transform='rotate(90 16 8)'
        fill='#AEADBC'
      />
      <rect
        opacity='0.5'
        x='18'
        width='6'
        height='18'
        rx='1'
        transform='rotate(90 18 0)'
        fill='#AEADBC'
      />
      <rect
        x='14'
        y='16'
        width='6'
        height='10'
        rx='1'
        transform='rotate(90 14 16)'
        fill='#AEADBC'
      />
    </svg>
  );
};
