export const APIDrawingCategories = {
  unknown: 'UNKNOWN',
  architectural: 'ARCHITECTURAL',
  electrical: 'ELECTRICAL',
  general: 'GENERAL',
} as const;

export const DRAWINGS_CATEGORY = {
  all: undefined,
  unknown: APIDrawingCategories.unknown,
  architectural: APIDrawingCategories.architectural,
  electrical: APIDrawingCategories.electrical,
  general: APIDrawingCategories.general,
};
export const DEFAULT_DRAWINGS_CATEGORY = 'all';
export const UNKNOWN_DATA_TEXT = 'Unknown';
