import {
  DEFAULT_DRAWINGS_CATEGORY,
  DRAWINGS_CATEGORY,
} from 'pages/projects/project/drawings/card-list/constants';
import { useParams } from 'react-router-dom';
import { useGetDrawingsQuery as useGetSliceDrawingsQuery } from 'redux/slices';
import { TUrlParamCategory } from 'types/drawing';

export const useGetDrawingsQuery = () => {
  const params = useParams();

  const { id = '', drawingsType = DEFAULT_DRAWINGS_CATEGORY } = params;
  const { data: drawingsList, isLoading } = useGetSliceDrawingsQuery({
    id,
    category: DRAWINGS_CATEGORY[drawingsType as TUrlParamCategory],
  });
  const drawingListData = drawingsList?.body;
  const drawingsMeta = drawingsList?.meta;

  return { drawingListData, isLoading, drawingsMeta };
};
