import { ANNOTATION_TYPES } from './apryse';

export const REQUEST_BODY = {
  settings: {
    grouping_distance: 80,
    cable_count: 9,
    grouping_reward: 100,
    turning_penalty: 0,
    iterations: 50,
    preferred_zones_reward: 50,
    width: 10668,
    height: 7620,
    pixels_per_ft: 15.873,
    conduits_spacing: 1.0,
  },
};

export const PANELS_PRESET = [
  {
    id: `Panel-1`,
    type: 'Rectangle',
    elementType: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
    _isLocked: false,
    _isVisible: true,
    isReadOnly: false,
    pageNumber: 1,
    x: 795.25,
    y: 1097.4,
    width: 15,
    height: 4,
    data: {
      panelName: '2L3',
      panelType: 'NORMAL',
    },
  },
  {
    id: `Panel-2`,
    type: 'Rectangle',
    elementType: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
    _isLocked: false,
    _isVisible: true,
    isReadOnly: false,
    pageNumber: 1,
    x: 815.63,
    y: 1061.76,
    width: 4,
    height: 15,
    data: {
      panelName: '2L4',
      panelType: 'NORMAL',
    },
  },
  {
    id: `Panel-3`,
    type: 'Rectangle',
    elementType: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
    _isLocked: false,
    _isVisible: true,
    isReadOnly: false,
    pageNumber: 1,
    x: 815.63,
    y: 1043.86,
    width: 4,
    height: 15,
    data: {
      panelName: '2L5',
      panelType: 'NORMAL',
    },
  },
  {
    id: `Panel-4`,
    type: 'Rectangle',
    elementType: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
    _isLocked: false,
    _isVisible: true,
    isReadOnly: false,
    pageNumber: 1,
    x: 745.17,
    y: 1007.42,
    width: 15,
    height: 4,
    data: {
      panelName: '1ECL3',
      panelType: 'NORMAL',
    },
  },
  {
    id: `Panel-5`,
    type: 'Rectangle',
    elementType: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
    _isLocked: false,
    _isVisible: true,
    isReadOnly: false,
    pageNumber: 1,
    x: 815.63,
    y: 1023.22,
    width: 4,
    height: 15,
    data: {
      panelName: '2ECL5',
      panelType: 'NORMAL',
    },
  },
];

export const CIRCUITS_PRESET = {
  'circuit:1': [
    {
      x: 4704.7404,
      y: 3531.2858,
      panel: '2L5',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:2': [
    {
      x: 3955.9992,
      y: 3340.9128,
      panel: '2L5',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:3': [
    {
      x: 2963.7482,
      y: 3529.3046,
      panel: '2L5',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:4': [
    {
      x: 2125.0402,
      y: 3345.7134,
      panel: '2L5',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:5': [
    {
      x: 6364.7828,
      y: 2560.3454,
      panel: '2L3',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:6': [
    {
      x: 7033.006,
      y: 1243.5586,
      panel: '2L3',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:7': [
    {
      x: 6365.748,
      y: 1607.82,
      panel: '2L3',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:8': [
    {
      x: 2062.0736,
      y: 2572.3342,
      panel: '2L4',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:9': [
    {
      x: 2061.2862,
      y: 1764.8682,
      panel: '2L4',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:10': [
    {
      x: 1963.9788,
      y: 1985.5688,
      panel: '2L4',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:11': [
    {
      x: 2210.3588,
      y: 2551.4046,
      panel: '2L4',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:12': [
    {
      x: 2280.793,
      y: 2113.5594,
      panel: '2L4',
      circuit: '1',
      type: 'None',
    },
  ],
  'circuit:13': [
    {
      x: 2282.2154,
      y: 5589.8034,
      panel: '2L5',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:14': [
    {
      x: 2047.3416,
      y: 5417.5406,
      panel: '2L5',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:15': [
    {
      x: 2034.032,
      y: 5398.9224,
      panel: '2L5',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:16': [
    {
      x: 2282.2916,
      y: 5288.7372,
      panel: '2L5',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:17': [
    {
      x: 2050.6944,
      y: 5133.4162,
      panel: '2L5',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:18': [
    {
      x: 6323.7364,
      y: 1381.6838,
      panel: '2L4',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:19': [
    {
      x: 5884.291,
      y: 803.656,
      panel: '2L4',
      circuit: '2',
      type: 'None',
    },
  ],
  'circuit:20': [
    {
      x: 3994.2008,
      y: 3011.4748,
      panel: '2L5',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:21': [
    {
      x: 3688.2324,
      y: 3136.9,
      panel: '2L5',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:22': [
    {
      x: 3828.0086,
      y: 3011.9828,
      panel: '2L5',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:23': [
    {
      x: 4067.0988,
      y: 3197.2758,
      panel: '2L5',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:24': [
    {
      x: 1964.7154,
      y: 1619.3516,
      panel: '2L4',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:25': [
    {
      x: 1962.6072,
      y: 1472.4126,
      panel: '2L4',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:26': [
    {
      x: 2136.0638,
      y: 1413.3576,
      panel: '2L4',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:27': [
    {
      x: 6905.4726,
      y: 1216.787,
      panel: '2L3',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:28': [
    {
      x: 7148.2204,
      y: 1218.3872,
      panel: '2L3',
      circuit: '3',
      type: 'None',
    },
  ],
  'circuit:29': [
    {
      x: 2033.0668,
      y: 5112.0548,
      panel: '2L5',
      circuit: '4',
      type: 'None',
    },
  ],
  'circuit:30': [
    {
      x: 2281.4534,
      y: 5002.0474,
      panel: '2L5',
      circuit: '4',
      type: 'None',
    },
  ],
  'circuit:31': [
    {
      x: 2057.8572,
      y: 4847.209,
      panel: '2L5',
      circuit: '4',
      type: 'None',
    },
  ],
  'circuit:32': [
    {
      x: 2033.0668,
      y: 4826.8382,
      panel: '2L5',
      circuit: '4',
      type: 'None',
    },
  ],
  'circuit:33': [
    {
      x: 4461.2306,
      y: 3194.5326,
      panel: '2L5',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:34': [
    {
      x: 4392.6506,
      y: 3010.9922,
      panel: '2L5',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:35': [
    {
      x: 4238.9806,
      y: 3010.3572,
      panel: '2L5',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:36': [
    {
      x: 4085.6154,
      y: 3144.012,
      panel: '2L5',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:37': [
    {
      x: 1965.6298,
      y: 979.7288,
      panel: '2L4',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:38': [
    {
      x: 2160.7272,
      y: 800.9636,
      panel: '2L4',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:39': [
    {
      x: 2492.3496,
      y: 1086.9422,
      panel: '2L4',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:40': [
    {
      x: 2090.9026,
      y: 1164.7932,
      panel: '2L4',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:41': [
    {
      x: 2005.1268,
      y: 802.1066,
      panel: '2L4',
      circuit: '5',
      type: 'None',
    },
  ],
  'circuit:42': [
    {
      x: 2282.1646,
      y: 4711.573,
      panel: '2L5',
      circuit: '6',
      type: 'None',
    },
  ],
  'circuit:43': [
    {
      x: 2048.4338,
      y: 4248.9628,
      panel: '2L5',
      circuit: '6',
      type: 'None',
    },
  ],
  'circuit:44': [
    {
      x: 2282.7234,
      y: 4409.948,
      panel: '2L5',
      circuit: '6',
      type: 'None',
    },
  ],
  'circuit:45': [
    {
      x: 2033.524,
      y: 4540.9612,
      panel: '2L5',
      circuit: '6',
      type: 'None',
    },
  ],
  'circuit:46': [
    {
      x: 2048.1798,
      y: 4560.6462,
      panel: '2L5',
      circuit: '6',
      type: 'None',
    },
  ],
  'circuit:47': [
    {
      x: 6474.5616,
      y: 3329.4574,
      panel: '2L5',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:48': [
    {
      x: 5327.396,
      y: 3321.558,
      panel: '2L5',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:49': [
    {
      x: 5742.7114,
      y: 3236.6204,
      panel: '2L5',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:50': [
    {
      x: 7012.94,
      y: 3090.0878,
      panel: '2L5',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:51': [
    {
      x: 5537.1238,
      y: 2800.5024,
      panel: '2L5',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:52': [
    {
      x: 5737.9362,
      y: 2821.813,
      panel: '2L5',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:53': [
    {
      x: 2593.5178,
      y: 2690.114,
      panel: '2L4',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:54': [
    {
      x: 2281.1486,
      y: 1213.231,
      panel: '2L4',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:55': [
    {
      x: 2490.2922,
      y: 1505.1278,
      panel: '2L4',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:56': [
    {
      x: 2603.373,
      y: 1968.3222,
      panel: '2L4',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:57': [
    {
      x: 2487.1172,
      y: 2352.4972,
      panel: '2L4',
      circuit: '7',
      type: 'None',
    },
  ],
  'circuit:58': [
    {
      x: 2490.4954,
      y: 5601.2334,
      panel: '2L5',
      circuit: '8',
      type: 'None',
    },
  ],
  'circuit:59': [
    {
      x: 2490.4446,
      y: 4648.1492,
      panel: '2L5',
      circuit: '8',
      type: 'None',
    },
  ],
  'circuit:60': [
    {
      x: 4331.208,
      y: 3842.4358,
      panel: '2ECL5',
      circuit: '8',
      type: 'None',
    },
  ],
  'circuit:61': [
    {
      x: 4330.573,
      y: 3619.9826,
      panel: '2ECL5',
      circuit: '8',
      type: 'None',
    },
  ],
  'circuit:62': [
    {
      x: 4662.9574,
      y: 3639.5914,
      panel: '2ECL5',
      circuit: '8',
      type: 'None',
    },
  ],
  'circuit:63': [
    {
      x: 5714.4666,
      y: 4251.452,
      panel: '2L5',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:64': [
    {
      x: 5316.2962,
      y: 3551.0724,
      panel: '2L5',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:65': [
    {
      x: 5508.6758,
      y: 3550.0564,
      panel: '2L5',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:66': [
    {
      x: 5682.6912,
      y: 3550.8946,
      panel: '2L5',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:67': [
    {
      x: 5460.1364,
      y: 3874.2874,
      panel: '2L5',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:68': [
    {
      x: 5805.297,
      y: 3933.2408,
      panel: '2L5',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:69': [
    {
      x: 1913.9408,
      y: 2994.1266,
      panel: '1ECL3',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:70': [
    {
      x: 1914.7536,
      y: 2502.3318,
      panel: '1ECL3',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:71': [
    {
      x: 3274.0092,
      y: 1646.7836,
      panel: '2L4',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:72': [
    {
      x: 3099.9684,
      y: 1505.458,
      panel: '2L4',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:73': [
    {
      x: 3273.5774,
      y: 1333.9318,
      panel: '2L4',
      circuit: '9',
      type: 'None',
    },
  ],
  'circuit:74': [
    {
      x: 3437.8138,
      y: 4664.0242,
      panel: '2L5',
      circuit: '10',
      type: 'None',
    },
  ],
  'circuit:75': [
    {
      x: 3687.699,
      y: 4566.3866,
      panel: '2L5',
      circuit: '10',
      type: 'None',
    },
  ],
  'circuit:76': [
    {
      x: 3624.961,
      y: 4807.077,
      panel: '2L5',
      circuit: '10',
      type: 'None',
    },
  ],
  'circuit:77': [
    {
      x: 3708.8064,
      y: 4666.8182,
      panel: '2L5',
      circuit: '10',
      type: 'None',
    },
  ],
  'circuit:78': [
    {
      x: 4329.43,
      y: 3955.6436,
      panel: '2ECL5',
      circuit: '10',
      type: 'None',
    },
  ],
  'circuit:79': [
    {
      x: 4329.6078,
      y: 4183.126,
      panel: '2ECL5',
      circuit: '10',
      type: 'None',
    },
  ],
  'circuit:80': [
    {
      x: 5152.4154,
      y: 3550.8692,
      panel: '2L5',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:81': [
    {
      x: 3959.1488,
      y: 3629.6346,
      panel: '2L5',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:82': [
    {
      x: 4130.9798,
      y: 4012.7174,
      panel: '2L5',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:83': [
    {
      x: 4820.7168,
      y: 3550.6152,
      panel: '2L5',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:84': [
    {
      x: 4683.6584,
      y: 3738.4228,
      panel: '2L5',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:85': [
    {
      x: 4084.0914,
      y: 1536.2174,
      panel: '2L4',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:86': [
    {
      x: 3597.3004,
      y: 1559.3822,
      panel: '2L4',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:87': [
    {
      x: 4084.955,
      y: 1406.0932,
      panel: '2L4',
      circuit: '11',
      type: 'None',
    },
  ],
  'circuit:88': [
    {
      x: 2994.4314,
      y: 5080.5588,
      panel: '2L5',
      circuit: '12',
      type: 'None',
    },
  ],
  'circuit:89': [
    {
      x: 3419.348,
      y: 4568.8758,
      panel: '2L5',
      circuit: '12',
      type: 'None',
    },
  ],
  'circuit:90': [
    {
      x: 3341.5732,
      y: 4807.5596,
      panel: '2L5',
      circuit: '12',
      type: 'None',
    },
  ],
  'circuit:91': [
    {
      x: 2974.1622,
      y: 4537.4814,
      panel: '2L5',
      circuit: '12',
      type: 'None',
    },
  ],
  'circuit:92': [
    {
      x: 2697.4292,
      y: 4724.273,
      panel: '2L5',
      circuit: '12',
      type: 'None',
    },
  ],
  'circuit:93': [
    {
      x: 6314.7702,
      y: 3856.8376,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:94': [
    {
      x: 6316.345,
      y: 3420.4402,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:95': [
    {
      x: 6315.0496,
      y: 3366.77,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:96': [
    {
      x: 6314.4146,
      y: 3601.0342,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:97': [
    {
      x: 6198.5144,
      y: 3705.606,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:98': [
    {
      x: 6034.7606,
      y: 3846.4998,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:99': [
    {
      x: 6035.1924,
      y: 4001.262,
      panel: '2L5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:100': [
    {
      x: 2587.3456,
      y: 1946.8592,
      panel: '2L4',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:101': [
    {
      x: 3012.2622,
      y: 1945.3352,
      panel: '2L4',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:102': [
    {
      x: 3589.6296,
      y: 1944.9542,
      panel: '2L4',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:103': [
    {
      x: 7054.0372,
      y: 1453.1848,
      panel: '2L3',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:104': [
    {
      x: 2037.9944,
      y: 1743.2782,
      panel: '2ECL5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:105': [
    {
      x: 2281.5296,
      y: 966.1144,
      panel: '2ECL5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:106': [
    {
      x: 2490.089,
      y: 901.446,
      panel: '2ECL5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:107': [
    {
      x: 2215.4134,
      y: 1413.7386,
      panel: '2ECL5',
      circuit: '13',
      type: 'None',
    },
  ],
  'circuit:108': [
    {
      x: 2591.3588,
      y: 5191.3028,
      panel: '2L5',
      circuit: '14',
      type: 'None',
    },
  ],
  'circuit:109': [
    {
      x: 2974.1876,
      y: 4933.2896,
      panel: '2L5',
      circuit: '14',
      type: 'None',
    },
  ],
  'circuit:110': [
    {
      x: 2869.6158,
      y: 5170.2208,
      panel: '2L5',
      circuit: '14',
      type: 'None',
    },
  ],
  'circuit:111': [
    {
      x: 2690.2156,
      y: 5495.1884,
      panel: '2L5',
      circuit: '14',
      type: 'None',
    },
  ],
  'circuit:112': [
    {
      x: 2894.1522,
      y: 5495.3916,
      panel: '2L5',
      circuit: '14',
      type: 'None',
    },
  ],
  'circuit:113': [
    {
      x: 2974.9242,
      y: 5628.259,
      panel: '2L5',
      circuit: '14',
      type: 'None',
    },
  ],
  'circuit:114': [
    {
      x: 2780.2332,
      y: 4459.8336,
      panel: '2L5',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:115': [
    {
      x: 5474.3604,
      y: 4270.8322,
      panel: '2L5',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:116': [
    {
      x: 4746.9044,
      y: 4460.2908,
      panel: '2L5',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:117': [
    {
      x: 3723.2336,
      y: 4271.899,
      panel: '2L5',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:118': [
    {
      x: 4046.0422,
      y: 4171.8992,
      panel: '2L5',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:119': [
    {
      x: 3077.8958,
      y: 2446.3502,
      panel: '2L4',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:120': [
    {
      x: 2682.9004,
      y: 2144.2172,
      panel: '2L4',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:121': [
    {
      x: 2778.0234,
      y: 2445.9946,
      panel: '2L4',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:122': [
    {
      x: 2972.2826,
      y: 2142.363,
      panel: '2L4',
      circuit: '15',
      type: 'None',
    },
  ],
  'circuit:123': [
    {
      x: 5743.6004,
      y: 3142.3356,
      panel: '2L5',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:124': [
    {
      x: 5744.1338,
      y: 3027.9594,
      panel: '2L5',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:125': [
    {
      x: 5744.6418,
      y: 2919.7808,
      panel: '2L5',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:126': [
    {
      x: 3173.5522,
      y: 2445.3596,
      panel: '2L4',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:127': [
    {
      x: 3666.5154,
      y: 2312.6446,
      panel: '2L4',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:128': [
    {
      x: 3501.9488,
      y: 2143.5314,
      panel: '2L4',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:129': [
    {
      x: 3666.2106,
      y: 1863.1916,
      panel: '2L4',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:130': [
    {
      x: 3336.798,
      y: 2143.5822,
      panel: '2L4',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:131': [
    {
      x: 3366.0588,
      y: 2122.4748,
      panel: '2L4',
      circuit: '17',
      type: 'None',
    },
  ],
  'circuit:132': [
    {
      x: 4246.8038,
      y: 6502.5016,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:133': [
    {
      x: 3623.9196,
      y: 6197.219,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:134': [
    {
      x: 3624.9102,
      y: 6374.4094,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:135': [
    {
      x: 3624.3514,
      y: 6491.9098,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:136': [
    {
      x: 3624.4022,
      y: 6642.5318,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:137': [
    {
      x: 4247.2356,
      y: 6647.1546,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:138': [
    {
      x: 4226.941,
      y: 6208.014,
      panel: '2ECL5',
      circuit: '18',
      type: 'None',
    },
  ],
  'circuit:139': [
    {
      x: 5817.6922,
      y: 4629.6072,
      panel: '2L5',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:140': [
    {
      x: 5746.3944,
      y: 4677.8164,
      panel: '2L5',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:141': [
    {
      x: 5648.198,
      y: 4677.6894,
      panel: '2L5',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:142': [
    {
      x: 4036.5426,
      y: 2618.9686,
      panel: '2L4',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:143': [
    {
      x: 3528.8474,
      y: 2713.0502,
      panel: '2L4',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:144': [
    {
      x: 3610.61,
      y: 2466.5686,
      panel: '2L4',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:145': [
    {
      x: 2594.4322,
      y: 2741.1426,
      panel: '2ECL5',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:146': [
    {
      x: 2592.3748,
      y: 2526.4364,
      panel: '2ECL5',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:147': [
    {
      x: 7156.2468,
      y: 1415.1864,
      panel: '2L3',
      circuit: '19',
      type: 'None',
    },
  ],
  'circuit:148': [
    {
      x: 4881.3466,
      y: 6498.0058,
      panel: '2ECL5',
      circuit: '20',
      type: 'None',
    },
  ],
  'circuit:149': [
    {
      x: 4266.2094,
      y: 6502.5524,
      panel: '2ECL5',
      circuit: '20',
      type: 'None',
    },
  ],
  'circuit:150': [
    {
      x: 4267.0222,
      y: 6647.6372,
      panel: '2ECL5',
      circuit: '20',
      type: 'None',
    },
  ],
  'circuit:151': [
    {
      x: 4881.9562,
      y: 6641.084,
      panel: '2ECL5',
      circuit: '20',
      type: 'None',
    },
  ],
  'circuit:152': [
    {
      x: 5491.4546,
      y: 4906.6704,
      panel: '2L5',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:153': [
    {
      x: 5061.2802,
      y: 4965.446,
      panel: '2L5',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:154': [
    {
      x: 4834.9916,
      y: 5111.9278,
      panel: '2L5',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:155': [
    {
      x: 4847.59,
      y: 4843.653,
      panel: '2L5',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:156': [
    {
      x: 4549.4448,
      y: 4798.2378,
      panel: '2L5',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:157': [
    {
      x: 5329.6566,
      y: 4842.3576,
      panel: '2L5',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:158': [
    {
      x: 4141.9526,
      y: 2445.7406,
      panel: '2L4',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:159': [
    {
      x: 4036.7966,
      y: 2551.7348,
      panel: '2L4',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:160': [
    {
      x: 3996.2328,
      y: 2445.639,
      panel: '2L4',
      circuit: '21',
      type: 'None',
    },
  ],
  'circuit:161': [
    {
      x: 5524.8556,
      y: 3979.2656,
      panel: '2ECL5',
      circuit: '22',
      type: 'None',
    },
  ],
  'circuit:162': [
    {
      x: 6128.4612,
      y: 6107.6332,
      panel: '2L5',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:163': [
    {
      x: 3242.1068,
      y: 6108.0142,
      panel: '2L5',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:164': [
    {
      x: 4177.8428,
      y: 5917.8698,
      panel: '2L5',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:165': [
    {
      x: 4888.4332,
      y: 5920.9432,
      panel: '2L5',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:166': [
    {
      x: 5526.5828,
      y: 5735.8788,
      panel: '2L5',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:167': [
    {
      x: 6315.583,
      y: 5911.9008,
      panel: '2L5',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:168': [
    {
      x: 4059.3264,
      y: 2029.4854,
      panel: '2L4',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:169': [
    {
      x: 4141.8764,
      y: 2049.5768,
      panel: '2L4',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:170': [
    {
      x: 3997.198,
      y: 2048.7894,
      panel: '2L4',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:171': [
    {
      x: 4418.2792,
      y: 1839.722,
      panel: '2L4',
      circuit: '23',
      type: 'None',
    },
  ],
  'circuit:172': [
    {
      x: 2636.5454,
      y: 4745.4058,
      panel: '2ECL5',
      circuit: '24',
      type: 'None',
    },
  ],
  'circuit:173': [
    {
      x: 2974.3146,
      y: 4882.1594,
      panel: '2ECL5',
      circuit: '24',
      type: 'None',
    },
  ],
  'circuit:174': [
    {
      x: 2512.2886,
      y: 5028.3618,
      panel: '2ECL5',
      circuit: '24',
      type: 'None',
    },
  ],
  'circuit:175': [
    {
      x: 3938.9812,
      y: 4937.633,
      panel: '2L5',
      circuit: '25',
      type: 'None',
    },
  ],
  'circuit:176': [
    {
      x: 4231.259,
      y: 5106.6192,
      panel: '2L5',
      circuit: '25',
      type: 'None',
    },
  ],
  'circuit:177': [
    {
      x: 3509.2386,
      y: 2670.0734,
      panel: '2L4',
      circuit: '25',
      type: 'None',
    },
  ],
  'circuit:178': [
    {
      x: 3250.4888,
      y: 2602.4586,
      panel: '2L4',
      circuit: '25',
      type: 'None',
    },
  ],
  'circuit:179': [
    {
      x: 3252.2414,
      y: 2489.3524,
      panel: '2L4',
      circuit: '25',
      type: 'None',
    },
  ],
  'circuit:180': [
    {
      x: 2013.8898,
      y: 6129.3248,
      panel: '2ECL5',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:181': [
    {
      x: 2511.3488,
      y: 5611.1394,
      panel: '2ECL5',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:182': [
    {
      x: 2510.7138,
      y: 5855.589,
      panel: '2ECL5',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:183': [
    {
      x: 2883.5858,
      y: 5789.0664,
      panel: '2ECL5',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:184': [
    {
      x: 2588.8696,
      y: 1668.9832,
      panel: '2L4',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:185': [
    {
      x: 3027.4006,
      y: 1666.6464,
      panel: '2L4',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:186': [
    {
      x: 2832.1,
      y: 1666.2654,
      panel: '2L4',
      circuit: '26',
      type: 'None',
    },
  ],
  'circuit:187': [
    {
      x: 4338.4978,
      y: 5329.6566,
      panel: '2L5',
      circuit: '27',
      type: 'None',
    },
  ],
  'circuit:188': [
    {
      x: 4246.1688,
      y: 5429.8342,
      panel: '2L5',
      circuit: '27',
      type: 'None',
    },
  ],
  'circuit:189': [
    {
      x: 2597.7596,
      y: 2991.9676,
      panel: '2L4',
      circuit: '27',
      type: 'None',
    },
  ],
  'circuit:190': [
    {
      x: 4309.2116,
      y: 2691.6634,
      panel: '2L4',
      circuit: '27',
      type: 'None',
    },
  ],
  'circuit:191': [
    {
      x: 4503.0644,
      y: 2990.723,
      panel: '2L4',
      circuit: '27',
      type: 'None',
    },
  ],
  'circuit:192': [
    {
      x: 3478.9364,
      y: 2800.4262,
      panel: '2L4',
      circuit: '27',
      type: 'None',
    },
  ],
  'circuit:193': [
    {
      x: 3816.0452,
      y: 5897.3212,
      panel: '2ECL5',
      circuit: '28',
      type: 'None',
    },
  ],
  'circuit:194': [
    {
      x: 3183.255,
      y: 5729.0716,
      panel: '2ECL5',
      circuit: '28',
      type: 'None',
    },
  ],
  'circuit:195': [
    {
      x: 3842.8676,
      y: 5586.9078,
      panel: '2ECL5',
      circuit: '28',
      type: 'None',
    },
  ],
  'circuit:196': [
    {
      x: 2589.0474,
      y: 2443.8102,
      panel: '2L4',
      circuit: '28',
      type: 'None',
    },
  ],
  'circuit:197': [
    {
      x: 2844.6476,
      y: 2390.8512,
      panel: '2L4',
      circuit: '28',
      type: 'None',
    },
  ],
  'circuit:198': [
    {
      x: 3414.8014,
      y: 2360.1426,
      panel: '2L4',
      circuit: '28',
      type: 'None',
    },
  ],
  'circuit:199': [
    {
      x: 3782.7712,
      y: 5567.4768,
      panel: '2L5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:200': [
    {
      x: 3576.1168,
      y: 4828.2352,
      panel: '2L5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:201': [
    {
      x: 3918.1278,
      y: 4948.9868,
      panel: '2L5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:202': [
    {
      x: 3520.9988,
      y: 5588.0,
      panel: '2L5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:203': [
    {
      x: 3714.0896,
      y: 5587.5428,
      panel: '2L5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:204': [
    {
      x: 3437.2804,
      y: 5896.5084,
      panel: '2L5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:205': [
    {
      x: 5071.2116,
      y: 3057.779,
      panel: '2L4',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:206': [
    {
      x: 4519.1426,
      y: 2444.9786,
      panel: '2ECL5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:207': [
    {
      x: 4331.7922,
      y: 2048.0274,
      panel: '2ECL5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:208': [
    {
      x: 3509.5688,
      y: 2488.8952,
      panel: '2ECL5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:209': [
    {
      x: 3509.4164,
      y: 2579.0144,
      panel: '2ECL5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:210': [
    {
      x: 4289.3234,
      y: 2445.4358,
      panel: '2ECL5',
      circuit: '29',
      type: 'None',
    },
  ],
  'circuit:211': [
    {
      x: 3513.1756,
      y: 1334.5668,
      panel: '2L4',
      circuit: '30',
      type: 'None',
    },
  ],
  'circuit:212': [
    {
      x: 3598.2656,
      y: 1411.351,
      panel: '2L4',
      circuit: '30',
      type: 'None',
    },
  ],
  'circuit:213': [
    {
      x: 1963.42,
      y: 5854.573,
      panel: '2L5',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:214': [
    {
      x: 4559.2492,
      y: 2445.512,
      panel: '2L4',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:215': [
    {
      x: 4377.055,
      y: 2050.3896,
      panel: '2L4',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:216': [
    {
      x: 4851.7302,
      y: 2184.654,
      panel: '2L4',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:217': [
    {
      x: 4648.1238,
      y: 2049.1196,
      panel: '2L4',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:218': [
    {
      x: 4439.8946,
      y: 1770.5324,
      panel: '2ECL5',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:219': [
    {
      x: 4437.6086,
      y: 1934.9212,
      panel: '2ECL5',
      circuit: '31',
      type: 'None',
    },
  ],
  'circuit:220': [
    {
      x: 3372.4596,
      y: 5587.1618,
      panel: '2ECL5',
      circuit: '32',
      type: 'None',
    },
  ],
  'circuit:221': [
    {
      x: 3299.714,
      y: 5588.4826,
      panel: '2ECL5',
      circuit: '32',
      type: 'None',
    },
  ],
  'circuit:222': [
    {
      x: 3182.8486,
      y: 5829.5286,
      panel: '2ECL5',
      circuit: '32',
      type: 'None',
    },
  ],
  'circuit:223': [
    {
      x: 6202.7308,
      y: 2744.5716,
      panel: '2L5',
      circuit: '33',
      type: 'None',
    },
  ],
  'circuit:224': [
    {
      x: 4438.7516,
      y: 1733.1182,
      panel: '2L4',
      circuit: '33',
      type: 'None',
    },
  ],
  'circuit:225': [
    {
      x: 4438.3198,
      y: 1905.5334,
      panel: '2L4',
      circuit: '33',
      type: 'None',
    },
  ],
  'circuit:226': [
    {
      x: 4708.906,
      y: 1667.383,
      panel: '2L4',
      circuit: '33',
      type: 'None',
    },
  ],
  'circuit:227': [
    {
      x: 4852.6954,
      y: 1865.8078,
      panel: '2L4',
      circuit: '33',
      type: 'None',
    },
  ],
  'circuit:228': [
    {
      x: 3210.7886,
      y: 1666.6464,
      panel: '2L4',
      circuit: '34',
      type: 'None',
    },
  ],
  'circuit:229': [
    {
      x: 3535.3498,
      y: 1667.3068,
      panel: '2L4',
      circuit: '34',
      type: 'None',
    },
  ],
  'circuit:230': [
    {
      x: 3346.1706,
      y: 1666.8496,
      panel: '2L4',
      circuit: '34',
      type: 'None',
    },
  ],
  'circuit:231': [
    {
      x: 3798.4684,
      y: 5215.8646,
      panel: '2L5',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:232': [
    {
      x: 3282.2896,
      y: 5200.8786,
      panel: '2L5',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:233': [
    {
      x: 3566.1346,
      y: 5201.7168,
      panel: '2L5',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:234': [
    {
      x: 3803.9802,
      y: 5201.9708,
      panel: '2L5',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:235': [
    {
      x: 3534.7148,
      y: 5216.7282,
      panel: '2L5',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:236': [
    {
      x: 3279.0892,
      y: 5215.8392,
      panel: '2L5',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:237': [
    {
      x: 2598.6486,
      y: 1312.1386,
      panel: '2L4',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:238': [
    {
      x: 3672.1796,
      y: 1123.442,
      panel: '2L4',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:239': [
    {
      x: 4502.9374,
      y: 1312.9768,
      panel: '2L4',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:240': [
    {
      x: 4226.052,
      y: 1334.3382,
      panel: '2L4',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:241': [
    {
      x: 4105.5036,
      y: 1426.1592,
      panel: '2L4',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:242': [
    {
      x: 4359.783,
      y: 1552.7782,
      panel: '2L4',
      circuit: '35',
      type: 'None',
    },
  ],
  'circuit:243': [
    {
      x: 5157.8256,
      y: 1425.3464,
      panel: '2L4',
      circuit: '37',
      type: 'None',
    },
  ],
  'circuit:244': [
    {
      x: 5155.3872,
      y: 900.9126,
      panel: '2L4',
      circuit: '37',
      type: 'None',
    },
  ],
  'circuit:245': [
    {
      x: 4872.0248,
      y: 2241.1944,
      panel: '2L4',
      circuit: '37',
      type: 'None',
    },
  ],
  'circuit:246': [
    {
      x: 5542.6864,
      y: 6490.7668,
      panel: '2ECL5',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:247': [
    {
      x: 4901.1078,
      y: 6497.6756,
      panel: '2ECL5',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:248': [
    {
      x: 4901.6158,
      y: 6641.338,
      panel: '2ECL5',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:249': [
    {
      x: 5523.5856,
      y: 6646.0878,
      panel: '2ECL5',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:250': [
    {
      x: 5523.2808,
      y: 6491.1732,
      panel: '2ECL5',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:251': [
    {
      x: 5543.6516,
      y: 6646.2402,
      panel: '2ECL5',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:252': [
    {
      x: 3526.79,
      y: 2534.7168,
      panel: '2L4',
      circuit: '38',
      type: 'None',
    },
  ],
  'circuit:253': [
    {
      x: 2555.6464,
      y: 6374.3078,
      panel: '2L5',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:254': [
    {
      x: 2398.3696,
      y: 6740.8552,
      panel: '2L5',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:255': [
    {
      x: 1963.928,
      y: 6398.5394,
      panel: '2L5',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:256': [
    {
      x: 2252.091,
      y: 6128.9692,
      panel: '2L5',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:257': [
    {
      x: 2130.425,
      y: 6127.0642,
      panel: '2L5',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:258': [
    {
      x: 6159.0936,
      y: 2725.2676,
      panel: '2L4',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:259': [
    {
      x: 5619.4452,
      y: 2780.5126,
      panel: '2L4',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:260': [
    {
      x: 5491.7848,
      y: 2456.1038,
      panel: '2L4',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:261': [
    {
      x: 5444.9726,
      y: 2475.865,
      panel: '2L4',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:262': [
    {
      x: 5177.9424,
      y: 2637.7138,
      panel: '2L4',
      circuit: '39',
      type: 'None',
    },
  ],
  'circuit:263': [
    {
      x: 3623.9704,
      y: 6605.7018,
      panel: '2L5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:264': [
    {
      x: 3625.1642,
      y: 6235.0142,
      panel: '2L5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:265': [
    {
      x: 3624.58,
      y: 6337.3508,
      panel: '2L5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:266': [
    {
      x: 3624.2752,
      y: 6529.578,
      panel: '2L5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:267': [
    {
      x: 3790.6706,
      y: 6740.8044,
      panel: '2L5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:268': [
    {
      x: 4037.0506,
      y: 6741.1854,
      panel: '2L5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:269': [
    {
      x: 6241.796,
      y: 6743.4206,
      panel: '2ECL5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:270': [
    {
      x: 6117.8694,
      y: 6741.2362,
      panel: '2ECL5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:271': [
    {
      x: 6070.5238,
      y: 6128.258,
      panel: '2ECL5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:272': [
    {
      x: 6236.335,
      y: 6127.9786,
      panel: '2ECL5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:273': [
    {
      x: 5694.2228,
      y: 6207.2012,
      panel: '2ECL5',
      circuit: '40',
      type: 'None',
    },
  ],
  'circuit:274': [
    {
      x: 2281.9868,
      y: 6023.7624,
      panel: '2L5',
      circuit: '41',
      type: 'None',
    },
  ],
  'circuit:275': [
    {
      x: 1963.293,
      y: 6045.3016,
      panel: '2L5',
      circuit: '41',
      type: 'None',
    },
  ],
  'circuit:276': [
    {
      x: 2282.2916,
      y: 5908.8782,
      panel: '2L5',
      circuit: '41',
      type: 'None',
    },
  ],
  'circuit:277': [
    {
      x: 1964.1058,
      y: 5975.6294,
      panel: '2L5',
      circuit: '41',
      type: 'None',
    },
  ],
  'circuit:278': [
    {
      x: 2033.4986,
      y: 5705.348,
      panel: '2L5',
      circuit: '41',
      type: 'None',
    },
  ],
  'circuit:279': [
    {
      x: 2026.4374,
      y: 2324.735,
      panel: '2L4',
      circuit: '41',
      type: 'None',
    },
  ],
  'circuit:280': [
    {
      x: 4672.711,
      y: 6740.6012,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:281': [
    {
      x: 4247.261,
      y: 6542.532,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:282': [
    {
      x: 4247.0832,
      y: 6607.683,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:283': [
    {
      x: 4267.0222,
      y: 6607.683,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:284': [
    {
      x: 4266.6412,
      y: 6542.1764,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:285': [
    {
      x: 4475.4546,
      y: 6741.5156,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:286': [
    {
      x: 4382.1858,
      y: 6231.2042,
      panel: '2L5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:287': [
    {
      x: 4568.8758,
      y: 5830.3668,
      panel: '2ECL5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:288': [
    {
      x: 4567.809,
      y: 5653.0494,
      panel: '2ECL5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:289': [
    {
      x: 4569.6886,
      y: 5378.1452,
      panel: '2ECL5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:290': [
    {
      x: 4569.6124,
      y: 5201.4882,
      panel: '2ECL5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:291': [
    {
      x: 4951.6538,
      y: 5131.7144,
      panel: '2ECL5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:292': [
    {
      x: 5091.6332,
      y: 5132.5018,
      panel: '2ECL5',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:293': [
    {
      x: 5473.2682,
      y: 1677.0858,
      panel: '2L4',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:294': [
    {
      x: 5274.4116,
      y: 1378.9406,
      panel: '2L4',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:295': [
    {
      x: 5473.573,
      y: 2015.6678,
      panel: '2L4',
      circuit: '42',
      type: 'None',
    },
  ],
  'circuit:296': [
    {
      x: 2255.1898,
      y: 2323.719,
      panel: '2L4',
      circuit: '43',
      type: 'None',
    },
  ],
  'circuit:297': [
    {
      x: 4901.9714,
      y: 6604.508,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:298': [
    {
      x: 4880.8894,
      y: 6534.8358,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:299': [
    {
      x: 4901.7682,
      y: 6535.4454,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:300': [
    {
      x: 4882.1594,
      y: 6603.9492,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:301': [
    {
      x: 5102.8346,
      y: 6740.6774,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:302': [
    {
      x: 5300.0148,
      y: 6740.1694,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:303': [
    {
      x: 5170.3986,
      y: 6226.3782,
      panel: '2L5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:304': [
    {
      x: 5061.1024,
      y: 5026.152,
      panel: '2ECL5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:305': [
    {
      x: 5061.6612,
      y: 5082.0828,
      panel: '2ECL5',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:306': [
    {
      x: 5281.9808,
      y: 2190.75,
      panel: '2L4',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:307': [
    {
      x: 5472.5316,
      y: 1846.2498,
      panel: '2L4',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:308': [
    {
      x: 5472.5824,
      y: 1518.0818,
      panel: '2L4',
      circuit: '44',
      type: 'None',
    },
  ],
  'circuit:309': [
    {
      x: 2089.3532,
      y: 2324.7604,
      panel: '2L4',
      circuit: '45',
      type: 'None',
    },
  ],
  'circuit:310': [
    {
      x: 5673.6996,
      y: 6227.5974,
      panel: '2L5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:311': [
    {
      x: 5543.931,
      y: 6381.3182,
      panel: '2L5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:312': [
    {
      x: 5544.2612,
      y: 6529.7812,
      panel: '2L5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:313': [
    {
      x: 5543.3468,
      y: 6608.2418,
      panel: '2L5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:314': [
    {
      x: 5524.1444,
      y: 6527.419,
      panel: '2L5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:315': [
    {
      x: 5523.6872,
      y: 6609.3086,
      panel: '2L5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:316': [
    {
      x: 5788.6854,
      y: 5429.9866,
      panel: '2ECL5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:317': [
    {
      x: 5785.5358,
      y: 5610.2504,
      panel: '2ECL5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:318': [
    {
      x: 5475.3764,
      y: 5830.4176,
      panel: '2ECL5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:319': [
    {
      x: 5474.8684,
      y: 5650.4332,
      panel: '2ECL5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:320': [
    {
      x: 5474.8938,
      y: 5377.5102,
      panel: '2ECL5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:321': [
    {
      x: 5474.081,
      y: 5198.7704,
      panel: '2ECL5',
      circuit: '46',
      type: 'None',
    },
  ],
  'circuit:322': [
    {
      x: 4330.3698,
      y: 3737.1782,
      panel: '2L5',
      circuit: '47',
      type: 'None',
    },
  ],
  'circuit:323': [
    {
      x: 4330.3444,
      y: 4071.366,
      panel: '2L5',
      circuit: '47',
      type: 'None',
    },
  ],
  'circuit:324': [
    {
      x: 6207.7346,
      y: 6740.3218,
      panel: '2L5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:325': [
    {
      x: 6083.2746,
      y: 6740.652,
      panel: '2L5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:326': [
    {
      x: 5777.2046,
      y: 6740.906,
      panel: '2L5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:327': [
    {
      x: 6314.7956,
      y: 6495.4404,
      panel: '2L5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:328': [
    {
      x: 6101.9944,
      y: 6127.9786,
      panel: '2L5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:329': [
    {
      x: 6202.1212,
      y: 6130.8488,
      panel: '2L5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:330': [
    {
      x: 6253.7086,
      y: 5751.0426,
      panel: '2ECL5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:331': [
    {
      x: 6080.3536,
      y: 5751.957,
      panel: '2ECL5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:332': [
    {
      x: 6254.2166,
      y: 5731.0528,
      panel: '2ECL5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:333': [
    {
      x: 6081.141,
      y: 5731.0528,
      panel: '2ECL5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:334': [
    {
      x: 6082.538,
      y: 5117.0332,
      panel: '2ECL5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:335': [
    {
      x: 6243.4978,
      y: 5116.6014,
      panel: '2ECL5',
      circuit: '48',
      type: 'None',
    },
  ],
  'circuit:336': [
    {
      x: 6115.304,
      y: 4480.433,
      panel: '2L5',
      circuit: '49',
      type: 'None',
    },
  ],
  'circuit:337': [
    {
      x: 6207.4552,
      y: 4480.8394,
      panel: '2L5',
      circuit: '49',
      type: 'None',
    },
  ],
  'circuit:338': [
    {
      x: 6131.2806,
      y: 4364.7868,
      panel: '2L5',
      circuit: '49',
      type: 'None',
    },
  ],
  'circuit:339': [
    {
      x: 6317.0054,
      y: 4372.737,
      panel: '2L5',
      circuit: '49',
      type: 'None',
    },
  ],
  'circuit:340': [
    {
      x: 6315.0496,
      y: 4205.478,
      panel: '2L5',
      circuit: '49',
      type: 'None',
    },
  ],
  'circuit:341': [
    {
      x: 6314.44,
      y: 4023.1314,
      panel: '2L5',
      circuit: '49',
      type: 'None',
    },
  ],
  'circuit:342': [
    {
      x: 4940.4778,
      y: 5689.0158,
      panel: '2L5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:343': [
    {
      x: 4869.9674,
      y: 5688.9142,
      panel: '2L5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:344': [
    {
      x: 6243.4216,
      y: 5095.0876,
      panel: '2ECL5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:345': [
    {
      x: 5614.0858,
      y: 4675.7082,
      panel: '2ECL5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:346': [
    {
      x: 5780.0748,
      y: 4676.775,
      panel: '2ECL5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:347': [
    {
      x: 6077.7882,
      y: 4479.3662,
      panel: '2ECL5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:348': [
    {
      x: 6244.9456,
      y: 4481.1696,
      panel: '2ECL5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:349': [
    {
      x: 6083.3762,
      y: 5095.5194,
      panel: '2ECL5',
      circuit: '50',
      type: 'None',
    },
  ],
  'circuit:350': [
    {
      x: 5182.0572,
      y: 5689.4476,
      panel: '2L5',
      circuit: '52',
      type: 'None',
    },
  ],
  'circuit:351': [
    {
      x: 5028.184,
      y: 5689.6762,
      panel: '2L5',
      circuit: '52',
      type: 'None',
    },
  ],
  'circuit:352': [
    {
      x: 5475.0716,
      y: 5792.7494,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:353': [
    {
      x: 4569.3838,
      y: 5795.391,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:354': [
    {
      x: 4568.9774,
      y: 5688.9142,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:355': [
    {
      x: 4751.1462,
      y: 5898.3372,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:356': [
    {
      x: 5474.3858,
      y: 5687.8474,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:357': [
    {
      x: 5291.9884,
      y: 5897.5244,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:358': [
    {
      x: 4948.4026,
      y: 5893.816,
      panel: '2L5',
      circuit: '54',
      type: 'None',
    },
  ],
  'circuit:359': [
    {
      x: 4569.5108,
      y: 5343.1186,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:360': [
    {
      x: 4568.6726,
      y: 5236.9974,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:361': [
    {
      x: 5475.0208,
      y: 5340.858,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:362': [
    {
      x: 5474.589,
      y: 5236.0576,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:363': [
    {
      x: 4762.2206,
      y: 5133.2384,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:364': [
    {
      x: 5027.8792,
      y: 5131.5366,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:365': [
    {
      x: 5284.1652,
      y: 5132.324,
      panel: '2L5',
      circuit: '56',
      type: 'None',
    },
  ],
  'circuit:366': [
    {
      x: 6219.5202,
      y: 5752.8714,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:367': [
    {
      x: 5801.995,
      y: 5450.1542,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:368': [
    {
      x: 6315.456,
      y: 5504.4848,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:369': [
    {
      x: 6117.8948,
      y: 5730.9258,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:370': [
    {
      x: 6219.7742,
      y: 5731.1544,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:371': [
    {
      x: 6116.9804,
      y: 5751.7792,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:372': [
    {
      x: 5786.2724,
      y: 5898.2102,
      panel: '2L5',
      circuit: '58',
      type: 'None',
    },
  ],
  'circuit:373': [
    {
      x: 6315.4306,
      y: 4906.4164,
      panel: '2L5',
      circuit: '60',
      type: 'None',
    },
  ],
  'circuit:374': [
    {
      x: 6313.551,
      y: 4661.5604,
      panel: '2L5',
      circuit: '60',
      type: 'None',
    },
  ],
  'circuit:375': [
    {
      x: 6118.1488,
      y: 5095.4178,
      panel: '2L5',
      circuit: '60',
      type: 'None',
    },
  ],
  'circuit:376': [
    {
      x: 6208.4458,
      y: 5096.3068,
      panel: '2L5',
      circuit: '60',
      type: 'None',
    },
  ],
  'circuit:377': [
    {
      x: 6117.9202,
      y: 5117.4904,
      panel: '2L5',
      circuit: '60',
      type: 'None',
    },
  ],
  'circuit:378': [
    {
      x: 6208.6744,
      y: 5116.8554,
      panel: '2L5',
      circuit: '60',
      type: 'None',
    },
  ],
};
