import React from 'react';

export const ScheduleIcon = ({ size = '13.5px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0775 0.0543047C11.2815 -4.50302e-06 10.2943 -2.52338e-06 9.02598 3.50376e-08H8.97402C7.7057 -2.52338e-06 6.71845 -4.50302e-06 5.92247 0.0543047C5.11485 0.109409 4.45368 0.222729 3.84636 0.474289C2.31964 1.10667 1.10667 2.31964 0.474289 3.84636C0.222729 4.45368 0.109409 5.11485 0.0543047 5.92247C-4.50302e-06 6.71845 -2.52338e-06 7.7057 3.50376e-08 8.97402V9.02598C-2.52338e-06 10.2943 -4.50302e-06 11.2815 0.0543047 12.0775C0.109409 12.8852 0.222729 13.5463 0.474289 14.1536C1.10667 15.6804 2.31964 16.8933 3.84636 17.5257C4.45368 17.7773 5.11485 17.8906 5.92247 17.9457C6.71845 18 7.70569 18 8.974 18H9.026C10.2943 18 11.2816 18 12.0775 17.9457C12.8852 17.8906 13.5463 17.7773 14.1536 17.5257C15.6804 16.8933 16.8933 15.6804 17.5257 14.1536C17.7773 13.5463 17.8906 12.8852 17.9457 12.0775C18 11.2816 18 10.2943 18 9.026V8.974C18 7.70569 18 6.71845 17.9457 5.92247C17.8906 5.11485 17.7773 4.45368 17.5257 3.84636C16.8933 2.31964 15.6804 1.10667 14.1536 0.474289C13.5463 0.222729 12.8852 0.109409 12.0775 0.0543047ZM9 3.24853C9.38235 3.24853 9.69231 3.55849 9.69231 3.94084V8.38572C9.69231 8.56368 9.7732 8.73198 9.91216 8.84315L12.6277 11.0156C12.9263 11.2545 12.9747 11.6901 12.7359 11.9887C12.497 12.2873 12.0614 12.3357 11.7628 12.0968L9.0472 9.92436C8.57979 9.55043 8.30769 8.9843 8.30769 8.38572V3.94084C8.30769 3.55849 8.61765 3.24853 9 3.24853Z'
        fill='#B9B9C3'
      />
    </svg>
  );
};
