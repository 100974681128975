export const styles = {
  modal: {
    bg: 'white',
    borderRadius: '32px',
    padding: '30px 45px 45px 45px',
  },
  modalHeader: {
    color: 'black.50',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 700,
    marginBottom: '24px',
    padding: '0px',
  },
  helperText: {
    color: '#313131',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '24px',
  },
  actionsBtnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    button: {
      height: '36px',
      maxWidth: '255,75px',
      width: '100%',
      outlineWidth: '1px',
      outlineOffset: '1px',
      borderWidth: '1px',
      '&:first-child': {
        marginBottom: '6px',
      },
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
  },
};
