import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ERoutes } from 'components/router/types';
import {
  MutationUpdateDrawingArgs,
  QueryGetDrawingsArgs,
  QueryGetDrawingsCategoriesResult,
  QueryGetDrawingsResult,
  QueryGetProjectsFetchParams,
  QueryGetProjectsQueryResult,
  QueryGetProjectsSourcesResult,
} from 'redux/slices/projects-api/projects-api.types';
import { TDrawing } from 'types/drawing';

export enum EProjectsApiTags {
  PROJECTS = 'Projects',
  DRAWINGS = 'Drawings',
}

export const ProjectsApiUrl = {
  PROJECTS: '/projects',
  SOURCES: (id: string) => `${ERoutes.PROJECTS}/${id}${ERoutes.SOURCES}`,
  SOURCE({ projectId, sourcesId }: { projectId: string; sourcesId: string }) {
    return `${this.SOURCES(projectId)}/${sourcesId}`;
  },
  DRAWINGS: (id: string) => `${ERoutes.PROJECTS}/${id}${ERoutes.DRAWINGS}`,
  DRAWING({ projectId, drawingId }: any) {
    return `${this.DRAWINGS(projectId)}/${drawingId}`;
  },
  DRAWING_PREVIEW: ({ projectId, drawingId }: { projectId: string; drawingId: number }) =>
    `${ERoutes.PROJECTS}/${projectId}${ERoutes.DRAWINGS}/${drawingId}/preview`,
  DRAWINGS_CATEGORIES: (id: string) =>
    `${ERoutes.PROJECTS}/${id}${ERoutes.DRAWINGS}${ERoutes.CATEGORIES}`,
};

export const projectsApiSlice = createApi({
  reducerPath: 'projectsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROJECTS_API,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem('ROCP_token');
      if (token) {
        headers.set('authorization', `Bearer ${token.replaceAll('"', '')}`);
      }
      return headers;
    },
  }),
  tagTypes: [EProjectsApiTags.PROJECTS, EProjectsApiTags.DRAWINGS],
  endpoints: (builder) => ({
    getProjects: builder.query<QueryGetProjectsQueryResult, QueryGetProjectsFetchParams>({
      query: (queryParams) => {
        return {
          url: ProjectsApiUrl.PROJECTS,
          params: queryParams,
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.meta = newItems.meta;
        currentCache.body = [...currentCache.body, ...newItems.body];
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: () => [EProjectsApiTags.PROJECTS],
    }),

    createProject: builder.mutation<any, any>({
      query: (formData) => ({
        url: ProjectsApiUrl.PROJECTS,
        method: 'POST',
        body: formData,
        invalidatesTags: [EProjectsApiTags.PROJECTS],
      }),
    }),
    uploadFiles: builder.mutation<any, any>({
      query({ id, files }: any): any {
        return {
          url: ProjectsApiUrl.SOURCES(id),
          method: 'POST',
          body: files,
        };
      },
    }),
    getProjectsSources: builder.query<QueryGetProjectsSourcesResult, string>({
      query(id) {
        return {
          url: ProjectsApiUrl.SOURCES(id),
          method: 'GET',
        };
      },
      providesTags: (result, error, projectId) => {
        if (result) {
          return result?.body?.map(({ id: sourceId }) => {
            return { type: EProjectsApiTags.PROJECTS, id: `${projectId}-${sourceId}` };
          });
        }
        return [EProjectsApiTags.PROJECTS];
      },
    }),

    getDrawings: builder.query<QueryGetDrawingsResult, QueryGetDrawingsArgs>({
      query({ id, category }) {
        return {
          url: ProjectsApiUrl.DRAWINGS(id),
          params: { size: 30, category },
          method: 'GET',
        };
      },
      providesTags: (result, error, projectId) => {
        return [EProjectsApiTags.DRAWINGS];
      },
    }),

    getDrawingsCategories: builder.query<QueryGetDrawingsCategoriesResult, string>({
      query(id) {
        return {
          url: ProjectsApiUrl.DRAWINGS_CATEGORIES(id),
          method: 'GET',
        };
      },
    }),

    updateDrawing: builder.mutation<TDrawing, MutationUpdateDrawingArgs>({
      query({ drawingId, projectId, body }) {
        return { url: ProjectsApiUrl.DRAWING({ drawingId, projectId }), method: 'PUT', body };
      },
      invalidatesTags: [EProjectsApiTags.DRAWINGS],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useCreateProjectMutation,
  useUploadFilesMutation,
  useGetProjectsSourcesQuery,
  useGetDrawingsQuery,
  useGetDrawingsCategoriesQuery,
  useUpdateDrawingMutation,
} = projectsApiSlice;
