import {
  Box,
  ChakraProps,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  SystemStyleObject,
} from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { EyeCloseIcon, InfoCircleIcon } from 'assets/icons';
import { forwardRef, RefObject, useState } from 'react';

import EyeIcon from '../../assets/icons/EyeIcon';
import { styles } from './styles';

export interface TextFieldProps extends InputProps {
  inputType?: string;
  wrapperProps?: ChakraProps;
  formControlStyles?: SystemStyleObject;
  errors?: Record<string, unknown>;
  hasError?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  iconHeight?: string;
  inputStyles?: SystemStyleObject;
}

const TextField = forwardRef(
  (
    {
      inputType = 'text',
      wrapperProps,
      hasError,
      inputStyles,
      iconHeight = '100%',
      formControlStyles,
      ...rest
    }: TextFieldProps,
    ref,
  ) => {
    const [currentInputType, setCurrentInputType] = useState(inputType);
    const [passwordVisibility, setPasswordVisiility] = useState(false);

    const handleChangePasswordVisibility = () => {
      setPasswordVisiility(!passwordVisibility);
      if (currentInputType === 'password') {
        setCurrentInputType('text');
      }
      if (currentInputType !== 'password') {
        setCurrentInputType('password');
      }
    };

    return (
      <FormControl isInvalid={hasError} sx={{ ...styles.inputWrapper, ...formControlStyles }}>
        <InputGroup sx={styles.groupStyle} {...wrapperProps}>
          <Input
            type={currentInputType}
            sx={{ ...styles.inputStyle, ...inputStyles }}
            variant='unstyled'
            padding={0}
            h='100%'
            ref={ref as RefObject<HTMLInputElement>}
            {...rest}
          />
          {inputType === 'password' ? (
            <InputRightElement h={iconHeight}>
              <IconButton
                variant='unstyled'
                icon={passwordVisibility ? <EyeCloseIcon /> : <EyeIcon />}
                onClick={handleChangePasswordVisibility}
                sx={styles.changeVisibilityBtn}
                aria-label='Show Hide Password'
              />
            </InputRightElement>
          ) : null}
          {Boolean(rest.name) && hasError && (
            <Box sx={styles.errorWrapper}>
              <InfoCircleIcon width='12.75px' height='13.5px' />
              <ErrorMessage
                errors={rest.errors}
                name={rest.name as string}
                as={FormErrorMessage}
                mt={0}
                fontFamily='Avenir Next'
                fontWeight={500}
                fontSize='12px'
              />
            </Box>
          )}
        </InputGroup>
      </FormControl>
    );
  },
);

export default TextField;
