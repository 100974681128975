import { Box } from '@chakra-ui/react';
import maskGroup from 'assets/images/mask-group.png';
import React from 'react';
import { HEXToRGB } from 'utils/rgb-hex';

interface ColorPickerContentProps {
  value: {
    color: string;
    alpha: number;
  };
}

export const ColorPickerContent = ({ value }: ColorPickerContentProps) => {
  return (
    <>
      <Box bg={`url(${maskGroup})`} borderRadius='2px' display='flex' boxSize='15px'>
        <Box
          w='7.5px'
          h='15px'
          borderTopLeftRadius='2px'
          borderBottomLeftRadius='2px'
          bgColor={HEXToRGB(value?.color)}
        />
        <Box
          w='7.5px'
          h='15px'
          borderTopRightRadius='2px'
          borderBottomRightRadius='2px'
          bgColor={HEXToRGB(value?.color, value?.alpha?.toString())}
        />
      </Box>
      <Box m='0px 10.5px 0px 4px'>{value.color} </Box>
      {value.alpha * 100}%
    </>
  );
};
