import { WebViewerInstance } from '@pdftron/webviewer';
import { ReactNode, useMemo, useState } from 'react';

import { WebviewerContext } from './webviewer-context';

type ProviderProps = {
  children: ReactNode;
};

const WebviewerProvider = ({ children }: ProviderProps) => {
  const [instance, setInstanceContext] = useState<WebViewerInstance | null>(null);
  const value = useMemo(() => ({ instance, setInstanceContext }), [instance]);

  return <WebviewerContext.Provider value={value}>{children}</WebviewerContext.Provider>;
};

export default WebviewerProvider;
