import { Flex, Text } from '@chakra-ui/react';
import { styles } from 'pages/projects/components/project-info-section/project-card-info-section.styles';
import React from 'react';
import { cropText } from 'utils';

export function ProjectInfoSectionItem({
  handleLinkClick,
  text,
  icon,
}: {
  text?: string;
  icon: JSX.Element;
  handleLinkClick: VoidFunction;
}) {
  return (
    <Flex>
      {icon}
      <Text sx={styles.cardInfoText} onClick={handleLinkClick}>
        {cropText(text || '', 19)}
      </Text>
    </Flex>
  );
}
