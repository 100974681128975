import { Box, Button, Text } from '@chakra-ui/react';
import { InfoCircleIcon } from 'assets/icons';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { styles } from './styles';

export const ProjectReportForms = () => {
  const params = useParams();

  const getButtonTitle = (status: string | undefined) => {
    if (status === 'add') {
      return 'Add info';
    }
    if (status === 'fix') {
      return 'Fix report';
    }
    return 'Edit info';
  };

  const formArray = [
    {
      id: 1,
      title: 'Sheet Name/Number',
      status: 'add',
      link: `/projects/${params.id}/drawings/${params.drawingId}/${params.sheetId}`,
    },
    {
      id: 2,
      title: 'Floors Height',
      status: 'fix',
      link: `/projects/${params.id}/drawings/${params.drawingId}/${params.sheetId}?tab=floors-height`,
    },
    {
      id: 3,
      title: 'Feeder Schedule',
      status: 'edit',
      link: `/projects/${params.id}/drawings/${params.drawingId}/${params.sheetId}?tab=feeder-schedule`,
    },
    {
      id: 4,
      title: 'Scale',
      status: 'add',
      link: `/projects/${params.id}/drawings/${params.drawingId}/${params.sheetId}`,
    },
    {
      id: 5,
      title: 'SLD Location',
      status: 'add',
      link: `/projects/${params.id}/drawings/${params.drawingId}/${params.sheetId}?tab=sld-location`,
    },
  ];

  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Box sx={styles.formReports}>
        <Text sx={styles.newReportTitle}>Report title</Text>
        {formArray.map((item) => {
          const statusStyleWrapper = () => {
            if (item.status === 'add') {
              return 'add-wrapper';
            }
            if (item.status === 'fix') {
              return 'fix-wrapper';
            }

            return 'edit-wrapper';
          };
          return (
            <Box key={item.id} sx={styles.formContainer} className={statusStyleWrapper()}>
              <Box sx={styles.formContent}>
                <Box display='flex' alignItems='center'>
                  <Box sx={styles.formIconWrapper} className='icon-wrapper'>
                    <InfoCircleIcon width='12.75px' height='13.5px' />
                  </Box>
                  {item.title}
                </Box>
                <Link to={item.link}>
                  <Button variant='primary' sx={styles.formButton}>
                    {getButtonTitle(item.status)}
                  </Button>
                </Link>
              </Box>
              {item.status === 'fix' && (
                <Box sx={styles.helperText}>Some drawings miss sheet name and/or sheet number.</Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
