import { State } from 'types/redux';

export const isApplicationDirty = (state: State) => state.application.dirty;

export const getSelectedTool = (state: State) => state.application.selectedTool;

export const getDetectionStatus = (state: State) => state.application.areAnnotationsDetected;

export const isDocumentLoaded = (state: State) => state.application.isDocumentLoaded;
export const getWorkMode = (state: State) => state.application.workMode;
