import { pxToRem } from 'utils';

export const sheetCardWrapperActiveStyles = {
  borderColor: 'purple.100',
  height: '352.5px',
  zIndex: 1,
  background: 'white',
  boxShadow: '0px 4px 24px rgba(34, 41, 47, 0.1)',
  transition: '0.1s',
  position: 'absolute',
};

export const styles = {
  drawingsBlockWrapper: {
    border: '1px solid #7368F0',
    background: 'white',
    borderRadius: '4.5px',
    width: '100%',
    height: '90%',
    padding: '18.75px 12px 18.75px 30px',
  },
  scrollWrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4.5px',
    },
    '&::-webkit-scrollbar-track': {
      width: '4.5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: '9px',
    },
    position: 'relative',
  },

  sheetCardWrapper: {
    width: '195px',
    height: '210px',
    borderRadius: '9px',
    border: '1px solid #F3F3F3',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '18px 18px 12px',
    position: 'relative',
    overflow: 'hidden',
    _hover: sheetCardWrapperActiveStyles,
  },
  expandableBlock: {
    flexDirection: 'column',
    marginTop: '24px',
    width: '100%',
  },
  sheetCardImageWrapper: {
    minHeight: '120px',
    width: '100%',
    height: '120px',
    borderRadius: '4.5px',
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  sheetCardImage: {
    minHeight: 'inherit',
    minWidth: 'inherit',
    height: 'inherit',
    width: 'inherit',
    objectFit: 'cover',
    borderRadius: 'inherit',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  sheetCardNameText: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '93.75%',
    color: 'purple.100',
    alignSelf: 'flex-start',
    marginBottom: '12px',
    cursor: 'pointer',
  },
  sheetCardTypeText: {
    fontWeight: 600,
    fontSize: '10.5px',
    lineHeight: '90%',
    color: 'gray.500',
    marginLeft: '6px',
  },
  sheetCardDivider: {
    width: '100%',
    background: '#ECECEC',
    height: pxToRem(1),
    marginY: '6px',
  },
  sheetAttributeNameText: {
    lineHeight: '107.25%',
    fontSize: '10.5px',
    color: 'gray.500',
  },
  sheetAttributeValueText: {
    lineHeight: '107.25%',
    fontSize: '10.5px',
    fontWeight: 600,
  },
};
