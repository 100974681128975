import { APIDrawingCategories } from 'pages/projects/project/drawings/card-list/constants';
import { useParams } from 'react-router-dom';
import { useGetDrawingsCategoriesQuery } from 'redux/slices';
import { TDrawingCategory } from 'redux/slices/projects-api/projects-api.types';
import { TAPIDrawingCategories } from 'types/drawing';

type TSourceStatisctics = { [key in TAPIDrawingCategories]: number };

export const useGetSourceStatistics = (): {
  sourceData?: TSourceStatisctics;
  isLoading: boolean;
} => {
  const params = useParams();
  const { id: projectId = '' } = params;
  const { data: sourceStatistics, isLoading } = useGetDrawingsCategoriesQuery(projectId, {
    refetchOnMountOrArgChange: true,
  });
  const sourceData = sourceStatistics?.reduce(
    (acc: TSourceStatisctics, source: TDrawingCategory) => {
      acc[source.category] = source.count;
      return acc;
    },
    {
      [APIDrawingCategories.unknown]: 0,
      [APIDrawingCategories.general]: 0,
      [APIDrawingCategories.electrical]: 0,
      [APIDrawingCategories.architectural]: 0,
    },
  );
  return { sourceData, isLoading };
};
