import { ERoutes } from 'components/router/types';
import ProjectLayout from 'layouts/project-layout';
import { ProjectAllReports } from 'pages/projects/components/project-reports';
import { ProjectReportForms } from 'pages/projects/components/project-reports/report-forms';
import { ProjectReportSheet } from 'pages/projects/components/project-reports/report-sheet';
import { PageHeaderReport, PageHeaderReportId } from 'pages/projects/project/headers';
import { PageHeaderReportSheet } from 'pages/projects/project/headers/page-header-report-sheet';
import React from 'react';
import { Route } from 'react-router-dom';

const reportPath = ERoutes.PROJECTS + ERoutes.ID + ERoutes.REPORTS;

export const ReportsRoutes = [
  <Route
    key='project-reports'
    path={reportPath}
    element={
      <ProjectLayout header={<PageHeaderReport />} sidebarProps={{ minimizedEnabled: true }}>
        <ProjectAllReports />
      </ProjectLayout>
    }
  />,
  <Route
    key='project-reportsId'
    path={reportPath + ERoutes.REPORT_ID}
    element={
      <ProjectLayout header={<PageHeaderReportId />} sidebarProps={{ minimizedEnabled: true }}>
        <ProjectReportForms />
      </ProjectLayout>
    }
  />,
  <Route
    key='project-reports-new'
    path={reportPath + ERoutes.REPORT_ID}
    element={
      <ProjectLayout header={<PageHeaderReportId />} sidebarProps={{ minimizedEnabled: true }}>
        <ProjectReportForms />
      </ProjectLayout>
    }
  />,
  <Route
    key='project-reports-sheet'
    path={reportPath + ERoutes.REPORT_ID + ERoutes.SHEET_ID}
    element={
      <ProjectLayout
        header={<PageHeaderReportSheet />}
        sidebarProps={{ minimizedEnabled: true }}
        contentOverflowX='hidden'
      >
        <ProjectReportSheet />
      </ProjectLayout>
    }
  />,
];
