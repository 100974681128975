import React from 'react';

const CheckIcon = ({ fill = '#fff', size = '19px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill={fill}
    >
      <path fill='none' d='M0 0h24v24H0z'></path>
      <path d='M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z'></path>
    </svg>
  );
};

export default CheckIcon;
