import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnotations } from 'redux/selectors';
import { applicationActions } from 'redux/slices';
import { ApryseAnnotation } from 'types';
import { drawAnnotations } from 'utils';

import { useCreateCustomTools } from './useCreateCustomTools';
import { useEnableCreationOverAnnotation } from './useEnableCreationOverAnnotation';
import { useRemoveUnusedElements } from './useRemoveUnusedElements';
import { useSetupScale } from './useSetupScale';

export const useDocumentLoadedListener = () => {
  const { instance } = useContext(WebviewerContext);

  const annotationEntities = useSelector(getAnnotations);
  const dispatch = useDispatch();

  const handleRemoveUnusedElements = useRemoveUnusedElements();
  const handleCreateCustomTools = useCreateCustomTools();
  const handleSetupScale = useSetupScale();
  const handleEnableCreationOverAnnotation = useEnableCreationOverAnnotation();

  const handler = useCallback(() => {
    if (instance) {
      // Set min zoom level value
      instance.UI.setMinZoomLevel(0.05);

      handleRemoveUnusedElements();
      handleSetupScale();
      handleEnableCreationOverAnnotation();
      handleCreateCustomTools();

      dispatch(
        applicationActions.setDocumentLoaded({
          isDocumentLoaded: false,
        }),
      );

      drawAnnotations({
        viewerInstance: instance,
        annotationList: annotationEntities[1] as ApryseAnnotation[],
      });
    }
  }, [
    annotationEntities,
    dispatch,
    handleCreateCustomTools,
    handleEnableCreationOverAnnotation,
    handleRemoveUnusedElements,
    handleSetupScale,
    instance,
  ]);

  useEffect(() => {
    if (instance) {
      instance.Core.documentViewer.addEventListener('documentLoaded', handler, { once: true });
    }
    return () => {
      if (instance) {
        instance.Core.documentViewer.removeEventListener('documentLoaded', handler);
      }
    };
  }, [handler, instance]);
};
