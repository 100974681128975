import { WEBKIT_SCROLLBAR_GRAY } from 'constants/styles';

export const styles = {
  tabList: {
    display: 'flex',
    flexDirection: 'column',
    w: '45px',
    minW: '45px',
    marginRight: '9px',
    background: 'white',
    borderRadius: '4.5px',
    padding: '18px 7.5px 0px 7.5px',
    boxShadow: '0px 4px 24px 0px #22292F1A',
    overflowY: 'auto',
    ...WEBKIT_SCROLLBAR_GRAY,
    // 132px is height of two headers and padding of container
    height: 'inherit',
  },
  tabListButton: (isActive: boolean) => ({
    width: '30px',
    minWidth: '30px',
    height: '30px',
    background: isActive ? '#f1f0fd' : 'gray.600',
    display: 'flex',
    marginBottom: '9px',
    _disabled: {
      background: '#FBFBFD',
      cursor: 'not-allowed',
      '& svg path, & svg circle, & svg rect': {
        fill: '#E8E7EF',
      },
    },
    '& svg path, & svg circle, & svg rect': {
      fill: isActive ? 'purple.100' : '#AEADBC',
    },
  }),
  tabListDivider: (isActive: boolean) => ({
    borderColor: isActive ? 'purple.100' : '#AEADBC',
  }),
  tabPanel: {
    background: 'white',
    padding: '25.5px 18px 18px 18px',
    borderRadius: '6px',
    minWidth: '231.5px',
    maxWidth: '231.5px',
    overflowY: 'auto',
    marginRight: '9px',
    ...WEBKIT_SCROLLBAR_GRAY,
    '& .chakra-tabs__tab-panel': {
      padding: '0px',
    },
  },
  tabPanelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: '0px 0px 10.5px 0px',
    h: '33px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16.5px',
    color: 'purple.100',
    borderBottom: '1px solid #7368F0',
  },
  addButton: {
    padding: '0px',
    minWidth: 'unset',
    h: 'unset',
    bg: 'transparent',
    marginRight: '6px',
    '&:hover, &:focus': {
      bg: 'transparent',
    },
    svg: {
      minWidth: '15px',
      minHeight: '15px',
    },
  },
  closeButton: {
    padding: '0px',
    minWidth: 'unset',
    h: 'unset',
    bg: 'transparent',
    '&:hover, &:focus': {
      bg: 'transparent',
    },
    svg: {
      minWidth: '15px',
      minHeight: '15px',
    },
  },
  tabPanelWrapper: {
    w: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
  tabPanelContent: {
    display: 'flex',
    w: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  apryseWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
  },
  drawingsWrapper: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',
    padding: '13.5px',
    backdropFilter: 'blur(1px)',
  },
};
