import { Button, Flex, IconButton, ModalBody, ModalFooter, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from 'assets/icons/AddIcon';
import SelectRHF from 'components/react-hook-form/select-rhf';
import TextFieldRHF from 'components/react-hook-form/textfield-rhf';
import { FormProvider, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments } from 'redux/selectors';
import { annotationActions, layerActions } from 'redux/slices';
import { EditLayerData, Layer, LayerData } from 'types/redux';
import { createLayer, editLayer } from 'validation/schemas/manage-layer.schema';

import { styles } from './styles';

interface FormContentProps {
  isEditMode: boolean;
  onClose: () => void;
  currentDocumentId: number;
  layerToEdit?: Layer;
}

const FormContent = ({ isEditMode, onClose, currentDocumentId, layerToEdit }: FormContentProps) => {
  const dispatch = useDispatch();
  const documentEntities = useSelector(getDocuments);

  const DEFAULT_VALUES = {
    layerName: layerToEdit?.name || '',
    documentId: '',
  };

  const methods = useForm<LayerData>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(isEditMode ? editLayer : createLayer),
    mode: 'onBlur',
  });

  const handleCreateLayer = (data: LayerData) => {
    const layerId = new Date().getTime();
    dispatch(
      layerActions.createLayer({
        documentId: currentDocumentId,
        layer: {
          id: layerId,
          name: data.layerName,
          visible: true,
          annotations: [],
        },
      }),
    );

    dispatch(
      layerActions.setActiveLayer({
        layerInfo: {
          layerId,
          layerName: data.layerName,
        },
      }),
    );

    onClose();
  };

  const handleEditLayer = (data: EditLayerData) => {
    dispatch(
      layerActions.renameLayer({
        documentId: currentDocumentId,
        layerId: layerToEdit?.id as number,
        name: data.layerName,
      }),
    );
    const annotations = layerToEdit?.annotations.map((annotation) => {
      const updatedAnnotation = { ...annotation };
      Object.assign(updatedAnnotation, {
        data: { layer: data.layerName },
      });
      return updatedAnnotation;
    });
    dispatch(
      annotationActions.modifyAnnotations({
        documentId: currentDocumentId,
        annotations: annotations as any[],
      }),
    );
    onClose();
  };

  const onSubmit = (data: LayerData | EditLayerData) => {
    if (isEditMode) {
      handleEditLayer(data);
    } else {
      handleCreateLayer(data);
    }
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalBody>
            <VStack spacing='22.5px'>
              <Flex sx={styles.layerFormFieldWrapper}>
                <Text sx={styles.layerFieldLabel}>Layer Name</Text>
                <TextFieldRHF
                  name='layerName'
                  placeholder='Layer name'
                  wrapperProps={{ height: '30px' }}
                />
              </Flex>
              {!isEditMode ? (
                <Flex sx={styles.layerFormFieldWrapper}>
                  <Text sx={styles.layerFieldLabel}>Document</Text>
                  <SelectRHF
                    name='documentId'
                    placeholder='Select document appearance'
                    wrapperProps={{ height: '30px' }}
                  >
                    {Object.values(documentEntities).map((document, idx) => (
                      <option key={idx} value={document.id}>
                        {document.name}
                      </option>
                    ))}
                  </SelectRHF>
                </Flex>
              ) : null}
            </VStack>
          </ModalBody>
          <ModalFooter mt='2'>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <IconButton
              colorScheme='purple'
              type='submit'
              ml='2.25px'
              px='3px'
              icon={
                isEditMode ? (
                  <>
                    <FaSave size='18px' />
                    <Text ml='6px'>Save</Text>
                  </>
                ) : (
                  <>
                    <AddIcon size='18px' fill='#fff' />
                    <Text ml='6px'>Create</Text>
                  </>
                )
              }
              aria-label='Add custom'
            />
          </ModalFooter>
        </form>
      </FormProvider>
    </>
  );
};

export default FormContent;
