import React from 'react';

export const PanelsIcon = ({ size = '15px' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.0246 11.7727C17.6903 11.6396 17.3431 11.5868 16.9676 11.5622C16.8774 11.5563 16.7893 11.5893 16.7254 11.6508L9.97361 18.1429C9.76144 18.3469 9.64224 18.6236 9.64224 18.9121C9.64224 19.513 10.1488 20 10.7736 20H15.6274C16.1632 20 16.6055 20 16.9676 19.9763C17.3431 19.9516 17.6903 19.8989 18.0246 19.7658C18.8087 19.4535 19.4316 18.8545 19.7564 18.1006C19.8949 17.7792 19.9497 17.4452 19.9753 17.0843C20 16.7361 20 16.3108 20 15.7956V15.7429C20 15.2276 20 14.8024 19.9753 14.4542C19.9497 14.0932 19.8949 13.7593 19.7564 13.4379C19.4316 12.684 18.8087 12.085 18.0246 11.7727Z'
        fill='#B9B9C3'
      />
      <path
        d='M11.4496 3.29836C11.1761 3.52775 10.8634 3.82848 10.4845 4.1928L10.3452 4.32674C9.8951 4.75952 9.64224 5.34649 9.64224 5.95853V15.6691C9.64224 16.1488 10.2454 16.3891 10.5982 16.0499L16.7071 10.176C17.0859 9.81168 17.3987 9.51097 17.6372 9.248C17.8846 8.97533 18.0914 8.70193 18.2298 8.38054C18.5546 7.6266 18.5546 6.77949 18.2298 6.02556C18.0914 5.70416 17.8846 5.43077 17.6372 5.1581C17.3987 4.89512 17.0859 4.59441 16.7071 4.23011L16.6682 4.19278C16.2894 3.82847 15.9766 3.52775 15.7031 3.29836C15.4195 3.06052 15.1352 2.86168 14.801 2.72856C14.0169 2.41627 13.1359 2.41627 12.3518 2.72856C12.0175 2.86168 11.7332 3.06052 11.4496 3.29836Z'
        fill='#B9B9C3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.76764 0.0237425C5.40555 -1.22376e-05 4.96325 -6.64455e-06 4.42742 2.32911e-07H4.37259C3.83676 -6.64455e-06 3.39446 -1.22376e-05 3.03237 0.0237425C2.65694 0.0483726 2.30966 0.101091 1.97541 0.234217C1.19132 0.546506 0.568367 1.1455 0.243586 1.89944C0.105135 2.22083 0.0503075 2.55475 0.0246923 2.91574C-1.27349e-05 3.2639 -6.81964e-06 3.68919 2.37554e-07 4.20441L8.56868e-05 15.7956C7.86296e-05 16.3108 7.27158e-05 16.7361 0.0247777 17.0843C0.050393 17.4452 0.10522 17.7792 0.243671 18.1006C0.568452 18.8545 1.19141 19.4535 1.9755 19.7658C2.30975 19.8989 2.65703 19.9516 3.03246 19.9763C3.39454 20 3.83684 20 4.37267 20H4.4275C4.96334 20 5.40563 20 5.76772 19.9763C6.14315 19.9516 6.49042 19.8989 6.82467 19.7658C7.60876 19.4535 8.23172 18.8545 8.5565 18.1006C8.69495 17.7792 8.74978 17.4452 8.7754 17.0843C8.8001 16.7361 8.8001 16.3108 8.80009 15.7956L8.8 4.2044C8.80001 3.68918 8.80001 3.2639 8.77531 2.91574C8.74969 2.55475 8.69487 2.22083 8.55642 1.89944C8.23163 1.1455 7.60868 0.546506 6.82459 0.234217C6.49034 0.101091 6.14306 0.0483726 5.76764 0.0237425ZM4.8 16.1538H4C3.55818 16.1538 3.2 16.4982 3.2 16.9231C3.2 17.3479 3.55818 17.6923 4 17.6923H4.8C5.24183 17.6923 5.60001 17.3479 5.60001 16.9231C5.60001 16.4982 5.24183 16.1538 4.8 16.1538Z'
        fill='#B9B9C3'
      />
    </svg>
  );
};
