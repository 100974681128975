import { UPPER_TOOLBAR_ZONES_INDEX } from 'constants/apryse';

import {
  PANEL_TYPE_DATA,
  PANELS,
  POLYGON_TOOL_GROUP_BUTTON,
  TOOL_BUTTON,
  TOOLBAR_GROUP,
} from '../constants';
import { AddPanelToolToPanelParams } from './types';

export const addPanelToolToPanel = ({ UI, tool, panelType }: AddPanelToolToPanelParams) => {
  UI.registerTool({
    ...PANEL_TYPE_DATA[panelType as PANELS],
    toolObject: tool,
  });

  UI.setHeaderItems((header) => {
    header
      .getHeader(TOOLBAR_GROUP.SHAPES)
      .get(POLYGON_TOOL_GROUP_BUTTON)
      .insertBefore({
        type: TOOL_BUTTON,
        toolName: PANEL_TYPE_DATA[panelType as PANELS].toolName,
      });
  });

  UI.setHeaderItems((header) => {
    const customPanelButton = {
      type: TOOL_BUTTON,
      toolName: PANEL_TYPE_DATA[panelType as PANELS].toolName,
    };
    const items = header.getItems();
    items.splice(UPPER_TOOLBAR_ZONES_INDEX, 0, customPanelButton);
  });
};
