interface IconProps {
  fill?: string;
  size?: string;
  style?: any;
}

const SearchIcon = ({ fill, size = '20px', style }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
      style={style}
    >
      <path
        fill={fill}
        d='M9.167 1.667c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5zm0 13.333A5.832 5.832 0 0015 9.167a5.832 5.832 0 00-5.833-5.834 5.831 5.831 0 00-5.834 5.834A5.832 5.832 0 009.167 15zm7.07.06l2.358 2.356-1.18 1.18-2.356-2.358 1.179-1.179z'
      ></path>
    </svg>
  );
};

export default SearchIcon;
