export const styles = {
  cardCreateReport: {
    maxWidth: '195px',
    width: '100%',
    padding: '34.68px 18px 9.18px 18px',
    backgroundImage:
      'repeating-linear-gradient(21deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px), repeating-linear-gradient(111deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px), repeating-linear-gradient(201deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px), repeating-linear-gradient(291deg, #7368f0, #7368f0 18px, transparent 18px, transparent 22px, #7368f0 22px)',
    backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px',
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '8px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px',
    color: 'purple.100',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '72px',
    marginBottom: '15px',
    '& .chakra-text': {
      margin: '31.5px 0px 12px 0px',
    },
    '& .chakra-button': {
      height: '36px',
      fontSize: '10.5px',
      fontWeight: 500,
      lineHeight: '18px',
    },
  },
  cardPreviewReport: {
    width: '195px',
    backgroundColor: '#ffffff',
    border: '1px solid #E8E7EF',
    borderRadius: '8px',
    padding: '18px 18px 12.375px 18px',
    margin: '0px 33px 15px 0px',
  },
  cardPreviewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px',
    color: 'purple.100',
  },
  cardPreviewTitle: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      minWidth: '15px',
      minHight: '15px',
      marginRight: '6px',
      marginTop: '-1px',
    },
    p: {
      width: '118.5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  projectInfoWrapper: {
    margin: '15px 0px 21px 0px',
  },
  projectInfo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '15px',
    color: 'gray.400',
    marginBottom: '6px',
    svg: {
      minWidth: '15px',
      minHeight: '15px',
      marginRight: '6px',
    },
    strong: {
      marginRight: '3.75px',
    },
  },
  progress: {
    borderRadius: '100px',
    width: '100%',
    height: '13.875px',
  },
  cardPreviewButton: {
    height: '36px',
    marginTop: '12px',
    fontWeight: 600,
    fontSize: '10.5px',
    lineHeight: '18px',
  },

  formReports: {
    maxW: '333px',
    width: '100%',
  },
  newReportTitle: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '21px',
    color: 'black.50',
    margin: '37.5px 0px 30px 0px',
    textAlign: 'center',
  },
  formContainer: {
    width: '100%',
    border: '1px solid #7368F0',
    borderRadius: '8px',
    background: 'white',
    padding: '18px 18px 18px 24px',
    fontSize: '13.5px',
    fontWeight: 600,
    lineHeight: '16.5px',
    color: 'black.50',
    marginBottom: '18px',
    '& svg path': {
      fill: 'purple.100',
    },
    '&.fix-wrapper': {
      borderColor: 'red.200',
      button: {
        backgroundColor: 'red.200',
      },
      '& .icon-wrapper': {
        backgroundColor: '#ffeced',
        '& svg path': {
          fill: 'red.200',
        },
      },
    },
    '&.edit-wrapper': {
      borderColor: 'green.200',
      button: {
        border: '1px solid',
        borderColor: 'green.200',
        backgroundColor: 'white',
        color: 'green.200',
      },
      '& .icon-wrapper': {
        backgroundColor: '#eaf7eb',
        '& svg path': {
          fill: 'green.200',
        },
      },
    },
  },
  formContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formIconWrapper: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    marginRight: '12.75px',
    backgroundColor: '#edebff',
  },
  formButton: {
    width: '75px',
    height: '36px',
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '18px',
    color: 'white',
  },
  helperText: {
    fontFamily: 'DM Sans',
    fontSize: '10.5px',
    fontWeight: 400,
    lineHeight: '22.5px',
    color: '#6F6C90',
    marginTop: '18px',
  },
  reportTable: {
    border: '1px solid',
    borderColor: '#EFEEFF',
    borderBottom: 'unset',
    '& tbody tr td': {
      background: '#F3F2FA',
      border: '1px solid #EFEEFF',
      borderLeft: 'none',
      borderRight: 'none',
      fontSize: '9px',
      fontWeight: 500,
      lineHeight: '14.25px',
      color: 'black.50',
    },
    '& tbody tr:nth-of-type(odd) td': {
      background: 'white',
      border: 'none',
    },
  },
  reportThead: {
    height: '47.25px',
    backgroundColor: '#EFEEFF',
    th: {
      fontSize: '9px',
      fontWeight: 500,
      lineHeight: '14.25px',
      color: 'black.50',
      textTransform: 'unset',
      padding: '0px 15px 0px 13.5px',
      border: 'none',
    },
  },
  reportTableContainer: {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    scrollbarColor: 'gray.350 #F3F2FA',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      width: '4.5px',
      height: '4.5px',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #F3F2FA',
      borderRadius: '4px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.350',
      borderRadius: '4px',
    },
  },
};
