import { WebViewerInstance } from '@pdftron/webviewer';
import { createContext, Dispatch, SetStateAction } from 'react';

type WebviewerContextType = {
  instance: WebViewerInstance | null;
  setInstanceContext: Dispatch<SetStateAction<WebViewerInstance | null>> | (() => void);
};

export const WebviewerContext = createContext<WebviewerContextType>({
  instance: null,
  setInstanceContext: () => {},
});
