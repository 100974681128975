const GalleryIcon = ({ size = '20px' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill='#000'
        d='M4.023 17.5l-.016.017-.018-.017H2.493a.827.827 0 01-.826-.828V3.327a.833.833 0 01.826-.827h15.014c.456 0 .826.37.826.828v13.345a.833.833 0 01-.826.827H4.023zm12.644-5V4.167H3.333v11.666L11.667 7.5l5 5zm0 2.357l-5-5-5.977 5.976h10.977v-.976zm-10-5.69a1.667 1.667 0 110-3.334 1.667 1.667 0 010 3.334z'
      ></path>
    </svg>
  );
};

export default GalleryIcon;
