import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  ToastMessage,
  useToast,
} from '@chakra-ui/react';
import { CheckboxCircleFillWhiteIcon } from 'assets/icons';

import DeleteToast from './delete-toast';
import { styles } from './styles';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const DeleteModal = ({ isOpen, onClose }: DeleteModalProps) => {
  const toast = useToast({
    render: DeleteToast as ToastMessage,
  });

  const handleDelete = () => {
    onClose();
    toast({
      position: 'top',
      description: 'Project was deleted',
      icon: <CheckboxCircleFillWhiteIcon size='24px' />,
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        p='30px 96.75px 45px'
        borderRadius='32px'
        top='50%'
        m='0'
        maxW='449px'
        transform='translateY(-50%)!important'
      >
        <ModalHeader sx={styles.modalHeader}>
          Are you sure that you want to delete this project?
        </ModalHeader>
        <ModalBody>
          <Text sx={styles.helperText}>
            If you delete a project, it will be impossible to restore it.
          </Text>
          <Box sx={styles.actionsBtnWrapper}>
            <Button variant='primary' onClick={handleDelete}>
              Delete
            </Button>
            <Button variant='outline' onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
