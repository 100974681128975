import { State } from '../../types/redux';

export const getSelectedAnnotations = (state: State, { documentId }: { documentId: number }) =>
  state.annotations.selectedAnnotations[documentId];

export const getAnnotations = (state: State) => state.annotations.annotations;

export const isAnnotationDetailsOpen = (state: State) => state.annotations.isAnnotationDetailsOpen;

export const isAnnotationListOpen = (state: State) => state.annotations.isAnnotationsListOpen;

export const getAnnotationById = (
  state: State,
  { documentId, annotationId }: { documentId: number; annotationId: string },
) =>
  state.annotations.annotations?.[documentId]?.find(
    (annotattion) => annotationId === annotattion.id,
  );
