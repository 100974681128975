import { ReactNode, useEffect, useState } from 'react';

export const useIntersection = (element: any, rootMargin?: string, children?: ReactNode) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin },
    );
    // eslint-disable-next-line no-unused-expressions
    element?.current && observer.observe(element.current);

    return () => {
      observer.disconnect();
    };
  }, [element, isVisible, setState, rootMargin, children]);

  return isVisible;
};
