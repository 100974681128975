import { Box } from '@chakra-ui/react';
import { FolderIcon, FolderOpen } from 'assets/icons';
import React from 'react';

import { styles } from './styles';

interface DrawingItemProps {
  isActive: boolean;
  handleClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  index: number;
  item: {
    to: string;
    label: string;
  };
}

export const DrawingItem = ({ isActive, handleClick, item, index }: DrawingItemProps) => {
  return (
    <Box
      key={item.to}
      sx={{
        ...styles.sidebarContent,
        ...(isActive && styles.sidebarContentActive),
      }}
      onClick={handleClick}
      data-index={index}
    >
      {isActive ? <FolderOpen /> : <FolderIcon fill='#000' />}
      {item.label}
    </Box>
  );
};
