import { Box, Button, Progress, Text } from '@chakra-ui/react';
import {
  AddCircleIcon,
  AddressIcon,
  CheckboxCircleFillIcon,
  FileAddIcon,
  UserIcon,
} from 'assets/icons';
import { REPORTS } from 'mock-data';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ProjectCardMenu } from '../menu/project-card-menu';
import { styles } from './styles';

export const ProjectAllReports = () => {
  const navigate = useNavigate();
  const params = useParams();

  const handleCreateReport = () => {
    navigate(`/projects/${params.id}/reports/${params.reportId}`);
  };

  const handleContinue = () => {
    navigate(`/projects/${params.id}/reports/${params.reportId}`);
  };
  return (
    <Box maxH='451.5px'>
      <Box display='flex' flexWrap='wrap'>
        {/* Card Create Report */}
        <Box sx={styles.cardCreateReport}>
          <FileAddIcon width='61.5px' height='62.25px' />
          <Text>Conduit Schedule Report</Text>
          <Button variant='primary' width='100%' onClick={handleCreateReport}>
            Create
          </Button>
        </Box>
        {/* End Of Card Create Report */}
        {/* Preview Card Report */}
        {REPORTS.map((item) => {
          const fullProgress = item.progress === 100;
          return (
            <Box key={item.id} sx={styles.cardPreviewReport}>
              <Box sx={styles.cardPreviewHeader}>
                <Box sx={styles.cardPreviewTitle}>
                  {fullProgress && <CheckboxCircleFillIcon width='15px' height='15.75px' />}
                  <Text>{item.name}</Text>
                </Box>
                <ProjectCardMenu handleEdit={handleContinue} />
              </Box>
              <Box sx={styles.projectInfoWrapper}>
                <Box sx={styles.projectInfo}>
                  <AddCircleIcon size='15px' />
                  <Text>
                    <strong>Created:</strong> {item.created}
                  </Text>
                </Box>
                <Box sx={styles.projectInfo}>
                  <UserIcon size='15px' />
                  <Text>
                    <strong>User:</strong> {item.user}
                  </Text>
                </Box>
                <Box sx={styles.projectInfo}>
                  <AddressIcon size='15px' />
                  <Text>
                    <strong>Status:</strong> {item.status}
                  </Text>
                </Box>
              </Box>
              <Progress value={item.progress} sx={styles.progress} />
              {fullProgress ? (
                <Button
                  variant='outline'
                  width='100%'
                  // onClick={handleDownload}
                  sx={styles.cardPreviewButton}
                >
                  Download
                </Button>
              ) : (
                <Button
                  variant='outline'
                  width='100%'
                  onClick={handleContinue}
                  sx={styles.cardPreviewButton}
                >
                  Continue
                </Button>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
