import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { FileListIcon, SourcesActiveIcon, SourcesIcon } from 'assets/icons';
import { ERoutes } from 'components/router/types';
import SidebarNavLink, { ENavLinkTypes } from 'components/sidebar-link';
import { SourcesSidebarLoader } from 'pages/projects/components/sidebar/components/loader';
import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProjectsSourcesQuery } from 'redux/slices';

interface ISourcesSidebarLinkProps {
  expanded: boolean;
}

export const SourcesSidebarLink = ({ expanded }: ISourcesSidebarLinkProps): ReactElement => {
  const params = useParams();
  const { id: projectId = '' } = params;
  const { data: sourcesData, isLoading } = useGetProjectsSourcesQuery(projectId);

  const sourcesLinks = useMemo(
    () =>
      sourcesData?.body?.map(({ id, fileName }) => {
        return (
          <SidebarNavLink
            linkType={ENavLinkTypes.SUB_LINK}
            to={`${ERoutes.PROJECTS}/${projectId}${ERoutes.SOURCES}/${id}`}
            key={id}
          >
            <FileListIcon size='12px' />
            {fileName}
          </SidebarNavLink>
        );
      }),
    [sourcesData, projectId],
  );

  return (
    <SidebarNavLink
      linkType={ENavLinkTypes.NAV_LINK}
      to={`${ERoutes.PROJECTS}/${params.id}${ERoutes.SOURCES}`}
    >
      {({ isActive }: { isActive: boolean }) => (
        <AccordionItem
          sx={{
            width: '100%',
            '& .chakra-collapse': {
              maxHeight: '375px',
              transition: 'max-height .4s .3s ease-out',
            },
            ...(!expanded && {
              '& [aria-expanded=true]~.chakra-collapse': {
                maxHeight: '0',
              },
            }),
          }}
        >
          <AccordionButton>
            <Box className='sources-icon-wrapper'>
              {isActive ? (
                <SourcesActiveIcon width='13.5px' height='16.5px' />
              ) : (
                <SourcesIcon width='13.5px' height='16.5px' />
              )}
            </Box>
            <Box as='span' flex='1' textAlign='left'>
              Sources
            </Box>
            <AccordionIcon width='15px' height='15px' />
          </AccordionButton>
          <AccordionPanel pb={3}>
            {isLoading ? <SourcesSidebarLoader /> : sourcesLinks}
          </AccordionPanel>
        </AccordionItem>
      )}
    </SidebarNavLink>
  );
};
