// import AutoSave from 'components/auto-save';
import WebviewerProvider from 'context/webviewer/webviewer-provider';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getDocuments } from 'redux/selectors';
import { IProjectPageHeaderProps } from 'types';

import SheetContent from './components/sheet-content';

const ProjectSheet = ({ path }: IProjectPageHeaderProps) => {
  const fileEntities = useSelector(getDocuments);

  if (!Object.keys(fileEntities).length) {
    return <Navigate to='/projects/add-project' replace />;
  }

  return (
    <WebviewerProvider>
      <SheetContent path={path} />
      {/* Temporary commented out becuase of webviewer rerendering issue */}
      {/* <AutoSave /> */}
    </WebviewerProvider>
  );
};

export default ProjectSheet;
