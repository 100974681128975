import { Box, Input, Select } from '@chakra-ui/react';
import { WebViewerInstance } from '@pdftron/webviewer';
import ColorPicker from 'components/color-picker';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import React, { SyntheticEvent, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getColumns } from 'redux/selectors';
import { annotationActions, layerActions } from 'redux/slices';
import { drawAnnotations } from 'utils';
import { convertRGBtoHex, getAlphaValue, HEXToRGB } from 'utils/rgb-hex';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableCell = ({ cell }: any) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { instance } = useContext(WebviewerContext);
  const [value, setValue] = useState(cell.value);
  const columnEntitites = useSelector(getColumns);
  const dispatch = useDispatch();

  const handleChange = (event: SyntheticEvent) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleUpdateInputValue = (event: SyntheticEvent) => {
    if (event.type === 'blur' || (event.nativeEvent as KeyboardEvent).key === 'Enter') {
      dispatch(
        annotationActions.modifyAnnotations({
          documentId: 1,
          annotations: [
            {
              id: cell.row.original.annotationId,
              data: {
                [cell.column.id]: value,
                updatedAt: Date.now(),
              },
            },
          ],
        }),
      );
      setEditMode(false);
    }
  };

  const handleUpdateSelectValue = (event: SyntheticEvent) => {
    if (event.type === 'blur') {
      dispatch(
        annotationActions.modifyAnnotations({
          documentId: 1,
          annotations: [
            {
              id: cell.row.original.annotationId,
              data: {
                [cell.column.id]: value,
                updatedAt: Date.now(),
                // updatedAt: dayjs(Date.now()).format('YYYY/MM/DD'),
              },
            },
          ],
        }),
      );
      setEditMode(false);
    }

    if (cell.column.Header === 'Layer' && value) {
      dispatch(
        layerActions.addAnnotationsToLayer({
          documentId: 1,
          layerId: value,
          annotations: [
            {
              id: cell.row.original.id,
              type: cell.row.original.type,
              elementType: cell.row.original.type,
              _isLocked: false,
              _isVisible: cell.row.original._isVisible,
              isReadOnly: false,
              data: { name: cell.row.original.type },
            },
          ],
        }),
      );
    }
  };

  const handleUpdateColorValue = (data: { color: string; alpha: number }) => {
    dispatch(
      annotationActions.modifyAnnotations({
        documentId: 1,
        annotations: [
          {
            id: cell.row.original.annotationId,
            data: {
              ...{
                fillColor: cell.row.original.fillColor,
                strokeColor: cell.row.original.strokeColor,
              },
              [cell.column.id]: HEXToRGB(data.color, data.alpha.toString()),
              updatedAt: Date.now(),
              // updatedAt: dayjs(Date.now()).format('YYYY/MM/DD'),
            },
          },
        ],
      }),
    );

    const annotationToRedraw = {
      id: cell.row.values.annotationId,
      type: cell.row.values.type,
      _isVisible: cell.row.values._isVisible,
      data: {
        ...{ fillColor: cell.row.original.fillColor, strokeColor: cell.row.original.strokeColor },
        [cell.column.id]: HEXToRGB(data.color, data.alpha.toString()),
      },
    };
    drawAnnotations({
      viewerInstance: instance as WebViewerInstance,
      // @ts-ignore
      annotationList: [annotationToRedraw],
      redraw: true,
    });

    setEditMode(false);
  };

  const renderCellInputs = () => {
    if (editMode) {
      switch (columnEntitites[cell.column.id].inputType) {
        case 'text':
          return (
            <Input
              h='full'
              value={value}
              onChange={handleChange}
              onBlur={handleUpdateInputValue}
              onKeyPress={handleUpdateInputValue}
              autoFocus
            />
          );
        case 'select':
          return (
            <Select
              placeholder={cell.column.Header}
              defaultValue={value}
              h='full'
              autoFocus
              onChange={handleChange}
              onBlur={handleUpdateSelectValue}
            >
              {columnEntitites[cell.column.id].options?.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          );
        case 'color':
          return (
            <ColorPicker
              value={
                typeof cell.value === 'string'
                  ? { color: convertRGBtoHex(cell.value), alpha: getAlphaValue(cell.value) }
                  : { color: cell.value.color, alpha: cell.value.alpha }
              }
              onColorChange={(color) => handleUpdateColorValue(color)}
              isTablePicker={true}
            />
          );
        default:
          return;
      }
    }
    return cell.render('Cell');
  };

  const handleCellClick = (event: SyntheticEvent) => {
    if (
      cell.column.id !== 'annotationId' &&
      cell.column.id !== '_isVisible' &&
      !columnEntitites[cell.column.id]?.readonly
    ) {
      if (
        columnEntitites[cell.column.id].inputType === 'color' &&
        (event as unknown as CustomEvent).detail === 1
      ) {
        setEditMode(true);
      }
      if (
        columnEntitites[cell.column.id].inputType !== 'color' &&
        (event as unknown as CustomEvent).detail === 2
      ) {
        setEditMode(true);
      }
    }
  };

  return (
    <Box as='td' {...cell.getCellProps()} sx={styles.bodyCell} onClick={handleCellClick}>
      {renderCellInputs()}
    </Box>
  );
};

export default TableCell;
