import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useCallback, useContext } from 'react';

import { PANELS, ZONES } from '../constants';
import {
  addCircuitToolToPanel,
  addConduitToolToPanel,
  addHomerunBoxToolToPanel,
  addMCCableToolToPanel,
  addPanelToolToPanel,
  addZoneToolToPanel,
  createCircuitTool,
  createConduitTool,
  createHomerunBoxTool,
  createMCCableTool,
  createPanelTool,
  createZoneTool,
} from '../utils';

export const useCreateCustomTools = () => {
  const { instance } = useContext(WebviewerContext);

  return useCallback(() => {
    if (instance) {
      const { documentViewer, Tools, Annotations, annotationManager } = instance.Core;

      /* Start of custom tool creation */

      const NoFlyZoneTool = createZoneTool(instance.Core, ZONES.NO_FLY_ZONE);
      const PreferredZoneTool = createZoneTool(instance.Core, ZONES.PREFERRED_ZONE);

      const CriticalPanelTool = createPanelTool(instance.Core, PANELS.CRITICAL, PANELS.CRITICAL);
      const CriticalHorizontalPanelTool = createPanelTool(
        instance.Core,
        PANELS.CRITICAL_HORIZONTAL,
        PANELS.CRITICAL,
      );
      const NormalPanelTool = createPanelTool(instance.Core, PANELS.NORMAL, PANELS.NORMAL);
      const NormalHorizontalPanelTool = createPanelTool(
        instance.Core,
        PANELS.NORMAL_HORIZONTAL,
        PANELS.NORMAL,
      );

      const MCCableTool = createMCCableTool(instance.Core);
      const ConduitTool = createConduitTool(instance.Core);
      const CircuitTool = createCircuitTool(instance.Core);

      addConduitToolToPanel({
        tool: ConduitTool,
        UI: instance.UI,
      });

      addCircuitToolToPanel({
        tool: CircuitTool,
        UI: instance.UI,
      });

      addMCCableToolToPanel({
        tool: MCCableTool,
        UI: instance.UI,
      });
      addZoneToolToPanel({
        tool: NoFlyZoneTool,
        UI: instance.UI,
        zoneType: ZONES.NO_FLY_ZONE,
      });
      addZoneToolToPanel({
        tool: PreferredZoneTool,
        UI: instance.UI,
        zoneType: ZONES.PREFERRED_ZONE,
      });

      // addPanelToolToPanel({
      //   tool: CriticalPanelTool,
      //   UI: instance.UI,
      //   panelType: PANELS.CRITICAL,
      // });
      // addPanelToolToPanel({
      //   tool: CriticalHorizontalPanelTool,
      //   UI: instance.UI,
      //   panelType: PANELS.CRITICAL_HORIZONTAL,
      // });

      // addPanelToolToPanel({
      //   tool: NormalPanelTool,
      //   UI: instance.UI,
      //   panelType: PANELS.NORMAL,
      // });
      addPanelToolToPanel({
        tool: NormalHorizontalPanelTool,
        UI: instance.UI,
        panelType: PANELS.NORMAL_HORIZONTAL,
      });

      const HomerunBoxTool = createHomerunBoxTool({
        Annotations,
        Tools,
        documentViewer,
        annotationManager,
      });
      addHomerunBoxToolToPanel({ tool: HomerunBoxTool, UI: instance.UI });
      /* End of custom tool creation */
    }
  }, [instance]);
};
