import { Switch, SwitchProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

interface SwitchRHFProps extends SwitchProps {
  name: string;
}

const SwitchRHF = ({ name, ...rest }: SwitchRHFProps) => {
  const { register } = useFormContext();

  return <Switch {...rest} {...register(name)} />;
};

export default SwitchRHF;
