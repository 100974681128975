import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BranchTabState } from 'types/redux';

export const branchTabSlice = createSlice({
  name: 'branchTab',
  initialState: {
    isOpen: false,
    activeTabIndex: 1,
    isTabModalOpen: false,
  },
  reducers: {
    setActiveTabIndex(
      state: BranchTabState,
      action: PayloadAction<{
        activeTabIndex: number;
      }>,
    ) {
      state.activeTabIndex = action.payload.activeTabIndex;
    },
    toggleTab(
      state: BranchTabState,
      action: PayloadAction<{
        isOpen: boolean;
      }>,
    ) {
      state.isOpen = action.payload.isOpen;
    },
    toggleTabModal(
      state: BranchTabState,
      action: PayloadAction<{
        isTabModalOpen: boolean;
      }>,
    ) {
      state.isTabModalOpen = action.payload.isTabModalOpen;
    },
  },
});

export const branchTabActions = branchTabSlice.actions;
