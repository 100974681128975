export const styles = {
  fieldWrapper: {
    width: '255px',
    fontWeight: 500,
    marginRight: '30px',
  },
  label: {
    color: 'black.100',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '9px',
  },
  textField: {
    padding: '9.375px',
    fontSize: '10.5px',
    color: '#6E6D7F',
    width: '100%',
    border: '1px solid #D4D1FF',
  },
};
