import { INTEGER_MESSAGE, REQUIRE_MESSAGE } from 'validation/messages';
import { number, object } from 'yup';

export const SETTINGS_FORM_DEFAULT_VALUES = {
  grouping_distance: 80,
  cable_count: 9,
  grouping_reward: 100,
  turning_penalty: 0,
  iterations: 50,
  preferred_zones_reward: 50,
  width: 10668,
  height: 7620,
  pixels_per_ft: 15.873,
  conduits_spacing: 1.0,
};

const NON_NEGATIVE_REQUIRED_NUMBER = number()
  .typeError('Amount must be a number')
  .required(REQUIRE_MESSAGE)
  .min(0);

export const SETTINGS_FORM_VALIDATION = object({
  grouping_distance: NON_NEGATIVE_REQUIRED_NUMBER,
  cable_count: NON_NEGATIVE_REQUIRED_NUMBER.integer(INTEGER_MESSAGE),
  grouping_reward: NON_NEGATIVE_REQUIRED_NUMBER.integer(INTEGER_MESSAGE),
  turning_penalty: NON_NEGATIVE_REQUIRED_NUMBER.integer(INTEGER_MESSAGE),
  iterations: NON_NEGATIVE_REQUIRED_NUMBER.integer(INTEGER_MESSAGE),
  preferred_zones_reward: NON_NEGATIVE_REQUIRED_NUMBER,
  width: NON_NEGATIVE_REQUIRED_NUMBER.integer(INTEGER_MESSAGE),
  height: NON_NEGATIVE_REQUIRED_NUMBER.integer(INTEGER_MESSAGE),
  pixels_per_ft: NON_NEGATIVE_REQUIRED_NUMBER,
  conduits_spacing: NON_NEGATIVE_REQUIRED_NUMBER,
});

const ANY_STEP = 'any';

export const SETTINGS_FORM_FIELDS = [
  { label: 'Grouping distance:', name: 'grouping_distance', step: ANY_STEP },
  { label: 'Cable Count:', name: 'cable_count', step: 1 },
  { label: 'Grouping', name: 'grouping_reward', step: 1 },
  { label: 'Turning Penalty:', name: 'turning_penalty', step: 1 },
  { label: 'Iterations:', name: 'iterations', step: 1 },
  { label: 'Fly Zones', name: 'preferred_zones_reward', step: ANY_STEP },
  { label: 'Width:', name: 'width', step: 1 },
  { label: 'Height:', name: 'height', step: 1 },
  { label: 'Pixels per ft:', name: 'pixels_per_ft', step: ANY_STEP },
  { label: 'Conduits Spacing:', name: 'conduits_spacing', step: ANY_STEP },
];
