import { Annotation } from 'types/redux';

import { fitScaleForPathPoints } from '../apryse-block/utils';

interface FilterPreferredZonesArguments {
  annotationList: Annotation[];
  docDimensions: {
    width: number;
    height: number;
  };
}

export const filterPreferredZones = ({
  annotationList,
  docDimensions,
}: FilterPreferredZonesArguments) => {
  const documentPreferredZones = annotationList.filter((annotation) =>
    ['Preferred Zone', 'PreferredZone', 'PREFERRED_ZONE'].includes(annotation.elementType),
  );
  const resultObject = {};

  documentPreferredZones.forEach((zone, idx) => {
    const convertedPathPoints = fitScaleForPathPoints({
      docDimensions,
      pathPoints: zone.pathPoints as number[][],
    });

    Object.assign(resultObject, {
      [`zone:${idx + 1}`]: [
        {
          x0: convertedPathPoints[0][0],
          x1: convertedPathPoints[2][0],
          y0: convertedPathPoints[0][1],
          y1: convertedPathPoints[2][1],
        },
      ],
    });
  });
  return resultObject;
};
