export const NoProjectsIcon = () => {
  return (
    <svg
      width='200'
      height='203'
      viewBox='0 0 200 203'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.50077 171.398C11.1168 189.983 23.5348 193.851 32.2656 189.983C49.1507 182.502 93.0123 174.143 138.06 193.791C197.723 219.814 210.417 180.552 191.376 140.837C172.334 101.123 165.88 60.9604 187.831 38.8333C209.782 16.7061 181.057 -17.9087 130.044 18.1229C97.0779 41.4079 72.8843 36.223 59.8925 29.6405C47.6481 23.4366 34.0308 16.9509 21.2728 22.0149C5.79229 28.16 -0.30751 41.4826 23.2454 71.2457C56.6218 113.422 -13.5715 129.74 3.50077 171.398Z'
        fill='#EFEEFF'
      />
      <path
        d='M34.5107 80.8643H153.039V172.02C153.039 172.205 152.965 172.383 152.834 172.514C152.703 172.646 152.525 172.72 152.339 172.72H35.2107C35.0251 172.72 34.847 172.646 34.7158 172.514C34.5845 172.383 34.5107 172.205 34.5107 172.02V80.8643Z'
        fill='#AAA5FB'
      />
      <path d='M123.304 45.1426H68.191L34.5107 80.864H153.039L123.304 45.1426Z' fill='#7368F0' />
      <path
        d='M45.7373 30.854L68.1907 45.1427L34.5105 80.8641L8.99512 61.4724L45.7373 30.854Z'
        fill='#AAA5FB'
      />
      <path
        d='M145.757 30.854L123.304 45.1427L153.039 80.8641L182.499 61.4724L145.757 30.854Z'
        fill='#AAA5FB'
      />
      <path
        d='M82.6703 31.5844C83.3039 31.284 83.7969 30.7503 84.0462 30.0949C84.2955 29.4395 84.2819 28.7131 84.0082 28.0675L73.9073 4.24955C73.7642 3.91218 73.5544 3.60722 73.2905 3.35302C73.0265 3.09882 72.7139 2.90061 72.3714 2.77033C72.0289 2.64005 71.6636 2.58038 71.2974 2.59491C70.9312 2.60944 70.5718 2.69786 70.2407 2.85487L68.2947 3.77761C67.9636 3.93461 67.6676 4.15694 67.4247 4.43124C67.1817 4.70554 66.9967 5.02614 66.8808 5.37377C66.7648 5.7214 66.7204 6.08888 66.7502 6.45411C66.7799 6.81935 66.8832 7.1748 67.0539 7.49909L79.1006 30.3941C79.4271 31.0147 79.9809 31.4851 80.6462 31.7069C81.3114 31.9287 82.0367 31.8848 82.6703 31.5844Z'
        fill='#7368F0'
      />
      <path
        d='M95.3338 29.2755C95.7924 29.2429 96.2206 29.0343 96.529 28.6933C96.8374 28.3524 97.0021 27.9054 96.9887 27.4459L96.4898 10.4896C96.4827 10.2494 96.4271 10.0132 96.3264 9.79501C96.2257 9.57685 96.0819 9.38131 95.9037 9.22014C95.7255 9.05898 95.5165 8.93551 95.2894 8.85717C95.0622 8.77882 94.8216 8.74722 94.5819 8.76425L93.1731 8.86463C92.9335 8.88169 92.6998 8.94701 92.4861 9.05666C92.2724 9.16632 92.0831 9.31805 91.9295 9.50273C91.7759 9.68742 91.6612 9.90125 91.5924 10.1314C91.5235 10.3615 91.5019 10.6032 91.5288 10.8419L93.4366 27.6979C93.4881 28.1548 93.7144 28.5742 94.068 28.8682C94.4216 29.1622 94.8751 29.3082 95.3338 29.2755Z'
        fill='#7368F0'
      />
      <path
        d='M105.11 35.5437C106.372 36.2729 108.142 35.6037 109.125 34.0264L126.564 6.01897C127.605 4.34583 127.359 2.30659 126.021 1.53309L124.156 0.455089C122.818 -0.31855 120.927 0.484767 119.997 2.22231L104.423 31.3076C103.546 32.9457 103.849 34.8143 105.11 35.5437Z'
        fill='#7368F0'
      />
    </svg>
  );
};
