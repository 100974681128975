import { Box, Button, Flex, Heading, Spinner, Text } from '@chakra-ui/react';
import { NoProjectsIcon, ProjectsLoadingErrorIcon } from 'assets/icons';
import { InfiniteScroll } from 'components/infinite-scroll';
import { PageHeader } from 'components/page-header';
import ProjectCard from 'pages/projects/components/project-card/project-card';
import { useGetColumnsCount } from 'pages/projects/hooks/useGetColumnsCount';
import { useGetProjects } from 'pages/projects/hooks/useGetProjects';
import { styles } from 'pages/projects/styles';
import React, { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import AddIcon from '../../assets/icons/AddIcon';
import Card from '../../components/card';

const ProjectsPage = () => {
  const navigate = useNavigate();
  const { setRef, columnsCount } = useGetColumnsCount();
  const { projectsArray, paginationInfo, projectsQuery, fetchMore } = useGetProjects(columnsCount);
  const { isLoading: isProjectsQueryLoading, status, error } = projectsQuery;

  const isProjectPending = status === 'pending';
  const isProjectLoading = isProjectsQueryLoading || isProjectPending;
  const showEmptyState = !projectsArray?.length && !isProjectLoading;
  const showErrorState = !!error;
  const errorStateText = 'Opps, something went wrong!';
  const emptyStateText = 'Add your first projects here';
  const lastItemRef = useRef<any>(null);
  const loader = useMemo(
    () => (
      <Box sx={styles.loaderStyles}>
        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='purple.100' size='xl' />
      </Box>
    ),
    [],
  );
  return (
    <Box sx={styles.projectsWrapper}>
      <PageHeader
        title='Projects'
        headerHeight='60px'
        actionButtons={
          <Button
            variant='primary'
            sx={styles.addProjectBtn}
            onClick={() => navigate('add-project')}
          >
            <Flex alignItems='center'>
              <AddIcon size='12.75px' />
              <Text ml='5.625px'>Add project</Text>
            </Flex>
          </Button>
        }
        withSortingMenu={true}
        withSearch={true}
      />
      <Card ref={setRef} wrapperProps={styles.wrapperStyles}>
        {showEmptyState || showErrorState ? (
          <Flex
            sx={{
              width: '100%',
              height: '100%',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '30px',
            }}
          >
            {error ? <ProjectsLoadingErrorIcon /> : <NoProjectsIcon />}
            <Heading sx={{ fontSize: '18px', fontWeight: 400 }}>
              {error ? errorStateText : emptyStateText}
            </Heading>
          </Flex>
        ) : (
          <InfiniteScroll
            topOffset='450px'
            lastItemRef={lastItemRef}
            fetchMore={fetchMore}
            hasNext={!!paginationInfo?.hasNext}
            loader={loader}
            isLoading={isProjectLoading}
            sx={{
              ...styles.infiniteScrollStyles,
            }}
          >
            {projectsArray?.map((projectData, index) => (
              <ProjectCard
                key={projectData.id}
                projectData={projectData}
                ref={(node: any) => {
                  if (index === projectsArray.length - 1) {
                    lastItemRef.current = node;
                  }
                }}
              />
            ))}
          </InfiniteScroll>
        )}
      </Card>
    </Box>
  );
};

export default ProjectsPage;
