const LogoIcon = ({ onClick, size }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 151.659 30.273'
      role='img'
      width={size}
      height={size}
      onClick={onClick}
      cursor='pointer'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1={13.74}
          y1={30.273}
          x2={5.74}
          gradientUnits='userSpaceOnUse'
        >
          <stop offset={0} stopColor='#8474e1' />
          <stop offset={1} stopColor='#ff7bac' />
        </linearGradient>
        <linearGradient
          id='linear-gradient-2'
          x1={13.74}
          y1={24.432}
          x2={13.74}
          y2={5.841}
          xlinkHref='#linear-gradient'
        />
        <linearGradient
          id='linear-gradient-3'
          x1={13.927}
          y1={27.438}
          x2={13.927}
          y2={2.834}
          xlinkHref='#linear-gradient'
        />
        <style>{'.cls-1{fill:#000}'}</style>
      </defs>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            className='cls-1'
            d='M42.333 22.871V7.121a3.452 3.452 0 01.605-.111c2.2 0 4.4-.044 6.6.026a7.675 7.675 0 017.712 7.479 7.672 7.672 0 01-7.227 8.317c-2.509.221-5.05.039-7.69.039zm3.415-3.182c1.533-.078 2.939-.067 4.322-.24a4.175 4.175 0 003.643-3.8c.24-2.443-.728-4.224-2.721-5.005a11.47 11.47 0 00-5.244-.187zM146.9 22.855h-3.822l-1.366-3.294h-6.691l-1.348 3.3h-3.7l6.524-15.8h3.856c2.165 5.221 4.336 10.457 6.547 15.794zm-10.677-6.394h4.268l-2.143-5.395zM90.122 11.1h2.886l2.182 6.994.183.014 2.075-6.994h3.284c-1.164 3.962-2.488 7.824-3.73 11.743H93.9l-2.2-7.027-.244-.023-2.213 7.013h-3.128l-3.759-11.688h3.331l2.094 6.9.226-.012zm-8.984 11.746h-3.183l-.075-1.318c-.137.047-.263.052-.323.117a4.848 4.848 0 01-5.237 1.342 5.322 5.322 0 01-3.86-4.426 7.136 7.136 0 01.956-5.45 5.257 5.257 0 017.994-.964 3.756 3.756 0 00.445.351l.118-1.449H81.1c.075 3.937.019 7.822.038 11.797zM74.625 20.2a3.084 3.084 0 003.275-3.116 3.128 3.128 0 00-3.08-3.326 3.084 3.084 0 00-3.265 3.131 3.047 3.047 0 003.07 3.311zm38.535-2.157h-8.793A2.274 2.274 0 00106 20.111c1.317.543 2.481.242 3.773-.891h3.193c-.728 2.94-4.047 4.642-7.462 3.872-3.2-.72-5.161-4.038-4.374-7.631a6.028 6.028 0 017.139-4.656c2.856.404 5.344 3.137 4.891 7.238zm-8.706-2.623a18.349 18.349 0 005.318-.106 2.721 2.721 0 00-2.714-1.71 2.592 2.592 0 00-2.604 1.816zM148.5 7.049h3.16v15.775h-3.17c-.245-.953-.251-14.713.01-15.775zM67.7 11.05v3.211c-.718 0-1.373-.008-2.028 0-2.224.034-3.2.978-3.228 3.169-.023 1.769 0 3.538 0 5.392h-3.173V11.139h2.89l.15 1.5a8.33 8.33 0 011.479-1.174 5.38 5.38 0 011.8-.4c.65-.065 1.31-.015 2.11-.015zm50.339 11.826h-3.2c-.007-3.919 0-7.806 0-11.76h2.972l.155 1.713c1.386-2.13 3.356-1.822 5.365-1.712v3.144h-1.222a13.49 13.49 0 00-1.493.036 2.614 2.614 0 00-2.526 2.834c-.051 1.525-.01 3.053-.015 4.579-.005.39-.025.781-.036 1.166z'
          />
          <path
            d='M13.093.75H2.791a1.5 1.5 0 100 1.5h10.3a12.887 12.887 0 010 25.773h-10.3a1.5 1.5 0 100 1.5h10.3a14.387 14.387 0 000-28.773zM1.5 2a.5.5 0 11.5-.5.5.5 0 01-.5.5zm0 27.273a.5.5 0 11.5-.5.5.5 0 01-.5.5z'
            fill='url(#linear-gradient)'
          />
          <path
            d='M13.574 22.182H8.729a1.5 1.5 0 100 1.5h4.845c4.394 0 7.968-3.833 7.968-8.545s-3.574-8.546-7.968-8.546H8.729a1.5 1.5 0 100 1.5h4.845c3.567 0 6.468 3.161 6.468 7.046s-2.901 7.045-6.468 7.045zm-6.136 1.25a.5.5 0 11.5-.5.5.5 0 01-.5.5zm0-15.591a.5.5 0 11.5-.5.5.5 0 01-.5.5z'
            fill='url(#linear-gradient-2)'
          />
          <path
            d='M4.861 24.438a1.5 1.5 0 101.292 2.25h7.384c6.041 0 10.955-5.182 10.955-11.551S19.578 3.584 13.537 3.584H6.153a1.5 1.5 0 100 1.5h7.384c5.213 0 9.455 4.51 9.455 10.053s-4.242 10.051-9.455 10.051H6.153a1.5 1.5 0 00-1.292-.75zm0-19.6a.5.5 0 11.5-.5.5.5 0 01-.5.496zm0 21.6a.5.5 0 11.5-.5.5.5 0 01-.5.5z'
            fill='url(#linear-gradient-3)'
          />
          <path
            className='cls-1'
            d='M2.812 4.88H.486v-1h2.326zM.486 26.484h2.326v-1H.486zm0-3.007h2.326v-1H.486zm0-15.59h2.326v-1H.486zm0 3.118h2.326v-1H.486zm0 3.118h2.326v-1H.486zm0 3.118h2.326v-1H.486zm0 3.118h2.326v-1H.486z'
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
