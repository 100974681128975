import { Box, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';

import BellIcon from '../../assets/icons/BellIcon';
import { styles } from './styles';

const NotificationsMenu = () => {
  return (
    <Menu>
      <MenuButton as={Box} sx={styles.menuBtn}>
        <BellIcon size='15.75px' />
        <Box sx={styles.notificationDot}>5</Box>
      </MenuButton>
      <MenuList>
        <MenuItem>Notifications...</MenuItem>
      </MenuList>
    </Menu>
  );
};
export default NotificationsMenu;
